import React from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Link } from 'react-router-dom';
import { routes } from '@const/routes';
import sidebarIllustration from '@svg/illustrations/uninstall_side.svg';
import Checkbox from '@components/common/checkbox';
import Textarea from '@components/common/textarea';
import Button from '@components/common/button';

const reasonsArray = [
    'Reinstall extension',
    'I no longer need it',
    "I don't know what is this",
    'It was slowing down my browser',
    'I didn’t find what I was looking for',
    'It wasn’t working as expected',
    'It was not provided the expected cashback',
    'Cashback is not counted',
    'I found a better alternative',
    'I stopped shopping as frequently online',
    'I was concerned about privacy and data security',
    'Too intrusive cashback notifications',
    'I’m reinstalling the extension',
    'I didn’t mean to uninstall it',
    'Other'
];

const UninstallExtensionLayout = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.header_wrapper}>
                    <Typography className={styles.title} variant="h3">
                        We’re sorry to see you go...
                    </Typography>
                    <Typography className={styles.description} variant="body1">
                        Your feedback is incredibly valuable — help us improve by filling out the
                        survey below so we can create a better experience next time
                    </Typography>
                </div>
                <div className={styles.sign_wrapper}>
                    <p>Didn’t mean to uninstall?</p>
                    <Link className={styles.link} to={routes.signin}>
                        Reinstall extension
                    </Link>
                </div>
                <div className={styles.content_wrapper}>
                    <Typography className={styles.description}>
                        Why did you uninstall the extension? Please select all that apply
                    </Typography>
                    <div className={styles.checkboxes}>
                        {reasonsArray.map((item, i) => {
                            return (
                                <div key={i} className={styles.checkbox_wrapper}>
                                    <Checkbox />
                                    <Typography>{item}</Typography>
                                </div>
                            );
                        })}
                    </div>
                    <Textarea
                        className={styles.textarea}
                        label="Feedback / Suggestion"
                        full
                        placeholder="Please enter your feedback here"
                    />
                    <Button className={styles.dark_button}>Submit</Button>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.content}>
                    <div className={styles.sign_wrapper}>
                        <p>Didn’t mean to uninstall?</p>
                        <Link className={styles.link} to={routes.signin}>
                            Reinstall extension
                        </Link>
                    </div>
                </div>
                <img className={styles.illustration} src={sidebarIllustration} alt="" />
            </div>
        </div>
    );
};

export default UninstallExtensionLayout;
