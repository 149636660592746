import React from 'react';
import illustration from '@svg/illustrations/success_order_card.svg';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

const SuccessStep = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'order_card.success'
    });
    const navigate = useNavigate();
    return (
        <div className={styles.wrapper}>
            <img src={illustration} alt="" />
            <Typography className={styles.title} variant="body1">
                Success!
            </Typography>
            <p className={styles.description}>
                Your XEPPT Primary card order has been submitted. It will arrive within 9 business
                days.
            </p>
            <Button variant="primary" size="normal" onClick={() => navigate(routes.dashboard)}>
                Done
            </Button>
        </div>
    );
};

export default SuccessStep;
