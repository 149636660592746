import React, { FC, SVGAttributes } from 'react';

const Send: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.9561 12.0054L6.00546 12.053L4.10919 3.82326C3.83762 2.64399 5.0904 1.69302 6.15086 2.27358L21.692 10.7741C22.6636 11.3053 22.6575 12.7041 21.6805 13.2347L6.11162 21.6908C5.04631 22.2688 3.80391 21.3201 4.08288 20.142L6.00232 12.0514"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Send;
