import React, { FC, SVGAttributes } from 'react';

const Copy: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.5 3V12C6.5 12.3978 6.65804 12.7794 6.93934 13.0607C7.22064 13.342 7.60218 13.5 8 13.5H14C14.3978 13.5 14.7794 13.342 15.0607 13.0607C15.342 12.7794 15.5 12.3978 15.5 12V5.4315C15.5 5.23167 15.46 5.03386 15.3825 4.84968C15.305 4.66551 15.1914 4.49868 15.0485 4.359L12.5623 1.9275C12.282 1.65349 11.9057 1.50005 11.5138 1.5H8C7.60218 1.5 7.22064 1.65804 6.93934 1.93934C6.65804 2.22064 6.5 2.60218 6.5 3Z"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 13.5V15C12.5 15.3978 12.342 15.7794 12.0607 16.0607C11.7794 16.342 11.3978 16.5 11 16.5H5C4.60218 16.5 4.22064 16.342 3.93934 16.0607C3.65804 15.7794 3.5 15.3978 3.5 15V6.75C3.5 6.35218 3.65804 5.97064 3.93934 5.68934C4.22064 5.40804 4.60218 5.25 5 5.25H6.5"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Copy;
