import React, { createContext, FC, ReactNode, useMemo, useState } from 'react';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import { TProgressItem } from '@components/progress_bar';
import { EUserType } from '@xeppt/xeppt-sdk/types';
import loginIllustration from '@svg/illustrations/auth/login.svg';
import {
    signupBusinessProgressSteps,
    signupPersonalProgressSteps
} from '@const/progress_bar_steps';

type TSignupContext = {
    steps: TProgressItem[];
    availableStep: TProgressItem | undefined;
    currentStep: TProgressItem | undefined;
    changeStep: (id: string) => void;
    nextStep: () => void;
    prevStep: () => void;
    onChangeType: (value: EUserType) => void;
    type: EUserType;
    currentIllustration: string;
    handleChangeIllustration: (illustration: string) => void;
};

export const SignupContext = createContext<TSignupContext>({} as TSignupContext);

interface IProps {
    children: ReactNode;
}

export const SignupContextProvider: FC<IProps> = ({ children }) => {
    const [signupType, setSignupType] = useState<EUserType>(EUserType.INDIVIDUAL);
    const [currentIllustration, setCurrentIllustration] = useState(loginIllustration);
    const stepsMemo = useMemo(
        () => [
            ...(signupType === EUserType.INDIVIDUAL
                ? signupPersonalProgressSteps
                : signupBusinessProgressSteps),
            undefined
        ],
        [signupType]
    );
    const { currentStep, availableStep, prevStep, nextStep, changeStep } =
        useProgressBar(stepsMemo);
    return (
        <SignupContext.Provider
            value={{
                currentStep,
                availableStep,
                handleChangeIllustration: (val) => setCurrentIllustration(val),
                currentIllustration,
                steps: (signupType === EUserType.INDIVIDUAL
                    ? signupPersonalProgressSteps
                    : signupBusinessProgressSteps
                ).filter((item) => item !== undefined) as TProgressItem[],
                prevStep,
                nextStep,
                changeStep,
                onChangeType: (val) => setSignupType(val),
                type: signupType
            }}>
            {children}
        </SignupContext.Provider>
    );
};
