import React, { FC, ReactNode, useMemo } from 'react';
import { Icon } from '@components/icons';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    children: ReactNode;
    variant?: 'gray' | 'warning' | 'error';
    className?: string;
}

const cx = classNames.bind(styles);

const InfoBlock: FC<IProps> = ({ variant, children, className }) => {
    const icon = useMemo(() => {
        switch (variant) {
            case 'gray':
                return 'info';
            case 'error':
            case 'warning':
                return 'warning';
            default:
                return 'info';
        }
    }, [variant]);

    return (
        <div className={cx(styles.wrapper, variant, className)}>
            <Icon className={styles.icon} name={icon} />
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default InfoBlock;
