import React, { FC, useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { EActivePayBills } from '@enum';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import PayBills from '@sections/pay_bills/pay_bills';
import ManagePayeesSection from '@sections/pay_bills/manage_payees';
import SchedulePaymentsSection from '@sections/pay_bills/schedule_payments';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import { useUserContext } from '@hooks/context/useUserContext';
import scheduleIllustration from '@svg/icons/schedule_payments.svg';
import payIllustration from '@svg/icons/pay_bill.svg';
import payeesIllustration from '@svg/icons/pay_bills_payees.svg';
import { Icon } from '@components/icons';
import { useNotFound } from '@hooks/helpers/useNotFound';

const cx = classNames.bind(styles);

interface IProps {
    activeTab?: EActivePayBills;
}

const PayBillsLayout: FC<IProps> = ({ activeTab }) => {
    const { t } = useTranslation('pay_bills');
    const navigate = useNavigate();
    const { verifiedAction } = useVerificationGuard();
    const [currentTab, setCurrentTab] = useState<EActivePayBills>();
    const { user } = useUserContext();
    const { handleBack } = useNotFound();

    const contentStyle = useMemo(
        () => cx([styles.content_wrapper, { isOpen: !!currentTab }]),
        [currentTab]
    );
    const sidebarStyle = useMemo(
        () => cx([styles.sidebar, { isOpen: !!currentTab, isTabletOpen: !!currentTab }]),
        [currentTab]
    );

    const renderTab = () => {
        switch (activeTab) {
            case EActivePayBills.PAY:
                return <PayBills />;
            case EActivePayBills.MANAGE:
                return <ManagePayeesSection />;
            case EActivePayBills.SCHEDULE:
                return <SchedulePaymentsSection />;
        }
    };

    useEffect(() => {
        if (activeTab) {
            verifiedAction(
                () => {
                    setCurrentTab(activeTab);
                },
                () => {
                    setCurrentTab(undefined);
                    navigate(routes.pay_bills);
                }
            );
        } else {
            setCurrentTab(undefined);
        }
    }, [activeTab, user]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            {currentTab ? (
                <div className={contentStyle}>
                    <div className={styles.content}>{renderTab()}</div>
                </div>
            ) : (
                <div className={sidebarStyle}>
                    <div className={cx(styles.title_wrapper, styles.isOpen)}>
                        <motion.div className={styles.header_wrapper}>
                            <Button
                                className={styles.back}
                                leftIcon="arrow_left"
                                onClick={handleBack}>
                                Back
                            </Button>
                            <Typography variant="h4">{t('title')}</Typography>
                        </motion.div>
                    </div>
                    <div className={styles.divider} />
                    <div
                        className={cx([
                            styles.tab,
                            { isActive: currentTab === EActivePayBills.PAY }
                        ])}
                        onClick={() => navigate(routes.pay_bills_pay)}>
                        <img src={payIllustration} alt="" />
                        <div className={styles.text_wrapper}>
                            <div className={styles.title}>{t('pay_bills')}</div>
                            <div className={styles.description}>
                                Pay your bills, schedule payments for a later date, or set up
                                recurring bill payments
                            </div>
                        </div>
                        <Icon className={styles.icon} name="nav_right" />
                    </div>
                    <div
                        className={cx([
                            styles.tab,
                            { isActive: currentTab === EActivePayBills.MANAGE }
                        ])}
                        onClick={() => navigate(routes.pay_bills_payees)}>
                        <img src={payeesIllustration} alt="" />
                        <div className={styles.text_wrapper}>
                            <div className={styles.title}>{t('manage_payees')}</div>
                            <div className={styles.description}>
                                View and manage your payees list: add new payees, or edit and delete
                                existing ones as needed
                            </div>
                        </div>
                        <Icon className={styles.icon} name="nav_right" />
                    </div>
                    <div
                        className={cx([
                            styles.tab,
                            { isActive: currentTab === EActivePayBills.SCHEDULE }
                        ])}
                        onClick={() => navigate(routes.pay_bills_schedule)}>
                        <img src={scheduleIllustration} alt="" />
                        <div className={styles.text_wrapper}>
                            <div className={styles.title}>{t('schedule_payments')}</div>
                            <div className={styles.description}>
                                Keep track of your scheduled bill payments, view details, and edit,
                                pause, or cancel them as needed
                            </div>
                        </div>
                        <Icon className={styles.icon} name="nav_right" />
                    </div>
                </div>
            )}
        </motion.div>
    );
};

export default PayBillsLayout;
