import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './header';
import styles from './styles.module.scss';
import planetImage from '@svg/planet.svg';
import { routes } from '@const/routes';

const fullWidthPages = [routes.extension_uninstall, routes.extension_install];

const PublicProvider = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className={styles.wrapper}>
            <Header />
            <main className={fullWidthPages.includes(location.pathname) ? '' : styles.content}>
                <Outlet />
            </main>
            {!fullWidthPages.includes(location.pathname) && (
                <div className={styles.planet}>
                    <img src={planetImage} alt="planet illustration" />
                </div>
            )}
        </div>
    );
};

export default PublicProvider;
