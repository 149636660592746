import { Country } from 'country-state-city';
import { EAddressType } from '@xeppt/xeppt-sdk/types/user';
import { ECardStatus, ECardType } from '@xeppt/xeppt-sdk/types/card';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    ETransactionType
} from '@xeppt/xeppt-sdk/types/transaction';
import * as XLSX from 'xlsx';
import moment from 'moment-timezone';
import {
    EEventName,
    ETicketStatus,
    TContact,
    TNotification,
    TSearchContact,
    TTicketMessage
} from '@xeppt/xeppt-sdk/types';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';
import { months } from '@const/dates';
import { EScheduleFrequency, EScheduleStatus } from '@xeppt/xeppt-sdk/types/schedule';
import { toast } from 'react-toastify';

export const getFirstLetter = (string?: string) => {
    if (!string) return '';
    return string.charAt(0);
};

export const getTwoFirstLetters = (string?: string) => {
    if (!string) return '';
    return string.slice(0, 1);
};

export const hideCardNumber = (
    value?: number | string,
    type?: 'full' | 'partial',
    variant?: 'dots' | 'stars'
) => {
    const separator = variant === 'dots' ? '••••' : '****';
    const string = String(value);
    if (type === 'full') {
        return `${separator} ${string.slice(12)}`;
    } else {
        return `${string.slice(0, 4)} ${separator} ${separator} ${string.slice(12)}`;
    }
};

export const capitalizeFirstLetter = (string: string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const hidePhoneNumber = (value: string | number) => {
    const str = String(value);
    return (
        str
            .substring(0, str.length - 4)
            .split('')
            .map(() => '•')
            .join('') + str.substring(str.length - 4, str.length)
    );
};

export const joiningArrayWords = (array: string[]) => {
    if (array.length === 0) {
        return '';
    } else if (array.length === 1) {
        return array[0];
    } else {
        const joinedFormats = array.slice(0, -1).join(', ');
        return `${joinedFormats} or ${array[array.length - 1]}`;
    }
};

export const getBirthDisabledDates = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    return maxDate;
};

export const getDisablePast = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear());

    return maxDate;
};

export const getDisableFeature = (): Date => {
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear());

    return maxDate;
};

export const prettifyGoogleAddress = ({ address_components }: any) => {
    let streetName = '';
    let streetNumber = '';
    const address = {
        country: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: '',
        region: ''
    };

    for (const component of address_components) {
        const componentType = component.types[0];
        switch (componentType) {
            case 'street_number': {
                streetNumber = component.long_name;
                break;
            }
            case 'route': {
                streetName = component.long_name;
                break;
            }
            case 'locality': {
                address['city'] = component.long_name;
                break;
            }
            case 'country': {
                address['country'] = component.short_name;
                break;
            }
            case 'postal_code': {
                address['zipCode'] = component.long_name;
                break;
            }
            case 'administrative_area_level_1': {
                address['region'] += component.long_name;
                break;
            }
            default:
                break;
        }
    }
    if (
        address.country === '' ||
        address.city === '' ||
        streetName === '' ||
        streetNumber === '' ||
        address.zipCode === '' ||
        address.region === ''
    ) {
        return undefined;
    } else {
        return {
            ...address,
            address1: streetNumber + ' ' + streetName
        };
    }
};

export const getAddressFormName = (type: EAddressType) => {
    if (type === EAddressType.BUSINESS) {
        return 'businessAddress';
    } else if (type === EAddressType.REGISTRATION) {
        return 'registrationAddress';
    } else if (type === EAddressType.BILLING) {
        return 'billingAddress';
    } else {
        return 'tradingAddress';
    }
};

export const getAddressName = (type: EAddressType, localization: (val: string) => string) => {
    if (type === EAddressType.BUSINESS) {
        return localization('business');
    } else if (type === EAddressType.REGISTRATION) {
        return localization('registration');
    } else if (type === EAddressType.BILLING) {
        return localization('billing');
    } else {
        return localization('trading');
    }
};

export const prettifyCardStatus = (status: ECardStatus) => {
    switch (status) {
        case ECardStatus.ACTIVE:
            return 'active';
        case ECardStatus.TEMPORARY_CLOSED:
            return 'inactive';
        case ECardStatus.WAITING_FOR_ACTIVATION:
            return 'waiting for activation';
        case ECardStatus.LOST:
            return 'lost';
        case ECardStatus.STOLEN:
            return 'stolen';
        case ECardStatus.OTHER:
            return 'other';
        case ECardStatus.FRAUD:
            return 'fraud';
        case ECardStatus.CUSTOMER_REQUEST:
            return 'requested';
        case ECardStatus.CLOSED:
            return 'closed';
        case ECardStatus.LEGAL_CLOSED:
            return 'legal closed';
        case ECardStatus.FREEZED:
            return 'frozen';
    }
};

export const prettifyCardType = (status?: ECardType) => {
    switch (status) {
        case ECardType.PHYSICAL:
            return 'primary';
        case ECardType.VIRTUAL:
            return 'virtual';
        case ECardType.VIRTUAL_SUPPLEMENTARY:
            return 'Supplementary';
        case ECardType.PHYSICAL_SUPPLEMENTARY:
            return 'Supplementary';
        default:
            return 'primary';
    }
};

export const getTransactionAction = (
    action: ETransactionAction,
    method: ETransactionMethod,
    type: ETransactionType,
    t: (val: string) => string
): string => {
    if (method === ETransactionMethod.E_TRANSFER) {
        if (type === ETransactionType.CREDIT) {
            return t('send_money');
        } else {
            return t('request_money');
        }
    }
    if (method === ETransactionMethod.EPS) {
        if (type === ETransactionType.CREDIT) {
            return t('refund');
        } else {
            return t('load_balance');
        }
    }
    if (method === ETransactionMethod.EFT) {
        if (type === ETransactionType.CREDIT) {
            return t('send_money');
        } else {
            return t('load_balance');
        }
    }
    if (method === ETransactionMethod.PAY_BILL) {
        if (type === ETransactionType.CREDIT) {
            return t('bill_payment');
        }
    }
    if (method === ETransactionMethod.WALLET) {
        if (type === ETransactionType.CREDIT) {
            switch (action) {
                case ETransactionAction.REQUEST:
                    return t('request_money');
                case ETransactionAction.TRANSFER:
                    return t('send_money');
                case ETransactionAction.CARD_DEPOSIT:
                    return t('load_card');
                case ETransactionAction.CARD_SERVICE:
                    return t('request_card');
                case ETransactionAction.REFUND:
                    return t('refund');
            }
        } else {
            switch (action) {
                case ETransactionAction.REQUEST:
                    return t('request_money');
                case ETransactionAction.TRANSFER:
                    return t('receive_money');
                case ETransactionAction.CASHBACK:
                    return t('cashback');
                case ETransactionAction.REFUND:
                    return t('refund');
            }
        }
    }

    return '';
};

export const getTagVariant = (status: ECardStatus) => {
    switch (status) {
        case ECardStatus.ACTIVE:
            return 'success';
        case ECardStatus.OTHER:
        case ECardStatus.WAITING_FOR_ACTIVATION:
        case ECardStatus.CUSTOMER_REQUEST:
            return 'warning';
        case ECardStatus.TEMPORARY_CLOSED:
        case ECardStatus.LOST:
        case ECardStatus.STOLEN:
        case ECardStatus.FRAUD:
        case ECardStatus.CLOSED:
        case ECardStatus.LEGAL_CLOSED:
        case ECardStatus.FREEZED:
            return 'error';
    }
};

export const getTransactionStatus = (status?: ETransactionStatus) => {
    switch (status) {
        case ETransactionStatus.APPROVED:
            return 'success';
        case ETransactionStatus.PENDING:
            return 'warning';
        case ETransactionStatus.DECLINED:
        case ETransactionStatus.CANCELED:
        case ETransactionStatus.ERROR:
            return 'error';
        default:
            return 'success';
    }
};

export const fileToBase64 = (file: File) => {
    const promise = async () =>
        new Promise<string>((res, rej) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                res(reader?.result as string);
            };
            reader.onerror = (error) => {
                rej(`Error reading file: ${error}`);
                console.error('Error reading file: ', error);
            };
        });
    return promise();
};

export const base64ToFile = (base64String: string, fileName: string, mimeType: string) => {
    const byteString = atob(base64String.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
};

export const downloadFileFromBlob = (data: any, name: string) => {
    const a = document.createElement('a');
    a.download = name;
    a.href = URL.createObjectURL(data);
    a.addEventListener('click', () => {
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
};

export const formatDataToXlsx = (data: any[]) => {
    if (!data || data.length === 0) {
        throw new Error('Data array cannot be empty');
    }

    // Collect unique keys and ensure consistent formatting
    const keys: string[] = [];
    data.forEach((item: Record<string, any>) => {
        Object.keys(item).forEach((key) => {
            const formattedKey = camelize(key);
            if (!keys.includes(formattedKey)) {
                keys.push(formattedKey);
            }
        });
    });

    // Calculate column widths dynamically
    const widthArray = keys.map((key) => {
        return {
            wch: Math.max(
                key.length, // Account for header name length
                // Get the widest recorded value for the column
                ...data.map((row) => `${row[key] || ''}`.length)
            )
        };
    });

    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Attach calculated column widths
    ws['!cols'] = widthArray;

    // Create workbook and write it to an XLSX buffer
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    return new Blob([excelBuffer], { type: fileType });
};

export const camelize = (str: string) => {
    return str
        .replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
            return index === 0 ? word.toUpperCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
};

export const prettifyAmount = (amount: string) => {
    const parts = amount.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};

export function getDefaultPhoneCode(tz?: string) {
    const countries = Country.getAllCountries();
    const localTimeZone = tz || moment.tz.guess();
    const defaultCountry = Country.getCountryByCode('CA');

    const localCountry = countries.find((country) =>
        country?.timezones?.find((data) => data.zoneName === localTimeZone)
    );

    return (localCountry?.phonecode as string) || (defaultCountry?.phonecode as string);
}

export function getStartAndEndOfMonth(year: number, month: number) {
    const startOfMonth = new Date(year, month - 1, 1);
    const endOfMonth = new Date(year, month, 0);

    return { startOfMonth, endOfMonth };
}

export const getDateMonthAgo = (): { dateNow: Date; dateMonthAgo: Date } => {
    const dateNow = new Date();
    const dateMonthAgo = new Date();

    // Set the date to a month ago
    dateMonthAgo.setMonth(dateMonthAgo.getMonth() - 1);

    return {
        dateNow,
        dateMonthAgo
    };
};

export const getNotificationString = (notification: TNotification) => {
    switch (notification.event) {
        case EEventName.UserVerified:
            return '';
        case EEventName.EftAccountLinked:
            return notification.payload?.account_name;
        case EEventName.BalanceUpdated:
            return '';
        case EEventName.BillPaymentProcesses:
            return '';
        case EEventName.PaymentReceived:
            return `${notification.payload?.amount} CAD ${notification.payload?.from} (${
                notification.payload?.method &&
                enumTranslate[notification.payload?.method as ETransactionMethod][
                    (localStorage.getItem('language') as 'en' | 'fr') || 'en'
                ]
            })`;
        case EEventName.PaymentStatusChanged:
            return (
                notification.payload?.status &&
                ETransactionStatusTranslate[notification.payload?.status as ETransactionStatus][
                    (localStorage.getItem('language') as 'en' | 'fr') || 'en'
                ]
            );
        case EEventName.CardStatusChanged:
            return capitalizeFirstLetter(
                prettifyCardStatus(notification.payload?.status as ECardStatus)
            );
        case EEventName.CardBalanceLoaded:
            return `${notification.payload?.amount} CAD`;
        case EEventName.EpsCardCreated:
            return `${notification.payload?.cardBrand} ${notification?.payload?.cardNumber}`;
        case EEventName.PaymentProcessed:
            return `${notification.payload?.amount} CAD (${notification.payload?.payee})`;
        default:
            return '';
    }
};

export const groupNotificationsByMonth = (
    notifications: TNotification[]
): { month: string; notifications: TNotification[] }[] => {
    const grouped = notifications.reduce(
        (acc, notification) => {
            const date = new Date(notification.createdAt);
            const monthIndex = date.getMonth(); // Get the month index (0-11)
            const monthName = months[monthIndex];

            if (!acc[monthName]) {
                acc[monthName] = [];
            }

            acc[monthName].push(notification);

            return acc;
        },
        {} as Record<string, TNotification[]>
    );

    return Object.keys(grouped).map((month) => ({
        month,
        notifications: grouped[month]
    }));
};

export const convertContactsListToOptions = (array?: (TContact | TSearchContact)[]) => {
    return (
        array?.map((item: any) => ({
            label: `${item.firstName} ${item.lastName} (${item.email || `+${item.phone}`})`,
            value: item.id
        })) || []
    );
};

export const searchByContacts = (search: string, array?: TContact[]) => {
    let newArray: TContact[] = [];
    if (newArray && search.length > 0) {
        array?.forEach((item) => {
            const str = `${item?.tag || ''} ${item.firstName} ${item.lastName} ${item?.phone || ''} ${item?.email || ''}`;
            if (str.includes(search)) {
                newArray.push(item);
            }
        });
    } else {
        newArray = array || [];
    }
    return newArray;
};

export const excludeExistedContacts = (
    existed?: (TContact | TSearchContact)[],
    exclude?: (TContact | TSearchContact)[]
) => {
    const finalArray: (TContact | TSearchContact)[] = [];

    exclude?.forEach((exc) => {
        if (!existed?.find((item) => item.tag === exc.tag)) {
            finalArray.push(exc);
        }
    });

    return finalArray;
};

export const prettifyFileSize = (sizeInBytes: number): string => {
    if (sizeInBytes === 0) return '0 Bytes';

    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const factor = 1024;
    const unitIndex = Math.floor(Math.log(sizeInBytes) / Math.log(factor));
    const prettySize = sizeInBytes / Math.pow(factor, unitIndex);

    // Ensure two decimal places for readability
    return `${prettySize.toFixed(2)} ${units[unitIndex]}`;
};

interface GroupedMessages {
    date: string; // Formatted as DD.MM.YYYY
    messages: TTicketMessage[];
}

export const groupMessagesByDate = (
    messages: TTicketMessage[],
    userTimezone: string // Pass the user's timezone as a parameter
): GroupedMessages[] => {
    const groupedMessages: Record<string, TTicketMessage[]> = {};

    // Group messages by date (adjusted to user's timezone)
    messages.forEach((msg) => {
        const dateKey = moment(msg.createdAt).tz(userTimezone).format('MM.DD.YYYY'); // Adjust to user's timezone
        if (!groupedMessages[dateKey]) {
            groupedMessages[dateKey] = [];
        }
        groupedMessages[dateKey].push(msg);
    });

    // Convert groupedMessages into an array, sort messages inside each group, and sort groups by date
    return Object.entries(groupedMessages)
        .map(([date, messages]) => ({
            date,
            messages: messages.sort((a, b) => {
                // Sort messages within the group by `created_at` (oldest to newest)
                const timeA = moment(a.createdAt).tz(userTimezone).valueOf();
                const timeB = moment(b.createdAt).tz(userTimezone).valueOf();
                return timeA - timeB; // Ascending order (oldest to newest)
            })
        }))
        .sort((a, b) => {
            // Sort groups by `date` in ascending order (oldest group first)
            //@ts-ignore
            const dateA = moment(a.date, 'MM.DD.YYYY').tz(userTimezone).valueOf();
            const dateB = moment(b.date, 'MM.DD.YYYY').tz(userTimezone).valueOf();
            return dateA - dateB; // Ascending order
        });
};

export const getTicketStatus = (status: ETicketStatus) => {
    switch (status) {
        case ETicketStatus.NEW:
        case ETicketStatus.OPEN:
        case ETicketStatus.HOLD:
            return ETicketStatus.OPEN;
        default:
            return status;
    }
};

export const getTicketStatusVariant = (status: ETicketStatus) => {
    switch (status) {
        case ETicketStatus.OPEN:
            return 'warning';
        case ETicketStatus.PENDING:
            return 'error';
        case ETicketStatus.SOLVED:
            return 'success';
        case ETicketStatus.CLOSED:
            return 'gray';
        default:
            return 'gray';
    }
};

export const highlightSearchText = (text: string, search: string): string => {
    if (!search) return text; // If there's no search query, return the original text

    const regex = new RegExp(`(${search})`, 'gi'); // Case-insensitive match for the search term
    return text.replace(regex, `<i>$1</i>`); // Wrap matching text in <i> tags
};

export const getScheduleStatusVariant = (status: EScheduleStatus) => {
    switch (status) {
        case EScheduleStatus.INACTIVE:
            return 'warning';
        case EScheduleStatus.ACTIVE:
            return 'success';
        case EScheduleStatus.DELETED:
            return 'error';
        case EScheduleStatus.COMPLETE:
            return 'gray';
        default:
            return 'gray';
    }
};

export const getFirstLettersBySpace = (string: string) => {
    return string
        .split(' ')
        .map((word) => word[0])
        .join('')
        .toUpperCase();
};

export function getScheduleDescription(
    startedAt: string | Date,
    frequency: EScheduleFrequency
): string {
    // Parse the start date using moment
    const startDate = moment(startedAt);

    // Get the day of the week (e.g., "Monday")
    const dayOfWeek = startDate.format('dddd');

    // Get the day of the month (e.g., 24)
    const dayOfMonth = startDate.date();

    // Determine the description based on the frequency enum
    switch (frequency) {
        case EScheduleFrequency.DAILY:
            return 'Every day';
        case EScheduleFrequency.WEEKLY:
            return `Every week on ${dayOfWeek}`;
        case EScheduleFrequency.BIWEEKLY:
            return `Every 2 weeks on ${dayOfWeek}`;
        case EScheduleFrequency.MONTHLY:
            return `Day ${dayOfMonth} on every month`;
        case EScheduleFrequency.QUARTERLY:
            return `Day ${dayOfMonth} on every quarter`;
        case EScheduleFrequency.SEMIANNUALLY:
            return `Day ${dayOfMonth} on every 6 months`;
        case EScheduleFrequency.ANNUALLY:
            return `Day ${dayOfMonth} every year`;
        case EScheduleFrequency.ONCE:
            return 'One-time payment';
        default:
            return `One-time schedule on Day ${dayOfMonth}`;
    }
}

export const getScheduleTypeByMethodAndAction = (
    method: ETransactionMethod,
    action: ETransactionAction
) => {
    if (method === ETransactionMethod.WALLET) {
        if (action === ETransactionAction.REQUEST) {
            return ETransactionType.DEBIT;
        } else if (action === ETransactionAction.TRANSFER) {
            return ETransactionType.CREDIT;
        }
    } else if (method === ETransactionMethod.EPS) {
        if (action === ETransactionAction.DEPOSIT) {
            return ETransactionType.DEBIT;
        }
    } else if (method === ETransactionMethod.EFT) {
        if (action === ETransactionAction.DEPOSIT) {
            return ETransactionType.DEBIT;
        } else if (action === ETransactionAction.WITHDRAW) {
            return ETransactionType.CREDIT;
        }
    } else if (method === ETransactionMethod.PAY_BILL) {
        if (action === ETransactionAction.PAYMENT) {
            return ETransactionType.CREDIT;
        }
    }
    return ETransactionType.DEBIT;
};

export function calculateStartDate(
    frequency: EScheduleFrequency,
    nextPayment?: string | Date,
    lastPayment?: string | Date,
    donePaymentCounter: number = 0
): Date {
    // Determine the base date to calculate from
    const baseDate = lastPayment
        ? moment(lastPayment) // If last payment exists, use it as the base
        : moment(nextPayment); // Otherwise, use next payment

    // If no base date is provided, throw an error
    if (!baseDate) {
        throw new Error('Either nextPayment or lastPayment must be provided.');
    }

    // Calculate the frequency interval in days
    const frequencyInterval = getFrequencyIntervalInDays(frequency);

    // Calculate the start date by subtracting the interval * donePaymentCounter
    const startDate = baseDate.clone().subtract(donePaymentCounter * frequencyInterval, 'days');

    return startDate.toDate();
}

/**
 * Get the frequency interval in days based on the schedule frequency.
 *
 * @param frequency - The schedule frequency as an enum of EScheduleFrequency.
 * @returns The interval in days for the given frequency.
 */
export function getFrequencyIntervalInDays(frequency: EScheduleFrequency): number {
    switch (frequency) {
        case EScheduleFrequency.ONCE:
            return 0;
        case EScheduleFrequency.DAILY:
            return 1;
        case EScheduleFrequency.WEEKLY:
            return 7;
        case EScheduleFrequency.BIWEEKLY:
            return 14;
        case EScheduleFrequency.MONTHLY:
            return 30; // Approximate, assumes 30-day months
        case EScheduleFrequency.QUARTERLY:
            return 90; // Approximate, assumes 3 months of 30 days each
        case EScheduleFrequency.SEMIANNUALLY:
            return 180; // Approximate, 6 months * 30 days
        case EScheduleFrequency.ANNUALLY:
            return 365;
        default:
            throw new Error('Invalid frequency provided.');
    }
}

export function calculateEndDate(
    frequency: EScheduleFrequency,
    nextPayment?: string | Date,
    lastPayment?: string | Date,
    remainingPayments: number = 0
): Date {
    // Determine the base date to calculate from
    const baseDate = nextPayment
        ? moment(nextPayment) // If next payment exists, use it as the base
        : moment(lastPayment); // Otherwise, use last payment

    // If no base date is provided, throw an error
    if (!baseDate) {
        throw new Error('Either nextPayment or lastPayment must be provided.');
    }

    // Calculate the frequency interval in days
    const frequencyInterval = getFrequencyIntervalInDays(frequency);

    // Calculate the end date by adding the interval * remainingPayments
    const endDate = baseDate.clone().add(remainingPayments * frequencyInterval, 'days');

    return endDate.toDate();
}

export const copyStringToClipboard = (text: string) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            toast.success('Copied to clipboard');
        })
        .catch((err) => {
            console.error('Failed to copy text: ', err);
        });
};
