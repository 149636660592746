import React, { FC, useCallback, useState } from 'react';
import styles from '@sections/send_and_request/manage_contacts/styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import { apiContactService } from '@api';
import { EContactType, TContact } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useSendAndRequestModals } from '@hooks/modals/useSendAndRequestModals';
import { getFirstLetter } from '@utils/index';
import classNames from 'classnames/bind';
import Tabs from '@components/tabs';
import { EContactViewType, ESendRequestMoneyMethod } from '@enum';
import Input from '@components/common/input';
import Info from '@components/common/info';
import Dropdown from '@components/common/dropdown';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import illustration from '@svg/illustrations/empty_contacts.svg';

interface IProps {
    onAdd: () => void;
    refetchContacts: () => void;
    handleEdit: (data: TContact) => void;
    onChangeTab: (data: EContactViewType) => void;
    activeTab: EContactViewType;
    blockedContacts: TContact[];
    contactList: TContact[];
}

const cx = classNames.bind(styles);

const tabs = [EContactViewType.CONTACTS, EContactViewType.BLOCKED];

const ContactsTable: FC<IProps> = ({
    onAdd,
    contactList,
    refetchContacts,
    handleEdit,
    blockedContacts,
    activeTab,
    onChangeTab
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.manage_contacts'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { handleDeleteContactModal, handleDeleteWalletContact } = useSendAndRequestModals();
    const [search, setSearch] = useState('');

    const { handleRequest: handleUnlockContact } = useApiMutation({
        method: (val: string) => {
            return apiContactService.unblockContact(val);
        },
        onSuccess: () => {
            refetchContacts();
            requestSuccessLocale('unblock_contact');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleBlockContact } = useApiMutation({
        method: (val: string) => {
            return apiContactService.blockContact(val);
        },
        onSuccess: () => {
            refetchContacts();
            requestSuccessLocale('block_contact');
        },
        onError: requestErrorLocale
    });

    const handleSendMoney = (type: EContactType, contactId: string) => {
        if (type === EContactType.INTERAC) {
            navigate(
                `${routes.send_and_request_send}?type=${ESendRequestMoneyMethod.E_TRANSFER}&contactId=${contactId}`
            );
        } else {
            navigate(
                `${routes.send_and_request_send}?type=${ESendRequestMoneyMethod.INTERNAL}&contactId=${contactId}`
            );
        }
    };

    const handleRequestMoney = (type: EContactType, contactId: string) => {
        if (type === EContactType.INTERAC) {
            navigate(
                `${routes.send_and_request_request}?type=${ESendRequestMoneyMethod.E_TRANSFER_REQUEST}&contactId=${contactId}`
            );
        } else {
            navigate(
                `${routes.send_and_request_request}?type=${ESendRequestMoneyMethod.INTERNAL_REQUEST}&contactId=${contactId}`
            );
        }
    };

    const columns = [
        {
            label: t('name'),
            key: 'firstName',
            render: (_: string, data: TContact, index: number) => (
                <div className={styles.user_wrapper}>
                    <div className={cx([styles.user_avatar, { isDark: index % 2 }])}>
                        {getFirstLetter(data.firstName)}
                    </div>
                    {`${data.firstName} ${data.lastName}`}
                </div>
            )
        },
        {
            label: t('email_address'),
            key: 'email'
        },
        {
            label: t('phone'),
            key: 'phone',
            render: (val: string) => (val ? `+1 ${val}` : '-')
        },
        {
            label: t('type'),
            key: 'type',
            render: (value: EContactType) =>
                value === EContactType.INTERAC ? t('e_transfer') : t('xeppt')
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: string, data: TContact) => (
                <div className={styles.table_actions}>
                    {activeTab === EContactViewType.BLOCKED ? (
                        <>
                            <Info maxWidth variant="left" content="Unblock">
                                <Button
                                    leftIcon="block"
                                    onClick={() => handleUnlockContact(data.id)}
                                />
                            </Info>
                            <Info maxWidth variant="left" content="Delete">
                                <Button
                                    leftIcon="delete"
                                    onClick={() => {
                                        if (data.type === EContactType.INTERAC) {
                                            handleDeleteContactModal(
                                                data.id,
                                                `${data.firstName} ${data.lastName}`,
                                                refetchContacts
                                            );
                                        } else if (data.type === EContactType.XEPPT) {
                                            handleDeleteWalletContact(
                                                data.id,
                                                `${data.firstName} ${data.lastName}`,
                                                refetchContacts
                                            );
                                        }
                                    }}
                                />
                            </Info>
                        </>
                    ) : (
                        <Dropdown
                            items={[
                                {
                                    label: 'Send money',
                                    icon: 'send',
                                    onClick: () => handleSendMoney(data.type, data.id)
                                },
                                {
                                    label: 'Request money',
                                    onClick: () => handleRequestMoney(data.type, data.id),
                                    icon: 'request_money'
                                },
                                data.type === EContactType.XEPPT && {
                                    label: 'Block',
                                    icon: 'block',
                                    onClick: () => handleBlockContact(data.id)
                                },
                                data.type === EContactType.INTERAC && {
                                    label: 'Edit',
                                    icon: 'edit',
                                    onClick: () => handleEdit(data)
                                },
                                {
                                    label: 'Delete',
                                    icon: 'delete',
                                    isRed: true,
                                    onClick: () => {
                                        if (data.type === EContactType.INTERAC) {
                                            handleDeleteContactModal(
                                                data.id,
                                                `${data.firstName} ${data.lastName}`,
                                                refetchContacts
                                            );
                                        } else if (data.type === EContactType.XEPPT) {
                                            handleDeleteWalletContact(
                                                data.id,
                                                `${data.firstName} ${data.lastName}`,
                                                refetchContacts
                                            );
                                        }
                                    }
                                }
                            ]}
                        />
                    )}
                </div>
            )
        }
    ];

    const contactsResults = useCallback(() => {
        const checkIsExist = (contact: TContact) => {
            return (
                contact?.tag?.toLowerCase().includes(search.toLowerCase()) ||
                contact?.email?.toLowerCase().includes(search.toLowerCase()) ||
                contact?.phone?.toLowerCase().includes(search.toLowerCase()) ||
                `${contact?.firstName?.toLowerCase()} ${contact?.lastName?.toLowerCase()}`?.includes(
                    search.toLowerCase()
                )
            );
        };
        return ((activeTab === EContactViewType.CONTACTS ? contactList : blockedContacts) || [])
            .filter(checkIsExist)
            .filter((item) => item.type !== EContactType.INTERAC);
    }, [search, blockedContacts, contactList, activeTab]);

    return (
        <>
            <Tabs
                onChange={(val) => onChangeTab(val as EContactViewType)}
                tabs={tabs.map((item) => ({ label: t(item), value: item }))}
                activeTab={activeTab}
                className={styles.tabs}
            />
            <div className={styles.content}>
                <div className={styles.search_wrapper}>
                    <Input
                        value={search}
                        onChange={(val) => setSearch(val)}
                        full
                        className={styles.search}
                        leftIcon="search"
                        rightIcon={search.length > 0 ? 'filed_close' : undefined}
                        onClickRightIcon={() => setSearch('')}
                        placeholder="Search by contact name, email or phone number..."
                    />
                    <Button variant="primary" size="small" onClick={onAdd}>
                        {t('add_contact_label')}
                    </Button>
                </div>
            </div>
            <motion.div {...pageAnimation} className={styles.content}>
                {((activeTab === EContactViewType.CONTACTS && contactList.length === 0) ||
                    (activeTab === EContactViewType.BLOCKED && blockedContacts.length === 0)) && (
                    <div className={styles.empty_table}>
                        <img src={illustration} alt="" />
                        <div className={styles.title}>No contacts yet</div>
                        <div className={styles.description}>
                            Contacts you've added will appear here
                        </div>
                        <Button variant="primary" size="small" onClick={onAdd}>
                            {t('add_contact_label')}
                        </Button>
                    </div>
                )}
                <Table columns={columns} rows={contactsResults()} isActions />
            </motion.div>
        </>
    );
};

export default ContactsTable;
