import React, { useEffect, useMemo } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { SignupContextProvider } from '@context/signup_context';
import Illustrations from '@sections/signup/illustrations';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import { routes } from '@const/routes';
import logo from '@svg/dark_header_logo.svg';

const AuthProvider = () => {
    const location = useLocation();
    const { i18n, t } = useTranslation('sign_in');

    const handleChangeLanguage = () => {
        const lang = i18n.language === 'en' ? 'fr' : 'en';
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const isShowLoginLink = useMemo(() => {
        const loginRoutes = [routes.signup, routes.forgot_password];
        return loginRoutes.includes(location.pathname);
    }, [location]);

    return (
        <SignupContextProvider>
            <div className={styles.wrapper}>
                <div className={styles.content_wrapper}>
                    <div className={styles.content}>
                        <div className={styles.content_header}>
                            <img src={logo} alt="Logo" />
                            <div className={styles.content_actions}>
                                <Link to={isShowLoginLink ? routes.signin : routes.signup}>
                                    {isShowLoginLink ? t('signin') : t('signup')}
                                </Link>
                                <Button className={styles.language} onClick={handleChangeLanguage}>
                                    <Typography
                                        className={i18n.language === 'en' ? styles.isActive : ''}
                                        variant="body2">
                                        EN
                                    </Typography>
                                    |
                                    <Typography
                                        className={i18n.language === 'fr' ? styles.isActive : ''}
                                        variant="body2">
                                        FR
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                        <div className={styles.body}>
                            <Outlet />
                        </div>
                    </div>
                    <div className={styles.content_footer}>
                        <div className={styles.links_wrapper}>
                            <Link to={routes.terms_of_service}>Terms of service</Link>
                            <Link to={routes.privacy_policy}>Privacy policy</Link>
                        </div>
                        <div className={styles.illustration_footer}>© XEPPT 2025</div>
                    </div>
                </div>
                <div className={styles.illustration_wrapper}>
                    <div className={styles.illustration_header}>
                        <div className={styles.links_wrapper}>
                            <Link to={routes.terms_of_service}>Terms of service</Link>
                            <Link to={routes.privacy_policy}>Privacy policy</Link>
                        </div>
                        <Button className={styles.language} onClick={handleChangeLanguage}>
                            <Typography
                                className={i18n.language === 'en' ? styles.isActive : ''}
                                variant="body2">
                                EN
                            </Typography>
                            |
                            <Typography
                                className={i18n.language === 'fr' ? styles.isActive : ''}
                                variant="body2">
                                FR
                            </Typography>
                        </Button>
                    </div>
                    <Illustrations className={styles.illustration} />
                    <div className={styles.illustration_footer}>© XEPPT 2025</div>
                </div>
            </div>
        </SignupContextProvider>
    );
};

export default AuthProvider;
