import React, { FC, SVGAttributes } from 'react';

const LinkCard: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.41797 11.3398C1.41797 10.9256 1.75376 10.5898 2.16797 10.5898H28.8346C29.2488 10.5898 29.5846 10.9256 29.5846 11.3398C29.5846 11.7541 29.2488 12.0898 28.8346 12.0898H2.16797C1.75376 12.0898 1.41797 11.7541 1.41797 11.3398Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.75 22.0059C6.75 21.5916 7.08579 21.2559 7.5 21.2559H10.1667C10.5809 21.2559 10.9167 21.5916 10.9167 22.0059C10.9167 22.4201 10.5809 22.7559 10.1667 22.7559H7.5C7.08579 22.7559 6.75 22.4201 6.75 22.0059Z"
                fill="currentColor"
            />
            <path
                d="M2.91797 10.5272C2.91797 8.18334 3.23314 7.01868 3.88029 6.37882C4.53039 5.73604 5.71531 5.42383 8.08797 5.42383H22.9013C25.281 5.42383 26.4694 5.73612 27.1211 6.37931C27.7694 7.01908 28.0846 8.18327 28.0846 10.5272V19.25C28.0846 19.6642 28.4204 20 28.8346 20C29.2488 20 29.5846 19.6642 29.5846 19.25V10.5272C29.5846 8.19105 29.3065 6.42858 28.1748 5.31168C27.0465 4.1982 25.2683 3.92383 22.9013 3.92383H8.08797C5.7273 3.92383 3.95222 4.19829 2.82565 5.31217C1.69613 6.42898 1.41797 8.19099 1.41797 10.5272V21.4872C1.41797 23.823 1.69599 25.5857 2.82616 26.701C3.95336 27.8134 5.72883 28.0837 8.08872 28.0772H15.2513C15.6656 28.0772 16.0013 27.7414 16.0013 27.3272C16.0013 26.9129 15.6656 26.5772 15.2513 26.5772L8.08797 26.5772L8.08586 26.5772C5.71309 26.5838 4.52903 26.2741 3.87978 25.6333C3.23328 24.9953 2.91797 23.8313 2.91797 21.4872V10.5272Z"
                fill="currentColor"
            />
            <path
                d="M16 22.0059C16 21.5916 15.6642 21.2559 15.25 21.2559H13.5C13.0858 21.2559 12.75 21.5916 12.75 22.0059C12.75 22.4201 13.0858 22.7559 13.5 22.7559H15.25C15.6642 22.7559 16 22.4201 16 22.0059Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.5 27C22.5 26.5858 22.8358 26.25 23.25 26.25H26.75C27.1642 26.25 27.5 26.5858 27.5 27C27.5 27.4142 27.1642 27.75 26.75 27.75H23.25C22.8358 27.75 22.5 27.4142 22.5 27Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.668 24.25C21.1492 24.25 19.918 25.4812 19.918 27C19.918 28.5188 21.1492 29.75 22.668 29.75H23.2513C23.6655 29.75 24.0013 30.0858 24.0013 30.5C24.0013 30.9142 23.6655 31.25 23.2513 31.25H22.668C20.3208 31.25 18.418 29.3472 18.418 27C18.418 24.6528 20.3208 22.75 22.668 22.75H23.2513C23.6655 22.75 24.0013 23.0858 24.0013 23.5C24.0013 23.9142 23.6655 24.25 23.2513 24.25H22.668Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 23.5C26 23.0858 26.3358 22.75 26.75 22.75H27.3333C29.6805 22.75 31.5833 24.6528 31.5833 27C31.5833 29.3472 29.6805 31.25 27.3333 31.25H26.75C26.3358 31.25 26 30.9142 26 30.5C26 30.0858 26.3358 29.75 26.75 29.75H27.3333C28.8521 29.75 30.0833 28.5188 30.0833 27C30.0833 25.4812 28.8521 24.25 27.3333 24.25H26.75C26.3358 24.25 26 23.9142 26 23.5Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default LinkCard;
