import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useLocales } from '@hooks/helpers/useLocales';
import Address from '@components/address';
import Input from '@components/common/input';
import Select from '@components/common/select';
import FormField from '@components/form_field';
import { useFormContext, useWatch } from 'react-hook-form';
import { ECardType, TCardLite } from '@xeppt/xeppt-sdk/types/card';
import { ECardStatus } from '@xeppt/xeppt-sdk/types';
import { hideCardNumber } from '@utils/index';

const ShippingAddress = () => {
    return (
        <div className={styles.wrapper}>
            <Typography variant="body1" className={styles.description}>
                Please enter your shipping address
            </Typography>
            <Address label="shipping_address" name="shippingAddress" />
        </div>
    );
};

export default ShippingAddress;
