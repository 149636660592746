import React from 'react';
import Typography from '@components/common/typography';
import CardsSection from '@sections/xeppt_cards/cards';
import styles from './styles.module.scss';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Button from '@components/common/button';
import { routes } from '@const/routes';
import { useNotFound } from '@hooks/helpers/useNotFound';

const XepptCardsLayout = () => {
    const { handleBack } = useNotFound();
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <Button
                    className={styles.back}
                    leftIcon="arrow_left"
                    onClick={() => {
                        handleBack(routes.send_and_request);
                    }}>
                    Back
                </Button>
                <Typography variant="h4">XEPPT cards</Typography>
            </div>
            <div className={styles.content}>
                <CardsSection />
            </div>
        </motion.div>
    );
};

export default XepptCardsLayout;
