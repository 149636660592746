import React, { FC, SVGAttributes } from 'react';

const AccountBalance: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 16.5H10"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.833 15.5H18.231C16.446 15.5 15 14.157 15 12.5C15 10.843 16.447 9.5 18.23 9.5H20.833C20.917 9.5 20.958 9.5 20.993 9.502C21.533 9.535 21.963 9.934 21.998 10.435C22 10.467 22 10.506 22 10.583V14.417C22 14.494 22 14.533 21.998 14.565C21.962 15.066 21.533 15.465 20.993 15.498C20.959 15.5 20.917 15.5 20.833 15.5Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M20.965 15.5C20.887 17.372 20.637 18.52 19.828 19.328C18.657 20.5 16.771 20.5 13 20.5H10C6.229 20.5 4.343 20.5 3.172 19.328C2.001 18.156 2 16.271 2 12.5C2 8.729 2 6.843 3.172 5.672C4.344 4.501 6.229 4.5 10 4.5H13C16.771 4.5 18.657 4.5 19.828 5.672C20.637 6.48 20.888 7.628 20.965 9.5"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M17.9912 12.5H18.0012"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AccountBalance;
