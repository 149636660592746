import React, { FC } from 'react';
import illustration from '@svg/illustrations/success_link_card_or_bank.svg';
import illustrationApprove from '@svg/illustrations/success_link_card_or_bank_waiting.svg';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Loader from '@components/loader';
import { useLocales } from '@hooks/helpers/useLocales';
import Button from '@components/common/button';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';

interface IProps {
    isLoading: boolean;
    isManual: boolean;
    changeStep: (val: string) => void;
}

const ThirdStepLinkBank: FC<IProps> = ({ isLoading, changeStep, isManual }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank.step_3'
    });
    const { submitLocale } = useLocales();
    const navigate = useNavigate();
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            {isManual ? (
                <>
                    <img src={illustrationApprove} alt="illustration" />
                    <p className={styles.title}>Bank verification pending</p>
                </>
            ) : !isLoading ? (
                <>
                    <img src={illustration} alt="illustration" />
                    <p className={styles.title}>Success!</p>
                </>
            ) : (
                <Loader />
            )}
            {isManual ? (
                <Typography className={styles.description_pending} variant="h4">
                    Your bank details are being reviewed. Please allow 3-5 business days for
                    verification to complete
                </Typography>
            ) : (
                <Typography className={styles.description} variant="h4">
                    {isLoading ? t('loading') : 'Your bank account has been successfully linked'}
                </Typography>
            )}
            {!isLoading && (
                <div className={styles.actions}>
                    <Button
                        size="normal"
                        variant="outlined-dark"
                        onClick={() => {
                            navigate(routes.dashboard);
                            changeStep('1');
                        }}>
                        {submitLocale('done')}
                    </Button>
                    <Button
                        size="normal"
                        variant="primary"
                        onClick={() => {
                            changeStep('1');
                        }}>
                        Link another bank account
                    </Button>
                </div>
            )}
        </motion.div>
    );
};

export default ThirdStepLinkBank;
