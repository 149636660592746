import React, { FC, SVGAttributes } from 'react';

const At: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.6 8.40001V12.9C15.6 13.6161 15.8845 14.3028 16.3908 14.8092C16.8972 15.3155 17.5839 15.6 18.3 15.6C19.0161 15.6 19.7029 15.3155 20.2092 14.8092C20.7155 14.3028 21 13.6161 21 12.9V12C21 9.97258 20.3155 8.00455 19.0573 6.41476C17.7991 4.82497 16.041 3.70657 14.0678 3.24077C12.0946 2.77496 10.0219 2.98903 8.1856 3.8483C6.34927 4.70757 4.85686 6.1617 3.95017 7.97509C3.04347 9.78847 2.77562 11.8549 3.19 13.8395C3.60438 15.8241 4.67672 17.6107 6.23328 18.9098C7.78984 20.2088 9.73943 20.9443 11.7662 20.997C13.7929 21.0496 15.7781 20.4165 17.4 19.2M15.6 12C15.6 12.4728 15.5069 12.9409 15.326 13.3777C15.1451 13.8144 14.8799 14.2113 14.5456 14.5456C14.2113 14.8799 13.8144 15.1451 13.3777 15.326C12.9409 15.5069 12.4728 15.6 12 15.6C11.5273 15.6 11.0591 15.5069 10.6223 15.326C10.1856 15.1451 9.78872 14.8799 9.45443 14.5456C9.12013 14.2113 8.85496 13.8144 8.67404 13.3777C8.49313 12.9409 8.40001 12.4728 8.40001 12C8.40001 11.0452 8.77929 10.1296 9.45443 9.45442C10.1296 8.77929 11.0452 8.40001 12 8.40001C12.9548 8.40001 13.8705 8.77929 14.5456 9.45442C15.2207 10.1296 15.6 11.0452 15.6 12Z"
                stroke="#282828"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default At;
