import useSignupContext from '@hooks/context/useSignupContext';
import { FC } from 'react';

interface IProps {
    className?: string;
}

const Illustrations: FC<IProps> = ({ className }) => {
    const { currentIllustration } = useSignupContext();
    return <img className={className} src={currentIllustration} alt="illustration" />;
};

export default Illustrations;
