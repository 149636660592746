import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import InfoBlock from '@components/common/info_block';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { ESendRequestMoneyMethod } from '@enum';
import { useFormContext, useWatch } from 'react-hook-form';
import { TContact, TPayee } from '@xeppt/xeppt-sdk/types';
import { useUserContext } from '@hooks/context/useUserContext';
import moment from 'moment-timezone';
import { getFrequencyList } from '@utils/billing';
import { useTranslation } from 'react-i18next';
import { EScheduleFrequency } from '@xeppt/xeppt-sdk/types/schedule';

interface IProps {
    onSubmit: () => void;
    onPrev: () => void;
    isLoading: boolean;
    isEdit?: boolean;
    method: ESendRequestMoneyMethod;
    contactList?: TContact[];
    payeesList?: TPayee[];
    isRequest?: boolean;
}

const Summary: FC<IProps> = ({
    onSubmit,
    onPrev,
    isLoading,
    isEdit,
    method,
    contactList,
    isRequest,
    payeesList
}) => {
    const { submitLocale } = useLocales();
    const { getValues, watch, control } = useFormContext();
    const { account } = useUserContext();
    const isSchedule = useWatch({ name: 'isSchedule', control });
    const { t: frequencyLocale } = useTranslation('sections', {
        keyPrefix: 'send_and_request.schedule_payments.payment_details'
    });

    const isPayBills = useMemo(() => method === ESendRequestMoneyMethod.PAY_BILLS, [method]);

    const isBank = useMemo(
        () =>
            method === ESendRequestMoneyMethod.BANK ||
            method === ESendRequestMoneyMethod.BANK_REQUEST,
        [method]
    );

    const data = useMemo(() => {
        const values = getValues();
        const foundContact = contactList?.find((item) => item.id === values.contact);
        const foundPayee = payeesList?.find((item) => item.id === values.account);
        const foundAccount = account?.bankAccounts?.find((item) => item.id === values.account);
        const foundCard = account?.epsCards?.find((item) => item.id === values.account);
        return {
            [ESendRequestMoneyMethod.INTERNAL]: [
                {
                    label: 'Recipient',
                    value: `${foundContact?.firstName} ${foundContact?.lastName}`
                },
                foundContact?.email && {
                    label: 'Recipient’s email',
                    value: foundContact?.email
                }
            ],
            [ESendRequestMoneyMethod.E_TRANSFER]: [
                {
                    label: 'Recipient',
                    value: `${foundContact?.firstName} ${foundContact?.lastName}`
                },
                foundContact?.email && {
                    label: 'Recipient’s email',
                    value: foundContact?.email
                },
                foundContact?.phone && {
                    label: 'Recipient’s phone',
                    value: `+${foundContact?.phone}`
                },
                values.securityQuestion && {
                    label: 'Security question',
                    value: values.securityQuestion
                },
                values.securityAnswer && {
                    label: 'Security answer',
                    value: values.securityAnswer
                }
            ],
            [ESendRequestMoneyMethod.BANK]: [
                {
                    label: 'Bank account name',
                    value: foundAccount?.accountName
                },
                {
                    label: 'Account number',
                    value: foundAccount?.accountNumber
                },
                {
                    label: 'Institution name',
                    value: foundAccount?.institutionName
                }
            ],
            [ESendRequestMoneyMethod.EPS_REQUEST]: [
                {
                    label: 'Cardholder',
                    value: foundCard?.cardHolderName
                },
                {
                    label: 'Card brand',
                    value: foundCard?.cardBrand
                },
                {
                    label: 'Card number',
                    value: foundCard?.cardNumber
                }
            ],
            [ESendRequestMoneyMethod.INTERNAL_REQUEST]: [
                {
                    label: 'Recipient',
                    value: `${foundContact?.firstName} ${foundContact?.lastName}`
                },
                foundContact?.email && {
                    label: 'Recipient’s email',
                    value: foundContact?.email
                }
            ],
            [ESendRequestMoneyMethod.BANK_REQUEST]: [
                {
                    label: 'Bank account name',
                    value: foundAccount?.accountName
                },
                {
                    label: 'Account number',
                    value: foundAccount?.accountNumber
                },
                {
                    label: 'Institution name',
                    value: foundAccount?.institutionName
                }
            ],
            [ESendRequestMoneyMethod.E_TRANSFER_REQUEST]: [
                {
                    label: 'Recipient',
                    value: `${foundContact?.firstName} ${foundContact?.lastName}`
                },
                foundContact?.email && {
                    label: 'Recipient’s email',
                    value: foundContact?.email
                },
                foundContact?.phone && {
                    label: 'Recipient’s phone',
                    value: `+${foundContact?.phone}`
                },
                values.securityQuestion && {
                    label: 'Security question',
                    value: values.securityQuestion
                },
                values.securityAnswer && {
                    label: 'Security answer',
                    value: values.securityAnswer
                }
            ],
            [ESendRequestMoneyMethod.PAY_BILLS]: [
                {
                    label: 'Payee name',
                    value: foundPayee?.name
                },
                {
                    label: 'Payee account number',
                    value: foundPayee?.accountNumber
                },
                {
                    label: 'Payee nickname',
                    value: foundPayee?.alias
                }
            ]
        };
    }, [watch(), contactList, payeesList, account, method]);

    const scheduleData = useMemo(() => {
        const values = getValues();
        return {
            title: 'Schedule details',
            rows: [
                {
                    label: 'Start date',
                    value: moment(values.nextPaymentAt).format('D MMMM YYYY')
                },
                {
                    label: 'Frequency',
                    value: `${
                        getFrequencyList(frequencyLocale).find(
                            (item) => item.value === values.paymentFrequency
                        )?.label
                    } ${values.paymentFrequency === EScheduleFrequency.ONCE || values.paymentFrequency === EScheduleFrequency.DAILY ? '' : `(every ${moment().format('dddd')})`}`
                },
                {
                    label: 'End date',
                    value: values.isInfinitePayment
                        ? 'Indefinite (until cancelled)'
                        : moment(values.paymentsEndDate).format('D MMMM YYYY')
                }
            ]
        };
    }, [watch()]);

    const paymentData = useMemo(() => {
        const values = getValues();
        return {
            title: 'Payment details',
            rows: [
                {
                    label: isRequest ? 'Amount you request' : 'Amount you send',
                    value: `${values.amount} CAD`,
                    isBold: true
                },
                {
                    label: 'Total fees (included)',
                    value: '0 СAD'
                },
                {
                    label: isRequest
                        ? 'You receive'
                        : `${isBank ? 'Bank' : isPayBills ? 'Payee' : data?.[method]?.[0]?.value} gets`,
                    value: `${values.amount} CAD`,
                    isBold: true
                },
                {
                    label: 'Message (optional)',
                    value: values.message
                }
            ]
        };
    }, [watch()]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.title}>Review and confirm</div>
                <div className={styles.description}>
                    Please review your payment details and make sure the recipient is correct
                </div>
            </div>
            <div className={styles.summary}>
                {paymentData && (
                    <div className={styles.block}>
                        <div className={styles.title}>{paymentData.title}</div>
                        <div className={styles.content}>
                            {paymentData.rows.map((item: any, i: number) => {
                                if (!item) return null;
                                return (
                                    <div className={styles.row} key={i}>
                                        {item.label}{' '}
                                        <span className={item.isBold ? styles.bold : ''}>
                                            {item.value || '-'}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                <div className={styles.divider} />
                {data?.[method] && (
                    <div className={styles.block}>
                        <div className={styles.title}>
                            {method === ESendRequestMoneyMethod.BANK
                                ? 'Bank account details'
                                : method === ESendRequestMoneyMethod.EPS_REQUEST
                                  ? 'Card details'
                                  : 'Recipient details'}
                        </div>
                        <div className={styles.content}>
                            {data?.[method]?.map((item: any, i: number) => {
                                if (!item) return null;
                                return (
                                    <div className={styles.row} key={i}>
                                        {item.label}{' '}
                                        <span className={item.isBold ? styles.bold : ''}>
                                            {item.value || '-'}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {isSchedule && <div className={styles.divider} />}
                {isSchedule && scheduleData && (
                    <div className={styles.block}>
                        <div className={styles.title}>{scheduleData.title}</div>
                        <div className={styles.content}>
                            {scheduleData.rows.map((item: any, i: number) => {
                                if (!item) return null;
                                return (
                                    <div className={styles.row} key={i}>
                                        {item.label}{' '}
                                        <span className={item.isBold ? styles.bold : ''}>
                                            {item.value || '-'}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            <InfoBlock variant="gray">
                By clicking{' '}
                {isSchedule ? '"Schedule payment"' : isRequest ? '"Request money"' : '"Send money"'}{' '}
                below, you agree that you have reviewed and confirmed the information above.
            </InfoBlock>
            <div className={styles.actions}>
                <Button
                    leftIcon="arrow_left"
                    variant="outlined-dark"
                    size="normal"
                    onClick={onPrev}>
                    {submitLocale('previous')}
                </Button>
                <Button variant="primary" size="normal" onClick={onSubmit} isLoading={isLoading}>
                    {isSchedule
                        ? isEdit
                            ? 'Update schedule'
                            : 'Schedule payment'
                        : isRequest
                          ? 'Request money'
                          : 'Send money'}
                </Button>
            </div>
        </motion.div>
    );
};

export default Summary;
