import React, { useEffect } from 'react';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import welcomeIllustration from '@svg/illustrations/registration_congrats.svg';
import Typography from '@components/common/typography';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useFormContext } from 'react-hook-form';
import useSignupContext from '@hooks/context/useSignupContext';
import { EUserType } from '@xeppt/xeppt-sdk/types';
import { defaultPersonalSignUpData } from '@const/default_form_data';
import successIllustration from '@svg/illustrations/auth/succes.svg';

const CongratsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.congrats'
    });
    const navigate = useNavigate();
    const { reset } = useFormContext();
    const { type, handleChangeIllustration } = useSignupContext();

    useEffect(() => {
        handleChangeIllustration(successIllustration);
    }, []);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <img src={welcomeIllustration} alt="welcome illustration" />
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body1">
                {t('description')}
            </Typography>
            <Button
                onClick={() => {
                    navigate(routes.dashboard);
                    reset(
                        type === EUserType.INDIVIDUAL
                            ? defaultPersonalSignUpData
                            : defaultPersonalSignUpData
                    );
                }}
                size="normal"
                variant="primary">
                {t('go_to_dashboard')}
            </Button>
        </motion.div>
    );
};

export default CongratsSection;
