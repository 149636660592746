import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useLocales } from '@hooks/helpers/useLocales';
import Address from '@components/address';
import Input from '@components/common/input';
import Select from '@components/common/select';
import FormField from '@components/form_field';
import { useFormContext, useWatch } from 'react-hook-form';
import { ECardType, TCardLite } from '@xeppt/xeppt-sdk/types/card';
import { ECardStatus } from '@xeppt/xeppt-sdk/types';
import { hideCardNumber } from '@utils/index';

interface IProps {
    cards: TCardLite[];
    type?: ECardType;
}

const CardDetails: FC<IProps> = ({ cards, type }) => {
    const { labelLocale, validationLocale } = useLocales();

    return (
        <div className={styles.wrapper}>
            <p
                className={`${styles.description} ${type === ECardType.PHYSICAL ? styles.isPhysical : ''}`}>
                {type === ECardType.PHYSICAL_SUPPLEMENTARY
                    ? 'Please choose the primary card and confirm the name which will be printed on your supplementary card'
                    : 'Please confirm the name which will be printed on your card'}
            </p>
            {type === ECardType.PHYSICAL_SUPPLEMENTARY && (
                <FormField<string | number>
                    name="cardId"
                    renderComponent={(props) => {
                        return (
                            <Select
                                {...props}
                                full
                                label={labelLocale('primary_card')}
                                items={
                                    cards
                                        ?.filter(
                                            (item) =>
                                                item.type === ECardType.PHYSICAL &&
                                                item.status === ECardStatus.ACTIVE
                                        )
                                        .map((item) => ({
                                            value: item.id,
                                            label: `${item.cardHolder} ${hideCardNumber(item.cardNumber)} $${item.balance}CAD`
                                        })) || []
                                }
                            />
                        );
                    }}
                    rules={{
                        required: {
                            value: true,
                            message: validationLocale('main_card')
                        }
                    }}
                />
            )}
            <div className={styles.row}>
                <FormField
                    name="firstName"
                    renderComponent={(props) => (
                        <Input {...props} full label={labelLocale('first_name')} />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: validationLocale('first_name')
                        }
                    }}
                />
                <FormField
                    name="lastName"
                    renderComponent={(props) => (
                        <Input {...props} full label={labelLocale('last_name')} />
                    )}
                    rules={{
                        required: {
                            value: true,
                            message: validationLocale('last_name')
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default CardDetails;
