import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiPayBillService } from '@api';
import Pagination from '@components/pagination';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { formatDateForMoment } from '@utils/date';
import Info from '@components/common/info';
import { TPayee } from '@xeppt/xeppt-sdk/types';
import { usePayBillsModal } from '@hooks/modals/usePayBillsModal';
import Input from '@components/common/input';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import emptyIllustration from '@svg/illustrations/empty_payees.svg';

interface IProps {
    onAdd: () => void;
    refetchPayees: () => void;
    payeesList: TPayee[];
}

const PayeesTable: FC<IProps> = ({ payeesList, refetchPayees, onAdd }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.manage_payees'
    });
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const { handleDeletePayeeModal } = usePayBillsModal();

    const columns = [
        {
            label: 'Payee name',
            key: 'name',
            width: 250
        },
        {
            label: 'Nickname',
            key: 'alias'
        },
        {
            label: 'Account number',
            key: 'accountNumber'
        },
        {
            label: 'Scheduled payments',
            key: 'scheduleCount'
        },
        {
            label: t('last_payment'),
            key: 'lastPayment',
            render: (value?: string) =>
                value ? moment(formatDateForMoment(value)).format('MM/DD/YYYY') : '-'
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: undefined, data: TPayee) => (
                <div className={styles.table_actions}>
                    <Info maxWidth variant="left" content="Pay a bill">
                        <Button
                            leftIcon="pay_bill"
                            onClick={() => navigate(routes.pay_bills_pay + `?payee=${data.id}`)}
                        />
                    </Info>
                    <Info maxWidth variant="left" content="Edit">
                        <Button
                            leftIcon="edit"
                            onClick={() => navigate(routes.pay_bills_payees + `?payee=${data.id}`)}
                        />
                    </Info>
                    <Info maxWidth variant="left" content="Delete">
                        <Button
                            leftIcon="trash"
                            className={styles.delete}
                            onClick={() =>
                                handleDeletePayeeModal(data.id, data.name, refetchPayees)
                            }
                        />
                    </Info>
                </div>
            )
        }
    ];

    const searchedPayees = useMemo(() => {
        return payeesList.filter((item) => {
            return (
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item?.alias?.toLowerCase()?.includes(search.toLowerCase()) ||
                item.accountNumber.toLowerCase().includes(search.toLowerCase())
            );
        });
    }, [search, payeesList]);

    return (
        <motion.div {...pageAnimation} className={styles.content}>
            <div className={styles.form_row}>
                <Input
                    leftIcon="search"
                    className={styles.search}
                    value={search}
                    onChange={(val) => setSearch(val)}
                    full
                    placeholder="Search by payee name, nickname or account number..."
                />
                <Button variant="primary" size="small" onClick={onAdd}>
                    Add new payee
                </Button>
            </div>
            {searchedPayees.length === 0 ? (
                <div className={styles.empty}>
                    <img src={emptyIllustration} alt="empty payees" />
                    <p className={styles.title}>No payees yet</p>
                    <p className={styles.description}>Payees you've added will appear here</p>
                    <Button variant="primary" size="normal" onClick={onAdd}>
                        Add new payee
                    </Button>
                </div>
            ) : (
                <Table
                    columns={columns}
                    rows={searchedPayees || []}
                    isActions
                    emptyDescription={t('empty_payees')}
                />
            )}
        </motion.div>
    );
};

export default PayeesTable;
