import React from 'react';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import useModalContext from '@hooks/context/useModalContext';

const DeleteContactModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'delete_contact'
    });
    const { modalData } = useModalContext();
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                Delete contact
            </Typography>
            <div className={styles.description}>
                Are you sure you want to delete {modalData?.name}?
            </div>
        </div>
    );
};

export default DeleteContactModal;
