import React, { FC } from 'react';
import { TBankAccount } from '@xeppt/xeppt-sdk/types';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';

interface IProps {
    account: TBankAccount;
}

const BankSection: FC<IProps> = ({ account }) => {
    return (
        <div className={styles.wrapper}>
            <Icon className={styles.icon} name="filled_bank" width={56} height={56} />
            <Typography variant="body2" className={styles.title}>
                {account.institutionName}
            </Typography>
            <Typography variant="body2" className={styles.description}>
                {account.accountName} {account.accountNumber}
            </Typography>
        </div>
    );
};

export default BankSection;
