import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { Icon } from '@components/icons';
import Status from '@components/common/status';
import moment from 'moment-timezone';
import { IModalComponentProps } from '@context/modal_context';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    ETransactionType,
    TTransaction
} from '@xeppt/xeppt-sdk/types';
import { currencies } from '@const/currency';
import {
    copyStringToClipboard,
    getFirstLettersBySpace,
    getTransactionStatus,
    prettifyAmount
} from '@utils/index';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';
import { useTranslation } from 'react-i18next';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiAccountService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import { useHelpCenterModals } from '@hooks/modals/useHelpCenterModals';

const ViewTransactionModal: FC<
    IModalComponentProps<{ transaction: TTransaction; refetchTransactions?: () => void }>
> = ({ onClose, modalData }) => {
    const { t, i18n } = useTranslation('transaction_details');
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { refetchAccount } = useUserContext();
    const { handleOpenSubmitRequestModal } = useHelpCenterModals();

    if (!modalData?.transaction) return null;

    const { transaction, refetchTransactions } = modalData;

    const isTransactionRequest = useMemo(
        () =>
            transaction?.action === ETransactionAction.REQUEST &&
            transaction?.status === ETransactionStatus.PENDING,
        [transaction]
    );

    const isCreditRequest = useMemo(
        () =>
            transaction?.action === ETransactionAction.REQUEST &&
            transaction?.type === ETransactionType.CREDIT &&
            transaction?.status === ETransactionStatus.PENDING,
        [transaction]
    );

    const isDebitRequest = useMemo(
        () =>
            transaction?.action === ETransactionAction.REQUEST &&
            transaction?.type === ETransactionType.CREDIT &&
            transaction?.status === ETransactionStatus.PENDING,
        [transaction]
    );

    const isInternalTransactionRequest = useMemo(
        () => isTransactionRequest && transaction?.method === ETransactionMethod.WALLET,
        [transaction]
    );

    const isNotMessage = useMemo(() => {
        if (transaction) {
            const actions = [
                ETransactionAction.CARD_SERVICE,
                ETransactionAction.CARD_DEPOSIT,
                ETransactionAction.PAYMENT
            ];
            const methods = [ETransactionMethod.EFT];
            return actions.includes(transaction?.action) || methods.includes(transaction.method);
        } else return false;
    }, [transaction]);

    const isCredit = useMemo(() => {
        return transaction.type === ETransactionType.CREDIT;
    }, []);

    const getIcon = useMemo(() => {
        if (transaction.automatic) {
            return <Icon className={styles.recurring_icon} name="sync" width={24} height={24} />;
        } else if (transaction.type === ETransactionType.CREDIT) {
            return <Icon name="arrow_up" width={24} height={24} />;
        } else {
            return <Icon name="arrow_down" width={24} height={24} />;
        }
    }, [transaction]);

    const getRecipientName = useMemo(() => {
        if (transaction.type === ETransactionType.CREDIT) {
            return transaction.destination.split('(')?.[0] || '';
        } else {
            return transaction.source.split('(')?.[0] || '';
        }
    }, [transaction]);

    const getAvatar = useMemo(() => {
        if (
            transaction.method === ETransactionMethod.WALLET ||
            transaction.method === ETransactionMethod.E_TRANSFER
        ) {
            if (transaction.action === ETransactionAction.CARD_DEPOSIT) {
                return <Icon name="credit_card" width={24} height={24} />;
            }
            return getFirstLettersBySpace(getRecipientName);
        } else if (ETransactionMethod.EFT === transaction.method) {
            return <Icon name="bank" width={24} height={24} />;
        } else if (ETransactionMethod.EPS === transaction.method) {
            return <Icon name="credit_card" width={24} height={24} />;
        } else if (ETransactionMethod.PAY_BILL === transaction.method) {
            return <Icon name="pay_bill" width={24} height={24} />;
        }
        return '';
    }, [transaction, getRecipientName]);

    const getEmail = useMemo(() => {
        if (transaction.type === ETransactionType.CREDIT) {
            return transaction.destination.split('(')?.[1]?.replace(')', '') || '';
        } else {
            return transaction.source.split('(')?.[1]?.replace(')', '') || '';
        }
    }, [transaction]);

    const { handleRequest: handleApproveTransaction, isLoading: isApproveLoading } = useApiMutation(
        {
            method: () => apiAccountService.approveRequestMoneyWallet(transaction.id as string),
            onSuccess: () => {
                refetchTransactions && refetchTransactions();
                requestSuccessLocale('approve_transaction');
                refetchAccount();
                onClose();
            },
            onError: requestErrorLocale
        }
    );

    const { handleRequest: handleDeclineTransaction, isLoading: isDeclineLoading } = useApiMutation(
        {
            method: () => apiAccountService.declineRequestMoneyWallet(transaction.id as string, ''),
            onSuccess: () => {
                refetchTransactions && refetchTransactions();
                requestSuccessLocale('cancel_transaction');
                refetchAccount();
                onClose();
            },
            onError: requestErrorLocale
        }
    );

    const { handleRequest: handleCancelTransaction, isLoading: isCancelLoading } = useApiMutation({
        method: () => apiAccountService.cancelRequestMoneyWallet(transaction.id as string),
        onSuccess: () => {
            refetchTransactions && refetchTransactions();
            requestSuccessLocale('cancel_transaction');
            refetchAccount();
            onClose();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleResendNotifiaction, isLoading: isResendLoading } = useApiMutation({
        method: () => apiAccountService.resendRequestMoneyNotification(transaction.id as string),
        onSuccess: () => {
            refetchTransactions && refetchTransactions();
            requestSuccessLocale('resend_transaction');
            refetchAccount();
            onClose();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleBlockContact } = useApiMutation({
        method: (val: string) => {
            return apiAccountService.blockMoneyRequester(val);
        },
        onSuccess: () => {
            requestSuccessLocale('block_contact');
            onClose();
        },
        onError: requestErrorLocale
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.icon}>{getIcon}</div>
                <p className={styles.title}>Transaction details</p>
            </div>
            <div className={styles.content}>
                <div className={styles.user}>
                    <div className={styles.user_avatar}>{getAvatar}</div>
                    <p className={styles.user_name}>{getRecipientName}</p>
                    <p className={styles.user_email}>{getEmail}</p>
                    <p className={styles.method}>
                        {transaction?.method &&
                            enumTranslate[transaction.method][i18n.language as 'en' | 'fr']}
                    </p>
                    {isCreditRequest && (
                        <Button
                            size="small"
                            variant="text-red"
                            className={styles.block_user}
                            onClick={() => handleBlockContact(transaction.id)}>
                            Block {getRecipientName.split(' ')?.[0]}
                        </Button>
                    )}
                    <div className={styles.divider} />
                    <div className={styles.payment_id}>
                        <p>Payment ID:</p>
                        <div className={styles.id}>
                            <p>{transaction.id}</p>
                            <button onClick={() => copyStringToClipboard(transaction.id)}>
                                <Icon name="copy" width={18} height={18} />
                            </button>
                        </div>
                    </div>
                    <Status variant={getTransactionStatus(transaction?.status)}>
                        {transaction?.status &&
                            ETransactionStatusTranslate[transaction.status][
                                i18n.language as 'en' | 'fr'
                            ]}
                    </Status>
                </div>
                <div className={styles.details}>
                    <div className={styles.row}>
                        <p>Date and time</p>
                        <p>{moment(transaction.createdAt).format('DD MMMM YYYY, HH:mm')}</p>
                    </div>
                    <div className={styles.row}>
                        <p>
                            {isCredit
                                ? `Amount ${isCreditRequest ? getRecipientName : 'you'} send`
                                : `Amount ${isCreditRequest ? getRecipientName : 'you'} request`}
                        </p>
                        <p>
                            <b>
                                {currencies[transaction?.currency as 'CAD']?.symbol}{' '}
                                {prettifyAmount(transaction?.amount?.toFixed(2) || '0')}{' '}
                                {transaction?.currency}
                            </b>
                        </p>
                    </div>
                    <div className={styles.row}>
                        <p>Total fees (included)</p>
                        <p>0 CAD</p>
                    </div>
                    <div className={styles.row}>
                        <p>
                            {isCredit
                                ? `${getRecipientName} got`
                                : `${isCreditRequest ? getRecipientName : 'You'} receive`}
                        </p>
                        <p>
                            <b>
                                {' '}
                                {currencies[transaction?.currency as 'CAD']?.symbol}{' '}
                                {prettifyAmount(transaction?.amount?.toFixed(2) || '0')}{' '}
                                {transaction?.currency}
                            </b>
                        </p>
                    </div>
                    {transaction.method === ETransactionMethod.E_TRANSFER &&
                        !(transaction.action === ETransactionAction.CARD_DEPOSIT) && (
                            <div className={styles.row}>
                                <p>{t('reference')}</p>
                                <p>
                                    {/*//@ts-ignore*/}
                                    {transaction?.details?.etransferReferenceNumber}
                                </p>
                            </div>
                        )}
                    {/*//@ts-ignore*/}
                    {!isNotMessage && transaction?.details?.message && (
                        <div className={styles.row}>
                            <p>Message (optional)</p>
                            {/*//@ts-ignore*/}
                            <p>{transaction?.details?.message || '-'}</p>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.actions}>
                <Button
                    variant="text-red"
                    size="normal"
                    onClick={() => {
                        handleOpenSubmitRequestModal(transaction?.id);
                    }}>
                    Report
                </Button>
                {/*<Button variant="outlined-dark" size="normal" onClick={}>*/}
                {/*    Refund*/}
                {/*</Button>*/}
                {isDebitRequest && isInternalTransactionRequest && (
                    <Button
                        variant="red"
                        size="normal"
                        onClick={handleCancelTransaction}
                        isLoading={isCancelLoading}>
                        Cancel
                    </Button>
                )}
                {isCreditRequest && isInternalTransactionRequest && (
                    <Button
                        variant="red"
                        size="normal"
                        onClick={handleDeclineTransaction}
                        isLoading={isDeclineLoading}>
                        Decline
                    </Button>
                )}
                {isCreditRequest && isInternalTransactionRequest && (
                    <Button
                        variant="green"
                        size="normal"
                        onClick={handleApproveTransaction}
                        isLoading={isApproveLoading}>
                        Approve
                    </Button>
                )}
                {isDebitRequest && isInternalTransactionRequest && (
                    <Button
                        variant="outlined-dark"
                        size="normal"
                        onClick={handleResendNotifiaction}
                        isLoading={isResendLoading}>
                        Send reminder
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ViewTransactionModal;
