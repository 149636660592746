import { useProgressBar } from '@hooks/helpers/useProgressBar';
import styles from './styles.module.scss';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider, useWatch } from 'react-hook-form';
import { apiAccountService, apiContactService, apiScheduleService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { eTransferSendMoneyProgressSteps } from '@const/progress_bar_steps';
import React, { useEffect, useMemo, useState } from 'react';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { ETransactionAction, ETransactionMethod, TContact } from '@xeppt/xeppt-sdk/types';
import { useTranslation } from 'react-i18next';
import { EContactType } from '@xeppt/xeppt-sdk/types/wallet';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import { EScheduleFrequency } from '@xeppt/xeppt-sdk/types/schedule';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import Recipient from '@sections/send_and_request/steps/recipient';
import Success from '@sections/send_and_request/steps/success';
import Amount from '@sections/send_and_request/steps/amount';
import Schedule from '@sections/send_and_request/steps/schedule';
import Summary from '@sections/send_and_request/steps/summary';
import ProgressBar from '@components/progress_bar';
import ChooseMethodSection from '@sections/send_and_request/steps/choose_methods/method';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { routes } from '@const/routes';
import Requester from '@sections/send_and_request/steps/requester';
import { isDayToday } from '@utils/date';

const defaultPaymentData = {
    paymentType: ESchedulePaymentType.PAYMENTS_DATE,
    contact: '',
    amount: undefined,
    securityQuestion: '',
    securityAnswer: '',
    isSchedule: false,
    account: '',
    message: '',
    paymentFrequency: EScheduleFrequency.ONCE,
    nextPaymentAt: new Date(),
    numberOfPayments: 1,
    isInfinitePayment: false
};

const SendMoneySection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const [type, contactId, scheduleId] = useQueryParams(['type', 'contactId', 'id']);
    const formMethods = useForm({
        defaultValues: defaultPaymentData
    });
    const { refetchAccount } = useUserContext();
    const [sendMethod, setSendMethod] = useState<ESendRequestMoneyMethod>(
        ESendRequestMoneyMethod.INTERNAL
    );
    const [contactsList, setContactsList] = useState<TContact[]>([]);
    const [isMethodChosen, setIsMethodChosen] = useState(false);
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control: formMethods.control });
    const isSchedule = useWatch({ name: 'isSchedule', control: formMethods.control });
    const { handleBack } = useNotFound();

    const isBank = useMemo(
        () =>
            sendMethod === ESendRequestMoneyMethod.BANK ||
            sendMethod === ESendRequestMoneyMethod.BANK_REQUEST,
        [sendMethod]
    );

    const isWallet = useMemo(
        () =>
            sendMethod === ESendRequestMoneyMethod.INTERNAL ||
            sendMethod === ESendRequestMoneyMethod.INTERNAL_REQUEST,
        [sendMethod]
    );

    const isETransfer = useMemo(
        () =>
            sendMethod === ESendRequestMoneyMethod.E_TRANSFER ||
            sendMethod === ESendRequestMoneyMethod.E_TRANSFER_REQUEST,
        [sendMethod]
    );

    const steps = useMemo(() => {
        return eTransferSendMoneyProgressSteps
            .filter((item) => {
                if (!isSchedule) {
                    if (sendMethod !== ESendRequestMoneyMethod.E_TRANSFER) {
                        return item?.id !== '1' && item?.id !== '4';
                    }
                    return item?.id !== '4';
                }
                if (sendMethod !== ESendRequestMoneyMethod.E_TRANSFER) {
                    return item?.id !== '1';
                }
                return true;
            })
            .map((item) => {
                if (item?.id === '2') {
                    if (isBank) return { ...item, title: 'Bank account' };
                    if (isWallet) return { ...item, title: 'Recipient' };
                    if (isETransfer) return { ...item, title: 'Recipient' };
                    return item;
                }
                return item;
            });
    }, [eTransferSendMoneyProgressSteps, isSchedule, isBank, isWallet, isETransfer, sendMethod]);

    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(steps, [
        formMethods.watch(),
        sendMethod
    ]);

    useEffect(() => {
        if (type) {
            //@ts-ignore
            setSendMethod(type as ESendRequestMoneyMethod);
            setIsMethodChosen(true);
        }
        if (contactId) {
            formMethods.setValue('contact', contactId);
        }
    }, [type, contactId, contactsList]);

    useEffect(() => {
        if (scheduleId) {
            changeStep('3');
        } else {
            if (sendMethod === ESendRequestMoneyMethod.E_TRANSFER) {
                changeStep('1');
            } else {
                changeStep('2');
            }
        }
    }, [sendMethod, scheduleId]);

    const { handleRequest: handleSendMoneyByETransfer, isLoading: isEtransferRequestLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                return apiAccountService.sendMoneyETransfer({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    securityQuestion: values.securityQuestion,
                    securityAnswer: values.securityAnswer,
                    message: values.message
                });
            },
            onSuccess: () => {
                requestSuccessLocale('send_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const getTransactionMethod = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.INTERNAL:
                return ETransactionMethod.WALLET;
            case ESendRequestMoneyMethod.BANK:
                return ETransactionMethod.EFT;
        }
    };

    const getTransactionAction = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.INTERNAL:
                return ETransactionAction.TRANSFER;
            case ESendRequestMoneyMethod.BANK:
                return ETransactionAction.WITHDRAW;
        }
    };

    useApiQuery({
        method: () => apiScheduleService.getSchedule(scheduleId as string),
        condition: !!scheduleId,
        onSuccess: (data) => {
            formMethods.reset({
                paymentType: ESchedulePaymentType.PAYMENTS_DATE,
                contact: data.method === ETransactionMethod.WALLET ? data.referenceId : undefined,
                //@ts-ignore
                amount: data.amount,
                account: data.method === ETransactionMethod.EFT ? data.referenceId : undefined,
                paymentFrequency: data.frequency,
                nextPaymentAt: data.nextPaymentAt,
                numberOfPayments: data.remainingPayments,
                isInfinitePayment: data.remainingPayments === -1
            });
        },
        deps: [scheduleId]
    });

    const { handleRequest: createSchedulePayment, isLoading: isScheduleLoading } = useApiMutation({
        method: () => {
            const values = formMethods.getValues();
            const {
                paymentFrequency,
                amount,
                numberOfPayments,
                nextPaymentAt,
                isInfinitePayment,
                contact,
                account
            } = values;
            return apiScheduleService.createSchedule({
                amount: Number(amount || 0),
                nextPaymentAt,
                frequency: paymentFrequency,
                numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments),
                referenceId: sendMethod === ESendRequestMoneyMethod.BANK ? account : contact,
                method: getTransactionMethod() as ETransactionMethod,
                action: getTransactionAction() as ETransactionAction
            });
        },
        onSuccess: () => {
            requestSuccessLocale('create_schedule_payment');
            nextStep();
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: updateSchedulePayment, isLoading: isUpdateScheduleLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                const {
                    paymentFrequency,
                    amount,
                    numberOfPayments,
                    nextPaymentAt,
                    isInfinitePayment
                } = values;
                return apiScheduleService.updateSchedule(scheduleId as string, {
                    amount: Number(amount || 0),
                    nextPaymentAt,
                    frequency: paymentFrequency,
                    numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments)
                });
            },
            onSuccess: () => {
                requestSuccessLocale('update_schedule_payment');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleSendMoneyByWallet, isLoading: isWalletRequestLoading } =
        useApiMutation({
            method: () => {
                const values = formMethods.getValues();
                return apiAccountService.sendMoneyWallet({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    message: values.message
                });
            },
            onSuccess: () => {
                requestSuccessLocale('send_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleSendMoneyByEft, isLoading: isEftRequestLoading } = useApiMutation({
        method: () => {
            const values = formMethods.getValues();
            return apiAccountService.sendMoneyEft({
                id: values.account,
                amount: Number(values.amount)
            });
        },
        onSuccess: () => {
            requestSuccessLocale('send_money');
            nextStep();
            refetchAccount();
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        if (scheduleId) {
            updateSchedulePayment(undefined);
        } else {
            if (
                watchedFrequency !== EScheduleFrequency.ONCE ||
                !isDayToday(new Date(formMethods.getValues().nextPaymentAt))
            ) {
                createSchedulePayment(undefined);
            } else {
                switch (sendMethod) {
                    case ESendRequestMoneyMethod.E_TRANSFER:
                        handleSendMoneyByETransfer(undefined);
                        break;
                    case ESendRequestMoneyMethod.INTERNAL:
                        handleSendMoneyByWallet(undefined);
                        break;
                    case ESendRequestMoneyMethod.BANK:
                        handleSendMoneyByEft(undefined);
                        break;
                }
            }
        }
    };

    const { data: contacts, handleRequest: refetchContacts } = useApiQuery({
        method: () => apiContactService.getContacts()
    });

    useEffect(() => {
        if (contacts) {
            if (sendMethod === ESendRequestMoneyMethod.E_TRANSFER) {
                setContactsList(contacts.filter((item) => item.type === EContactType.INTERAC));
            } else {
                setContactsList(contacts.filter((item) => item.type === EContactType.XEPPT));
            }
        }
    }, [sendMethod, contacts]);

    const handleNextStep = (onSuccess: () => void) => {
        formMethods.trigger().then((isValid) => {
            if (isValid) {
                onSuccess();
            }
        });
    };

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return <Requester isRequest={false} onSubmit={() => handleNextStep(nextStep)} />;
            case '2':
                return (
                    <Recipient
                        onSubmit={() => handleNextStep(nextStep)}
                        contactsList={contactsList || []}
                        method={sendMethod}
                    />
                );
            case '3':
                return (
                    <Amount
                        isEdit={!!scheduleId}
                        onPrev={prevStep}
                        contactList={contactsList || []}
                        method={sendMethod}
                        onSubmit={() =>
                            handleNextStep(() => (isSchedule ? nextStep() : changeStep('5')))
                        }
                    />
                );
            case '4':
                return <Schedule onPrev={prevStep} onSubmit={() => handleNextStep(nextStep)} />;
            case '5':
                return (
                    <Summary
                        isEdit={!!scheduleId}
                        onPrev={prevStep}
                        onSubmit={() =>
                            handleNextStep(() => {
                                onSubmit();
                            })
                        }
                        contactList={contactsList}
                        isLoading={
                            isScheduleLoading ||
                            isWalletRequestLoading ||
                            isUpdateScheduleLoading ||
                            isEftRequestLoading ||
                            isEtransferRequestLoading
                        }
                        method={sendMethod}
                    />
                );
            case '6':
                return (
                    <Success
                        onReset={() => {
                            formMethods.reset(defaultPaymentData);
                            changeStep('1');
                            refetchContacts();
                        }}
                        isSchedule={isSchedule}
                        method={sendMethod}
                    />
                );
        }
    };

    const renderTitle = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.INTERNAL:
                return 'internal';
            case ESendRequestMoneyMethod.E_TRANSFER:
                return 'e_transfer';
            case ESendRequestMoneyMethod.BANK:
                return 'bank';
            default:
                return 'title';
        }
    };

    useEffect(() => {
        scrollTo(0, 0);
    }, [currentStep, sendMethod]);

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            {isMethodChosen ? (
                <>
                    <div className={styles.header}>
                        <Button
                            className={styles.back}
                            leftIcon="arrow_left"
                            onClick={() => {
                                if (isMethodChosen) {
                                    formMethods.reset(defaultPaymentData);
                                    setIsMethodChosen(false);
                                    changeStep('1');
                                } else {
                                    handleBack(routes.send_and_request);
                                }
                            }}>
                            Back
                        </Button>
                        <Typography
                            variant="h4"
                            dangerouslySetInnerHTML={{ __html: t(renderTitle()) }}
                        />
                    </div>
                    <div className={styles.content_wrapper}>
                        <ProgressBar
                            disabled={!!scheduleId}
                            steps={steps}
                            availableStep={availableStep}
                            currentStep={currentStep}
                            className={styles.progress}
                            changeStep={changeStep}
                        />
                        <div className={styles.body_wrapper}>
                            <FormProvider {...formMethods}>{renderContent()}</FormProvider>
                        </div>
                    </div>
                </>
            ) : (
                <ChooseMethodSection
                    method={sendMethod}
                    onChangeMethod={(val) => setSendMethod(val)}
                    onSubmit={() => setIsMethodChosen(true)}
                    title={t('title')}
                    type="send"
                    styles={styles}
                />
            )}
        </motion.div>
    );
};

export default SendMoneySection;
