import React from 'react';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import useModalContext from '@hooks/context/useModalContext';
import InfoBlock from '@components/common/info_block';

const DeletePayeeModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'delete_payee'
    });
    const { modalData } = useModalContext();
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                Delete payee
            </Typography>
            <div className={styles.description}>
                Are you sure you want to delete {modalData?.name}?
            </div>
            <InfoBlock variant="error">{t('description')}</InfoBlock>
        </div>
    );
};

export default DeletePayeeModal;
