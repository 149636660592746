import React, { FC, SVGAttributes } from 'react';

const Business: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9951 16.677V14.14"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.19 5.33044C19.88 5.33044 21.24 6.70044 21.24 8.39044V11.8304C18.78 13.2704 15.53 14.1404 11.99 14.1404C8.45 14.1404 5.21 13.2704 2.75 11.8304V8.38044C2.75 6.69044 4.12 5.33044 5.81 5.33044H18.19Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4951 5.326V4.96C15.4951 3.74 14.5051 2.75 13.2851 2.75H10.7051C9.48512 2.75 8.49512 3.74 8.49512 4.96V5.326"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.77441 15.4832L2.96341 17.9922C3.09141 19.6832 4.50041 20.9902 6.19541 20.9902H17.7944C19.4894 20.9902 20.8984 19.6832 21.0264 17.9922L21.2154 15.4832"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Business;
