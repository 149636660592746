import React, { FC } from 'react';
import moment from 'moment-timezone';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Info from '@components/common/info';
import { getFirstLetter } from '@utils/index';
import { TTicketAttachment } from '@xeppt/xeppt-sdk/types';
import { useHelpCenterModals } from '@hooks/modals/useHelpCenterModals';
import { Icon } from '@components/icons';

const images = ['.png, .jpg, .jpeg, .'];

interface IProps {
    avatar?: string;
    name: string;
    message: string;
    attachments?: TTicketAttachment[];
    className?: string;
    isSender?: boolean;
    isShowUser?: boolean;
    createdAt: Date;
}

const cx = classNames.bind(styles);

const Message: FC<IProps> = ({
    avatar,
    message,
    attachments = [],
    isSender,
    name,
    isShowUser,
    createdAt,
    className
}) => {
    const { handleOpenPreviewAttachmentModal } = useHelpCenterModals();
    return (
        //@ts-ignore
        <div className={cx(styles.message_container, [className, { isSender }])}>
            {isShowUser && (
                <Info variant={isSender ? 'left' : 'right'} content={name}>
                    <div className={styles.avatar}>
                        {avatar ? <img src={avatar} alt={name} /> : <p>{getFirstLetter(name)}</p>}
                    </div>
                </Info>
            )}
            <div className={styles.message_wrapper}>
                <div className={styles.message}>
                    <div dangerouslySetInnerHTML={{ __html: message }} />
                    <div className={styles.time}>{moment(createdAt).format('HH:mm')}</div>
                </div>

                {attachments?.length > 0 && (
                    <div className={styles.attachments_wrapper}>
                        {attachments.map((item, i) => {
                            if (i === 0) {
                                const urlArray = item.url.split('.');
                                if (!images.includes(urlArray?.[urlArray.length - 1])) {
                                    return (
                                        <div key={i} className={styles.file}>
                                            <Icon name="attach" />
                                            <p>
                                                {decodeURIComponent(item.url.split('name=')?.[1])}
                                            </p>
                                        </div>
                                    );
                                }
                                return (
                                    <img
                                        key={i}
                                        onClick={() =>
                                            handleOpenPreviewAttachmentModal({
                                                url: item.url,
                                                name,
                                                date: createdAt,
                                                avatar
                                            })
                                        }
                                        src={item.url}
                                        alt="Message image"
                                    />
                                );
                            }
                        })}
                        {attachments.length > 1 && (
                            <div className={styles.attachments}>
                                {attachments.map((item, i) => {
                                    if (i !== 0) {
                                        const urlArray = item.url.split('.');
                                        if (!images.includes(urlArray?.[urlArray.length - 1])) {
                                            return (
                                                <div key={i} className={styles.file}>
                                                    <Icon name="attach" />
                                                    <p>
                                                        {decodeURIComponent(
                                                            item.url.split('name=')?.[1]
                                                        )}
                                                    </p>
                                                </div>
                                            );
                                        }
                                        return (
                                            <img
                                                onClick={() =>
                                                    handleOpenPreviewAttachmentModal({
                                                        url: item.url,
                                                        name,
                                                        date: createdAt,
                                                        avatar
                                                    })
                                                }
                                                key={i}
                                                src={item.url}
                                                alt="Message image"
                                            />
                                        );
                                    }
                                })}
                            </div>
                        )}
                        <div className={styles.time}>{moment(createdAt).format('HH:mm')}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default Message;
