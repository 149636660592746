import React, { FC, SVGAttributes } from 'react';

const Ghost: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.15 10.6498C15.15 10.9461 15.0691 11.1941 14.962 11.3548C14.8527 11.5186 14.7512 11.5498 14.7 11.5498C14.6487 11.5498 14.5472 11.5186 14.438 11.3548C14.3309 11.1941 14.25 10.9461 14.25 10.6498C14.25 10.3535 14.3309 10.1055 14.438 9.94483C14.5472 9.78098 14.6487 9.7498 14.7 9.7498C14.7512 9.7498 14.8527 9.78098 14.962 9.94483C15.0691 10.1055 15.15 10.3535 15.15 10.6498Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.9"
            />
            <path
                d="M9.74999 10.6498C9.74999 10.9461 9.6691 11.1941 9.56197 11.3548C9.45273 11.5186 9.35125 11.5498 9.29999 11.5498C9.24874 11.5498 9.14725 11.5186 9.03802 11.3548C8.93088 11.1941 8.84999 10.9461 8.84999 10.6498C8.84999 10.3535 8.93088 10.1055 9.03802 9.94483C9.14725 9.78098 9.24874 9.7498 9.29999 9.7498C9.35125 9.7498 9.45273 9.78098 9.56197 9.94483C9.6691 10.1055 9.74999 10.3535 9.74999 10.6498Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.9"
            />
            <path
                d="M21 18.9507V12.2705C21 7.15056 16.9706 3 12 3C7.02944 3 3 7.15056 3 12.2705V18.9507C3 20.1408 4.21588 20.9148 5.24928 20.3826C6.08453 19.9524 7.07952 20.0162 7.85652 20.5498C8.73062 21.1501 9.86938 21.1501 10.7435 20.5498L11.0609 20.3319C11.6296 19.9413 12.3704 19.9413 12.9391 20.3319L13.2565 20.5498C14.1306 21.1501 15.2694 21.1501 16.1435 20.5498C16.9205 20.0162 17.9155 19.9524 18.7507 20.3826C19.7841 20.9148 21 20.1408 21 18.9507Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
        </svg>
    );
};

export default Ghost;
