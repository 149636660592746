export const routes = {
    dashboard: '/',
    xeppt_cards: '/xeppt-cards',
    xeppt_card: '/xeppt-cards/:id',
    order_xeppt_card: '/xeppt-cards/order-card',
    transactions: '/transactions',
    transaction_details: '/transactions/:id',
    signin: '/sign-in',
    signup: '/sign-up',
    forgot_password: '/forgot-password',
    settings_profile: '/settings/profile',
    settings_business: '/settings/business',
    settings_notifications: '/settings/notifications',
    settings: '/settings',
    link_card_bank: '/link-card-or-bank',
    help_center: '/help-center',
    privacy_policy: '/privacy-policy',
    terms_of_service: '/terms-of-service',
    statements: '/statements',
    send_and_request: '/send-and-request',
    send_and_request_send: '/send-and-request/send',
    send_and_request_request: '/send-and-request/request',
    send_and_request_schedule: '/send-and-request/schedule',
    send_and_request_contacts: '/send-and-request/contacts',
    send_and_request_autodeposit: '/send-and-request/autodeposit',
    pay_bills: '/pay-bills',
    pay_bills_pay: '/pay-bills/pay-bills',
    pay_bills_payees: '/pay-bills/manage-payees',
    pay_bills_schedule: '/pay-bills/schedule-payments',
    manage_accounts: '/manage_accounts',
    not_found: '/not-found',
    cashback_history: '/cashback-history',
    extension_install: '/cash-back/installation',
    extension_uninstall: '/cash-back/uninstallation'
};
