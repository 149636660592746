import React, { FC, useEffect, useState } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import Link from '@components/common/link';
import useSignupContext from '@hooks/context/useSignupContext';
import { routes } from '@const/routes';
import { useFormContext, useWatch } from 'react-hook-form';
import FormField from '@components/form_field';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiRegistrationService } from '@api';
import emailIllustration from '@svg/illustrations/auth/email.svg';
import codeIllustration from '@svg/illustrations/auth/code.svg';
import Checkbox from '@components/common/checkbox';
import VerificationCode from '@components/verification_code';
import { EUserType } from '@xeppt/xeppt-sdk/types';

interface IProps {
    isEmailSent: boolean;
    toggleIsEmailSent: (val: boolean) => void;
}

const EmailSection: FC<IProps> = ({ isEmailSent, toggleIsEmailSent }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.email'
    });
    const { labelLocale, submitLocale, requestErrorLocale, placeholderLocale } = useLocales();
    const { nextStep, prevStep, handleChangeIllustration } = useSignupContext();
    const { trigger, getValues, control } = useFormContext();
    const [isAgree, setIsAgree] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [intervalId, setIntervalId] = useState<any>();
    const type = useWatch({ name: 'type', control });

    useEffect(() => {
        if (isEmailSent) {
            handleChangeIllustration(codeIllustration);
        } else {
            handleChangeIllustration(emailIllustration);
        }
    }, []);

    const { handleRequest: handleValidateEmail, isLoading: isValidationLoading } = useApiMutation({
        method: () => {
            const values = getValues();
            return apiRegistrationService.validateEmail(values.email);
        },
        onSuccess: () => {
            toggleIsEmailSent(true);
            setResendTimer(60);
            setIntervalId(
                setInterval(() => {
                    setResendTimer((state) => state - 1);
                }, 1000)
            );
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleVerifyEmail } = useApiMutation({
        method: () => {
            const values = getValues();
            return apiRegistrationService.verifyEmail(values.email, values.code);
        },
        onSuccess: nextStep,
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        if (isAgree) {
            trigger().then((isValid) => {
                if (isValid) {
                    if (isEmailSent) {
                        handleVerifyEmail(undefined);
                    } else {
                        handleValidateEmail(undefined);
                    }
                }
            });
        }
    };

    const onResend = () => {
        handleValidateEmail(undefined);
    };

    useEffect(() => {
        if (resendTimer === 0) {
            clearInterval(intervalId);
        }
    }, [resendTimer]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.steps}>
                STEP {isEmailSent ? '2' : '1'}/{type === EUserType.BUSINESS ? '11' : '7'}
            </div>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            {isEmailSent && (
                <Typography className={styles.email_sent_desc} variant="body2">
                    {t('enter_email_confirmation')} <span>{getValues().email}</span>
                </Typography>
            )}
            <div className={styles.form}>
                {!isEmailSent && (
                    <>
                        <FormField
                            name="email"
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    placeholder={placeholderLocale('enter_email')}
                                    readOnly={isEmailSent}
                                    required
                                    label={labelLocale('email')}
                                />
                            )}
                        />
                        <div className={styles.agree}>
                            <Checkbox
                                checked={isAgree}
                                onClick={() => setIsAgree((state) => !state)}
                                size="small"
                                className={styles.checkbox}
                            />
                            <Typography>
                                {t('by_registration')}{' '}
                                <Link to={routes.terms_of_service}>{t('term')}</Link> {t('and')}{' '}
                                <Link to={routes.privacy_policy}>{t('privacy')}</Link>
                            </Typography>
                        </div>
                    </>
                )}
                {isEmailSent && (
                    <>
                        <FormField
                            name="code"
                            renderComponent={(props) => <VerificationCode {...props} />}
                        />
                        {resendTimer > 0 ? (
                            <p className={styles.resend_text}>Resend the code in 60 sec </p>
                        ) : (
                            <button className={styles.resend_button} onClick={onResend}>
                                Resend code
                            </button>
                        )}
                    </>
                )}
                <div className={styles.actions}>
                    <Button
                        onClick={onSubmit}
                        size="normal"
                        variant="primary"
                        rightIcon={!isEmailSent ? 'arrow_right' : undefined}
                        isLoading={isValidationLoading}>
                        {submitLocale(isEmailSent ? 'verify' : 'next')}
                    </Button>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        onClick={() => {
                            if (isEmailSent) {
                                toggleIsEmailSent(false);
                            } else {
                                prevStep();
                            }
                        }}>
                        {submitLocale('back')}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default EmailSection;
