import React, { FC, SVGAttributes } from 'react';

const ErrorTriangle: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.73414 2.70967L14.8223 11.5403C14.9343 11.8039 14.9833 12.0182 14.9973 12.2409C15.0253 12.7611 14.8433 13.2668 14.4864 13.6559C14.1294 14.0436 13.6465 14.2725 13.1216 14.3003H2.87525C2.65828 14.2871 2.44132 14.2379 2.23835 14.1616C1.22351 13.7523 0.733592 12.6009 1.14653 11.6027L6.2697 2.70342C6.44467 2.3906 6.71063 2.12077 7.03958 1.94736C7.99142 1.41951 9.20223 1.76632 9.73414 2.70967ZM8.60692 8.52887C8.60692 8.86182 8.33397 9.13996 7.99802 9.13996C7.66207 9.13996 7.38212 8.86182 7.38212 8.52887V6.56658C7.38212 6.23294 7.66207 5.96312 7.99802 5.96312C8.33397 5.96312 8.60692 6.23294 8.60692 6.56658V8.52887ZM7.99802 11.5128C7.66207 11.5128 7.38212 11.2347 7.38212 10.9024C7.38212 10.5688 7.66207 10.2913 7.99802 10.2913C8.33397 10.2913 8.60692 10.5625 8.60692 10.8948C8.60692 11.2347 8.33397 11.5128 7.99802 11.5128Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ErrorTriangle;
