import React, { FC, useMemo, useState } from 'react';
import { getFirstLetter, highlightSearchText } from '@utils/index';
import FormField from '@components/form_field';
import { EContactType, EDdrStatus, TContact, TPayee } from '@xeppt/xeppt-sdk/types';
import { Icon, TIconType } from '@components/icons';
import styles from './styles.module.scss';
import Input from '@components/common/input';
import { useUserContext } from '@hooks/context/useUserContext';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { apiContactService } from '@api';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useLocales } from '@hooks/helpers/useLocales';
import Skeleton from '@components/common/skeleton';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import InfoBlock from '@components/common/info_block';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { ESendRequestMoneyMethod } from '@enum';

interface IProps {
    contactsList?: TContact[];
    payeesList?: TPayee[];
    onSubmit: () => void;
    method: ESendRequestMoneyMethod;
}

const Recipient: FC<IProps> = ({ contactsList, onSubmit, method, payeesList }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money.info'
    });
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const { account } = useUserContext();
    const { control, trigger, setValue } = useFormContext();
    const watchedContact = useWatch({ name: 'contact', control });
    const watchedAccount = useWatch({ name: 'account', control });
    const { validationLocale, labelLocale, submitLocale } = useLocales();

    const isBank = useMemo(
        () =>
            method === ESendRequestMoneyMethod.BANK ||
            method === ESendRequestMoneyMethod.BANK_REQUEST,
        [method]
    );

    const isWallet = useMemo(
        () =>
            method === ESendRequestMoneyMethod.INTERNAL ||
            method === ESendRequestMoneyMethod.INTERNAL_REQUEST,
        [method]
    );

    const isETransfer = useMemo(
        () =>
            method === ESendRequestMoneyMethod.E_TRANSFER ||
            method === ESendRequestMoneyMethod.E_TRANSFER_REQUEST,
        [method]
    );

    const isEps = useMemo(() => method === ESendRequestMoneyMethod.EPS_REQUEST, [method]);

    const isPayBills = useMemo(() => method === ESendRequestMoneyMethod.PAY_BILLS, [method]);

    const isRequest = useMemo(() => {
        return (
            method === ESendRequestMoneyMethod.INTERNAL_REQUEST ||
            method === ESendRequestMoneyMethod.E_TRANSFER_REQUEST ||
            method === ESendRequestMoneyMethod.EPS_REQUEST ||
            method === ESendRequestMoneyMethod.BANK_REQUEST
        );
    }, [method]);

    const contacts = useMemo(() => {
        if (isBank) {
            return (
                account?.bankAccounts
                    ?.filter((item) => {
                        return (
                            item.accountName?.toLowerCase().includes(search?.toLowerCase()) ||
                            item.branchName?.toLowerCase().includes(search?.toLowerCase()) ||
                            item.accountNumber?.toLowerCase().includes(search?.toLowerCase())
                        );
                    })
                    .map((item) => ({
                        firstName: '',
                        lastName: item.branchName,
                        email: `${item.accountName} ${item.accountNumber}`,
                        id: item.id
                    })) || []
            );
        }
        if (isEps) {
            return (
                account?.epsCards
                    ?.filter((item) => {
                        return (
                            item.cardHolderName?.toLowerCase().includes(search?.toLowerCase()) ||
                            item.cardBrand?.toLowerCase().includes(search?.toLowerCase()) ||
                            item.cardNumber?.toLowerCase().includes(search?.toLowerCase())
                        );
                    })
                    .map((item) => ({
                        firstName: '',
                        lastName: item.cardHolderName,
                        email: `${item.cardBrand} ${item.cardNumber}`,
                        id: item.id
                    })) || []
            );
        }
        if (isPayBills) {
            return (
                payeesList
                    ?.filter((item) => {
                        return (
                            item?.alias?.toLowerCase().includes(search?.toLowerCase()) ||
                            item?.name?.toLowerCase().includes(search?.toLowerCase()) ||
                            item?.accountNumber?.toLowerCase().includes(search?.toLowerCase())
                        );
                    })
                    .map((item) => ({
                        firstName: '',
                        lastName: item?.alias ? `${item.alias} (${item.name})` : item.name,
                        email: `${item.accountNumber}`,
                        id: item.id
                    })) || []
            );
        }
        return (
            contactsList
                ?.filter((item) => {
                    return (
                        item?.tag?.toLowerCase()?.includes(search?.toLowerCase()) ||
                        item?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
                        item?.phone?.toLowerCase()?.includes(search?.toLowerCase()) ||
                        `${item.firstName?.toLowerCase()} ${item.lastName?.toLowerCase()}`.includes(
                            search?.toLowerCase()
                        )
                    );
                })
                .filter((item) => {
                    if (isETransfer && watchedContact) {
                        return item.id === watchedContact;
                    }
                    return true;
                }) || []
        );
    }, [contactsList, search, isBank, account, watchedContact, isETransfer, payeesList]);

    const foundContact = useMemo(() => {
        if (contacts) {
            //@ts-ignore
            return contacts.find(
                (item: any) => item.id === watchedContact || item.id === watchedAccount
            );
        }
        return undefined;
    }, [watchedContact, contacts]);

    const { data: ddrContactStatus, isLoading: isContactDdrLoading } = useApiQuery({
        method: () => apiContactService.checkContactDDR(watchedContact),
        deps: [watchedContact],
        condition: isETransfer && watchedContact && !isRequest
    });

    const handleSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                onSubmit();
            }
        });
    };

    const pageTitle = useMemo(() => {
        if (isBank) return 'Bank account';
        if (isEps) return 'Card';
        if (isWallet) return 'Recipient';
        if (isPayBills) return 'payee';
        if (isETransfer) return 'Recipient';
        return 'Recipient';
    }, [isBank, isEps, isWallet, isPayBills, isETransfer]);

    const pageDescription = useMemo(() => {
        if (isBank) return 'bank accounts';
        if (isEps) return 'linked cards';
        if (isWallet) return 'XEPPT contacts';
        if (isPayBills) return 'payee list';
        if (isETransfer) return 'Interac e-Transfer contacts';
        return 'contacts list';
    }, [isBank, isEps, isWallet, isPayBills, isETransfer]);

    const addButtonData = useMemo(() => {
        if (isBank)
            return {
                icon: 'link_bank',
                text: 'Link a bank account',
                onClick: () => navigate(routes.link_card_bank + '?type=bank')
            };
        if (isEps)
            return {
                icon: 'link_card',
                text: 'Link a card',
                onClick: () => navigate(routes.link_card_bank + '?type=card')
            };
        if (isWallet)
            return {
                icon: 'person',
                text: 'Add new contact',
                onClick: () =>
                    navigate(
                        routes.send_and_request_contacts + `?isAdd=true&type=${EContactType.XEPPT}`
                    )
            };
        if (isETransfer)
            return {
                icon: 'person',
                text: 'Add new contact',
                onClick: () =>
                    navigate(
                        routes.send_and_request_contacts +
                            `?isAdd=true&type=${EContactType.INTERAC}`
                    )
            };
        if (isPayBills)
            return {
                icon: 'add_payee',
                text: 'Add new payee',
                onClick: () => navigate(routes.pay_bills_payees)
            };
    }, [isBank, isEps, isWallet, isPayBills, isETransfer]);

    const searchPlaceholder = useMemo(() => {
        if (isBank) return 'account or institution name';
        if (isEps) return 'cardholder name, card number or card brand';
        if (isWallet) return 'contact name, email or phone number';
        if (isPayBills) return 'payee name, alias or account number';
        if (isETransfer) return 'contact name, email or phone number';
        return pageTitle.toLowerCase();
    }, [isBank, isEps, isWallet, isPayBills, isETransfer]);

    const listTitle = useMemo(() => {
        if (isBank) return 'linked bank accounts';
        if (isEps) return 'linked cards';
        if (isWallet) return 'XEPPT Contacts';
        if (isPayBills) return 'payees';
        if (isETransfer) return 'Interac e-Transfer contacts';
        return 'contacts';
    }, [isBank, isEps, isWallet, isPayBills, isETransfer]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.title}>{pageTitle}</div>
                <div className={styles.description}>
                    Choose the {pageTitle.toLowerCase()} from your {pageDescription}
                </div>
            </div>
            <div className={styles.search_wrapper}>
                <Input
                    full
                    value={search}
                    leftIcon="search"
                    onChange={(val) => {
                        setSearch(val);
                        if (isETransfer && watchedContact) {
                            setValue('contact', '');
                        }
                    }}
                    placeholder={`Search by ${searchPlaceholder}...`}
                />
            </div>
            {foundContact && !isETransfer && !search && (
                <div
                    className={`${styles.contact} ${styles.isActive} ${styles.found_contact}`}
                    key={foundContact.id}>
                    <div className={styles.left}>
                        {!isBank && !isEps && (
                            <div className={styles.avatar}>
                                {getFirstLetter(foundContact?.firstName)}
                                {getFirstLetter(foundContact?.lastName)}
                            </div>
                        )}
                        <div className={styles.text_wrapper}>
                            <div
                                className={styles.title}
                                dangerouslySetInnerHTML={{
                                    __html: highlightSearchText(
                                        `${foundContact.firstName} ${foundContact.lastName}`,
                                        search
                                    )
                                }}
                            />
                            <div
                                className={styles.description}
                                dangerouslySetInnerHTML={{
                                    __html: highlightSearchText(
                                        foundContact.email ||
                                            //@ts-ignore
                                            `+${foundContact.phone}`,
                                        search
                                    )
                                }}
                            />
                        </div>
                    </div>
                    <Icon name="check" />
                </div>
            )}
            <div className={styles.content}>
                {((isETransfer && !watchedContact) || !isETransfer || isRequest) && (
                    <div className={styles.add_new}>
                        {addButtonData && (
                            <div className={styles.left} onClick={addButtonData.onClick}>
                                <div className={styles.avatar}>
                                    <Icon name={addButtonData.icon as TIconType} />
                                </div>{' '}
                                {addButtonData.text}
                            </div>
                        )}
                        <Icon className={styles.icon} name="nav_right" />
                    </div>
                )}
                <div className={styles.results_wrapper}>
                    <div className={styles.result}>
                        {((isETransfer && !watchedContact) || !isETransfer) && (
                            <div className={styles.result_length}>
                                Your {listTitle} ({contacts?.length})
                            </div>
                        )}
                        <FormField
                            name={isBank || isEps || isPayBills ? 'account' : 'contact'}
                            renderComponent={({ onChange }) => {
                                return (
                                    <>
                                        {contacts.map((contact) => {
                                            if (
                                                foundContact &&
                                                contact.id === foundContact.id &&
                                                !search &&
                                                !isETransfer
                                            )
                                                return null;
                                            return (
                                                <div
                                                    className={`${styles.contact} ${contact.id === watchedContact || contact.id === watchedAccount ? styles.isActive : ''}`}
                                                    key={contact.id}
                                                    onClick={() => {
                                                        onChange(contact.id);
                                                        if (!isETransfer || isRequest) {
                                                            onSubmit();
                                                        }
                                                    }}>
                                                    <div className={styles.left}>
                                                        {!isBank && !isEps && (
                                                            <div className={styles.avatar}>
                                                                {getFirstLetter(contact?.firstName)}
                                                                {getFirstLetter(contact?.lastName)}
                                                            </div>
                                                        )}
                                                        <div className={styles.text_wrapper}>
                                                            <div
                                                                className={styles.title}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: highlightSearchText(
                                                                        `${contact.firstName} ${contact.lastName}`,
                                                                        search
                                                                    )
                                                                }}
                                                            />
                                                            <div
                                                                className={styles.description}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: highlightSearchText(
                                                                        contact.email ||
                                                                            //@ts-ignore
                                                                            `+${contact.phone}`,
                                                                        search
                                                                    )
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {(contact.id === watchedContact ||
                                                        contact.id === watchedAccount) && (
                                                        <Icon name="check" />
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </>
                                );
                            }}
                        />
                        {contacts.length === 0 && (
                            <div className={styles.empty_wrapper}>
                                <div className={styles.empty_icon}>
                                    <Icon name="ghost" />
                                </div>
                                You don't have any {listTitle} yet.
                            </div>
                        )}
                    </div>
                </div>
                {isETransfer && watchedContact && (
                    <>
                        {isContactDdrLoading ? (
                            <>
                                <Skeleton className={styles.skeleton_info} />
                                <Skeleton className={styles.skeleton} />
                                <Skeleton className={styles.skeleton} />
                            </>
                        ) : (
                            <>
                                {ddrContactStatus === EDdrStatus.ENABLED && (
                                    <InfoBlock variant="gray" className={styles.info}>
                                        <span>{t('autodeposit_title')}</span> -{' '}
                                        {t('autodeposit_description')}
                                    </InfoBlock>
                                )}
                                {ddrContactStatus === EDdrStatus.DISABLED && (
                                    <>
                                        <InfoBlock variant="warning" className={styles.info}>
                                            {t('autodeposit_off')}
                                        </InfoBlock>
                                        <FormField
                                            name="securityQuestion"
                                            renderComponent={(props) => (
                                                <Input
                                                    className={styles.input}
                                                    full
                                                    label={labelLocale('security_question')}
                                                    placeholder="Enter your security question"
                                                    {...props}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: validationLocale(
                                                        'invalid_security_question'
                                                    )
                                                },
                                                validate: (value: string) => {
                                                    const cleanValue = value.replaceAll(' ', '');
                                                    if (cleanValue.length >= 40) {
                                                        return validationLocale(
                                                            'invalid_security_question_length'
                                                        );
                                                    }
                                                }
                                            }}
                                        />
                                        <FormField
                                            name="securityAnswer"
                                            renderComponent={(props) => (
                                                <Input
                                                    className={styles.input}
                                                    full
                                                    label={labelLocale('answer')}
                                                    placeholder="Enter the answer"
                                                    {...props}
                                                />
                                            )}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message:
                                                        validationLocale('invalid_security_answer')
                                                },
                                                validate: (value: string) => {
                                                    const cleanValue = value.replaceAll(' ', '');
                                                    if (
                                                        cleanValue.length <= 3 ||
                                                        cleanValue.length >= 25
                                                    ) {
                                                        return validationLocale(
                                                            'invalid_security_answer_length'
                                                        );
                                                    }
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
            <div className={styles.actions}>
                {isETransfer && (
                    <Button
                        leftIcon="arrow_left"
                        variant="outlined-dark"
                        size="normal"
                        onClick={() => setValue('contact', '')}>
                        {submitLocale('previous')}
                    </Button>
                )}
                {(watchedContact || watchedAccount) && (
                    <Button
                        onClick={handleSubmit}
                        rightIcon="arrow_right"
                        variant="primary"
                        size="normal">
                        {submitLocale('next')}
                    </Button>
                )}
            </div>
        </motion.div>
    );
};

export default Recipient;
