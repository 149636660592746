import React, { useState } from 'react';
import styles from '@layouts/settings/profile/styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import FormField from '@components/form_field';
import DatePicker from '@components/date_picker';
import { capitalizeFirstLetter, getBirthDisabledDates } from '@utils/index';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import Contacts from '@sections/settings/contacts';
import { useFormContext, useWatch } from 'react-hook-form';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiUserService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';
import { occupationsList } from '@_mock/index';
import Select from '@components/common/select';
import { EUserStatus } from '@xeppt/xeppt-sdk/types';
import Button from '@components/common/button';
import { getAllTimezonesOptions } from '@utils/date';
import { useDebounceValue } from 'usehooks-ts';
import { useApiQuery } from '@hooks/api/useApiQuery';

const PersonalSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'settings.contact'
    });
    const {
        labelLocale,
        submitLocale,
        requestErrorLocale,
        requestSuccessLocale,
        validationLocale
    } = useLocales();
    const form = useFormContext();
    const [isEdit, setIsEdit] = useState(false);
    const [isEditGeneral, setIsEditGeneral] = useState(false);
    const { refetchUser, user } = useUserContext();
    const watchedTag = useWatch({ name: 'profile.tag', control: form.control });
    const [debouncedTag] = useDebounceValue(watchedTag, 1000);

    useApiQuery({
        method: () => apiUserService.checkTag(debouncedTag),
        isInitialRequest: false,
        condition:
            !!debouncedTag && debouncedTag.length >= 5 && debouncedTag !== user?.profile?.tag,
        deps: [debouncedTag],
        onError: () => {
            form.setError('profile.tag', { message: validationLocale('tag_taken') });
        }
    });

    const { handleRequest: handleUpdateUser } = useApiMutation({
        method: () => {
            const {
                profile: { firstName, lastName, birthDate, occupancy }
            } = form.getValues();
            return apiUserService.updateProfile({
                firstName,
                lastName,
                birthDate,
                occupancy
            });
        },
        onSuccess: () => {
            requestSuccessLocale('update_profile');
            refetchUser();
            setIsEdit(false);
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleUpdateTimezone } = useApiMutation({
        method: () => {
            const {
                profile: { timezone, tag }
            } = form.getValues();
            const promises = [apiUserService.updateTimezone(timezone)];
            if (tag !== user?.profile?.tag) {
                promises.push(apiUserService.updateTag(tag));
            }
            return Promise.all(promises);
        },
        onSuccess: () => {
            requestSuccessLocale('update_profile');
            refetchUser();
            setIsEditGeneral(false);
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        if (!isEdit) {
            setIsEdit(true);
        } else {
            form.trigger().then((isValid) => {
                isValid && handleUpdateUser(undefined);
            });
        }
    };

    const onSubmitGeneral = () => {
        if (!isEditGeneral) {
            setIsEditGeneral(true);
        } else {
            form.trigger().then((isValid) => {
                isValid && handleUpdateTimezone(undefined);
            });
        }
    };

    const onCancel = () => {
        form.reset(user);
        setIsEditGeneral(false);
    };

    return (
        <>
            <div className={styles.row}>
                <div className={styles.header}>
                    <Typography variant="body1" lh="120%" weight="semibold">
                        {t('title')}
                    </Typography>
                    {user?.status === EUserStatus.UNVERIFIED && (
                        <Button
                            leftIcon={isEdit ? undefined : 'edit'}
                            variant="outlined"
                            size="normal"
                            onClick={onSubmit}>
                            {isEdit ? submitLocale('save') : submitLocale('edit')}
                        </Button>
                    )}
                </div>
                <div className={styles.inputs_wrapper}>
                    <div className={styles.input_wrapper}>
                        <FormField
                            name="profile.firstName"
                            renderComponent={(props) => (
                                <Input
                                    label={labelLocale('first_name')}
                                    {...props}
                                    full
                                    readOnly={!isEdit}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.input_wrapper}>
                        <FormField
                            name="profile.lastName"
                            renderComponent={(props) => (
                                <Input
                                    label={labelLocale('last_name')}
                                    {...props}
                                    full
                                    readOnly={!isEdit}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.input_wrapper}>
                        <FormField<string | null>
                            name="profile.birthDate"
                            renderComponent={(props) => (
                                <DatePicker
                                    {...props}
                                    label={labelLocale('birth_date')}
                                    isBirthday
                                    full
                                    // readOnly
                                    readOnly={!isEdit}
                                    maxDate={getBirthDisabledDates()}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.input_wrapper}>
                        <FormField<string | number>
                            name="profile.occupancy"
                            renderComponent={(props) => (
                                <Select
                                    {...props}
                                    full
                                    isAllowSearch
                                    readOnly={!isEdit}
                                    label={labelLocale('occupation')}
                                    items={occupationsList.map((item) => ({
                                        value: capitalizeFirstLetter(item),
                                        label: capitalizeFirstLetter(item)
                                    }))}
                                />
                            )}
                        />
                    </div>
                    <Contacts />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.header}>
                    <Typography variant="body1" lh="120%" weight="semibold">
                        {t('general_title')}
                    </Typography>
                    <div className={styles.address_actions}>
                        {isEditGeneral && (
                            <Button
                                variant="outlined"
                                size="normal"
                                onClick={() => onCancel()}
                                disabled={!isEditGeneral}>
                                Cancel
                            </Button>
                        )}
                        <Button
                            leftIcon={isEditGeneral ? undefined : 'edit'}
                            variant="outlined"
                            size="normal"
                            onClick={onSubmitGeneral}>
                            {isEditGeneral ? submitLocale('save') : submitLocale('edit')}
                        </Button>
                    </div>
                </div>
                <div className={styles.inputs_wrapper}>
                    <div className={styles.input_wrapper}>
                        <FormField<string | number>
                            name="profile.timezone"
                            renderComponent={(props) => (
                                <Select
                                    {...props}
                                    full
                                    isAllowSearch
                                    readOnly={!isEditGeneral}
                                    label={labelLocale('timezone')}
                                    items={getAllTimezonesOptions()}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.input_wrapper}>
                        <FormField<string>
                            name="profile.tag"
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    readOnly={!isEditGeneral}
                                    label={labelLocale('username')}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonalSection;
