import React, { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Status from '@components/common/status';
import moment from 'moment-timezone';
import Chat from '@components/chat';
import classNames from 'classnames/bind';
import { ETicketStatus, TTicket } from '@xeppt/xeppt-sdk/types';
import { capitalizeFirstLetter, getTicketStatus, getTicketStatusVariant } from '@utils/index';

interface IProps {
    isOpen: boolean;
    ticketData?: TTicket;
    onClose: () => void;
    refetchTicket: () => void;
}

const cx = classNames.bind(styles);

const ChatDrawer: FC<IProps> = ({ isOpen, onClose, ticketData, refetchTicket }) => {
    useEffect(() => {
        if (isOpen) {
            const launcher = document.querySelector('[style="visibility: visible;"]');

            if (launcher) {
                //@ts-ignore
                launcher.style.visibility = 'hidden';
            }
        }
        return () => {
            const oldScript = document.getElementById('ze-snippet');
            const launcher = document.querySelector('[style="visibility: hidden;"]');
            if (oldScript) {
                document.body.removeChild(oldScript);
            }
            if (launcher) {
                //@ts-ignore
                launcher.style.visibility = 'visible';
            }
        };
    }, [isOpen]);

    return (
        <div className={cx([styles.wrapper, { isOpen }])}>
            <div className={styles.drawer}>
                <div className={styles.header_actions}>
                    <button onClick={onClose}>
                        <Icon name="close" />
                    </button>
                </div>
                <div className={styles.header_wrapper}>
                    <div className={styles.left_header}>
                        <div className={styles.request_data}>
                            <Typography variant="h5" weight="bold" className={styles.title}>
                                Request #{ticketData?.id}
                            </Typography>
                            <Status
                                variant={getTicketStatusVariant(
                                    getTicketStatus(ticketData?.status as ETicketStatus)
                                )}>
                                {capitalizeFirstLetter(
                                    getTicketStatus(ticketData?.status as ETicketStatus)
                                )}
                            </Status>
                        </div>
                        <Typography variant="body3" className={styles.date}>
                            {moment(ticketData?.createdAt).format('MM.DD.YYYY, HH:mm')}
                        </Typography>
                    </div>
                    <Typography variant="body3" className={styles.subject}>
                        {ticketData?.subject}
                    </Typography>
                </div>
                {isOpen && (
                    <Chat
                        createdAt={ticketData?.createdAt}
                        messages={ticketData?.comments || []}
                        users={ticketData?.users || []}
                        id={ticketData?.id || ''}
                        refetchChat={refetchTicket}
                        status={ticketData?.status}
                    />
                )}
            </div>
            {isOpen && <div onClick={onClose} className={styles.overlay} />}
        </div>
    );
};

export default ChatDrawer;
