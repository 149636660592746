import React, { FC } from 'react';
import styles from './styles.module.scss';
import moment from 'moment-timezone';
import { IModalComponentProps } from '@context/modal_context';
import { TCard, TTransaction } from '@xeppt/xeppt-sdk/types';
import { capitalizeFirstLetter } from '@utils/index';

const CardAgreementModal: FC<IModalComponentProps<TCard>> = ({ onClose, modalData }) => {
    if (!modalData?.id) return null;

    const { agreementDetails, agreementAcceptedAt } = modalData;

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.title}>Agreement</h1>
            <div className={styles.content_wrapper}>
                <div className={styles.content}>
                    <p className={styles.caption}>CARDHOLDER AGREEMENT 20240301</p>
                    <h1>
                        CARDHOLDER AGREEMENT FOR A PREPAID CARD between Digital Commerce Bank and
                        the Cardholder noted below
                    </h1>
                    <p>
                        <b>Customer service:</b> 1-866-221-1002 or support@xeppt.com
                    </p>
                    <p>
                        <b>Website:</b> www.xeppt.com
                    </p>
                    <p>
                        <b>DCB Customer Service:</b> 1-844-836-6040
                    </p>
                    <h2>CARDHOLDER INFORMATION & AGREEMENT</h2>
                    <table className={styles.user}>
                        <tbody>
                            <tr>
                                <td>
                                    <b>First Name:</b>
                                    <br /> {agreementDetails?.personalInfo?.firstName}
                                </td>
                                <td>
                                    <b>Middle Name:</b>
                                    <br /> {agreementDetails?.personalInfo?.middleName || ''}
                                </td>
                                <td>
                                    <b>Last Name:</b>
                                    <br /> {agreementDetails?.personalInfo?.lastName}
                                </td>
                                <td>
                                    <b>Alias:</b>
                                    <br /> {agreementDetails?.personalInfo?.alias}
                                </td>
                                <td>
                                    <b>Phone Number (Home):</b>
                                    <br /> {agreementDetails?.personalInfo?.homePhone}
                                </td>
                                <td>
                                    <b>Cell Phone Number:</b>
                                    <br /> {agreementDetails?.personalInfo?.mobilePhone}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Street Address:</b>
                                    <br /> {agreementDetails?.billingAddress?.address1}
                                </td>
                                <td>
                                    <b>City:</b>
                                    <br /> {agreementDetails?.billingAddress?.city}
                                </td>
                                <td colSpan={2}>
                                    <b>Province:</b>
                                    <br /> {agreementDetails?.billingAddress?.region}
                                </td>
                                <td>
                                    <b>Postal Code:</b>
                                    <br /> {agreementDetails?.billingAddress?.zipCode}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Date of Birth:</b>
                                    <br />{' '}
                                    {moment(agreementDetails?.personalInfo?.birthDate).format(
                                        'MM/DD/YYYY'
                                    )}
                                </td>
                                <td>
                                    <b>Citizenship:</b>
                                    <br /> {agreementDetails?.personalInfo?.citizenship}
                                </td>
                                <td>
                                    <b>Birth Date:</b>
                                    <br />{' '}
                                    {moment(agreementDetails?.personalInfo?.birthDate).format(
                                        'MM/DD/YYYY'
                                    )}
                                </td>
                                <td colSpan={2}>
                                    <b>Email:</b>
                                    <br /> {agreementDetails?.personalInfo?.email}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Occupation:</b>
                                    <br />{' '}
                                    {capitalizeFirstLetter(
                                        agreementDetails?.employerInfo?.occupation
                                    )}
                                </td>
                                <td colSpan={2}>
                                    <b>Employer Name:</b> {agreementDetails?.employerInfo?.name}
                                </td>
                                <td colSpan={4}>
                                    <b>Employer Business Telephone Number:</b>
                                    <br /> {agreementDetails?.employerInfo?.businessPhone}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <b>Employer Street Address:</b>
                                    <br /> {agreementDetails?.employerAddress?.address1}
                                </td>
                                <td>
                                    <b>Employer City:</b>
                                    <br /> {agreementDetails?.employerAddress?.city}
                                </td>
                                <td colSpan={2}>
                                    <b>Employer Province:</b>
                                    <br /> {agreementDetails?.employerAddress?.region}
                                </td>
                                <td>
                                    <b>Employer Postal Code:</b>
                                    <br /> {agreementDetails?.employerAddress?.zipCode}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <b>Cardholder's Signature:</b>
                                    <span>
                                        I have read, understood and agree to the Cardholder
                                        Agreement above
                                    </span>
                                </td>
                                <td colSpan={4}>
                                    <b>Date:</b> {moment(agreementAcceptedAt).format('MM/DD/YYYY')}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>INFORMATION ABOUT THIS PREPAID CARD</h2>
                    <p>
                        <b> Features and benefits of this prepaid card:</b>
                    </p>
                    <ul>
                        <li>
                            There's <b>no credit check</b> needed to obtain this prepaid card.
                        </li>
                        <li>
                            You <b>don't need a bank account</b> to obtain or use this card so it's
                            a great option if you don't have (or don't want) a traditional banking
                            relationship.
                        </li>
                        <li>
                            This card is available to individuals who are unable to obtain a credit
                            card for making purchases due to unsatisfactory/<b>no credit history</b>
                            .
                        </li>
                        <li>
                            Our prepaid cards can be <b>used almost anywhere</b> that major payment
                            methods are accepted.
                        </li>
                        <li>
                            You can use it for <b>in-store and online purchases</b>.
                        </li>
                        <li>
                            It can be used as a <b>budgeting tool</b> to avoid overspending since
                            you can't spend more than you have loaded to the card.
                        </li>
                        <li>
                            There's <b>no chance of incurring debt</b> since you're only spending
                            money that has been loaded onto the card.
                        </li>
                        <li>
                            You can <b>avoid overdraft</b> or overlimit fees since your card stops
                            working once it reaches a $0 balance. There are{' '}
                            <b>no non-sufficient funds (NSF) fees</b> if your account is too low to
                            make a purchase. With these types of fees being $45 on average,
                            overlimit/NSF fees can add up quickly.
                        </li>
                        <li>
                            There are <b>no hidden fees.</b> All of our fees are clearly outlined
                            below so you know up front exactly what you'll be charged for use of the
                            card. Please ensure that you review these fees carefully before you
                            choose our card.
                        </li>
                        <li>
                            This card is safer than cash and has a <b>zero-liability guarantee</b>.
                        </li>
                        <li>
                            You are <b>not &quot;locked-in&quot;</b> to this product. You can
                            transfer out your money and stop using this card at any time and once
                            you do, no further fees will apply.
                        </li>
                    </ul>
                    <br />
                    <p>
                        These product features above won't apply equally to everyone since not
                        everyone needs these features or they can access similar benefits with other
                        products. However, the prepaid card offers ease of use and wide payment
                        acceptance without the risk of overspending!
                    </p>
                    <br />
                    <p>
                        <b>Reasons why this prepaid card may not be right for you:</b>
                    </p>
                    <br />
                    <ul>
                        <li>
                            You won't earn interest on the funds loaded to the card. This card may
                            not be the right choice for you if you intend to deposit a large balance
                            on the card and leave it unused for a long period of time.
                        </li>
                        <li>
                            Prepaid card activity is not reported to credit agencies so you can't
                            use this product to help build up your credit.
                        </li>
                        <li>
                            This product may not be right for you if need a low fee or no fee
                            product since it could be potentially more expensive than a traditional
                            debit or no fee credit card.
                        </li>
                    </ul>
                    <p>
                        If you have any questions about this product or its features, please contact
                        us at the number above.
                    </p>
                    <h2>AGREEMENT FOR DCBANK PREPAID CARD</h2>
                    <p className={styles.center}>
                        <b>CARD FEES INFORMATION BOX</b>
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Type of fee</th>
                                <th>Transaction Fee Amount</th>
                                <th>Details of Fee Charged</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Domestic ATM Withdrawal Fee</th>
                                <th>Free</th>
                                <th>In Canada (per withdrawal).</th>
                            </tr>
                            <tr>
                                <th>International ATM Withdrawal Fee</th>
                                <th>Free</th>
                                <th>In the U.S. or International (per withdrawal).</th>
                            </tr>
                            <tr>
                                <th>Domestic POS Transaction Fee</th>
                                <th>Free</th>
                                <th>In Canada (per transaction).</th>
                            </tr>
                            <tr>
                                <th>Load / Reload Fee</th>
                                <th>Free</th>
                                <th>
                                    We will charge you this fee each time you load or reload the
                                    Card.
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    INTERAC&reg; Email Money Transfer Fee (from Card to a bank
                                    account)
                                </th>
                                <th>$1.00</th>
                                <th>
                                    We will charge you this fee in each instance where you initiate
                                    an INTERAC e-Transfer from your Card to a bank account.
                                </th>
                            </tr>
                            <tr>
                                <th>Bill Payment</th>
                                <th>$1.95</th>
                                <th>
                                    We will charge you this fee for each instance where you utilize
                                    the Card to conduct an online bill payment.
                                </th>
                            </tr>
                            <tr>
                                <th>Monthly Fee</th>
                                <th>$4.95</th>
                                <th>
                                    We will charge this fee thirty (30) days after activation of the
                                    Card and each month thereafter.
                                </th>
                            </tr>
                            <tr>
                                <th>Foreign Currency Administration Fee</th>
                                <th>3.00%</th>
                                <th>
                                    For foreign currency transactions, we will charge you in
                                    Canadian currency if you use the Card to make transactions in a
                                    foreign currency. We will convert the transaction amount
                                    directly to Canadian dollars at the exchange rate in effect at
                                    the time we post the transaction to the Card. When the
                                    transaction is posted to the Card, in addition to the exchange
                                    rate, you will be charged the amount of this fee based on the
                                    amount of the transaction for each foreign currency transaction.
                                </th>
                            </tr>
                            <tr>
                                <th>Card to Card Transfer Fee</th>
                                <th>Free</th>
                                <th>
                                    We will charge you this fee in each instance where you transfer
                                    funds between Cards or Wallets.
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <p className={styles.center}>
                        <b>LIMITS TABLE</b>
                    </p>
                    <br />
                    <table className={styles.two_column}>
                        <tbody>
                            <tr>
                                <th>Wallet Load Daily Limit</th>
                                <th>
                                    A minimum amount of $20.00, up to a maximum amount of $2,500.00
                                    may be loaded on the Wallet per day.
                                </th>
                            </tr>
                            <tr>
                                <th>Card to Card Transfer Limit</th>
                                <th>
                                    Up to a maximum amount of $250.00 may be transferred between
                                    Cards or Wallets per transaction.
                                </th>
                            </tr>
                            <tr>
                                <th>INTERAC&reg; Email Money Transfer Load Limit</th>
                                <th>
                                    Up to a maximum amount of $500.00 may be loaded on the Wallet
                                    per transaction.
                                </th>
                            </tr>
                            <tr>
                                <th>Direct Deposit Load Limit</th>
                                <th>
                                    Up to a maximum amount of $2,500.00 may be loaded on the Wallet
                                    per transaction.
                                </th>
                            </tr>
                            <tr>
                                <th>POS Transaction Daily Limit</th>
                                <th>
                                    Up to a maximum of $2,500.00 per day is allowed for purchases of
                                    goods and services. Unlimited POS Transactions up to a maximum
                                    of Funds Available on the Wallet is allowed for purchases of
                                    goods and services.
                                </th>
                            </tr>
                            <tr>
                                <th>ATM Transaction Daily Limit</th>
                                <th>
                                    Up to a maximum of $250.00 per day is allowed for cash
                                    withdrawals from ATMs. Up to a maximum of 2 ATM Withdrawal
                                    Transactions per day is allowed for cash withdrawals from ATMs.
                                </th>
                            </tr>
                            <tr>
                                <th>Maximum Transaction Daily Limit</th>
                                <th>
                                    The maximum daily limit for all transactions is $2,500.00 per
                                    day of the Funds Available on the Wallet.
                                </th>
                            </tr>
                            <tr>
                                <th>Funds Available Limit</th>
                                <th>
                                    The maximum amount of Funds Available on the Wallet at any time
                                    is $2,500.00.
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    <p className={styles.point}>1. DEFINITIONS</p>
                    <br />
                    <p>The terms below have the following meanings when used in this Agreement:</p>
                    <br />
                    <p>
                        Agreement: this cardholder agreement for a prepaid card between DCBank and
                        the Cardholder, the Privacy Code, and any amendments, replacements or
                        updates we may provide from time to time.
                    </p>
                    <br />
                    <p>ATM: an automated teller machine.</p>
                    <br />
                    <p>
                        Card: a prepaid card issued by DCBank, or any replacement card, which allows
                        you to access funds loaded on the Wallet and which may also provide you with
                        access to certain products and services including, among other things, Third
                        Party Services. The term &quot;Card&quot; also includes the temporary
                        instant issue card that may be provided to you until you receive a permanent
                        Card.
                    </p>
                    <br />
                    <p>Cardholder: the cardholder noted above.</p>
                    <br />
                    <p>
                        Customer Service Representative: a customer service representative,
                        available to you through the Customer Service number listed on the first
                        page of the Agreement.
                    </p>
                    <br />
                    <p>DCBank: Digital Commerce Bank.</p>
                    <br />
                    <p>
                        Funds Available: the amount of funds that have been successfully loaded on
                        the Wallet, up to the Funds Available Limit (as described in the Limits
                        Table), less the total amount of: (i) all purchases (including any amounts
                        relating to pending or preauthorized transactions); (ii) any holds placed on
                        funds that have been loaded; (iii) any cash withdrawals; (iv) any fees and
                        other amounts that may be charged to a Card under the Agreement.
                    </p>
                    <br />
                    <p>
                        Loading: &quot;loading&quot; the Wallet is the process of adding funds or
                        increasing the balance of funds that may be accessed on a Card.
                    </p>
                    <br />
                    <p>
                        Head of an International Organization: (sometimes referred to as an HIO) is
                        a person who is either (i) the head of an international organization
                        established by the governments of states; or (ii) the head of an institution
                        established by an international organization. An international organization
                        is an organization set up by the governments of more than one country. If an
                        organization was established by means of a formally signed agreement between
                        the governments of more than one country, then the head of that organization
                        is a HIO. The existence of these organizations is recognized by law in their
                        member countries, but the organizations are not seen to be resident
                        organizations of any one-member country.
                    </p>
                    <br />
                    <p>
                        {' '}
                        Politically Exposed Canadian Person: a PEP (sometimes referred to as a
                        Domestic PEP) in/from/related to Canada.
                    </p>
                    <br />
                    <p>
                        Politically Exposed Foreign Person: a PEP (sometimes referred to as a PEFP)
                        in/from/related to a country outside of Canada.
                    </p>
                    <br />
                    <p>
                        Politically Exposed Person: (sometimes referred to as a PEP) is (A) any
                        person who holds or has ever held one of the following offices or positions
                        in or on behalf of a country: (1) a head of state or government, (2) a
                        member of the executive council of government or member of a legislature,
                        (3) a deputy minister (or equivalent), (4) an ambassador or an ambassador's
                        attach&eacute; or counsellor, (5) a military general (or higher rank), (6) a
                        president of a state owned company of bank, (7) a head of a government
                        agency, (8) a judge, or (9) a leader or president of a political party in a
                        legislature; or (B) any of the following family members of an individual
                        described in (A)(1)-(9): (i) mother, (ii) father, (iii) spouse, (iv) common
                        law partner, (v) spouse's or common law partner's mother or father, (vi)
                        brother, (vii) sister, (viii) half- brother, or (ix) half-sister, (in all
                        cases regardless of citizenship, residence status, or birth place).
                    </p>
                    <br />
                    <br />
                    <p>PIN: Personal Identification Number.</p>
                    <br />
                    <p>
                        POS Transaction: a transaction where you access any Funds Available to
                        purchase goods and services wherever the Card is accepted by a merchant or
                        business.
                    </p>
                    <br />
                    <p>
                        Privacy Code: the DCBank privacy code, which may be updated from time to
                        time.
                    </p>
                    <br />
                    <p>
                        Third Party Services: certain services (which may include credit or other
                        lending services and loyalty programs) which may be offered by a third party
                        other than DCBank, pursuant to your agreement(s) with any such third party,
                        and which the third party may associate with the Card or to which the third
                        party may provide access through the Card.
                    </p>
                    <br />
                    <p>
                        Transaction: any transaction that is associated with a Wallet or Card
                        including, but not limited to, any POS Transaction, as well as obtaining
                        cash at any applicable ATM.
                    </p>
                    <br />
                    <p>
                        Wallet: the virtual wallet which is associated with Cards issued under this
                        Agreement and includes the record of debits and credits with respect to
                        transactions originated by a Cardholder and any other transactions.
                    </p>
                    <br />
                    <p>We, us, our, and DCBank: DCBank or its agents.</p>
                    <br />
                    <p>
                        Website: the Website which is listed on the first page of this Agreement,
                        which you may access for details on the transactions you made on your Card
                        or to obtain a copy of the Agreement.
                    </p>
                    <br />
                    <p>
                        You, your, yours, and Cardholder: the Cardholder and any other person who
                        uses the Card.
                    </p>
                    <br />
                    <p className={styles.point}>
                        2. ACCEPTANCE AND AGREEMENT TO TERMS AND CONDITIONS
                    </p>
                    <br />
                    <p>
                        2.1 The Agreement is between you and DCBank and governs the use of the Card.
                        You should keep a copy of this Agreement with your important records. The
                        Agreement shall come into effect on the date that is the earlier of (i) the
                        date that we issue you a Card, (ii) the date that the Card is activated or
                        (iii) the date of the Card's first use.
                    </p>
                    <br />
                    <p>
                        2.2 You will receive a Card issued by DCBank. You must immediately, and
                        before using the Card, sign the back of the Card. By signing this Agreement,
                        loading a balance in the Wallet, or by activating or otherwise using the
                        Card, you, as the Cardholder, acknowledge having (i) received and read the
                        entire Agreement, (ii) accepted its terms and conditions, and (iii) agreed
                        to the fees that are detailed herein.
                    </p>
                    <br />
                    <p>
                        2.3 You may receive a temporary &quot;instant issue&quot; Card issued by
                        DCBank. Subsequently you may receive (to the address you provided) a
                        permanent Card with your name on it. Once you receive and activate the Card,
                        your temporary Card will no longer be active. You may also have more than
                        one permanent Card from time to time.
                    </p>
                    <br />
                    <div className={styles.info}>
                        <p className={styles.info_title}>
                            TO AVOID PROBLEMS WHEN USING YOUR CARD, WE RECOMMEND THAT YOU DO NOT USE
                            YOUR CARD IN THE SITUATIONS DESCRIBED IN SECTION 4.2 AND 4.3 OF THIS
                            AGREEMENT.
                        </p>
                        <br />
                        The Card may allow you to access certain products and services provided by
                        third parties other than DCBank, including, among other things, Third Party
                        Services. DCBANK DOES NOT PROVIDE ANY THIRD PARTY SERVICES INCLUDING,
                        WITHOUT LIMITATION, ANY LENDING, AND DCBANK DOES NOT CHARGE FOR ANY LOANS
                        PROVIDED BY ANY THIRD PARTY. The terms and conditions applicable to the
                        Third Party Services that are accessible through the Card are contained in
                        the agreement(s) between you and the third party provider of such Third
                        Party Services and DCBank is under no circumstances a party to any such
                        agreement(s). This Agreement only relates to the Card, the fees payable or
                        deductible as a result of the use of the Card, and any other matters
                        expressly described in this Agreement.
                    </div>
                    <br />
                    <p className={styles.point}>3. OWNERSHIP OF THE CARD</p>
                    <br />
                    <p>
                        3.1 The Card belongs to us and you cannot transfer it or the Wallet to
                        anyone else without our consent. The Card is for your use alone and you must
                        comply with the terms and conditions of this Agreement. You must return the
                        Card immediately when we request it.
                    </p>
                    <br />
                    <p>
                        3.2 You are responsible for all transactions initiated by use of the Card
                        and for all losses as set out in Section 6. If you permit someone else to
                        use the Card, you agree that we may refuse to authorize any transactions
                        initiated by such person with the Card. However, if we authorize
                        transactions by another person, we may treat such person as authorized by
                        you to use the Card and you authorize us to debit the amount of such
                        transactions from the Funds Available.
                    </p>
                    <br />
                    <p className={styles.point}>4. USING THE CARD</p>
                    <br />
                    <p>
                        4.1 The Card is a prepaid card, which means the Wallet must be loaded or
                        preloaded with a certain amount of funds prior to use of the Card. Your card
                        is not a credit card. We will only honour transactions up to the amount of
                        the Funds Available. You must always make sure you have sufficient Funds
                        Available to cover the transaction.
                    </p>
                    <br />
                    <p>
                        4.2 Except as provided in Section 5.3 below, the transaction will likely be
                        declined if the Funds Available on your Card are not sufficient to complete
                        the transaction, including any associated fees and taxes. You can ask the
                        merchant if they will accept a split tender transaction. This will enable
                        you to use the amount up to the amount of Funds Available and cover the
                        difference with another form of payment. Merchants are not obliged to accept
                        split tender transactions.
                    </p>
                    <br />
                    <p>
                        4.3 Certain merchants may place a hold on the Funds Available by way of a
                        pre-authorized charge. This may happen when you rent a car, reserve a hotel
                        room or pay for gas at the pump. You are able to use your Card in these
                        situations, however, the amount of Funds Available will be reduced by the
                        amount of the hold and, as a result, will not be available to you until the
                        merchant releases the hold. For this reason, we recommend that you do not
                        use your Card in these situations. You can still use your Card to pay for
                        these services when the transaction is completed. For example, you can use
                        your Card when you return the rental car, when you check out of your hotel
                        room, or when purchasing gas.
                    </p>
                    <br />
                    <p>
                        4.4 Any funds which are loaded in the Wallet: (i) are not on deposit with
                        DCBank; (ii) do not establish a separate individual deposit account; (iii)
                        do not earn interest; and (iv) are not insured deposits under the Canada
                        Deposit Insurance Corporation Act.
                    </p>
                    <br />
                    <p>
                        4.5 You may access any Funds Available to complete POS Transactions. The
                        Card includes a PIN and can be used to access any Funds Available to obtain
                        cash at ATMs displaying one of the card network logos or other acceptance
                        marks that are on the Card. When you use the Card, the amount of the
                        purchase plus any applicable fees and taxes for the POS Transaction or ATM
                        transaction will be deducted from the Funds Available.
                    </p>
                    <br />
                    <p>
                        4.6 We may, in our sole discretion and at any time, restrict your ability to
                        conduct an ATM transaction or otherwise modify your use permissions in an
                        ATM Transaction, which may include limiting the amount of funds that you can
                        withdraw in such ATM transaction.
                    </p>
                    <br />
                    <p>
                        4.7 The Card will be valid and usable until the Funds Available are depleted
                        or until the expiry date associated with the Card. Notwithstanding the
                        expiry date associated with the Card, your right to use the Funds Available
                        that are loaded in the Wallet will not expire. When the Card expires, you
                        can obtain another Card by calling a Customer Service Representative. In
                        order to load the Wallet, you may visit the merchant where you received the
                        Card (if applicable) or you may otherwise visit the Website for additional
                        options.
                    </p>
                    <br />
                    <p>
                        4.8 You agree to comply with all laws and not to use the Card for any
                        illegal transactions, including the purchase of goods or services forbidden
                        or not available to minors.
                    </p>
                    <br />
                    <p className={styles.point}>5. FUNDS AVAILABLE ON THE WALLET</p>
                    <br />
                    <p>
                        {' '}
                        5.1 The Wallet may be associated with one or more Cards. You may only load
                        the Wallet and use a Card up to the amounts described in the Limits Table
                        above. You acknowledge and agree that DCBank may from time to time and at
                        any time, in its sole and absolute discretion, amend the maximum amounts and
                        limits provided in the Limits Table for anygiven period, with or without
                        notice to you.
                    </p>
                    <br />
                    <p>5.2 The time before funds become available may vary.</p>
                    <br />
                    <p>
                        5.3 Your Card can only be used if the Funds Available on your Card are
                        sufficient to cover your transaction, including any associated fees and
                        taxes. Otherwise, the transaction will in most cases be declined. However,
                        if an authorization should occur for any amount over the amount of Funds
                        Available, due to an offline transaction or for any other reason, you
                        acknowledge and agree that you are required to immediately repay us the
                        amount that exceeds the Funds Available, regardless of how it was incurred.
                        If you do exceed the Funds Available and don't repay us the amount due plus
                        any applicable fees within 30 days, you agree that we can share information
                        about you and your use of the Card with consumer reporting agencies.
                    </p>
                    <br />
                    <p>
                        5.4 The Funds Available will decrease each time you use your Card for a
                        transaction, or any time one of the fees identified in Section 7 are
                        deducted.
                    </p>
                    <br />
                    <p>
                        5.5 If you do not have any Funds Available and you attempt three
                        transactions while the Card has insufficient Funds Available, we will
                        disable the Card to prevent any further transactions until you have a
                        positive balance of Funds Available.
                    </p>
                    <br />
                    <p>
                        5.6 You may obtain information about the Funds Available by calling a
                        Customer Service Representative at the number listed on the first page of
                        this Agreement. For details on the transactions you made on your Card or to
                        obtain a copy of the Agreement you can access the Website listed on the
                        first page of this Agreement. To access the DCBank information you will need
                        your user ID and password or other online authentication as allowed by
                        DCBank from time to time.
                    </p>
                    <br />
                    <p>
                        5.7 It is your responsibility to obtain a paper record of each POS
                        Transaction or ATM transaction for which you use the Card and ensure that it
                        is accurate. DCBank is not responsible for providing you with any
                        transaction record or periodic statement. If you identify an error in any
                        transaction record, you must address such error with the applicable merchant
                        or ATM operator. Furthermore, you must notify a Customer Service
                        Representative by contacting the number listed on the first page of this
                        Agreement within 30 days after the receipt is issued on which the problem or
                        error appeared, failing which DCBank will not have any responsibility to
                        assist you to rectify the situation.
                    </p>
                    <br />
                    <p>
                        5.8 If you mistakenly receive cash or credit that belongs to DCBank, a
                        merchant or other party, you agree to reimburse DCBank for any such error or
                        allow DCBank to correct such error by adjusting the amount of the Funds
                        Available.
                    </p>
                    <br />
                    <p className={styles.point}>
                        6. YOUR RESPONSIBILITY FOR LOST, STOLEN OR MISUSED CARDS
                    </p>
                    <br />
                    <p>
                        6.1 You must take reasonable care to safeguard the Card and your PIN against
                        loss, theft or misuse. You must not allow any person other than a Cardholder
                        to use the Card. If you authorize or permit someone else to use the Card
                        and/or PIN, you will be liable for all resulting transactions and any fees
                        and losses incurred, even if the other person was a minor or did not comply
                        with any limitations you placed on their use of the Card. You will be liable
                        for all charges incurred in connection with the unauthorized use of the
                        Card.
                    </p>
                    <br />
                    <p>
                        6.2 If you lose a Card, someone might be able to use the Card to access
                        Funds Available. A Card may be used without a PIN to make purchases. You are
                        solely responsible for the care and control of the Card and for maintaining
                        the confidentiality of your PIN. You can safeguard your PIN by:
                    </p>
                    <div className={styles.nested_list}>
                        <p>
                            <span>a.</span> Not voluntarily disclosing it to anyone, including
                            friends or family members;
                        </p>
                        <p>
                            <span>b.</span> Keeping your PIN separate from the Card; and
                        </p>
                        <p>
                            <span>c.</span>Not choosing a PIN selected from easily accessible
                            information such as your name, date of birth, telephone numbers, address
                            or social insurance number.
                        </p>
                    </div>
                    <br />
                    <p>
                        6.3 You must notify a Customer Service Representative by telephone within
                        twenty-four (24) hours if you learn of the loss, theft or misuse of the
                        Card, or if you know or suspect that someone else knows your PIN.
                    </p>
                    <br />
                    <p>
                        6.4 If someone uses the Card without your authorization, you will not be
                        held responsible if:
                    </p>
                    <div className={styles.nested_list}>
                        <p>
                            <span>a.</span> You did not contribute to the unauthorized use
                        </p>
                        <p>
                            <span>b.</span> You used reasonable care to safeguard the Card and your
                            PIN, and
                        </p>
                        <p>
                            <span>c.</span>You notified Customer Service Representative by telephone
                            within twenty-four (24) hours after you learned of the loss, theft or
                            misuse of the Card, or after you suspected that someone else knows your
                            PIN.
                        </p>
                    </div>
                    <p>
                        If you do not meet the above conditions, you will be responsible for all the
                        charges incurred in connection with the unauthorized use.
                    </p>
                    <br />
                    <p>
                        6.5 You agree to cooperate and help with any investigation that we conduct
                        regarding the unauthorized use you reported before we will consider
                        reimbursing you for any losses. This cooperation may include filing a report
                        with law enforcement authorities.
                    </p>
                    <br />
                    <p>
                        6.6 If you report to us an erroneous Card transaction and our investigation
                        reveals that it was not the fault of the merchant or ATM operator, we apply
                        the Charge Back Fee described in the Card Fees Information Box above to the
                        amount of Funds Available.
                    </p>
                    <br />
                    <p>
                        6.7 The toll-free number to call to notify a Customer Service Representative
                        is listed on the first page of the Agreement. If you give a Customer Service
                        Representative your name on the Card and the Card number, DCBank will refund
                        any remaining Funds Available after we process all transactions that were
                        completed before we had an opportunity to act on your information. You will
                        be required to answer an identifying question drawn from your personal
                        information. A replacement Card with any remaining Funds Available will be
                        issued within 21 days after you report the Card lost or stolen to ensure
                        that all transactions have been processed properly.
                    </p>
                    <br />
                    <p className={styles.points}>7. FEES WE CHARGE</p>
                    <br />
                    <p>
                        7.1 As of the date of this Agreement, the Card fees are set out in the Card
                        Fees Information Box and will also be posted on the Website. You are aware
                        that when using the Card, ATM operators and merchants may charge separate
                        additional fees for their services.
                    </p>
                    <br />
                    <p>
                        7.2 Any fees payable to, or deductible by, DCBank in connection with access
                        to a Card and as listed in the Card Fees Information Box may be charged
                        against the amount of Funds Available.
                    </p>
                    <br />
                    <div className={styles.info}>
                        Other fees may be associated with Third Party Services. DCBank is under no
                        obligation to disclose such fees in the Agreement or otherwise. YOU ARE NOT
                        OBLIGATED TO OBTAIN ANY SUCH THIRD PARTY SERVICES IN ORDER TO OBTAIN A CARD
                        UNDER THIS AGREEMENT NOR ARE YOU OBLIGATED BY DCBANK TO OBTAIN THE CARD FOR
                        SUCH THIRD PARTY SERVICES. You agree that DCBank is not responsible for any
                        matters pertaining to any Third Party Services. This Agreement only relates
                        to the Card, the fees payable or deductible as a result of the use of the
                        Card, and any other matters expressly described in this Agreement.
                    </div>
                    <br />
                    <p className={styles.point}>CHANGES TO THIS AGREEMENT</p>
                    <br />
                    <p>
                        8.1 A current copy of the Agreement can be obtained on the Website at any
                        time. We may propose to change any of the terms of this Agreement, either
                        permanently or temporarily (including any fee(s) or amounts to be paid by
                        you or Card features) or replace this Agreement with another agreement, at
                        any time. If we make a change to the Agreement, we will let you know by
                        sending a notice to the most recent address we have for you at least 30 days
                        in advance and by posting a notice on the Website at least 60 days in
                        advance of the change.
                    </p>
                    <br />
                    <p>
                        8.2 We may make any other change to the Agreement in order to comply with
                        any governmental, provincial, or federal laws or regulations governing the
                        Card or for any other reasons we may see fit by posting a notice on the
                        Website.
                    </p>
                    <br />
                    <p>8.3 Any changes will become effective on the date shown on the notice.</p>
                    <br />
                    <p>
                        8.4 You may refuse the amendment by terminating the Agreement in the manner
                        provided in Section 10.4 without cost, penalty or cancellation fees by
                        notifying us within thirty (30) days of the effective date of the change.
                    </p>
                    <br />
                    <p>
                        8.5 Using your Card after the effective date of the change means that you
                        agree to the changes, including any new or increased fees, schedules of
                        service, or other terms. If you do not agree to any change of this
                        Agreement, you agree to immediately stop using the Card, return the Card to
                        us and notify us that you are terminating this Agreement. You may update
                        your contact information by calling the Customer Service Representative
                        toll-free at the number listed on the first page of this Agreement.
                    </p>
                    <br />
                    <p className={styles.point}>FOREIGN CURRENCY TRANSACTIONS</p>
                    <br />
                    <p>
                        9.1 The Card is in Canadian currency. Unless otherwise specified, all
                        references to dollar amounts in the Agreement (without further description)
                        mean Canadian dollars.
                    </p>
                    <br />
                    <p>
                        9.2 We convert transactions made in a foreign currency to Canadian dollars.
                        If you make a purchase with the Card in a currency other than Canadian
                        currency, you authorize us to convert the amount of such transaction to
                        Canadian currency based on the rate charged by the card network (Visa or
                        MasterCard) on the day we process the transaction, plus a Foreign Currency
                        Administration Fee identified in the Card Fee Information Box above.
                    </p>
                    <br />
                    <p>
                        9.3 We will also convert credits (e.g. refunds or returns) in a foreign
                        currency to Canadian currency based on the rate charged by the network
                        (Visa, MasterCard, Cirrus or Plus) on the day we process the credit, and
                        charge a Foreign Currency Administration Fee as identified in the Card Fee
                        Information Box above.
                    </p>
                    <br />
                    <p>
                        9.4 Our refund exchange rate may not be the same as the rate that was in
                        effect on the date the transaction was refunded. The difference between our
                        purchase exchange rate and our refund exchange rate means that the amount
                        credited to the Card for a refund of a foreign currency transaction will in
                        most cases be less than the original amount charged to the Card for the
                        transaction.
                    </p>
                    <br />
                    <p className={styles.point}>10. CANCELLATION</p>
                    <br />
                    <p>
                        10.1 In provinces and territories other than Quebec, we may at any time,
                        without notice, do any of the following:
                    </p>
                    <div className={styles.nested_list}>
                        <p>
                            <span>a.</span>cancel any Card on the account;
                        </p>
                        <p>
                            <span>b.</span> cancel your rights and privileges related to the Card;
                            or
                        </p>
                        <p>
                            <span>c.</span>require you to immediately return all the Cards to us.
                        </p>
                    </div>
                    <br />
                    <p>
                        10.2 In Quebec, even if you are not in default under this Agreement, we may
                        terminate the Agreement at any time, upon 60 days written notice to you.
                    </p>
                    <br />
                    <p>10.3 If we cancel the Card, withdraw your rights and privileges:</p>
                    <br />
                    <div className={styles.nested_list}>
                        <p>
                            <span>a.</span>We may seize the Card;
                        </p>
                        <p>
                            <span>b.</span> You may not use the Card;
                        </p>
                        <p>
                            <span>c.</span>You must destroy the Card;
                        </p>
                        <p>
                            <span>d.</span>You must immediately repay any amount that exceeds the
                            Funds Available and any applicable fees; and
                        </p>
                        <p>
                            <span>e.</span>You may pay the legal fees and expenses we incur to
                            recover the amounts you owe us.
                        </p>
                    </div>
                    <br />
                    <p>
                        10.4 You may at any time terminate this Agreement and any Card by returning
                        such Card(s) to DCBank or other authorized agent. Despite any termination of
                        this Agreement, you must fulfill all of your obligations under this
                        Agreement, and you remain responsible for any use of the Card even after the
                        Card is terminated or expires.
                    </p>
                    <br />
                    <p className={styles.points}>11. ABOUT YOUR PERSONAL INFORMATION</p>
                    <br />
                    <p>
                        11.1 Personal information is information that identifies you as an
                        individual. It includes not only your name and address, age and gender, but
                        also your personal financial records, identification numbers, including your
                        social insurance number, personal references and employment records.
                    </p>
                    <br />
                    <p>
                        11.2 In the course of providing the Card, we will collect and use your
                        personal information to confirm your identity, obtain a credit report,
                        provide Card services to you, provide notifications, and communicate with
                        you by telephone or writing. We may also use your personal information to
                        report to any governmental body or agency, a credit bureau or to a
                        third-party collection agency, and to collect any amounts you owe us.
                    </p>
                    <br />
                    <p>
                        11.3 Except as required by law or for the purposes of this Agreement, we
                        will protect the confidentiality of your personal information and will not
                        disclose your personal information without your consent. You may withdraw
                        your consent to such use at any time by notifying us in writing. In the
                        event you refuse to consent to our use of your information, and that as a
                        consequence, we may no longer serve you, you must return the Card to DCBank.
                        The Card will be cancelled and the balance of any Funds Available will
                        returned to you subject to the requirements of this Agreement.
                    </p>
                    <br />
                    <p>
                        11.4 Calls to a Customer Service Representatives may be monitored and/or
                        recorded for quality purposes.
                    </p>
                    <br />
                    <p>
                        11.5 We are committed to respecting the privacy of your information and we
                        will not collect, use or disclose your information in a manner that is
                        inconsistent with the Privacy Code available at www.dcbank.ca.
                    </p>
                    <br />
                    <p className={styles.point}>12. POLITICALLY EXPOSED PERSON</p>
                    <br />
                    <p>
                        12.1 By signing this Agreement, you certify to DCBank that you are not a
                        Politically Exposed Foreign Person, or Politically Exposed Canadian Person
                        or Head of an International Organization and that you are applying for a
                        Card on your own behalf and not on behalf of any other person.
                    </p>
                    <br />
                    <p className={styles.point}>13. ELECTRONIC COMMUNICATIONS</p>
                    <br />
                    <p>
                        13.1 If your consent is required by applicable law and you provide us with
                        your consent, we may provide any information relating to the Card
                        electronically, including over the internet, through online or mobile
                        banking or to any email address that you have provided for this purpose.
                    </p>
                    <br />
                    <p>
                        13.2 If you have provided us with an email address or cellular phone number
                        capable of receiving text/SMS messages, it means that you agree that we can
                        send you messages by text/SMS messages. Information sent via text/SMS
                        messages may contain personal information and that it is your responsibility
                        to ensure that the numbers you provided are secure and under your control.
                        If you would like to discontinue email or text/SMS messaging, you may do so
                        by contacting us through the number listed on the first page of this
                        Agreement. If the email addresses or cellular numbers you provided are
                        invalid, cease to be active, or bounce back messages indicating the
                        applicable email or phone account is not able to receive messages, we will
                        have no obligation to try to contact you through other means to obtain a
                        valid and operating email address or cellular number. Any cost incurred by
                        you as a result of receiving email messages or text/SMS messages are your
                        responsibility.
                    </p>
                    <br />
                    <p>
                        13.3 For legal purposes, documents that you have received electronically
                        will be considered to be &quot;in writing&quot; and to have been signed
                        and/or delivered by DCBank. We may rely upon and treat as duly executed and
                        binding on you any electronically authenticated document that we receive
                        from you or which appears to have been sent by you.
                    </p>
                    <br />
                    <p className={styles.point}>14. IF YOU HAVE A PROBLEM WITH A CARD</p>
                    <br />
                    <p>
                        14.1 You agree that we will not be liable to you for any loss, damages or
                        expenses whatsoever due to:
                    </p>
                    <div className={styles.nested_list}>
                        <p>
                            <span>a.</span>any of your instructions not being sufficiently clear;
                        </p>
                        <p>
                            <span>b.</span> any failure by you to provide correct information;
                        </p>
                        <p>
                            <span>c.</span>any failure due to events outside our reasonable control,
                            including the failure, error, malfunction or technical problems related
                            to systems or equipment;
                        </p>
                        <p>
                            <span>d.</span>any system failure or industrial dispute;
                        </p>
                        <p>
                            <span>e.</span>any ATM refusing to, or being unable to, accept the Card;
                        </p>
                        <p>
                            <span>f.</span>any problem you have with a merchant, including a
                            merchant's refusal to accept our Card or to provide a good or a product;
                        </p>
                        <p>
                            <span>g.</span>any problem, failure, or any other matter relating to any
                            Third Party Services;
                        </p>
                        <p>
                            <span>h.</span>the lack of suitability or quality of any goods or
                            services purchased from retailers through the use of the Card;
                        </p>
                        <p>
                            <span>i.</span>an ATM's failure to dispense cash;
                        </p>
                        <p>
                            <span>j.</span>our taking of any action required by any government under
                            federal or provincial law or court order;
                        </p>
                        <p>
                            <span>k.</span>anything specifically excluded or limited elsewhere in
                            this Agreement; or
                        </p>
                        <p>
                            <span>l.</span>any breach or violation of this Agreement by you.
                        </p>
                    </div>
                    <br />
                    <p>
                        14.2 Except in Quebec, we will not be liable for any claims whatsoever,
                        including claims for personal injury, death, damage to property or economic
                        loss, howsoever caused, arising from the use of the Card, negligence on our
                        part, breach of contract or any other tort or cause of action at common law,
                        in equity or by statute.
                    </p>
                    <br />
                    <p>
                        14.3 You agree that our aggregate liability to you in respect of all causes
                        of action arising under this Agreement or in connection with a Card shall be
                        limited to the Card fees incurred in the previous twelve (12) month period.
                    </p>
                    <br />
                    <p>
                        14.4 Except for any Cardholder residing and domiciled in the province of
                        Quebec, with respect to any action or claim related, directly or indirectly,
                        to a complaint or inquiry, you agree that joinder or consolidation of any
                        action with the action or actions of any other persons is not permitted and
                        you will not request and will oppose any such joinder or consolidation.
                        Furthermore, you agree not to commence or participate in any class action
                        either as a representative plaintiff or as a member of a plaintiff if the
                        class action involves directly or indirectly any complaint or inquiry
                        arising out of or related to this Agreement.
                    </p>
                    <br />
                    <p>
                        14.5 Information sent over the Internet may not be completely secure and the
                        Internet and related online systems may not function at all times.
                        Accordingly, we are not responsible for any loss or damages that you may
                        incur if a third party obtains access to your confidential information
                        transmitted over the Internet or if you are temporarily unable to access
                        your information pertaining to the Funds Available.
                    </p>
                    <br />
                    <p className={styles.point}>15. IF YOU HAVE A COMPLAINT</p>
                    <br />
                    <p>
                        If you have a complaint or inquiry about any aspect of a Card, including
                        loading the Wallet or the Funds Available, please call a Customer Service
                        Representative toll-free at the number listed on the first page of this
                        Agreement. You may also call a DCBank customer service representative at the
                        DCBank Customer Service number listed on the first page of this Agreement.
                    </p>
                    <br />
                    <p>
                        If you have a complaint, we encourage you to let us know and give us the
                        opportunity to resolve the issue. A copy of DCBank's complaint and dispute
                        resolution procedure is available on the DCBank website and can be directly
                        accessed here: https://www.dcbank.ca/legal/resolving-complaints/. We will do
                        our best to resolve your complaint or inquiry.
                    </p>
                    <br />
                    <p>
                        ll banks must comply with a variety of federal consumer laws that protect
                        you. The Financial Consumer Agency of Canada (&quot;FCAC&quot;) supervises
                        all federally regulated financial institutions for compliance with federal
                        consumer protection laws. The FCAC does not resolve individual customer
                        complaints, but will determine whether we are in compliance. If you have a
                        complaint about a potential violation of federal consumer protection laws,
                        please contact us or you may contact the FCAC by mail at: 6th Floor,
                        Enterprise Building 427 Laurier Avenue West, Ottawa, Ontario K1R 1B9, by
                        phone at 1-866-461-3222 for service in English or 1-866-461-2232 for service
                        in French or online at www.fcac-acfc.gc.ca.
                    </p>
                    <br />
                    <p className={styles.point}>16. OTHER TERMS</p>
                    <br />
                    <p>16.1 Entire Agreement</p>
                    <br />
                    <p>
                        This sets out the entire agreement between the parties with respect to the
                        services provided by DCBank for the use of the Card. This Agreement replaces
                        all prior agreements and understandings between the parties with respect to
                        the Card.
                    </p>
                    <br />
                    <p>16.2 Severability</p>
                    <br />
                    <p>
                        If any term of this Agreement is found by a court to be illegal or not
                        enforceable, all other terms will still be in effect.
                    </p>
                    <br />
                    <p>16.3 Assignments</p>
                    <br />
                    <p>
                        The Card, the Wallet, and your obligations under this Agreement may not be
                        assigned. We may transfer our rights under this Agreement.
                    </p>
                    <br />
                    <p>16.4 Governing Law</p>
                    <br />
                    <p>
                        This Agreement will be governed by the laws of the province of Alberta and
                        the applicable laws of Canada. The parties submit to the exclusive
                        jurisdiction of the courts of Alberta in relation to any dispute arising out
                        of this Agreement.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CardAgreementModal;
