import React, { ChangeEvent, FC, ReactNode, useEffect, useId, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import { joiningArrayWords, prettifyFileSize } from '@utils/index';

export type TAcceptedFile =
    | '.doc'
    | '.xls'
    | 'image/png'
    | 'image/jpg'
    | 'image/jpeg'
    | '.pdf'
    | '.docx';

export const fileFormatString = {
    '.doc': 'doc',
    '.xls': 'xls',
    'image/png': 'png',
    'image/jpg': 'jpg',
    'image/jpeg': 'jpeg',
    '.pdf': 'pdf',
    '.docx': 'docx'
};

interface IProps {
    variant?: 'clickable' | 'default';
    label?: ReactNode;
    full?: boolean;
    size: number; // in MB
    accept: TAcceptedFile[];
    onChange: (files: FormData[]) => void;
}

const cx = classNames.bind(styles);

interface FileInfo {
    name: string;
    id: number;
    size: number;
    type: TAcceptedFile;
}

const Uploader: FC<IProps> = ({ variant = 'default', label, full, size, accept, onChange }) => {
    const [value, setValue] = useState<FormData[]>([]);
    const [filesInfo, setFilesInfo] = useState<FileInfo[]>([]); // State to store file details
    const id = useId();

    const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const files = e?.target?.files;
        if (files) {
            // Clear existing values
            setValue([]);
            setFilesInfo([]);

            const uploadedFilesInfo: FileInfo[] = [];
            Array.from(files).forEach((item) => {
                // Collect file details (name and size)
                uploadedFilesInfo.push({
                    id: Math.random(),
                    name: item.name,
                    size: item.size,
                    type: item.type as TAcceptedFile // Convert size to MB
                });

                // Append file to FormData
                const formData = new FormData();
                formData.append('file', item);
                setValue((state) => [...state, formData]);
            });

            // Update state with file details
            setFilesInfo(uploadedFilesInfo);
        }
    };

    useEffect(() => {
        onChange(value);
    }, [value]);

    return (
        <div className={cx(styles.wrapper, { full })}>
            {label}
            <label className={styles.drop_wrapper}>
                <input
                    type="file"
                    size={(size * 10) ^ 6}
                    accept={accept.join(',')}
                    onChange={handleUpload}
                    id={id}
                    style={{ display: 'none' }}
                    multiple
                />
                <Icon className={styles.icon} name="uploader" />
                <div className={styles.content_wrapper}>
                    <div className={styles.title}>
                        Drag and drop file or <span>upload</span>
                    </div>
                    <div className={styles.accept}>
                        Accepted file types:{' '}
                        {joiningArrayWords(accept.map((item) => fileFormatString[item]))}. Max file
                        size:
                        {size}MB
                    </div>
                </div>
            </label>
            {filesInfo.length > 0 && (
                <div className={styles.files_wrapper}>
                    {filesInfo.map((file, i) => (
                        <div key={file.id} className={styles.file}>
                            <div className={styles.file_icon}>
                                <Icon name="uploaded_file_format" width={24} height={32} />
                                <p className={styles.text}>{fileFormatString[file.type]}</p>
                            </div>
                            <div className={styles.content}>
                                <div className={styles.title}>
                                    <p>{file.name}</p> <span>{prettifyFileSize(file.size)}</span>
                                </div>
                            </div>
                            <button
                                className={styles.delete}
                                onClick={() => {
                                    setValue((state) => state.filter((item, index) => i !== index));
                                    setFilesInfo((state) =>
                                        state.filter((item, index) => i !== index)
                                    );
                                }}>
                                <Icon name="trash" />
                            </button>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Uploader;
