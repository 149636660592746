import React, { createContext, useLayoutEffect, useMemo, useState } from 'react';
import { EUserRole, TBusinessProfile, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';
import { apiAccountService, apiAuthService, apiUserService } from '@api';
import { routes } from '@const/routes';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isBusinessUserTypeGuard, TBusinessDetails } from '@types';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { TAccount, TCashbackStat } from '@xeppt/xeppt-sdk/types';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';
import { useSupportChat } from '@hooks/helpers/useSupportChat';

type TUserContext = {
    user?: TIndividualProfile | TBusinessProfile;
    account?: TAccount;
    cashback?: TCashbackStat;
    businessData?: TBusinessDetails;
    handleLogout: (isRedirect?: boolean) => void;
    refetchAccount: () => void;
    refetchCashback: () => void;
    refetchUser: () => void;
    isDataLoading: boolean;
};

const initialState: TUserContext = {
    user: undefined,
    account: undefined,
    cashback: undefined,
    businessData: undefined,
    handleLogout: () => Promise.resolve(),
    refetchAccount: () => Promise.resolve(),
    refetchCashback: () => Promise.resolve(),
    refetchUser: () => Promise.resolve(),
    isDataLoading: true
};

const publicRoutes = [
    routes.signin,
    routes.forgot_password,
    routes.signup,
    routes.terms_of_service,
    routes.privacy_policy,
    routes.extension_install,
    routes.extension_uninstall,
];

const authRoutes = [routes.signin, routes.forgot_password, routes.signup];

export const UserContext = createContext<TUserContext>(initialState);

export const UserContextProvider = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [businessData, setBusinessData] = useState<TBusinessDetails>();
    const [user, setUser] = useState<TBusinessProfile | TIndividualProfile>();
    const [cashback, setCashback] = useState<TCashbackStat>();
    const [account, setAccount] = useState<TAccount>();
    const [isAuth, setIsAuth] = useState(true);
    const [isRender, setIsRender] = useState(false);
    useSupportChat(user);

    const handleLogout = (isRedirect?: boolean) => {
        apiAuthService.logout().catch(() => {});
        navigate(routes.signin, {
            state: { redirect: isRedirect ? location.pathname : undefined }
        });
        setUser(undefined);
        setCashback(undefined);
        setBusinessData(undefined);
        setAccount(undefined);
        localStorage.removeItem('client-metadata');
    };

    const { isLoading: isCashbackLoading, handleRequest: refetchCashback } = useApiQuery({
        method: () => apiAccountService.getCashbackStat(),
        condition: !isAuth,
        deps: [isAuth],
        onSuccess: (data) => setCashback(data)
    });

    const { isLoading: isUserLoading, handleRequest: refetchUser } = useApiQuery({
        method: () => apiUserService.profile(),
        condition: !isAuth,
        deps: [isAuth],
        onSuccess: (data) => {
            moment.tz.setDefault(data.profile.timezone);
            if (data.role !== EUserRole.REGULAR_USER) {
                handleLogout();
            }
            setUser(data);
            if (isBusinessUserTypeGuard(data)) {
                if (data.business) {
                    const { business } = data;
                    setBusinessData(business);
                }
            }
        },
        onError: () => {
            handleLogout(true);
        }
    });

    const { handleRequest: refetchAccount, isLoading: isAccountLoading } = useApiQuery({
        method: () => apiAccountService.getAccount(),
        onSuccess: (data) => {
            setAccount(data);
        },
        condition: !isAuth,
        deps: [isAuth]
    });

    useLayoutEffect(() => {
        if (publicRoutes.includes(location.pathname)) {
            setIsAuth(true);
        } else {
            setIsAuth(false);
        }
        setIsRender(true);
    }, [location, user]);

    const isDataLoading = useMemo(() => {
        return isUserLoading || isAccountLoading || isCashbackLoading;
    }, [isUserLoading, isAccountLoading, isCashbackLoading]);

    const isShowUi = useMemo(() => {
        if (!publicRoutes.includes(location.pathname) && user && isRender) {
            return true;
        }
        if (publicRoutes.includes(location.pathname) && isRender) {
            return true;
        }
    }, [user, isRender, location]);

    return (
        <UserContext.Provider
            value={{
                user,
                account,
                businessData,
                refetchCashback,
                cashback,
                handleLogout,
                refetchAccount,
                refetchUser,
                isDataLoading
            }}>
            {isShowUi && (
                //@ts-ignore
                <AnimatePresence>
                    <Outlet />
                </AnimatePresence>
            )}
        </UserContext.Provider>
    );
};
