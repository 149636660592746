import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiSupportService } from '@api';
import { TCreateRequest } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';

export const useHelpCenterModals = (refetchTickets?: () => void) => {
    const { onOpen, onClose, handleChangeLoading, changeModalData } = useModalContext();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();

    const { handleRequest: handleCreateRequest } = useApiMutation({
        method: (payload: TCreateRequest) => apiSupportService.createTicket(payload),
        onSuccess: () => {
            onClose();
            handleChangeLoading(false);
            refetchTickets && refetchTickets();
            requestSuccessLocale('create_request');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleUploadAttachment } = useApiMutation({
        method: (payload: FormData) => apiSupportService.uploadAttachment(payload)
    });

    const handleOpenSubmitRequestModal = (transactionId?: string) => {
        if (transactionId) {
            changeModalData({ transactionId, subject: `Transaction ${transactionId}` });
        }
        onOpen({
            modalId: modalIds.SUBMIT_HELP_REQUEST,
            onSubmit: (modalData: {
                comment: string;
                subject: string;
                uploads?: FormData[];
                reason: string;
            }) => {
                handleChangeLoading(true);
                if (modalData?.uploads && modalData?.uploads.length > 0) {
                    Promise.all(modalData.uploads.map((item) => handleUploadAttachment(item))).then(
                        (data) => {
                            handleCreateRequest({
                                comment: modalData?.comment,
                                subject: modalData?.subject,
                                //@ts-ignore
                                uploads: data.map((item) => item?.token)
                            });
                        }
                    );
                } else {
                    handleCreateRequest({
                        comment: modalData?.comment,
                        subject: modalData?.subject,
                        reason: modalData?.reason,
                        uploads: []
                    });
                }
            }
        });
    };

    const handleOpenPreviewAttachmentModal = (data: {
        url: string;
        name: string;
        date: Date;
        avatar?: string;
    }) => {
        changeModalData(data);
        onOpen({
            modalId: modalIds.PREVIEW_ATTACHMENT
        });
    };

    return {
        handleOpenSubmitRequestModal,
        handleOpenPreviewAttachmentModal
    };
};
