import React, { FC, SVGAttributes } from 'react';

const Trash: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.4948 7.60059C14.4948 7.60059 14.0876 12.6518 13.8513 14.7796C13.7388 15.7958 13.1111 16.3913 12.0828 16.4101C10.1261 16.4453 8.16706 16.4476 6.21106 16.4063C5.22181 16.3861 4.60456 15.7831 4.49431 14.7848C4.25656 12.6383 3.85156 7.60059 3.85156 7.60059"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.531 5.17969H2.8125"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.079 5.17974C12.4902 5.17974 11.9832 4.76349 11.8677 4.18674L11.6855 3.27474C11.573 2.85399 11.192 2.56299 10.7577 2.56299H7.58297C7.14872 2.56299 6.76772 2.85399 6.65522 3.27474L6.47297 4.18674C6.35747 4.76349 5.85047 5.17974 5.26172 5.17974"
                stroke="currentColor"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Trash;
