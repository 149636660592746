import React, { FC, useMemo } from 'react';
import illustration from '@svg/illustrations/send_money_success.svg';
import illustrationBankSend from '@svg/illustrations/send_money_bank.svg';
import illustrationRequest from '@svg/illustrations/success_request.svg';
import illustrationRequestSchedule from '@svg/illustrations/request_shcedule.svg';
import illustrationLoad from '@svg/illustrations/load_balance.svg';
import illustrationLoadSchedule from '@svg/illustrations/load_balance_schedule.svg';
import illustrationSchedule from '@svg/illustrations/send_schedule_success.svg';
import illustrationBill from '@svg/illustrations/pay_bill.svg';
import illustrationBillSchedule from '@svg/illustrations/pay_bill_schedule.svg';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { ESendRequestMoneyMethod } from '@enum';

interface IProps {
    isSchedule: boolean;
    onReset: () => void;
    method: ESendRequestMoneyMethod;
}

const Success: FC<IProps> = ({ isSchedule, onReset, method }) => {
    const { submitLocale } = useLocales();
    const navigate = useNavigate();

    const data = useMemo(() => {
        switch (method) {
            case ESendRequestMoneyMethod.INTERNAL:
                return {
                    illustration: illustration,
                    illustrationSchedule: illustrationSchedule,
                    description:
                        'Payment sent to processing! We will notify you, when the payment will be processed',
                    scheduleDescription:
                        'Your payment has been successfully scheduled. You can check the progress of your scheduled payments and manage them on',
                    submitButton: 'New payment',
                    scheduleLink: routes.send_and_request_schedule
                };
            case ESendRequestMoneyMethod.E_TRANSFER:
                return {
                    illustration: illustration,
                    illustrationSchedule: '',
                    description:
                        'Payment sent to processing! We will notify you, when the payment will be processed',
                    scheduleDescription: '',
                    submitButton: 'New payment',
                    scheduleLink: routes.send_and_request_schedule
                };
            case ESendRequestMoneyMethod.BANK:
                return {
                    illustration: illustrationBankSend,
                    illustrationSchedule: illustrationBankSend,
                    description:
                        'Payment sent to processing! We will notify you, when the payment will be processed',
                    scheduleDescription:
                        'Your payment has been successfully scheduled. You can check the progress of your scheduled payments and manage them on',
                    submitButton: 'New payment',
                    scheduleLink: routes.send_and_request_schedule
                };
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                return {
                    illustration: illustrationRequest,
                    illustrationSchedule: illustrationRequestSchedule,
                    description:
                        'Your payment request has been successfully sent. You’ll receive a notification once the recipient accepts or declines your request. If accepted, money will be deposited directly into your wallet',
                    scheduleDescription:
                        'Your payment request has been successfully scheduled. You’ll receive a notification once the recipient accepts or declines your request. You can check the progress of your scheduled requests and manage them on',
                    submitButton: 'New payment request',
                    scheduleLink: routes.send_and_request_schedule
                };
            case ESendRequestMoneyMethod.E_TRANSFER_REQUEST:
                return {
                    illustration: illustrationRequest,
                    illustrationSchedule: illustrationRequestSchedule,
                    description:
                        'Your payment request has been successfully sent. You’ll receive an email from Interac once the recipient accepts or declines your request. If accepted, money will be deposited directly into your wallet',
                    scheduleDescription: '',
                    submitButton: 'New payment request',
                    scheduleLink: routes.send_and_request_schedule
                };
            case ESendRequestMoneyMethod.BANK_REQUEST:
                return {
                    illustration: illustrationLoad,
                    illustrationSchedule: illustrationLoadSchedule,
                    description:
                        'Your load balance request has been successfully sent. You’ll receive a notification once the money is deposited into your wallet',
                    scheduleDescription:
                        'Your load balance request has been successfully scheduled. You’ll receive a notification once the money is deposited into your wallet',
                    submitButton: 'New load balance',
                    scheduleLink: routes.send_and_request_schedule
                };
            case ESendRequestMoneyMethod.EPS_REQUEST:
                return {
                    illustration: illustrationLoad,
                    illustrationSchedule: illustrationLoadSchedule,
                    description:
                        'Your load balance request has been successfully sent. You’ll receive a notification once the money is deposited into your wallet',
                    scheduleDescription: '',
                    submitButton: 'New load balance',
                    scheduleLink: routes.send_and_request_schedule
                };
            case ESendRequestMoneyMethod.PAY_BILLS:
                return {
                    illustration: illustrationBill,
                    illustrationSchedule: illustrationBillSchedule,
                    description:
                        'Your bill payment sent to processing! We will notify you, when the payment will be processed',
                    scheduleDescription:
                        'Your bill payment has been successfully scheduled. You can check the progress of your scheduled bill payments and manage them on',
                    submitButton: 'New bill payment',
                    scheduleLink: routes.pay_bills_schedule
                };
        }
    }, [method, isSchedule]);

    if (!data) return null;

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <img
                src={isSchedule ? data.illustrationSchedule : data.illustration}
                alt="illustration"
            />
            <div className={styles.text_wrapper}>
                <div className={styles.title}>SUCCESS!</div>
                <div className={styles.description}>
                    {isSchedule ? data.scheduleDescription : data.description}{' '}
                    {isSchedule && (
                        <Link className={styles.link} to={data.scheduleLink}>
                            Schedule payments page
                        </Link>
                    )}
                </div>
            </div>
            <div className={styles.actions}>
                <Button
                    variant="outlined-dark"
                    size="normal"
                    onClick={() => navigate(routes.dashboard)}>
                    {submitLocale('done')}
                </Button>
                <Button variant="primary" size="normal" onClick={onReset}>
                    {data.submitButton}
                </Button>
            </div>
        </motion.div>
    );
};

export default Success;
