import React, { FC, useMemo } from 'react';
import ChooseMethod from '@sections/send_and_request/steps/choose_method';
import { ESendRequestMoneyMethod } from '@enum';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import { routes } from '@const/routes';
import { useNotFound } from '@hooks/helpers/useNotFound';

interface IProps {
    onChangeMethod: (val: ESendRequestMoneyMethod) => void;
    method: ESendRequestMoneyMethod;
    onSubmit: () => void;
    type: 'request' | 'send';
    styles: any;
    title: string;
}

const requestMethods = [
    ESendRequestMoneyMethod.INTERNAL_REQUEST,
    // ESendRequestMoneyMethod.E_TRANSFER_REQUEST,
    ESendRequestMoneyMethod.BANK_REQUEST,
    ESendRequestMoneyMethod.EPS_REQUEST
];

const sendMethods = [
    ESendRequestMoneyMethod.INTERNAL,
    // ESendRequestMoneyMethod.E_TRANSFER,
    ESendRequestMoneyMethod.BANK
];

const ChooseMethodSection: FC<IProps> = ({ onChangeMethod, onSubmit, type, title, styles }) => {
    const { handleBack } = useNotFound();
    const methods = useMemo(() => (type === 'request' ? requestMethods : sendMethods), [type]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        onClick={() => handleBack(routes.send_and_request)}>
                        Back
                    </Button>
                    <Typography variant="h4">{title}</Typography>
                </div>
                <div className={styles.cards_wrapper}>
                    {methods.map((item, i) => (
                        <ChooseMethod
                            key={i}
                            onClick={() => {
                                onChangeMethod(item);
                                onSubmit();
                            }}
                            type={item}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ChooseMethodSection;
