import { useEffect } from 'react';
import { TBusinessProfile, TIndividualProfile } from '@xeppt/xeppt-sdk/types/user';

export const useSupportChat = (user?: TIndividualProfile | TBusinessProfile) => {
    useEffect(() => {
        if (user) {
            const script = document.createElement('script');
            const launcher = document.querySelector('[style="visibility: hidden;"]');

            if (launcher) {
                //@ts-ignore
                launcher.style.visibility = 'visible';
            }

            script.src =
                'https://static.zdassets.com/ekr/snippet.js?key=0eb81945-e753-4896-80d7-9d18d5ae3f18'; // Replace with the actual URL
            script.async = true;
            script.id = 'ze-snippet';

            document.body.appendChild(script);
        }

        return () => {
            const oldScript = document.getElementById('ze-snippet');
            const launcher = document.querySelector('[style="visibility: visible;"]');
            if (oldScript) {
                document.body.removeChild(oldScript);
            }
            if (launcher) {
                //@ts-ignore
                launcher.style.visibility = 'hidden';
            }
        };
    }, [user]);
};
