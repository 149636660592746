import React from 'react';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import useModalContext from '@hooks/context/useModalContext';
import { useTranslation } from 'react-i18next';

const DeleteCardModal = () => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'delete_account'
    });
    const { modalData } = useModalContext();
    return (
        <div className={styles.wrapper}>
            <Icon name="error_outlined" width={60} height={60} />
            <Typography variant="h4">{t('title')}</Typography>
            <Typography className={styles.title} variant="body2">
                {modalData?.cardBrand} {modalData?.cardNumber} {t('removed')}
            </Typography>
        </div>
    );
};

export default DeleteCardModal;
