import React, { FC } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { IModalComponentProps } from '@context/modal_context';

const DeletePhoneModal: FC<IModalComponentProps<{ phoneCode: string; phoneNumber: string }>> = ({
    modalData
}) => {
    const { t } = useTranslation('modals', {
        keyPrefix: 'delete_phone'
    });

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" className={styles.title}>
                {t('title')}
            </Typography>
            <Typography variant="body2" className={styles.description}>
                {t('sure')}{' '}
                <b>
                    {modalData.phoneCode} {modalData.phoneNumber}
                </b>
                ?
            </Typography>
        </div>
    );
};

export default DeletePhoneModal;
