import { modalIds } from '@const/modals';
import { apiUserService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';
import useModalContext from '@hooks/context/useModalContext';
import { TEmail, TNewPhone, TPhone } from '@xeppt/xeppt-sdk/types';
import { phoneCode } from '@xeppt/xeppt-sdk/validator';

export const useSettingsModals = () => {
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { refetchUser } = useUserContext();
    const { onOpen, onClose, handleChangeLoading, changeModalData } = useModalContext();

    const handleChangeAvatar = () => {
        onOpen({
            modalId: modalIds.SETTINGS_UPLOAD_IMAGE,
            onSubmit: (data: { avatar: string }) => {
                handleChangeLoading(true);
                apiUserService
                    .updateAvatar(data.avatar)
                    .then(() => {
                        refetchUser();
                        onClose();
                        requestSuccessLocale('update_avatar');
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleDeleteAvatar = () => {
        apiUserService
            .deleteAvatar()
            .then(() => {
                refetchUser();
                requestSuccessLocale('delete_avatar');
                onClose();
            })
            .catch(requestErrorLocale);
    };

    const handleOpenChangePasswordModal = () => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: (data: { secureId: string; code: string }) => {
                onOpen({
                    modalId: modalIds.SETTINGS_CHANGE_PASSWORD,
                    onSubmit: ({
                        oldPassword,
                        newPassword
                    }: {
                        oldPassword: string;
                        newPassword: string;
                    }) => {
                        handleChangeLoading(true);
                        apiUserService
                            .changePassword(oldPassword, newPassword, {
                                id: data.secureId,
                                code: data.code
                            })
                            .then(() => {
                                requestSuccessLocale('change_password');
                                onClose();
                            })
                            .catch(requestErrorLocale)
                            .finally(() => handleChangeLoading(false));
                    }
                });
            }
        });
    };

    const handleOpenAddPhoneModal = (refetchPhones: () => void) => {
        onOpen({
            modalId: modalIds.SETTINGS_ADD_PHONE_MODAL,
            onSubmit: (data: TNewPhone) => {
                handleChangeLoading(true);
                apiUserService
                    .addPhone(data)
                    .then(({ id }) => {
                        changeModalData({ ...data, phoneId: id });
                        onOpen({
                            modalId: modalIds.SETTINGS_VERIFICATION_PHONE,
                            onSubmit: (data: { phoneId: string; code: string }) => {
                                handleChangeLoading(true);
                                apiUserService
                                    .verifyPhone(data.phoneId, data?.code as string)
                                    .then(() => {
                                        requestSuccessLocale('verify_phone');
                                        onClose();
                                        refetchPhones();
                                    })
                                    .catch(requestErrorLocale)
                                    .finally(() => handleChangeLoading(false));
                            }
                        });
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleOpenAddEmailModal = (refetchEmails: () => void) => {
        onOpen({
            modalId: modalIds.SETTINGS_ADD_EMAIL_MODAL,
            onSubmit: (data: { email: string }) => {
                if (data?.email) {
                    handleChangeLoading(true);
                    apiUserService
                        .addEmail(data.email as string)
                        .then(({ id }) => {
                            changeModalData({ ...data, emailId: id });
                            apiUserService
                                .sendEmailVerificationCode(id)
                                .then(() => {
                                    requestSuccessLocale('send_verification_email');
                                    onOpen({
                                        modalId: modalIds.SETTINGS_VERIFICATION_EMAIL,
                                        onSubmit: (data: { emailId: string; code: string }) => {
                                            handleChangeLoading(true);
                                            apiUserService
                                                .verifyEMAIL(data.emailId, data?.code as string)
                                                .then(() => {
                                                    requestSuccessLocale('verify_email');
                                                    onClose();
                                                    refetchEmails();
                                                })
                                                .catch(requestErrorLocale)
                                                .finally(() => handleChangeLoading(false));
                                        }
                                    });
                                })
                                .catch(requestErrorLocale);
                        })
                        .catch(requestErrorLocale)
                        .finally(() => handleChangeLoading(false));
                }
            }
        });
    };

    const handleSetPrimaryPhone = (phone: TPhone, refetchPhones: () => void) => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: (data: { secureId: string; code: string }) => {
                handleChangeLoading(true);
                apiUserService
                    .setPrimaryPhone(phone.id, {
                        id: data?.secureId,
                        code: data?.code
                    })
                    .then(() => {
                        requestSuccessLocale('set_primary_phone');
                        onClose();
                        refetchPhones();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleVerifyPhone = (phone: TPhone, refetchPhones: () => void) => {
        changeModalData({
            phoneNumber: phone.phoneNumber,
            phoneCode: phone.phoneCode,
            phoneId: phone.id
        });
        onOpen({
            modalId: modalIds.SETTINGS_VERIFICATION_PHONE,
            onSubmit: (data: { phoneId: string; code: string }) => {
                handleChangeLoading(true);
                apiUserService
                    .verifyPhone(data.phoneId, data?.code as string)
                    .then(() => {
                        requestSuccessLocale('verify_phone');
                        onClose();
                        refetchPhones();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleVerifyEmail = (email: TEmail, refetchEmails: () => void) => {
        changeModalData({ email: email.email, emailId: email.id });
        onOpen({
            modalId: modalIds.SETTINGS_VERIFICATION_EMAIL,
            onSubmit: (data: { emailId: string; code: string }) => {
                handleChangeLoading(true);
                apiUserService
                    .verifyEMAIL(data.emailId, data?.code as string)
                    .then(() => {
                        requestSuccessLocale('verify_email');
                        onClose();
                        refetchEmails();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleDeletePhone = (data: TPhone, refetchPhones: () => void) => {
        changeModalData({
            phoneCode: data.phoneCode,
            phoneNumber: data.phoneNumber
        });
        onOpen({
            modalId: modalIds.DELETE_PHONE,
            onSubmit: () => {
                handleChangeLoading(true);
                apiUserService
                    .deletePhone(data.id)
                    .then(() => {
                        requestSuccessLocale('delete_phone');
                        onClose();
                        refetchPhones();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleSetPrimaryEmail = (email: TEmail, refetchEmails: () => void) => {
        onOpen({
            modalId: modalIds.SETTINGS_VERIFICATION_PHONE,
            onSubmit: (data: { secureId: string; code: string }) => {
                handleChangeLoading(true);
                apiUserService
                    .setPrimaryEmail(email.id, {
                        id: data?.secureId,
                        code: data?.code
                    })
                    .then(() => {
                        requestSuccessLocale('set_primary_email');
                        onClose();
                        refetchEmails();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleDeleteEmail = (data: TEmail, refetchEmails: () => void) => {
        changeModalData({ email: data.email });
        onOpen({
            modalId: modalIds.DELETE_EMAIL,
            onSubmit: () => {
                handleChangeLoading(true);
                apiUserService
                    .deleteEmail(data.id)
                    .then(() => {
                        requestSuccessLocale('delete_email');
                        onClose();
                        refetchEmails();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    return {
        handleChangeAvatar,
        handleDeleteAvatar,
        handleOpenChangePasswordModal,
        handleOpenAddPhoneModal,
        handleOpenAddEmailModal,
        handleSetPrimaryPhone,
        handleDeletePhone,
        handleSetPrimaryEmail,
        handleDeleteEmail,
        handleVerifyPhone,
        handleVerifyEmail
    };
};
