import React, { FC, SVGAttributes } from 'react';

const FilledBank: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.9502 18.9102V21.9102H1.9502V18.9102C1.9502 18.3602 2.4002 17.9102 2.9502 17.9102H20.9502C21.5002 17.9102 21.9502 18.3602 21.9502 18.9102Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M6.9502 10.9102H4.9502V17.9102H6.9502V10.9102Z" fill="currentColor" />
            <path d="M10.9502 10.9102H8.9502V17.9102H10.9502V10.9102Z" fill="currentColor" />
            <path d="M14.9502 10.9102H12.9502V17.9102H14.9502V10.9102Z" fill="currentColor" />
            <path d="M18.9502 10.9102H16.9502V17.9102H18.9502V10.9102Z" fill="currentColor" />
            <path
                d="M22.9502 22.6602H0.950195C0.540195 22.6602 0.200195 22.3202 0.200195 21.9102C0.200195 21.5002 0.540195 21.1602 0.950195 21.1602H22.9502C23.3602 21.1602 23.7002 21.5002 23.7002 21.9102C23.7002 22.3202 23.3602 22.6602 22.9502 22.6602Z"
                fill="currentColor"
            />
            <path
                d="M21.3202 5.66L12.3202 2.06C12.1202 1.98 11.7802 1.98 11.5802 2.06L2.5802 5.66C2.2302 5.8 1.9502 6.21 1.9502 6.59V9.91C1.9502 10.46 2.4002 10.91 2.9502 10.91H20.9502C21.5002 10.91 21.9502 10.46 21.9502 9.91V6.59C21.9502 6.21 21.6702 5.8 21.3202 5.66ZM11.9502 8.41C11.1202 8.41 10.4502 7.74 10.4502 6.91C10.4502 6.08 11.1202 5.41 11.9502 5.41C12.7802 5.41 13.4502 6.08 13.4502 6.91C13.4502 7.74 12.7802 8.41 11.9502 8.41Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default FilledBank;
