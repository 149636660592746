import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useNotificationsContext } from '@hooks/context/useNotificationsContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiNotificationService } from '@api';
import { EEventName, TNotification } from '@xeppt/xeppt-sdk/types/notification';
import { groupNotificationsByMonth } from '@utils/index';
import Skeleton from '@components/common/skeleton';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Icon, TIconType } from '@components/icons';
import Button from '@components/common/button';
import Checkbox from '@components/common/checkbox';
import classNames from 'classnames/bind';
import { routes } from '@const/routes';
import { useVerificationResult } from '@hooks/api/useVerificationResult';
import { executeNotificationsData } from '@utils/notifications';
import moment from 'moment-timezone';
import { ENotificationCategory } from '@enum';
import emptyIllustration from '@svg/illustrations/empty_notifications.svg';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const voidNotifications = [EEventName.BalanceUpdated, EEventName.ETransferAccountUpdated];

const cx = classNames.bind(styles);

const NotificationsSidebar: FC<IProps> = ({ isOpen, onClose }) => {
    const { t } = useTranslation('notifications');
    const { t: titleLocale } = useTranslation('settings', {
        keyPrefix: 'notifications.tabs'
    });
    const navigate = useNavigate();
    const {
        notifications,
        isNotificationsLoading,
        refetchNotifications,
        totalNotifications,
        loadMoreNotifications
    } = useNotificationsContext();
    const { notificationsLocale, requestErrorLocale, requestSuccessLocale } = useLocales();
    const [selected, setSelected] = useState<string[]>([]);
    const [readNotifications, setReadNotifications] = useState<string[]>([]);
    const [chosenNotification, setChosenNotification] = useState<TNotification>();
    const { handleGetVerificationResult } = useVerificationResult();

    useEffect(() => {
        if (!isOpen) {
            setSelected([]);
            setChosenNotification(undefined);
        }
    }, [isOpen]);

    const { handleRequest: handleReadNotification } = useApiMutation({
        method: (id: string) => apiNotificationService.readNotification(id)
    });

    const { handleRequest: handleBulkDeleteNotifications } = useApiMutation({
        method: () => apiNotificationService.bulkDeleteNotifications(selected),
        onSuccess: () => {
            refetchNotifications();
            setChosenNotification(undefined);
            setSelected([]);
            requestSuccessLocale('delete_notifications');
        },
        onError: requestErrorLocale
    });

    const onReadNotification = (notification: TNotification) => {
        if (!notification.isRead) {
            setReadNotifications((state) => [...state, notification.id]);
            handleReadNotification(notification.id);
        }
    };

    const handleChangeSelected = (id: string) => {
        if (selected.includes(id)) {
            setSelected((state) => state.filter((item) => item !== id));
        } else {
            setSelected((state) => [...state, id]);
        }
    };

    const wrapperStyle = useMemo(() => cx([styles.wrapper, { isOpen }]), [isOpen]);

    const notificationDetailsData = useMemo(() => {
        if (!chosenNotification) return undefined;
        return executeNotificationsData({
            notification: chosenNotification,
            locale: notificationsLocale,
            titleLocale: titleLocale,
            navigate,
            handleGetVerificationResult,
            dateLocale: t
        });
    }, [chosenNotification]);

    const handleSelectAll = () => {
        if (selected.length === notifications.length) {
            setSelected([]);
        } else {
            setSelected(notifications.map((notification) => notification.id));
        }
    };

    const getCategoryColor = (category?: ENotificationCategory) => {
        switch (category) {
            case ENotificationCategory.CARD:
                return '#DDF2FD';
            case ENotificationCategory.MARKETING:
                return '#D1F0E1';
            case ENotificationCategory.PAYMENTS:
                return '#FDF1DC';
            case ENotificationCategory.PERSONAL:
                return '#E5DFF5';
            case ENotificationCategory.SCHEDULE:
                return '#F4F8D5';
            default:
                return '#FDF1DC';
        }
    };

    return (
        <>
            {isOpen && <div className={styles.overlay} onClick={onClose} />}
            <div className={wrapperStyle}>
                <div className={cx([styles.details, { isOpen: !!chosenNotification }])}>
                    <div className={styles.actions_header}>
                        <button onClick={() => setChosenNotification(undefined)}>
                            <Icon name="nav_right" />
                        </button>
                        <Button
                            leftIcon="trash"
                            onClick={() => {
                                setSelected([chosenNotification?.id || '']);
                                handleBulkDeleteNotifications(undefined);
                            }}
                        />
                    </div>
                    {chosenNotification && (
                        <div className={styles.header}>
                            <div className={styles.title_wrapper}>
                                <div className={styles.header_icon}>
                                    <div
                                        className={styles.icon}
                                        style={{
                                            background: getCategoryColor(
                                                notificationDetailsData?.category
                                            )
                                        }}>
                                        <Icon name={notificationDetailsData?.icon as TIconType} />
                                    </div>
                                    <Typography className={styles.details_title}>
                                        {notificationDetailsData?.title}
                                    </Typography>
                                </div>
                                <span className={styles.date}>
                                    {moment(chosenNotification?.createdAt).format(
                                        'DD MMM YYYY, HH:mm'
                                    )}
                                </span>
                            </div>
                            <div className={styles.notification_content}>
                                <div className={styles.body}>
                                    <Typography variant="body1">
                                        {notificationDetailsData?.description}
                                    </Typography>
                                    {!voidNotifications.includes(
                                        chosenNotification?.event as EEventName
                                    ) &&
                                        (!!chosenNotification?.id ||
                                            !!chosenNotification?.payload?.codes) && (
                                            <Button
                                                onClick={() => {
                                                    onClose();
                                                    notificationDetailsData?.action();
                                                }}
                                                variant="primary"
                                                size="small"
                                                className={styles.details_button}>
                                                View details
                                            </Button>
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={cx([styles.content, { isDetailsOpen: !!chosenNotification }])}>
                    <button className={styles.close_button} onClick={onClose}>
                        <Icon name="close" />
                    </button>
                    <div className={styles.header}>
                        <Typography variant="h4">Notifications</Typography>
                    </div>
                    <div className={styles.actions_wrapper}>
                        <div className={styles.choose_all}>
                            {notifications.length > 0 && (
                                <>
                                    <Checkbox
                                        isDelete
                                        onClick={handleSelectAll}
                                        checked={
                                            selected.length === notifications.length &&
                                            notifications.length !== 0
                                        }
                                    />{' '}
                                    {selected.length} chosen
                                </>
                            )}
                        </div>
                        <div className={styles.actions}>
                            {selected.length !== 0 && (
                                <Button
                                    leftIcon="trash"
                                    onClick={() =>
                                        selected.length > 0 &&
                                        handleBulkDeleteNotifications(undefined)
                                    }
                                />
                            )}
                            <Button
                                leftIcon="settings"
                                onClick={() => navigate(routes.settings_notifications)}
                            />
                            <Button leftIcon="refresh" onClick={refetchNotifications} />
                        </div>
                    </div>
                    {notifications.length === 0 && (
                        <div className={styles.empty_wrapper}>
                            <img src={emptyIllustration} alt="" />
                            <p>You don't have any notifications for this time</p>
                        </div>
                    )}
                    <div className={styles.notifications_wrapper}>
                        {notifications.length !== 0 && (
                            <div className={styles.notification_block}>
                                {groupNotificationsByMonth(notifications || [])?.map((item) => {
                                    return (
                                        <div
                                            key={item.month}
                                            className={styles.notification_month_block}>
                                            <div className={styles.month}>{item.month}</div>
                                            {item.notifications?.map((item) => {
                                                const { category, description, date, icon } =
                                                    executeNotificationsData({
                                                        notification: item,
                                                        locale: notificationsLocale,
                                                        titleLocale: titleLocale,
                                                        navigate,
                                                        handleGetVerificationResult,
                                                        dateLocale: t
                                                    });
                                                return (
                                                    <div
                                                        className={styles.notification_wrapper}
                                                        key={item.id}>
                                                        <Checkbox
                                                            checked={selected.includes(item.id)}
                                                            onClick={() => {
                                                                handleChangeSelected(item.id);
                                                            }}
                                                        />
                                                        <div
                                                            className={styles.icon}
                                                            style={{
                                                                background:
                                                                    getCategoryColor(category)
                                                            }}>
                                                            <Icon name={icon} />
                                                        </div>
                                                        <div
                                                            className={`${styles.text_wrapper} ${item.isRead || readNotifications.includes(item.id) ? `${styles.isRead}` : ''}`}
                                                            onClick={() => {
                                                                setChosenNotification(item);
                                                                onReadNotification(item);
                                                            }}>
                                                            <Typography
                                                                className={styles.title}
                                                                fw={500}
                                                                variant="h5">
                                                                {description}
                                                            </Typography>
                                                            <Typography
                                                                className={styles.time}
                                                                variant="body3">
                                                                {date}
                                                            </Typography>
                                                        </div>
                                                        <span />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        )}{' '}
                        {isNotificationsLoading && (
                            <>
                                <Skeleton className={styles.skeleton} />
                                <Skeleton className={styles.skeleton} />
                                <Skeleton className={styles.skeleton} />
                            </>
                        )}
                        {totalNotifications !== 0 && totalNotifications > notifications?.length && (
                            <Button
                                className={styles.load_more_button}
                                onClick={loadMoreNotifications}
                                leftIcon="nav_down">
                                Load more messages
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default NotificationsSidebar;
