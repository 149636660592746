import { useTranslation } from 'react-i18next';
import { errorCodes } from '@const/error_codes';
import { toast } from 'react-toastify';
import { EEventName } from '@xeppt/xeppt-sdk/types/notification';
import handleRequestError from '@utils/errors';

export const useLocales = () => {
    const { t } = useTranslation(['form', 'requests', 'submit']);

    const validationLocale = (string: string) => {
        return t(`validation.${string}`, { ns: 'form' });
    };

    const labelLocale = (string: string) => {
        return t(`labels.${string}`, { ns: 'form' });
    };

    const placeholderLocale = (string: string) => {
        return t(`placeholders.${string}`, { ns: 'form' });
    };

    const requestSuccessLocale = (string: string) => {
        return toast.success(t(`success.${string}`, { ns: 'requests' }));
    };

    const requestErrorLocale = (e: any, conflictMessage?: string) => {
        if (!e?.errorCode || !errorCodes.includes(e?.errorCode)) {
            return toast.error(
                t(`error.${handleRequestError(e?.statusCode, conflictMessage)}`, { ns: 'requests' })
            );
        }
        return toast.error(t(`error.${e?.errorCode}`, { ns: 'requests' }));
    };

    const submitLocale = (string: string) => {
        return t(string, { ns: 'submit' });
    };

    const notificationsLocale = (string: string, payload: Record<string, any>) => {
        if (string === EEventName.UserVerified && payload?.codes) {
            return t('USER_DOESNT_VERIFIED', { ns: 'notification_messages' });
        }
        return t(string, { ns: 'notification_messages' });
    };

    return {
        validationLocale,
        labelLocale,
        placeholderLocale,
        requestSuccessLocale,
        requestErrorLocale,
        submitLocale,
        notificationsLocale,
        t
    };
};
