import { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAuthService, apiNotificationService } from '@api';
import { EEventName, TNotification } from '@xeppt/xeppt-sdk/types/notification';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useUserContext } from '@hooks/context/useUserContext';
import notificationSound from '@audio/new_notification.mp3';
import { ENewNotificationType } from '@enum';
import { getNotificationTypeByEvent } from '@utils/notifications';
import { useVerificationResult } from '@hooks/api/useVerificationResult';

type TNotificationsContext = {
    database?: any;
    notifications: TNotification[];
    isNotificationsLoading: boolean;
    newNotificationType?: ENewNotificationType;
    lastNotificationType?: EEventName;
    refetchNotifications: () => void;
    loadMoreNotifications: () => void;
    totalNotifications: number;
    notificationsCursor?: Date;
};

export const NotificationsContext = createContext<TNotificationsContext>({
    refetchNotifications: () => {},
    loadMoreNotifications: () => {},
    isNotificationsLoading: false,
    notifications: [],
    totalNotifications: 0
});

interface IProps {
    children: ReactNode;
}

export const NotificationsContextProvider: FC<IProps> = ({ children }) => {
    const [database, setDatabase] = useState<any>();
    const { user, refetchAccount, refetchUser } = useUserContext();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isSecondRender, setIsSecondRender] = useState(false);
    const [newNotificationType, setNewNotificationType] = useState<ENewNotificationType>();
    const [newNotification] = useObjectVal(database ? ref(database, user?.id) : null);
    const { handleGetVerificationResult } = useVerificationResult();
    const [totalNotifications, setTotalNotifications] = useState(0);
    const [notificationsCursor, setNotificationsCursor] = useState<Date>();
    const [notifications, setNotifications] = useState<TNotification[]>([]);
    const [lastNotificationType, setLastNotificationType] = useState<EEventName>();

    const playNotificationsSound = () => {
        const audio = new Audio(notificationSound);
        audio.play();
    };

    const { data: firebaseConfig } = useApiQuery({
        method: () => apiAuthService.getRDBCredentials(),
        condition: !!user,
        deps: [user]
    });

    const { isLoading: isNotificationsLoading, handleRequest: refetchNotifications } = useApiQuery({
        method: (isRefresh?: boolean) =>
            apiNotificationService.getNotifications({
                limit: 10,
                cursor: isRefresh ? undefined : notificationsCursor
            }),
        isInitialRequest: false,
        onSuccess: ({ total, cursor, data }, isRefresh) => {
            if (notificationsCursor && !isRefresh) {
                setNotifications((state) => [...state, ...data]);
            } else {
                setNotifications(data);
            }
            setNotificationsCursor(cursor);
            setTotalNotifications(total);
        }
    });

    const handleUpdateNotificationType = (event: EEventName) => {
        const resultKey = getNotificationTypeByEvent(event);
        setNewNotificationType(resultKey);
        if (resultKey) {
            setTimeout(() => {
                setNewNotificationType(undefined);
            }, 0);
        }
    };

    useEffect(() => {
        if (!isFirstRender) {
            setIsSecondRender(true);
        }
        setIsFirstRender(false);
        //@ts-ignore
        if (isSecondRender) {
            playNotificationsSound();
            refetchAccount();
            refetchUser();
            refetchNotifications();
            //@ts-ignore
            handleUpdateNotificationType(newNotification?.event as EEventName);
            //@ts-ignore
            setLastNotificationType(newNotification?.event as EEventName);
            //@ts-ignore
            if (newNotification?.event === EEventName.UserVerified) {
                handleGetVerificationResult(
                    //@ts-ignore
                    newNotification?.codes?.split(',').filter(Boolean),
                    //@ts-ignore
                    newNotification?.status
                );
            }
        }
    }, [newNotification]);

    useEffect(() => {
        if (firebaseConfig) {
            const firebaseApp = initializeApp(JSON.parse(firebaseConfig));
            //@ts-ignore
            setDatabase(getDatabase(firebaseApp));
        }
    }, [firebaseConfig]);

    const loadMoreNotifications = () => {
        refetchNotifications();
    };

    const refreshNotifications = () => {
        setNotificationsCursor(undefined);
        setTotalNotifications(0);
        setNotifications([]);
        refetchNotifications(true);
    };

    return (
        <NotificationsContext.Provider
            value={{
                database,
                notifications: notifications,
                isNotificationsLoading,
                refetchNotifications: refreshNotifications,
                newNotificationType,
                lastNotificationType,
                loadMoreNotifications,
                totalNotifications,
                notificationsCursor
            }}>
            {children}
        </NotificationsContext.Provider>
    );
};
