import React, { FC, SVGAttributes } from 'react';

const RequestMoney: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 13.6 2.376 15.112 3.043 16.453C3.221 16.809 3.28 17.216 3.177 17.601L2.582 19.827C2.52307 20.0473 2.52312 20.2792 2.58216 20.4995C2.64119 20.7197 2.75712 20.9206 2.91831 21.0819C3.07951 21.2432 3.2803 21.3593 3.50053 21.4184C3.72075 21.4776 3.95267 21.4778 4.173 21.419L6.399 20.823C6.78538 20.7254 7.19414 20.7727 7.548 20.956C8.93095 21.6446 10.4551 22.002 12 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M12 15.333C13.105 15.333 14 14.587 14 13.667C14 12.747 13.105 12 12 12C10.895 12 10 11.254 10 10.333C10 9.413 10.895 8.667 12 8.667M12 15.333C10.895 15.333 10 14.587 10 13.667M12 15.333V16M12 8.667V8M12 8.667C13.105 8.667 14 9.413 14 10.333"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default RequestMoney;
