import React from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';

const TermsLayout = () => {
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h2">Terms of service</Typography>
            <div className={styles.content}>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    INTRODUCTION
                </Typography>
                <div>
                    These Terms of Use (the <b>“Terms”</b>) form a legally binding contract that
                    regulates the relationship between XEPPT Inc., a company incorporated under the
                    laws of Ontario (<b>“We,”</b> <b>“Us,”</b> or the possessive <b>“Our”</b> as
                    applicable) and users (<b>“You”</b> or
                    <b>“Users”</b>) of the XEPPT digital wallet (the <b>“App”</b> or the{' '}
                    <b>“Digital Wallet”</b>) our website accessible at
                    <a href="https://xeppt.com/">https://xeppt.com/</a> the <b>“Website”</b>) and
                    associated Services provided by us (the <b>“Services”</b>)
                </div>
                <div>
                    By visiting or using the App, Website or any of Our Services (collectively the
                    <b>“Platform”</b>), You agree that You have read, comprehend, and agree to be
                    bound by these Terms. Our privacy policy (the <b>“Privacy Policy”</b>) which can
                    be viewed at{' '}
                    <a href="https://xeppt.com/privacy-policy">https://xeppt.com/privacy-policy</a>{' '}
                    also forms part of and is integrated into these Terms by way of reference. If
                    You do not agree to be governed by these Terms and the Privacy Policy, You may
                    not access the Platform.
                </div>
                <div>
                    If You are using the Platform on behalf of a company, entity, or organization
                    (collectively, a <b>“Subscribing Organization”</b>) then You represent and
                    warrant that You: (a) are an authorized agent or representative of that
                    Subscribing Organization with the permission to bind such a Subscribing
                    Organization to these Terms; (b) have read and comprehend these Terms, and (c)
                    agree to these Terms on behalf of such Subscribing Organization
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Amendment of Terms
                </Typography>
                <div>
                    These Terms may be periodically amended by Us. We will provide notice of such
                    changes by displaying them clearly on the Platform or providing You prior notice
                    through the contact information You have shared with us. By continuing to use
                    the Platform after such amendments to the Terms take effect, You agree to abide
                    by the Terms as amended. If You do not agree to be governed by the amended
                    Terms, You must immediately terminate all use of the Platform.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Additional Terms
                </Typography>
                <div>
                    Before creating an account on our Platform (an <b>“Account”</b>) or using our
                    Digital Wallet You may be required to agree to additional terms as set out
                    through our sign-up process (the <b>“Additional Terms”</b>), which will be
                    available to you on account sign-up, as well as comply with any other
                    requirements imposed by us, including submitting any client verification
                    information requested by us. We reserve the right to refuse the right to
                    register an Account on our Platform to anyone for any reason in Our sole
                    discretion.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    XEPPT Deposit Waitlist Bonus
                </Typography>
                <div>
                    The first 1,000 users to join our waitlist for our XEPPT Digital Wallet
                    solutions will be eligible to receive a $50 “welcome” bonus credit on opening an
                    Account (the “Welcome Bonus”). In order to claim the Welcome Bonus You must be
                    deemed eligible and approved to open an Account on the Platform by Us, including
                    by providing any necessary client verification information requested by Us and
                    deposit a minimum of $__50___ in your Account in the first ___10 days___ after
                    opening your Account. The Welcome Bonus may not be transferred or exchanged, and
                    any individual User may only claim a single Welcome Bonus. Users attempting to
                    circumvent this restriction, including through joining the waitlist multiple
                    times with different contact information will be ineligible to receive the
                    Welcome Bonus and may have their access to the Platform suspended. The Welcome
                    Bonus may only be used on transactions using our Digital Wallet after the user
                    has completed a minimum of $____50___ of transactions through the Digital
                    Wallet, and may not be withdrawn as cash from the Digital Wallet. Users who have
                    their Account suspended or terminated by Us or otherwise breach these Terms
                    (including any Additional Terms) will have their right to receive the bonus
                    terminated and have any unused portion of the Welcome Bonus revoked without any
                    compensation. Additional terms for claiming the Welcome Bonus may apply, and We
                    reserve the right to refuse the Welcome Bonus to any User for any reason.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Your Obligations
                </Typography>
                <div>
                    By using accessing the Platform, You agree: (a) not to re-publish, modify, copy
                    or otherwise infringe the intellectual property rights connected with any
                    resources provided on the Platform; (b) that the Platform will be used by You
                    only in accordance with all Canadian and foreign, federal, state and local
                    statutes, orders and regulations (<b>“Applicable Laws”</b>), and under no
                    circumstances will the Platform be used in a manner, or for any purpose that
                    breaches Applicable Laws, or that may expose Us to civil or criminal liability;
                    (c) not to submit any false or misleading information on the Platform; (d) not
                    to hinder or attempt to interfere with the standard running of the Platform or
                    any activities operated on the Platform (this includes taking any action that
                    inflicts, or may inflict an disproportionate or unreasonably substantial load on
                    the infrastructure of the Platform as determined by Us in our discretion); (e)
                    not to gather or accumulate any information or data concerning the Platform or
                    use any robot, scraper, spider or other automated means to access the Platform
                    for any reason without our express written permission, and not to divulge any
                    information regarding the Platform to third parties; (f) not to reverse
                    engineer, decompile, disassemble or otherwise attempt to determine the source
                    code of the Platform or use the Platform for competitive analysis or
                    benchmarking purposes; (g) not to take any action that may damage, harm, or
                    diminish in any way, Our reputation or Our public image; (h) not to use Platform
                    for any conduct that could be interpreted as unlawful, abusive, threatening,
                    stalking, tortuous, harmful, libelous, vulgar, defamatory, hateful, hostile,
                    offensive, racially, obscene, ethnically or otherwise unacceptable or
                    objectionable, including engaging in such conduct towards other users of the
                    Platform; (i) not to upload any material to the Platform designed to interrupt
                    or interfere with the Platform inclusive of, but not limited to viruses,
                    spyware, worms, and malware; (j) not to infect the Platform with a virus or
                    other computer code, files or programs designed to disturb, damage, destroy or
                    limit the functionality of any computer hardware or software.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    User Content
                </Typography>
                <div>
                    By uploading material of any kind to the Platform (<b>“User Content”</b>), You
                    hereby grant Us and our partners a worldwide, non-exclusive, sub-licensable,
                    fully-paid, royalty-free license to use, modify, publicly perform, publicly
                    display and distribute such User Content in any manner as determined by Us. You
                    also represent and warrant that you are the owner of all intellectual property
                    rights in such User Content or have a license granting You the right to grant
                    the rights set out herein, and that uploading or publishing such User Content
                    shall not infringe on the intellectual property rights of any third party. You
                    agree that You are entirely responsible: (a) for all User Content that you
                    upload, post, email, transmit, or otherwise make available via the Platform; and
                    (b) for ensuring that such User Content is accurate, lawful, and does not
                    include misleading information or infringe or violate anyone’s rights. You will
                    not hold Us responsible or liable for any inaccuracies or for any errors or
                    omissions in any User Content or for any loss, injury or damages of any kind
                    incurred by Us as a result of the use of or reliance upon any User Content.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    User Content
                </Typography>
                <div>
                    The Platform may include links to third party websites, services, products, or
                    content (<b>“Third-Party Materials”</b>). We do not own, regulate or license any
                    such Third-Party Materials and We make no endorsements, representations,
                    guarantees, or warranties with respect to any Third-Party Materials. If You
                    click-through on any link posted on the Platform, You do so at Your own risk and
                    acknowledge that We (including, but not limited to, our owners, directors,
                    officers, senior managers, agents, and employees) shall not be liable in any
                    manner for any harm (including emotional harm), injury, cost, damage, or loss
                    You may undergo as a result of doing so.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Contact by Us
                </Typography>
                <div>
                    You agree that may contact You in respect to your Account or your activities on
                    the Platform, including for example for the purpose of providing appointment
                    confirmations, at the phone number, email address or other contact information
                    provided by You when you created your Account, as well as through push
                    notifications through our App. You acknowledge that there may be additional fees
                    charged by you by your phone carrier for receiving any communications and that
                    you will be solely responsible for these fees. You also agree and acknowledge
                    that we may use third-party service providers, including automated phone or text
                    message providers, to communicate with you and consent to receiving
                    communications from these providers.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Intellectual Property Rights
                </Typography>
                <div>
                    The Platform and materials provided on the Platform are Our intellectual
                    property and We reserve all right to the same. Nothing in these Terms shall act
                    as a license or assignment to such materials to You or any third party. Without
                    limiting the generality of the foregoing, You may not copy, modify, create
                    derivative works based upon, publicly display or otherwise commercially exploit
                    the Platform or materials in any manner without our express written permission.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Disclaimer of Warranties
                </Typography>
                <div>
                    YOUR USE OF THE PLATFORM INCLUDING THE SERVICES ARE AT YOUR OWN RISK. THE
                    PLATFORM, INCLODING THE SERVICES IN WHOLE AND IN PART ARE PROVIDED, TRANSMITTED,
                    DISTRIBUTED, AND MADE AVAILABLE ON AN "AS IS" “AS AVAILABLE” BASIS WITHOUT
                    EXPRESS OR IMPLIED WARRANTIES OF ANY KIND, INCLUDING, WITHOUT LIMITATION,
                    WARRANTIES OF TITLE, IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                    PARTICULAR PURPOSE OR NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY OF THE
                    FOREGOING, WE MAKE NO WARRANTY: (A) THAT THE PLATFORM, INCLUDING THE SERVICES,
                    WILL BE UNINTERRUPTED OR ERROR FREE AND YOU ACKNOWLEDGE THAT ACCESS AND USE OF
                    THE PLATFORM, INCLUDING THE SERVICES, MAY BE SUBJECT TO BUGS, MALFUNCTIONS,
                    DELAYS, AND DOWNTIME.; (B) THAT THE PLATFORM WILL BE FREE FROM VIRUSES OR OTHER
                    HARMFUL COMPONENTS; (C) AS TO THE QUALITY, ACCURACY, COMPLETENESS AND VALIDITY
                    OF ANY INFORMATION OR MATERIALS IN CONNECTION WITH THE PLATFORM INCLUDING THE
                    SERVICES; (D) THAT YOUR USE OF THE PLATFORM INCLUDING ANY SERVICES WILL MEET
                    YOUR REQUIREMENTS; OR (E) THAT TRANSMISSIONS THROUGH THE PLATFORM OF YOUR DATA
                    WILL BE SECURE OR FREE FROM SPYWARE OR MALWARE.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Limitation of Liability
                </Typography>
                <div>
                    BY ACCESSING THE PLATFORM, INCLUDING THE SERVICES YOU AGREE AND ACKNOWLEDGE THAT
                    YOUR USE IS AT YOUR OWN RISK AND THAT WE, OUR SHAREOLDERS, DIRECTORS, EMPLOYEES,
                    CONSULTANTS, AGENTS, AND THIRD PARTY BUSINESS PARTNERS (COLLECTIVELY{' '}
                    <b>"RELEASED PARTIES"</b>) SHALL NOT BE LIABLE FOR ANY DIRECT, INCIDENTAL,
                    CONSEQUENTIAL, INDIRECT, SPECIAL, OR PUNITIVE DAMAGES, OR ANY OTHER LOSSES,
                    COSTS, OR EXPENSES OF ANY KIND (INCLUDING, WITHOUT LIMITATION, LOST PROFITS,
                    LOSS OF DATA, LEGAL FEES, EXPERT FEES, COST OF PROCURING SUBSTITUTE SERVICES,
                    LOST OPPORTUNITY, OR OTHER DISBURSEMENTS) WHICH MAY ARISE, DIRECTLY OR
                    INDIRECTLY, THROUGH THE ACCESS TO, USE OF, RELIANCE ON ANY MATERIAL OR CONTENT
                    ON THE PLATFORM INCLUDING THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Limitation Period
                </Typography>
                <div>
                    UNLESS SUCH A RESTRICTION IS PROHIBITED BY LAW IN YOUR JURISDICTION OF
                    RESIDENCE, ANY CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING IN ANY WAY TO THESE
                    TERMS OR YOUR USE OF THE PLATFORM, INCLDUING THE SERVICES, MUST BE COMMENCED IN
                    ARBITRATION WITHIN ONE (1) YEAR AFTER THE EARLIER OF THE DATE CAUSE OF ACTION IS
                    DISCOVERED OR OUGHT TO HAVE BEEN DISCOVERED BY YOUY BY THE EXERCISE OF
                    REASONABLE DILIGENCE. AFTER SUCH (1)-YEAR PERIOD, SUCH CAUSE OF ACTION OR CLAIM
                    IS PERMANENTLY BARRED.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Class Action and Jury Trial Waiver
                </Typography>
                <div>
                    UNLESS SUCH A RESTRICTION IS PROHIBITED BY LAW IN YOUR JURISDICTION OF
                    RESIDENCE, YOU AGREE, TO THE MAXIMUM EXTENT BY LAW TO: (A) WAIVE ANY RIGHT TO
                    COMMENCE OR PARTICIPATE IN ANY CLASS ACTION AGAINST THE RELEASED PARTIES AND
                    WHERE APPLICABLE, AGREE TO OPT OUT OF ANY CLASS PROCEEDINGS AGAINST THE RELEASED
                    PARTIES, INCLUDING THE US, RELATING TO THE SAME; AND (B) WAIVE ANY RIGHT TO HAVE
                    ANY DISPUTE WITH THE RELEASED PARTIES RELATING TO THIS AGREEMENT RESOLVED
                    THROUGH A JURY TRIAL.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Indemnity
                </Typography>
                <div>
                    By accessing the Platform and using the Services, You agree to indemnify and
                    hold Us and our affiliated companies, officers, senior managers, directors,
                    employees, partners and agents harmless from any demand or claim, including
                    without limitation legal costs and reasonable attorneys' fees, made by any third
                    party as a result of or arising out of Your conduct on this Platform, Your
                    violation of these Terms or Your violation of any rights of any other party.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Exception
                </Typography>
                <div>
                    Notwithstanding the foregoing, the terms of Sections 10 to 12 and 14 above shall
                    not apply to any damages suffered by You as a result of Our use for research
                    purposes of any data obtained from You by Us, including User Content, that does
                    not contain the personally identifiable information related to You or any third
                    party.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Applicable Law
                </Typography>
                <div>
                    You acknowledge and agree that these Terms along with Your use of the Platform
                    and Services are governed by the laws of British Columbia and the federal laws
                    of Canada applicable therein, without regard to conflict of law provisions.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Dispute Resolution
                </Typography>
                <div>
                    You agree that in the event of any dispute between You and Us arising out of or
                    relating to these Terms or our relationship You shall consult and negotiate with
                    Us in good faith and attempt to reach a solution satisfactory solution. If We
                    are unable to resolve such dispute within a period of sixty (60) days from the
                    date discussions began, then such dispute shall be referred to and finally
                    resolved by mandatory and binding arbitration in accordance with the Arbitration
                    Act (Ontario) The place of arbitration shall be Toronto, Ontario the number of
                    arbitrators shall be one, and the language of the arbitration will be English.
                    If the courts or applicable law in your province, state, or country will not
                    permit you to consent to binding arbitration, then any legal suit, action, or
                    proceeding arising out of or related to these Terms will be instituted
                    exclusively in the courts of the Province of British Columbia, Canada or the
                    federal courts of Canada, as applicable, and you irrevocably submit to the
                    exclusive jurisdiction of such courts in any such suit, action, or proceeding.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Age of Majority
                </Typography>
                <div>
                    This Platform is not intended for persons who are under the age of 19 or the age
                    of majority in their jurisdiction. By accessing or using the Platform, You
                    represent and warrant that You are above the age of 19 and above the age of
                    majority in Your jurisdiction. If You are under the age of 19 or the age of
                    majority in Your jurisdiction You must immediately terminate all use of the
                    Platform.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Severability
                </Typography>
                <div>
                    If any provision of these Terms, or the application of such provision to any
                    individual or incident, will be held unenforceable, the remainder of these
                    Terms, or the application of such provision to any individual or incident other
                    than those to which it is held unenforceable, will not be influenced by it to
                    the degree that doing so will not affect the primary objective of the Terms.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Entire Agreement
                </Typography>
                <div>
                    These Terms and any Service Agreement contain the entire agreement between the
                    parties relating to the subject matter thereto and supersede all previous
                    agreements, negotiations, understandings and discussions between the parties,
                    and there are no warranties, representations, conditions, covenants, or other
                    terms other than those clearly contained in these Terms. These Terms may only be
                    amended by Us in writing as is set out in section 2 of these Terms. In the event
                    of any conflict between these Terms and the Service Agreement, the terms of the
                    Service Agreement shall govern.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Force Majeure
                </Typography>
                <div>
                    In no event will We be liable or responsible to you, or be deemed to have
                    defaulted under or breached these Terms, for any failure or delay in fulfilling
                    or performing any term of these Terms, when and to the extent that such failure
                    or delay is caused by any circumstances beyond Our reasonable control, including
                    acts of God, flood, fire, public health emergency, pandemic or communicable
                    disease outbreak, earthquake, tsunami, war, terrorism, riot or other civil
                    unrest, embargoes or blockades in effect on or after the date of these Terms,
                    national or regional emergency, strikes, labour stoppages or slowdowns or other
                    industrial disturbances, passage of law or any action taken by a governmental or
                    public authority, including restrictions resulting from a health emergency,
                    pandemic or communicable disease outbreak, such as quarantines or other
                    governmental restrictions.
                </div>
                <Typography className={styles.subtitle} weight="semibold" variant="h4">
                    Waiver
                </Typography>
                <div>
                    No waiver of any of these Terms shall be inferred from or implied by anything
                    done or omitted by Us, save for an express waiver in writing. No waiver,
                    condoning, excusing or overlooking by Us of any default or breach of these Terms
                    shall operate as a waiver of our rights under these Terms in respect of any
                    continuing or subsequent default or breach of these Terms.
                </div>
                <Typography className={styles.subtitle_left} weight="semibold" variant="h4">
                    Effective Date: 2024-11-09
                </Typography>
            </div>
        </motion.div>
    );
};

export default TermsLayout;
