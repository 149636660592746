import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import CardTypeSection from '@sections/order_card/card_type';
import ProgressBar from '@components/progress_bar';
import Button from '@components/common/button';
import SuccessStep from '@sections/order_card/success';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { useForm } from '@hooks/helpers/useForm';
import {
    addressValidationErrors,
    defaultAddressData,
    defaultRequestCard
} from '@const/default_form_data';
import { zodResolver } from '@hookform/resolvers/zod';
import { requestCardValidation } from '@helpers/validation_objects/cards';
import { useLocales } from '@hooks/helpers/useLocales';
import { FormProvider, useWatch } from 'react-hook-form';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiDocumentService, apiUserService, apiWalletService } from '@api';
import { useUserContext } from '@hooks/context/useUserContext';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { ECardType } from '@xeppt/xeppt-sdk/types/card';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { Country } from 'country-state-city';
import CardDetails from '@sections/order_card/card_details';
import ShippingAddress from '@sections/order_card/address';
import Cardholder from '@sections/order_card/cardholder';
import Agreement from '@sections/order_card/agreement';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { TAddress } from '@types';

const steps = [
    {
        title: 'Card details',
        id: '1'
    },
    {
        title: 'Shipping address',
        id: '2'
    },
    {
        title: 'Cardholder info',
        id: '3'
    },
    {
        title: 'Agreement',
        id: '4'
    },
    {
        title: 'Done',
        id: '5'
    }
];

const OrderCardLayout = () => {
    const { t } = useTranslation('order_card');
    const { validationLocale, requestSuccessLocale, requestErrorLocale, submitLocale } =
        useLocales();
    const { user, refetchAccount } = useUserContext();
    const stepsMemo = useMemo(() => steps, [t]);
    const { currentStep, availableStep, changeStep, prevStep, nextStep } =
        useProgressBar(stepsMemo);
    const { verifiedAction } = useVerificationGuard();
    const [isDone, setIsDone] = useState(false);
    const [cardType, setCardType] = useState<ECardType>();
    const { handleBack } = useNotFound();
    const form = useForm({
        defaultValues: defaultRequestCard,
        resolver: zodResolver(
            requestCardValidation({
                currentStep: Number(currentStep?.id || 1) - 1,
                messages: {
                    shippingAddress: addressValidationErrors(validationLocale),
                    firstName: validationLocale('firstName'),
                    lastName: validationLocale('lastName'),
                    billingAddress: addressValidationErrors(validationLocale),
                    employerAddress: addressValidationErrors(validationLocale),
                    birthDate: validationLocale('birthDate'),
                    occupation: validationLocale('occupation'),
                    employerName: validationLocale('employerName'),
                    employerPhone: validationLocale('employerPhone'),
                    cellPhone: validationLocale('cellPhone'),
                    citizenship: validationLocale('citizenship'),
                    email: validationLocale('email')
                },
                cardType
            })
        )
    });
    const watchedAgreement = useWatch({ name: 'agree', control: form.control });

    const { data: addressData } = useApiQuery({
        method: () => apiUserService.getAddress()
    });

    useEffect(() => {
        form.reset({
            ...defaultRequestCard,
            firstName: user?.profile.firstName,
            lastName: user?.profile.lastName,
            email: user?.email,
            birthDate: user?.profile?.birthDate,
            cellPhone: `${user?.phoneCode}${user?.phoneNumber}`,
            //@ts-ignore
            occupation: user?.profile?.occupancy,
            billingAddress: {
                //@ts-ignore
                ...(addressData?.billingAddress || defaultAddressData),
                //@ts-ignore
                address2: addressData?.billingAddress?.address2 || ''
            } as TAddress
        });
    }, [user, addressData]);

    const { data: cards } = useApiQuery({
        method: () => apiWalletService.getCards(),
        isInitialRequest: false
    });

    const { data: agreement } = useApiQuery({
        method: () => apiDocumentService.GetDocumentByName('card_agreement'),
        isInitialRequest: false
    });

    const getAgreementInfo = () => {
        const values = form.getValues();
        return {
            agreementDocumentId: agreement?.id || '',
            agreementAcceptedAt: new Date(),
            agreementDetails: {
                personalInfo: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    middleName: values.middleName,
                    birthDate: values.birthDate,
                    alias: values.alias,
                    citizenship: values.citizenship,
                    homePhone: values.phoneNumber,
                    mobilePhone: values.cellPhone,
                    email: values.email
                },
                billingAddress: {
                    ...values.billingAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === values.billingAddress.country
                        )?.isoCode || values.billingAddress.country
                },
                employerAddress: {
                    ...values.employerAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === values.employerAddress.country
                        )?.isoCode || values.employerAddress.country
                },
                employerInfo: {
                    name: values.employerName,
                    occupation: values.occupation,
                    businessPhone: values.employerPhone
                }
            }
        };
    };

    const { handleRequest: handleRequestCard, isLoading: isRequestCardLoading } = useApiMutation({
        method: () =>
            apiWalletService.requestPhysicCard({
                ...form.getValues(),
                profile: {
                    firstName: form.getValues().firstName || '',
                    lastName: form.getValues().lastName || ''
                },
                shippingAddress: {
                    ...form.getValues().shippingAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === form.getValues().shippingAddress.country
                        )?.isoCode || form.getValues().shippingAddress.country
                },
                ...getAgreementInfo()
            }),
        onSuccess: () => {
            requestSuccessLocale('request_card');
            refetchAccount();
            nextStep();
            setIsDone(true);
        },
        onError: (e) => {
            setIsDone(false);
            requestErrorLocale(e);
        }
    });

    const {
        handleRequest: handleRequestAnotherCard,
        isLoading: isRequestSupplementaryCardLoading
    } = useApiMutation({
        method: () => {
            const values = form.getValues();
            return apiWalletService.requestSupplementaryCard({
                cardId: cards?.[0]?.id || '',
                profile: {
                    firstName: values.firstName || '',
                    lastName: values.lastName || ''
                },
                shippingAddress: {
                    ...values.shippingAddress,
                    country:
                        Country.getAllCountries().find(
                            (item) => item.name === values.shippingAddress.country
                        )?.isoCode || values.shippingAddress.country
                },
                ...getAgreementInfo()
            });
        },
        onSuccess: () => {
            requestSuccessLocale('request_card');
            refetchAccount();
            nextStep();
            setIsDone(true);
        },
        onError: (e) => {
            setIsDone(false);
            requestErrorLocale(e);
        }
    });

    const renderStep = () => {
        switch (currentStep?.id) {
            case '1':
                return <CardDetails type={cardType} cards={cards || []} />;
            case '2':
                return <ShippingAddress />;
            case '3':
                return <Cardholder />;
            case '4':
                return <Agreement />;
            default:
                return <SuccessStep />;
        }
    };

    const renderTitle = () => {
        if (!cardType) {
            return t('choose_card');
        }
        return cardType === ECardType.PHYSICAL_SUPPLEMENTARY
            ? 'Order a new supplementary card'
            : 'Order a new primary card';
    };

    const onSubmit = () => {
        verifiedAction(() => {
            form.trigger().then((isValid) => {
                if (isValid) {
                    if (currentStep?.id !== '4') {
                        nextStep();
                    } else {
                        cardType === ECardType.PHYSICAL_SUPPLEMENTARY &&
                            handleRequestAnotherCard(undefined);
                        cardType === ECardType.PHYSICAL && handleRequestCard(undefined);
                    }
                }
            });
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <Button
                    className={styles.back}
                    leftIcon="arrow_left"
                    onClick={() => {
                        if (cardType) {
                            form.reset({
                                ...defaultRequestCard,
                                firstName: user?.profile.firstName,
                                lastName: user?.profile.lastName
                            });
                            setCardType(undefined);
                            changeStep('1');
                        } else {
                            handleBack(routes.send_and_request);
                        }
                    }}>
                    Back
                </Button>
                <Typography variant="h4" dangerouslySetInnerHTML={{ __html: t(renderTitle()) }} />
            </div>
            <div className={styles.content_wrapper}>
                {!cardType ? (
                    <CardTypeSection cards={cards || []} onChange={(value) => setCardType(value)} />
                ) : (
                    <>
                        <ProgressBar
                            steps={steps}
                            availableStep={availableStep}
                            currentStep={currentStep}
                            className={styles.progress}
                            changeStep={changeStep}
                        />
                        <div
                            className={`${styles.body_wrapper} ${currentStep?.id === '4' ? styles.large : ''}`}>
                            <FormProvider {...form}>{renderStep()}</FormProvider>
                        </div>
                    </>
                )}
            </div>
            {cardType && currentStep?.id !== '5' && (
                <div className={styles.actions_wrapper}>
                    {currentStep?.id !== '1' && (
                        <Button
                            className={styles.back}
                            leftIcon="arrow_left"
                            variant="outlined-dark"
                            size="normal"
                            onClick={() => {
                                prevStep();
                            }}>
                            {submitLocale('previous')}
                        </Button>
                    )}
                    <Button
                        variant="primary"
                        size="normal"
                        rightIcon="arrow_right"
                        disabled={!watchedAgreement && currentStep?.id === '4'}
                        isLoading={isRequestSupplementaryCardLoading || isRequestCardLoading}
                        onClick={onSubmit}>
                        {isDone ? t('done') : t('next')}
                    </Button>
                </div>
            )}
        </motion.div>
    );
};

export default OrderCardLayout;
