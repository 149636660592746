import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '../common/typography';
import canadian from '@svg/flags/canadian.svg';
import { prettifyAmount } from '@utils/index';

interface IProps {
    amount?: number;
    currency?: string;
    onClick?: () => void;
}

const AccountBalance: FC<IProps> = ({ amount = 0, onClick, currency }) => {
    return (
        <button
            className={styles.wrapper}
            onClick={() => {
                if (onClick) onClick();
            }}>
            <div className={styles.currency_wrapper}>
                <img src={canadian} alt="canadian flag" />
                {currency}
            </div>
            <Typography className={styles.amount} variant="h4">
                ${prettifyAmount(amount.toFixed(2))}
            </Typography>
        </button>
    );
};

export default AccountBalance;
