import React, { FC, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Link, useLocation } from 'react-router-dom';

interface IProps {
    href: string;
    children: ReactNode;
    className?: string;
    routes?: string[];
}

const cx = classNames.bind(styles);

const NavLink: FC<IProps> = ({ href, className, children, routes }) => {
    const location = useLocation();
    const rootStyles = useMemo(
        () =>
            cx([
                styles.root,
                { isActive: location.pathname === href || routes?.includes(location.pathname) },
                className
            ]),
        [className, href, location]
    );

    return (
        <Link to={href} className={rootStyles}>
            {children}
        </Link>
    );
};

export default NavLink;
