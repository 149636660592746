import { ETransactionAction } from '@xeppt/xeppt-sdk/types/transaction';
import { ETransactionMethod } from '@xeppt/xeppt-sdk/types';

export const transactionsActionsFilterArray = (t: (val: string) => string) => [
    {
        label: 'Refund',
        action: ETransactionAction.REFUND
    },
    {
        label: 'Card Service',
        action: ETransactionAction.CARD_SERVICE
    },
    {
        label: 'Send money',
        action: ETransactionAction.TRANSFER
    },
    {
        label: 'Load Card',
        action: ETransactionAction.CARD_DEPOSIT
    },
    {
        label: 'Cashback',
        action: ETransactionAction.CASHBACK
    },
    {
        label: 'Receive money or load balance',
        action: ETransactionAction.DEPOSIT
    },
    {
        label: 'Bill payment',
        action: ETransactionAction.PAYMENT
    },
    {
        label: 'Send money to Bank account',
        action: ETransactionAction.WITHDRAW
    }
];
export const transactionsMethodsFilterArray = (t: (val: string) => string) => [
    {
        label: 'Wallet',
        method: ETransactionMethod.WALLET
    },
    {
        label: 'Linked card',
        method: ETransactionMethod.EPS
    },
    {
        label: 'Bank account',
        method: ETransactionMethod.EFT
    },
    // {
    //     label: 'E-Transfer',
    //     method: ETransactionMethod.E_TRANSFER
    // },
    {
        label: 'Bill payment',
        method: ETransactionMethod.PAY_BILL
    }
];
