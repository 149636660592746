import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import FormField from '@components/form_field';
import Select from '@components/common/select';
import { getFrequencyList } from '@utils/billing';
import DatePicker from '@components/date_picker';
import { getDisablePast } from '@utils/index';
import { EScheduleFrequency } from '@xeppt/xeppt-sdk/types/schedule';
import Checkbox from '@components/common/checkbox';
import { ESchedulePaymentType } from '@enum';
import Radio from '@components/common/radio';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import Button from '@components/common/button';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useFrequency } from '@hooks/helpers/useFrequency';

interface IProps {
    onSubmit: () => void;
    onPrev: () => void;
}

const Schedule: FC<IProps> = ({ onSubmit, onPrev }) => {
    const { labelLocale, validationLocale, submitLocale } = useLocales();
    const { control } = useFormContext();
    const { t: recurringLocale } = useTranslation('sections', {
        keyPrefix: 'send_and_request.recurring'
    });
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money'
    });
    useFrequency({ isFrequencyExist: true });
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control });
    const isInfinitePayment = useWatch({ name: 'isInfinitePayment', control });
    const watchedNextPaymentAt = useWatch({ name: 'nextPaymentAt', control });
    const isFrequencyExist = useMemo(
        () => watchedFrequency !== EScheduleFrequency.ONCE,
        [watchedFrequency]
    );

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.title}>Schedule</div>
                <div className={styles.description}>
                    You can schedule your payment for a later date or set up recurring payment{' '}
                </div>
            </div>
            <div className={styles.form}>
                <div className={styles.row}>
                    <FormField<string | number>
                        name="paymentFrequency"
                        renderComponent={(props) => (
                            <Select
                                full
                                label={labelLocale('frequency')}
                                {...props}
                                items={getFrequencyList(recurringLocale)}
                                className={styles.frequency}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: validationLocale('frequency')
                            }
                        }}
                    />
                    <FormField<string | null>
                        name="nextPaymentAt"
                        renderComponent={(props) => (
                            <DatePicker
                                label={recurringLocale('next_payment')}
                                minDate={getDisablePast()}
                                full
                                {...props}
                            />
                        )}
                        rules={{
                            required: {
                                value: true,
                                message: validationLocale('next_payment')
                            }
                        }}
                    />
                </div>
                {watchedFrequency !== EScheduleFrequency.ONCE && (
                    <FormField<boolean>
                        name="isInfinitePayment"
                        renderComponent={({ value, onChange }) => (
                            <div className={styles.infinite}>
                                <Checkbox
                                    checked={value}
                                    onClick={() => {
                                        onChange(!value);
                                    }}
                                />
                                {t('info.infinite_payment')}
                            </div>
                        )}
                    />
                )}
                {isFrequencyExist && !isInfinitePayment && (
                    <div>
                        <div className={styles.schedule_title}>Ends</div>
                        <div className={styles.row}>
                            <div className={styles.row}>
                                <FormField<ESchedulePaymentType>
                                    name="paymentType"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.row}>
                                            <div
                                                className={styles.switch}
                                                onClick={() =>
                                                    onChange(ESchedulePaymentType.PAYMENTS_DATE)
                                                }>
                                                <Radio
                                                    small
                                                    checked={
                                                        value === ESchedulePaymentType.PAYMENTS_DATE
                                                    }
                                                />{' '}
                                                <Typography variant="body3">On</Typography>
                                            </div>
                                            <FormField<string | null>
                                                name="paymentsEndDate"
                                                renderComponent={(props) => (
                                                    <DatePicker
                                                        full
                                                        disabled={
                                                            value !==
                                                            ESchedulePaymentType.PAYMENTS_DATE
                                                        }
                                                        minDate={new Date(watchedNextPaymentAt)}
                                                        {...props}
                                                    />
                                                )}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                            <div className={styles.row}>
                                <FormField<ESchedulePaymentType>
                                    name="paymentType"
                                    renderComponent={({ value, onChange }) => (
                                        <div className={styles.row}>
                                            <div
                                                className={styles.switch}
                                                onClick={() =>
                                                    onChange(
                                                        ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                    )
                                                }>
                                                <Radio
                                                    small
                                                    checked={
                                                        value ===
                                                        ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                    }
                                                />{' '}
                                                <Typography variant="body3">After</Typography>
                                            </div>
                                            <FormField
                                                name="numberOfPayments"
                                                renderComponent={(props) => (
                                                    <Input
                                                        disabled={
                                                            value !==
                                                            ESchedulePaymentType.NUMBER_OF_PAYMENTS
                                                        }
                                                        className={styles.counter}
                                                        full
                                                        onlyNumbers
                                                        {...props}
                                                    />
                                                )}
                                            />
                                            <Typography variant="body3">occurrences</Typography>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.actions}>
                <Button
                    leftIcon="arrow_left"
                    variant="outlined-dark"
                    size="normal"
                    onClick={onPrev}>
                    {submitLocale('previous')}
                </Button>
                <Button rightIcon="arrow_right" variant="primary" size="normal" onClick={onSubmit}>
                    {submitLocale('next')}
                </Button>
            </div>
        </motion.div>
    );
};

export default Schedule;
