import React, { FC, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import TransactionTable from '@components/transactions_table';
import { useLocales } from '@hooks/helpers/useLocales';
import {
    ETransactionType,
    TTransactionLite,
    TTransactionsFilter
} from '@xeppt/xeppt-sdk/types/transaction';
import { downloadFileFromBlob, formatDataToXlsx, getTransactionAction } from '@utils/index';
import moment from 'moment/moment';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';
import { useUserContext } from '@hooks/context/useUserContext';
import Tabs from '@components/tabs';
import {
    transactionsActionsFilterArray,
    transactionsMethodsFilterArray
} from '@const/transactions';
import Chips from '@components/common/chips';

interface IProps {
    transactions: TTransactionLite[];
    isTransactionsLoading?: boolean;
    toggleFilter: () => void;
    refetchTransactions: () => void;
    activeTab: string;
    filter: TTransactionsFilter;
    onChangeTab: (val: string) => void;
    onChangeFilter: (val: TTransactionsFilter) => void;
}

const TableSection: FC<IProps> = ({
    transactions,
    toggleFilter,
    onChangeFilter,
    isTransactionsLoading,
    refetchTransactions,
    onChangeTab,
    activeTab,
    filter
}) => {
    const { t, i18n } = useTranslation('sections', {
        keyPrefix: 'transactions.table'
    });
    const { t: transactionItemLocale } = useTranslation('components', {
        keyPrefix: 'transaction_tables.transaction_item'
    });
    const { user } = useUserContext();
    const { submitLocale } = useLocales();

    const handleDownloadTransaction = () => {
        const formatTransaction = transactions.map((item) => ({
            'Transaction ID': item.id,
            Name: item.consumerName,
            'Consumer Email': item.consumerEmail,
            Action: getTransactionAction(
                item.action,
                item.method,
                item.type,
                transactionItemLocale
            ),
            Amount: item.type === ETransactionType.CREDIT ? item.amount * -1 : item.amount,
            Currency: item.currency,
            'Date ': `${moment(item.createdAt).format('MM/DD/YYYY HH:mm:ss')} (${user?.profile?.timezone})`,
            Method: enumTranslate[item.method][i18n.language as 'en' | 'fr'],
            Status: ETransactionStatusTranslate[item.status][i18n.language as 'en' | 'fr'],
            'Is Automatic': item.automatic ? 'Yes' : 'No'
        }));
        downloadFileFromBlob(formatDataToXlsx(formatTransaction), 'all-transactions.xlsx');
    };

    const isFilterActive = useMemo(() => {
        return (
            !!filter?.action?.length ||
            !!filter?.method?.length ||
            !!filter?.type?.length ||
            (filter?.dateFrom && filter?.dateTo)
        );
    }, [filter]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.table_header}>
                <Tabs
                    onChange={onChangeTab}
                    tabs={[
                        {
                            label: 'Transaction',
                            value: 'transactions'
                        },
                        {
                            label: 'Requests',
                            value: 'requests'
                        }
                    ]}
                    activeTab={activeTab}
                />
                <div className={styles.right}>
                    <Button
                        variant="outlined-dark"
                        size="medium"
                        leftIcon="filter"
                        onClick={toggleFilter}>
                        <p>{submitLocale('filter')}</p>
                    </Button>
                    <Button
                        variant="primary"
                        size="medium"
                        leftIcon="download"
                        onClick={handleDownloadTransaction}>
                        <p>{submitLocale('download')}</p>
                    </Button>
                </div>
            </div>
            <div className={styles.content}>
                {isFilterActive && (
                    <div className={styles.active_filters}>
                        {filter?.action?.map((item) => {
                            return (
                                <Chips
                                    key={item}
                                    label={
                                        transactionsActionsFilterArray(t).find(
                                            (action) => action.action === item
                                        )?.label || ''
                                    }
                                    onClose={() =>
                                        onChangeFilter({
                                            ...filter,
                                            action: filter?.action?.filter((type) => type !== item)
                                        })
                                    }
                                />
                            );
                        })}
                        {filter?.method?.map((item) => {
                            return (
                                <Chips
                                    key={item}
                                    label={
                                        transactionsMethodsFilterArray(t).find(
                                            (method) => method.method === item
                                        )?.label || ''
                                    }
                                    onClose={() =>
                                        onChangeFilter({
                                            ...filter,
                                            method: filter?.method?.filter((type) => type !== item)
                                        })
                                    }
                                />
                            );
                        })}
                        {filter?.type?.map((item) => {
                            return (
                                <Chips
                                    key={item}
                                    label={
                                        item === ETransactionType.CREDIT ? 'Outgoing' : 'Incoming'
                                    }
                                    onClose={() =>
                                        onChangeFilter({
                                            ...filter,
                                            type: filter?.type?.filter((type) => type !== item)
                                        })
                                    }
                                />
                            );
                        })}
                        {filter?.dateFrom && filter?.dateTo && (
                            <Chips
                                label={`${moment(filter.dateFrom).format('MM/DD/YYYY')} - ${moment(filter.dateTo).format('MM/DD/YYYY')}`}
                                onClose={() =>
                                    onChangeFilter({
                                        ...filter,
                                        dateTo: undefined,
                                        dateFrom: undefined
                                    })
                                }
                            />
                        )}
                        <button className={styles.clear_all} onClick={() => onChangeFilter({})}>
                            Clear all
                        </button>
                    </div>
                )}
                <TransactionTable
                    isTransactionsLoading={isTransactionsLoading}
                    transactions={transactions}
                    refetchTransactions={refetchTransactions}
                />
            </div>
        </div>
    );
};

export default TableSection;
