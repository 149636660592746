import React, { FC } from 'react';
import { TBankAccount } from '@xeppt/xeppt-sdk/types';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import { useUserContext } from '@hooks/context/useUserContext';
import { useNavigate } from 'react-router-dom';
import Button from '@components/common/button';
import { routes } from '@const/routes';

const WalletSection = () => {
    const { account } = useUserContext();
    const navigate = useNavigate();
    return (
        <>
            <div className={styles.wrapper}>
                <Icon className={styles.icon} name="filled_wallet" width={56} height={56} />
                <Typography variant="body2" className={styles.title}>
                    XEPPT Wallet
                </Typography>
            </div>
            <div className={styles.actions}>
                <Icon className={styles.flag} name="canadian" width={40} height={40} />
                <div className={styles.title}>${account?.balance.toFixed(2)} CAD</div>
                <div className={styles.description}>Available on balance</div>
                <Button
                    variant="primary"
                    size="normal"
                    onClick={() => navigate(routes.send_and_request)}>
                    Move money
                </Button>
            </div>
        </>
    );
};

export default WalletSection;
