import React, { FC, useEffect } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext, useWatch } from 'react-hook-form';
import Phone from '@components/phone';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiRegistrationService } from '@api';
import { toast } from 'react-toastify';
import { EUserType, TNewPhone } from '@xeppt/xeppt-sdk/types';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import codeIllustration from '@svg/illustrations/auth/code.svg';
import InfoBlock from '@components/common/info_block';

interface IProps {
    onCodeSent: () => void;
}

const PhoneSection: FC<IProps> = ({ onCodeSent }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.phone'
    });
    const { submitLocale, labelLocale, requestErrorLocale } = useLocales();
    const { prevStep, handleChangeIllustration } = useSignupContext();
    const { getValues, trigger, control } = useFormContext();
    const type = useWatch({ name: 'type', control });

    useEffect(() => {
        handleChangeIllustration(codeIllustration);
    }, []);

    const { handleRequest: handleValidatePhone, isLoading: isValidateLoading } = useApiMutation({
        method: (data: TNewPhone) => apiRegistrationService.validatePhone(data),
        onSuccess: () => {
            onCodeSent();
            toast.success('Verification code was sent on your phone');
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        const { phoneCode, phoneNumber } = getValues();
        trigger().then((isValid) => {
            if (isValid) {
                handleValidatePhone({
                    phoneCode,
                    phoneNumber
                });
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.steps}>STEP 3/{type === EUserType.BUSINESS ? '11' : '7'}</div>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <Phone label={labelLocale('enter_phone')} />
                <InfoBlock className={styles.info_wrapper} variant="gray">
                    <Typography className={styles.info}>{t('description')}</Typography>
                </InfoBlock>
                <div className={styles.actions}>
                    <Button
                        onClick={onSubmit}
                        size="normal"
                        variant="primary"
                        isLoading={isValidateLoading}>
                        {submitLocale('submit_code')}
                    </Button>
                    <Button className={styles.back} leftIcon="arrow_left" onClick={prevStep}>
                        {submitLocale('back')}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default PhoneSection;
