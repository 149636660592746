import React from 'react';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Info from '@components/common/info';
import useResponsive from '@hooks/helpers/useResponsive';
import { useCashbackModals } from '@hooks/modals/useCashbackModals';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import { Link } from 'react-router-dom';
import { routes } from '@const/routes';
import { useUserContext } from '@hooks/context/useUserContext';

const Cashback = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.cashback'
    });
    const { windowWidth } = useResponsive();
    const { handleOpenCashbackModal } = useCashbackModals();
    const { submitLocale } = useLocales();
    const { cashback } = useUserContext();

    return (
        <div className={styles.cashback_wrapper}>
            <div className={styles.header}>
                <div className={styles.icon}>
                    <Icon name="gift" />
                </div>
                <Typography variant="h5">{t('title')}</Typography>
            </div>
            <div className={styles.cashback_content}>
                <div className={styles.info}>
                    <Typography variant="body3">{t('amount')}</Typography>
                    <Info
                        variant={windowWidth < 1900 ? 'bottom' : 'right'}
                        content={
                            <>
                                <span>{t('note')}</span>: {t('note_1')}
                            </>
                        }
                    />
                </div>
                <Typography variant="body1" weight="semibold">
                    ${cashback?.available.toFixed(2)}
                </Typography>
            </div>
            <div
                className={`${styles.footer_wrapper} ${(cashback?.available || 0) >= 5 ? styles.isAvailable : ''}`}>
                {(cashback?.available || 0) >= 5 ? (
                    <Button
                        variant="gold"
                        leftIcon="arrow_down"
                        size="normal"
                        onClick={handleOpenCashbackModal}>
                        {submitLocale('redeem')}
                    </Button>
                ) : (
                    <Typography variant="body3">
                        {t('error_1')} <b>${(5 - (cashback?.available || 0)).toFixed(2)}</b>{' '}
                        {t('error_2')}
                    </Typography>
                )}
                <Link to={routes.cashback_history} className={styles.link}>
                    {t('view_history')}
                </Link>
            </div>
        </div>
    );
};

export default Cashback;
