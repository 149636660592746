import React, { FC, SVGAttributes } from 'react';

const FilledWallet: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.1358 10.6714C28.0603 10.6666 27.978 10.6666 27.8927 10.6667L27.8714 10.6667H24.5268C21.7699 10.6667 19.4109 12.8368 19.4109 15.6667C19.4109 18.4966 21.7699 20.6667 24.5268 20.6667H27.8714L27.8927 20.6667C27.978 20.6667 28.0603 20.6667 28.1358 20.662C29.2557 20.5913 30.246 19.7149 30.3293 18.491C30.3347 18.4107 30.3347 18.3242 30.3346 18.244L30.3346 18.2222V13.1111L30.3346 13.0894C30.3347 13.0092 30.3347 12.9227 30.3293 12.8424C30.246 11.6184 29.2557 10.7421 28.1358 10.6714ZM24.2302 17C24.94 17 25.5153 16.403 25.5153 15.6667C25.5153 14.9303 24.94 14.3333 24.2302 14.3333C23.5204 14.3333 22.9451 14.9303 22.9451 15.6667C22.9451 16.403 23.5204 17 24.2302 17Z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.8919 22.6667C28.0907 22.6615 28.2411 22.8457 28.1872 23.0371C27.9195 23.987 27.4947 24.7966 26.813 25.4783C25.8152 26.4762 24.5499 26.919 22.9867 27.1292C21.4677 27.3334 19.5269 27.3334 17.0765 27.3333H14.2594C11.8091 27.3334 9.86823 27.3334 8.34927 27.1292C6.78604 26.919 5.52078 26.4762 4.52296 25.4783C3.52514 24.4805 3.08232 23.2153 2.87215 21.652C2.66793 20.1331 2.66795 18.1922 2.66797 15.7419V15.5914C2.66795 13.1411 2.66793 11.2003 2.87215 9.6813C3.08232 8.11807 3.52514 6.85281 4.52296 5.85499C5.52078 4.85717 6.78604 4.41435 8.34927 4.20418C9.86822 3.99996 11.8091 3.99998 14.2594 4L17.0765 4C19.5269 3.99998 21.4677 3.99996 22.9867 4.20418C24.5499 4.41435 25.8152 4.85717 26.813 5.85499C27.4947 6.5367 27.9195 7.34632 28.1872 8.29624C28.2411 8.48767 28.0906 8.67183 27.8918 8.66667L24.5267 8.66668C20.7445 8.66668 17.4108 11.6545 17.4108 15.6667C17.4108 19.6788 20.7445 22.6667 24.5267 22.6667L27.8919 22.6667ZM7.66797 9.33333C7.11568 9.33333 6.66797 9.78105 6.66797 10.3333C6.66797 10.8856 7.11568 11.3333 7.66797 11.3333H13.0013C13.5536 11.3333 14.0013 10.8856 14.0013 10.3333C14.0013 9.78105 13.5536 9.33333 13.0013 9.33333H7.66797Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default FilledWallet;
