import React, { FC, useMemo } from 'react';
import { Icon } from '@components/icons';
import Typography from '@components/common/typography';
import Radio from '@components/common/radio';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Button from '@components/common/button';
import linkCardIllustration from '@svg/illustrations/link_eps.svg';
import linkBankIllustration from '@svg/illustrations/link_bank.svg';

interface IProps {
    onChange: (value: 'card' | 'bank') => void;
}

const cx = classNames.bind(styles);

const ChooseTypeSection: FC<IProps> = ({ onChange }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.choose_type'
    });

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.card}>
                <img src={linkCardIllustration} alt="illustration" />
                <div className={styles.text_wrapper}>
                    <Typography className={styles.title} variant="body1" weight="semibold">
                        {t('credit')}
                    </Typography>
                    <p className={styles.description}>{t('accept_credit')}</p>
                </div>
                <Button variant="primary" size="normal" onClick={() => onChange('card')}>
                    Link a card
                </Button>
            </div>
            <div className={styles.card}>
                <img src={linkBankIllustration} alt="illustration" />
                <div className={styles.text_wrapper}>
                    <Typography className={styles.title} variant="body1" weight="semibold">
                        {t('bank')}
                    </Typography>
                    <p className={styles.description}>{t('accept_bank')}</p>
                </div>
                <Button variant="primary" size="normal" onClick={() => onChange('bank')}>
                    Link a bank account
                </Button>
            </div>
        </motion.div>
    );
};

export default ChooseTypeSection;
