import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';
import { tagRegex } from '@const/regex';

interface IProps {
    messages: {
        firstName: string;
        lastName: string;
        birthDate: string;
        occupancy: string;
        tag: string;
        timezone: string;
    };
}

export const profileSettingsValidation = ({ messages }: IProps) => {
    const validationObject: any = {
        profile: z.object({
            firstName: validator.firstName(messages.firstName),
            lastName: validator.lastName(messages.lastName),
            // birthDate: validator.birthday(messages.birthDate),
            occupancy: z.string().min(1, { message: messages.occupancy }),
            tag: z
                .string()
                .min(1, { message: messages.tag })
                .regex(tagRegex, { message: messages.tag }),
            timezone: z.string().min(1, { message: messages.timezone })
        })
    };
    return z.object(validationObject);
};

interface IBusinessValidatorProps {
    messages: {
        companyName: string;
        tradingName: string;
        registrationNumber: string;
        category: string;
        size: string;
        website: string;
    };
}

export const businessSettingsValidation = ({ messages }: IBusinessValidatorProps) => {
    return z.object({
        companyName: z.string().min(1, { message: messages.companyName }),
        tradingName: z.string().min(1, { message: messages.tradingName }),
        registrationNumber: z.string().min(1, { message: messages.registrationNumber }),
        category: z.string().min(1, { message: messages.category }),
        size: z.string().min(1, { message: messages.size }),
        website: z.string().url({ message: messages.website })
    });
};
