import React, { FC } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import FormField from '@components/form_field';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

const SecondStepManualLinkBank = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank.step_2_manual'
    });
    const { labelLocale } = useLocales();
    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.content}>
                <Typography className={styles.title} variant="body1">
                    {t('description')}
                </Typography>
                <div className={styles.right}>
                    <FormField
                        name="accountName"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label="Account nickname"
                                placeholder="Enter a nickname for your account"
                            />
                        )}
                    />
                    <FormField
                        name="transitNumber"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label={labelLocale('transit_number')}
                                mask="99999"
                                placeholder="Enter the transit number"
                            />
                        )}
                    />
                    <FormField
                        name="accountNumber"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                label={labelLocale('account_number')}
                                mask="9999999"
                                placeholder="Enter the account number"
                            />
                        )}
                    />
                    <FormField
                        name="bankNumber"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                onlyNumbers
                                label={labelLocale('bank_number')}
                                placeholder="Enter your bank number"
                            />
                        )}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default SecondStepManualLinkBank;
