import React, { FC, SVGAttributes } from 'react';

const Bank: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.63 2.60492L2.63 6.2049C2.28 6.3449 2 6.76489 2 7.13489V10.4549C2 11.0049 2.45 11.4549 3 11.4549H21C21.55 11.4549 22 11.0049 22 10.4549V7.13489C22 6.76489 21.72 6.3449 21.37 6.2049L12.37 2.60492C12.17 2.52492 11.83 2.52492 11.63 2.60492Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2 22.4551H22V19.4551C22 18.9051 21.55 18.4551 21 18.4551H3C2.45 18.4551 2 18.9051 2 19.4551V22.4551Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20 18.4551V11.4551"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 18.4551V11.4551"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 18.4551V11.4551"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8 18.4551V11.4551"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4 18.4551V11.4551"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M23 22.4551H1"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 8.95508C11.1716 8.95508 10.5 8.28351 10.5 7.45508C10.5 6.62665 11.1716 5.95508 12 5.95508C12.8284 5.95508 13.5 6.62665 13.5 7.45508C13.5 8.28351 12.8284 8.95508 12 8.95508Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Bank;
