import styles from './styles.module.scss';
import React, { useEffect, useState } from 'react';
import Typography from '@components/common/typography';
import PaymentsTable from '@sections/send_and_request/schedule_payments/payments_table';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiScheduleService } from '@api';
import { ENewNotificationType, ESendRequestMoneyMethod } from '@enum';
import Pagination from '@components/pagination';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { EScheduleFrequency, EScheduleStatus, TSchedule } from '@xeppt/xeppt-sdk/types/schedule';
import { ETransactionAction, ETransactionMethod } from '@xeppt/xeppt-sdk/types';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useDebounceValue } from 'usehooks-ts';
import Button from '@components/common/button';
import { useNotFound } from '@hooks/helpers/useNotFound';

const SchedulePaymentsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.schedule_payments'
    });
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search, 500);
    const [filter, setFilter] = useState<{
        frequency?: EScheduleFrequency[];
        status?: EScheduleStatus[];
        method?: ETransactionMethod[];
        action?: ETransactionAction[];
    }>({
        frequency: [],
        status: [],
        method: [],
        action: []
    });
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const { handleBack } = useNotFound();

    const { data: paymentsList, handleRequest: refetchPaymentsList } = useApiQuery({
        method: () => {
            return apiScheduleService.listSchedules(pageSize, page + 1, {
                frequency: (filter.frequency || [])?.length > 0 ? filter.frequency : undefined,
                status: (filter.status || [])?.length > 0 ? filter.status : undefined,
                method:
                    (filter.method || [])?.length > 0
                        ? filter.method
                        : [
                              ETransactionMethod.WALLET,
                              ETransactionMethod.EFT,
                              ETransactionMethod.EPS
                          ],
                action: (filter.action || [])?.length > 0 ? filter.action : undefined,
                info: debouncedSearch.length >= 3 ? debouncedSearch : undefined
            });
        },
        isInitialRequest: false,
        deps: [filter, page, pageSize],
        notificationType: ENewNotificationType.BILL
    });

    useEffect(() => {
        setDebouncedSearch(search);
    }, [search]);

    useEffect(() => {
        if (debouncedSearch.length >= 3) {
            refetchPaymentsList();
        } else if (debouncedSearch.length === 0) {
            refetchPaymentsList();
        }
    }, [debouncedSearch]);

    const onEdit = (schedule: TSchedule) => {
        if (schedule.method === ETransactionMethod.WALLET) {
            if (schedule.action === ETransactionAction.TRANSFER) {
                navigate(
                    `${routes.send_and_request_send}?type=${ESendRequestMoneyMethod.INTERNAL}&id=${schedule.id}`
                );
            } else if (schedule.action === ETransactionAction.REQUEST) {
                navigate(
                    `${routes.send_and_request_request}?type=${ESendRequestMoneyMethod.INTERNAL_REQUEST}&id=${schedule.id}`
                );
            }
        } else if (schedule.method === ETransactionMethod.EFT) {
            if (schedule.action === ETransactionAction.WITHDRAW) {
                navigate(
                    `${routes.send_and_request_send}?type=${ESendRequestMoneyMethod.BANK}&id=${schedule.id}`
                );
            } else if (schedule.action === ETransactionAction.DEPOSIT) {
                navigate(
                    `${routes.send_and_request_request}?type=${ESendRequestMoneyMethod.BANK_REQUEST}&id=${schedule.id}`
                );
            }
        } else if (schedule.method === ETransactionMethod.EPS) {
            if (schedule.action === ETransactionAction.DEPOSIT) {
                navigate(
                    `${routes.send_and_request_request}?type=${ESendRequestMoneyMethod.EPS_REQUEST}&id=${schedule.id}`
                );
            }
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Button
                            className={styles.back}
                            leftIcon="arrow_left"
                            onClick={() => handleBack(routes.send_and_request)}>
                            Back
                        </Button>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                </div>
                <PaymentsTable
                    refetchPaymentsList={refetchPaymentsList}
                    paymentsList={paymentsList?.data || []}
                    onFilterChange={(filter) => setFilter(filter)}
                    filter={filter}
                    onEdit={onEdit}
                    onSearch={(val) => setSearch(val)}
                    search={search}
                />
                {(paymentsList?.pagesTotal || 0) > 1 && (
                    <Pagination
                        pageCount={paymentsList?.pagesTotal || 0}
                        onPageChange={(val) => setPage(val)}
                        pageSize={pageSize}
                        onPageSizeChange={(val) => setPageSize(val)}
                    />
                )}
            </div>
        </motion.div>
    );
};

export default SchedulePaymentsSection;
