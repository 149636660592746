import React, { FC, useEffect } from 'react';
import Typography from '@components/common/typography';
import Select from '@components/common/select';
import Textarea from '@components/common/textarea';
import styles from './styles.module.scss';
import Uploader from '@components/uploader';
import { Icon } from '@components/icons';
import { IModalComponentProps } from '@context/modal_context';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiSupportService } from '@api';

const SubmitRequestModal: FC<
    IModalComponentProps<{
        subject: string;
        comment: string;
        reason: string;
        uploads: FormData[];
        transactionId?: string;
    }>
> = ({ modalData, changeModalData, handleChangeDisable }) => {
    useEffect(() => {
        if (!modalData?.subject || !modalData?.comment) {
            handleChangeDisable(true);
        } else {
            handleChangeDisable(false);
        }
    }, [modalData]);

    const { data: reasons } = useApiQuery({
        method: () => apiSupportService.getReasons()
    });

    return (
        <div className={styles.wrapper}>
            <Typography variant="h4" className={styles.header}>
                Submit a request
            </Typography>
            <div className={styles.content}>
                {!modalData?.transactionId && (
                    <Select
                        items={[{ value: 'Test', label: 'Test' }]}
                        onChange={(val) =>
                            changeModalData({ ...modalData, subject: val as string })
                        }
                        value={modalData?.subject}
                        label="Subject"
                        full
                        placeholder="Select the subject of your request"
                    />
                )}
                <Select
                    items={reasons?.map((item) => ({ label: item.name, value: item.value })) || []}
                    onChange={(val) => changeModalData({ ...modalData, reason: val as string })}
                    value={modalData?.reason}
                    label="Reason"
                    full
                    placeholder="Select the reason of your request"
                />
                <Textarea
                    full
                    label="Description"
                    placeholder="Please describe your issue here..."
                    onChange={(val) => changeModalData({ ...modalData, comment: val })}
                    value={modalData?.comment}
                />
                <Uploader
                    full
                    size={10}
                    accept={['.doc', 'image/png', 'image/jpg', 'image/jpeg', '.pdf', '.docx']}
                    label={
                        <div className={styles.attachment_label}>
                            <Icon name="attach" /> Attach file (optional)
                        </div>
                    }
                    onChange={(files) => changeModalData({ ...modalData, uploads: files })}
                />
            </div>
        </div>
    );
};

export default SubmitRequestModal;
