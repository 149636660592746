import React, { FC, SVGAttributes } from 'react';

const OpenEye: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1614 8.0536C13.1614 9.7996 11.7454 11.2146 9.99938 11.2146C8.25338 11.2146 6.83838 9.7996 6.83838 8.0536C6.83838 6.3066 8.25338 4.8916 9.99938 4.8916C11.7454 4.8916 13.1614 6.3066 13.1614 8.0536Z"
                stroke="#282828"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.998 15.355C13.806 15.355 17.289 12.617 19.25 8.05298C17.289 3.48898 13.806 0.750977 9.998 0.750977H10.002C6.194 0.750977 2.711 3.48898 0.75 8.05298C2.711 12.617 6.194 15.355 10.002 15.355H9.998Z"
                stroke="#282828"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default OpenEye;
