import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '@hooks/context/useUserContext';
import { Icon } from '@components/icons';
import { routes } from '@const/routes';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';

const SecondStepInstantLinkBank = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank.step_2_instant'
    });
    const { user } = useUserContext();

    const { data: authToken } = useApiQuery({
        method: () => apiAccountService.getFlinksConnectToken()
    });

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography variant="h4">{t('title')}</Typography>
            <Typography variant="body1">{t('description')}</Typography>
            {authToken && (
                <iframe
                    className="flinksconnect"
                    height="760"
                    style={{ border: 'none' }}
                    src={`${process.env.REACT_APP_FLINKS_URL}/?demo=true&removePadding=true&headerEnable=false&headerImgEnable=false&authorizeToken=${authToken}&staticLoadingEnable=true&accountSelectorEnable=true&accountSelectorCurrency=cad&consentEnable=false&redirectUrl=${window.location.origin}${routes.link_card_bank}&user=${user?.id}`}
                />
            )}
        </motion.div>
    );
};

export default SecondStepInstantLinkBank;
