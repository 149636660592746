import { ENewNotificationType, ENotificationCategory } from '@enum';
import { EEventName } from '@xeppt/xeppt-sdk/types/notification';

export const notificationsByType = {
    [ENewNotificationType.ACCOUNT]: [
        EEventName.EftAccountLinked,
        EEventName.BalanceUpdated,
        EEventName.ETransferAccountUpdated,
        EEventName.EpsCardCreated
    ],
    [ENewNotificationType.LOGOUT]: [EEventName.PasswordChanged],
    [ENewNotificationType.USER]: [EEventName.UserVerified],
    [ENewNotificationType.CARD]: [
        EEventName.CardBalanceLoaded,
        EEventName.CardCreated,
        EEventName.CardLimitsUpdated,
        EEventName.CardPinChanged,
        EEventName.CardStatusChanged
    ],
    [ENewNotificationType.BILL]: [EEventName.BillPaymentProcesses],
    [ENewNotificationType.TRANSACTION]: [
        EEventName.PaymentReceived,
        EEventName.MoneyRequestCreated,
        EEventName.PaymentStatusChanged,
        EEventName.PaymentProcessed,
        EEventName.BillPaymentProcesses
    ],
    [ENewNotificationType.PHONE]: [EEventName.PrimaryPhoneChanged],
    [ENewNotificationType.EMAIL]: [EEventName.PrimaryEmailChanged]
};

export const notificationsGroups = {
    [ENotificationCategory.CARD]: [
        EEventName.CardBalanceLoaded,
        EEventName.CardCreated,
        EEventName.CardLimitsUpdated,
        EEventName.CardPinChanged,
        EEventName.CardStatusChanged,
        EEventName.EpsCardCreated
    ],
    [ENotificationCategory.PERSONAL]: [
        EEventName.PasswordChanged,
        EEventName.UserVerified,
        EEventName.ETransferAccountUpdated
    ],
    [ENotificationCategory.PAYMENTS]: [
        EEventName.PaymentReceived,
        EEventName.MoneyRequestCreated,
        EEventName.PaymentStatusChanged,
        EEventName.PaymentProcessed,
        EEventName.BalanceUpdated
    ],
    [ENotificationCategory.SCHEDULE]: [EEventName.BillPaymentProcesses],
    [ENotificationCategory.MARKETING]: []
};
