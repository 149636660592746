import React, { FC, useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import { EActiveETransfer } from '@enum';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import SendMoneySection from '@sections/send_and_request/send_money';
import RequestMoneySection from '@sections/send_and_request/request_money';
import ManageAutodepositSection from '@sections/send_and_request/manage_autodeposit';
import ManageContactsSection from '@sections/send_and_request/manage_contacts';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation, slideAnimation } from '@const/animation';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import { useUserContext } from '@hooks/context/useUserContext';
import useResponsive from '@hooks/helpers/useResponsive';
import SchedulePaymentsSection from '@sections/send_and_request/schedule_payments';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { Icon } from '@components/icons';
import sendIllustration from '@svg/icons/send_money.svg';
import requestIllustration from '@svg/icons/request_money.svg';
import scheduleIllustration from '@svg/icons/schedule_payments.svg';
import manageIllustration from '@svg/icons/manage_contacts.svg';
import autodepositIllustration from '@svg/icons/interac_autodeposit.svg';

const cx = classNames.bind(styles);

interface IProps {
    activeTab?: EActiveETransfer;
}

const SendAndRequestLayout: FC<IProps> = ({ activeTab }) => {
    const navigate = useNavigate();
    const { t } = useTranslation('send_and_request');
    const { verifiedAction } = useVerificationGuard();
    const [currentTab, setCurrentTab] = useState<EActiveETransfer>();
    const { user } = useUserContext();
    const { isTablet, isMobile } = useResponsive();
    const [isTabletOpen, setIsTabletOpen] = useState(true);
    const isLessThenTablet = useMemo(() => isTablet || isMobile, [isTablet, isMobile]);
    const { handleBack } = useNotFound();

    const contentStyle = useMemo(
        () => cx([styles.content_wrapper, { isOpen: !!currentTab }]),
        [currentTab]
    );
    const sidebarStyle = useMemo(
        () =>
            cx([
                styles.sidebar,
                { isOpen: !!currentTab, isTabletOpen: isTabletOpen && !!currentTab }
            ]),
        [currentTab, isTabletOpen]
    );

    useEffect(() => {
        setIsTabletOpen(!isLessThenTablet || !currentTab);
    }, [isLessThenTablet, currentTab]);

    const renderTab = () => {
        switch (activeTab) {
            case EActiveETransfer.SEND:
                return <SendMoneySection />;
            case EActiveETransfer.REQUEST:
                return <RequestMoneySection />;
            case EActiveETransfer.CONTACTS:
                return <ManageContactsSection />;
            case EActiveETransfer.AUTODEPOSIT:
                return <ManageAutodepositSection />;
            case EActiveETransfer.SCHEDULE_PAYMENTS:
                return <SchedulePaymentsSection />;
        }
    };

    useEffect(() => {
        if (activeTab) {
            verifiedAction(
                () => {
                    setCurrentTab(activeTab);
                },
                () => {
                    setCurrentTab(undefined);
                    navigate(routes.send_and_request);
                }
            );
        } else {
            setCurrentTab(undefined);
        }
    }, [activeTab, user]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            {activeTab ? (
                <div className={contentStyle}>
                    <div className={styles.content}>{renderTab()}</div>
                </div>
            ) : (
                <div className={sidebarStyle}>
                    <div className={cx(styles.title_wrapper, { isOpen: isTabletOpen })}>
                        <motion.div
                            className={styles.header_wrapper}
                            {...(isLessThenTablet && slideAnimation)}>
                            <Button
                                className={styles.back}
                                leftIcon="arrow_left"
                                onClick={handleBack}>
                                Back
                            </Button>
                            <Typography variant="h4">{t('title')}</Typography>
                        </motion.div>
                    </div>
                    <div className={styles.divider} />
                    <div
                        className={cx([
                            styles.tab,
                            { isActive: currentTab === EActiveETransfer.SEND }
                        ])}
                        onClick={() => navigate(routes.send_and_request_send)}>
                        <img src={sendIllustration} alt="" />
                        <div className={styles.text_wrapper}>
                            <div className={styles.title}>{t('send_money')}</div>
                            <div className={styles.description}>
                                Send money to XEPPT account or to the bank account
                            </div>
                        </div>
                        <Icon className={styles.icon} name="nav_right" />
                    </div>
                    <div
                        className={cx([
                            styles.tab,
                            { isActive: currentTab === EActiveETransfer.REQUEST }
                        ])}
                        onClick={() => navigate(routes.send_and_request_request)}>
                        <img src={requestIllustration} alt="" />
                        <div className={styles.text_wrapper}>
                            <div className={styles.title}>{t('request_money')}</div>
                            <div className={styles.description}>
                                Request money from XEPPT or load balance from a linked bank account
                                or card
                            </div>
                        </div>
                        <Icon className={styles.icon} name="nav_right" />
                    </div>
                    <div
                        className={cx([
                            styles.tab,
                            { isActive: currentTab === EActiveETransfer.SCHEDULE_PAYMENTS }
                        ])}
                        onClick={() => navigate(routes.send_and_request_schedule)}>
                        <img src={scheduleIllustration} alt="" />
                        <div className={styles.text_wrapper}>
                            <div className={styles.title}>{t('schedule_payments')}</div>
                            <div className={styles.description}>
                                Monitor the progress of your scheduled payments, view details, and
                                edit, pause, or cancel them when needed
                            </div>
                        </div>
                        <Icon className={styles.icon} name="nav_right" />
                    </div>
                    <div
                        className={cx([
                            styles.tab,
                            { isActive: currentTab === EActiveETransfer.CONTACTS }
                        ])}
                        onClick={() => navigate(routes.send_and_request_contacts)}>
                        <img src={manageIllustration} alt="" />
                        <div className={styles.text_wrapper}>
                            <div className={styles.title}>{t('manage_contacts')}</div>
                            <div className={styles.description}>
                                View and manage your XEPPT contacts: add new contacts, update
                                details, delete or block as needed
                            </div>
                        </div>
                        <Icon className={styles.icon} name="nav_right" />
                    </div>
                    {/*<div*/}
                    {/*    className={cx([*/}
                    {/*        styles.tab,*/}
                    {/*        { isActive: currentTab === EActiveETransfer.AUTODEPOSIT }*/}
                    {/*    ])}*/}
                    {/*    onClick={() => navigate(routes.send_and_request_autodeposit)}>*/}
                    {/*    <img src={autodepositIllustration} alt="" />*/}
                    {/*    <div className={styles.text_wrapper}>*/}
                    {/*        <div className={styles.title}>{t('manage_autodeposit')}</div>*/}
                    {/*        <div className={styles.description}>*/}
                    {/*            Set up Interac e-Transfer autodeposit, view and update your Interac*/}
                    {/*            email*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <Icon className={styles.icon} name="nav_right" />*/}
                    {/*</div>*/}
                </div>
            )}
        </motion.div>
    );
};

export default SendAndRequestLayout;
