import React, { useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider, useWatch } from 'react-hook-form';
import { apiPayBillService, apiScheduleService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { defaultPaymentData } from '@const/default_form_data';
import { useApiQuery } from '@hooks/api/useApiQuery';
import moment from 'moment/moment';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useUserContext } from '@hooks/context/useUserContext';
import { payBillsPayBillsProgressSteps } from '@const/progress_bar_steps';
import { ENewNotificationType, ESendRequestMoneyMethod } from '@enum';
import { EScheduleFrequency } from '@xeppt/xeppt-sdk/types/schedule';
import { ETransactionAction, ETransactionMethod } from '@xeppt/xeppt-sdk/types';
import Recipient from '@sections/send_and_request/steps/recipient';
import Amount from '@sections/send_and_request/steps/amount';
import Schedule from '@sections/send_and_request/steps/schedule';
import Summary from '@sections/send_and_request/steps/summary';
import Success from '@sections/send_and_request/steps/success';
import Button from '@components/common/button';
import { routes } from '@const/routes';
import Typography from '@components/common/typography';
import ProgressBar from '@components/progress_bar';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { useQueryParams } from '@hooks/helpers/useQueryParams';

const PayBills = () => {
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const form = useForm({
        defaultValues: defaultPaymentData
    });
    const [queryPayee, scheduleId] = useQueryParams(['payee', 'id']);
    const { refetchAccount } = useUserContext();
    const isSchedule = useWatch({ name: 'isSchedule', control: form.control });
    const { handleBack } = useNotFound();
    const steps = useMemo(() => {
        return payBillsPayBillsProgressSteps.filter((item) => {
            if (!isSchedule) {
                return item?.id !== '3';
            }
            return true;
        });
    }, [payBillsPayBillsProgressSteps, isSchedule]);
    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(steps, [
        form.watch()
    ]);

    const { data: listPayees } = useApiQuery({
        method: () => apiPayBillService.listPayees(1000, 1),
        notificationType: ENewNotificationType.CARD
    });

    const { handleRequest: createOneTimePayment, isLoading: isPaymentLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const { amount, account } = values;
            return apiPayBillService.createPayment(account, Number(amount));
        },
        onSuccess: () => {
            requestSuccessLocale('create_payment');
            nextStep();
            refetchAccount();
            form.reset(defaultPaymentData);
        },
        onError: requestErrorLocale
    });
    const { handleRequest: createSchedulePayment, isLoading: isScheduleLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const {
                paymentFrequency,
                amount,
                numberOfPayments,
                nextPaymentAt,
                isInfinitePayment,
                account
            } = values;
            return apiScheduleService.createSchedule({
                amount: Number(amount || 0),
                nextPaymentAt,
                frequency: paymentFrequency,
                numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments),
                referenceId: account,
                method: ETransactionMethod.PAY_BILL,
                action: ETransactionAction.PAYMENT
            });
        },
        onSuccess: () => {
            requestSuccessLocale('create_schedule_payment');
            nextStep();
            refetchAccount();
            form.reset(defaultPaymentData);
        },
        onError: requestErrorLocale
    });

    useEffect(() => {
        if (queryPayee) {
            form.setValue('account', queryPayee);
        }
    }, [queryPayee]);

    const onSubmit = () => {
        const values = form.getValues();
        const { paymentFrequency, nextPaymentAt } = values;
        if (
            paymentFrequency === EScheduleFrequency.ONCE &&
            moment(nextPaymentAt).isSame(moment(), 'day')
        ) {
            createOneTimePayment(undefined);
        } else {
            createSchedulePayment(undefined);
        }
    };

    const handleNextStep = (onSuccess: () => void) => {
        form.trigger().then((isValid) => {
            if (isValid) {
                onSuccess();
            }
        });
    };

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return (
                    <Recipient
                        payeesList={listPayees?.data || []}
                        onSubmit={() => handleNextStep(nextStep)}
                        method={ESendRequestMoneyMethod.PAY_BILLS}
                    />
                );
            case '2':
                return (
                    <Amount
                        isEdit={!!scheduleId}
                        onPrev={prevStep}
                        method={ESendRequestMoneyMethod.PAY_BILLS}
                        onSubmit={() =>
                            handleNextStep(() => (isSchedule ? nextStep() : changeStep('4')))
                        }
                    />
                );
            case '3':
                return <Schedule onPrev={prevStep} onSubmit={() => handleNextStep(nextStep)} />;
            case '4':
                return (
                    <Summary
                        isEdit={!!scheduleId}
                        onPrev={prevStep}
                        onSubmit={() =>
                            handleNextStep(() => {
                                onSubmit();
                            })
                        }
                        payeesList={listPayees?.data || []}
                        isLoading={isScheduleLoading || isPaymentLoading}
                        method={ESendRequestMoneyMethod.PAY_BILLS}
                    />
                );
            case '5':
                return (
                    <Success
                        onReset={() => {
                            form.reset(defaultPaymentData);
                            changeStep('1');
                        }}
                        isSchedule={isSchedule}
                        method={ESendRequestMoneyMethod.PAY_BILLS}
                    />
                );
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.header}>
                <Button
                    className={styles.back}
                    leftIcon="arrow_left"
                    onClick={() => {
                        form.reset(defaultPaymentData);
                        changeStep('1');
                        handleBack(routes.send_and_request);
                    }}>
                    Back
                </Button>
                <Typography variant="h4">Pay a bill</Typography>
            </div>
            <div className={styles.content_wrapper}>
                <ProgressBar
                    disabled={!!scheduleId}
                    steps={steps}
                    availableStep={availableStep}
                    currentStep={currentStep}
                    className={styles.progress}
                    changeStep={changeStep}
                />
                <div className={styles.body_wrapper}>
                    <FormProvider {...form}>{renderContent()}</FormProvider>
                </div>
            </div>
        </motion.div>
    );
};

export default PayBills;
