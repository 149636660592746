import React, { FC } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { apiScheduleService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { getBillingStatusList, getFrequencyList } from '@utils/billing';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { formatDateForMoment } from '@utils/date';
import { EScheduleFrequency, EScheduleStatus, TSchedule } from '@xeppt/xeppt-sdk/types/schedule';
import Input from '@components/common/input';
import {
    getScheduleStatusVariant,
    getScheduleTypeByMethodAndAction,
    getTransactionAction
} from '@utils/index';
import { enumTranslate } from '@locales/index';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionType
} from '@xeppt/xeppt-sdk/types/transaction';
import Dropdown from '@components/common/dropdown';
import Status from '@components/common/status';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import useModalContext from '@hooks/context/useModalContext';
import { modalIds } from '@const/modals';
import emptyIllustration from '@svg/illustrations/empty_schedule_bill.svg';

interface IProps {
    onEdit: (data: TSchedule) => void;
    onSearch: (value: string) => void;
    refetchPaymentsList: () => void;
    search: string;
    paymentsList: TSchedule[];
    filter: {
        frequency?: EScheduleFrequency[];
        status?: EScheduleStatus[];
        method?: ETransactionMethod[];
        action?: ETransactionAction[];
    };
    onFilterChange: (val: {
        paymentFrequency?: EScheduleFrequency[];
        status?: EScheduleStatus[];
    }) => void;
}

const PaymentsTable: FC<IProps> = ({
    paymentsList,
    refetchPaymentsList,
    onEdit,
    onSearch,
    filter,
    search,
    onFilterChange
}) => {
    const navigate = useNavigate();
    const { onOpen, changeModalData, onClose } = useModalContext();
    const { t, i18n } = useTranslation('sections', {
        keyPrefix: 'send_and_request.schedule_payments.table'
    });
    const { t: transactionLocale } = useTranslation('components', {
        keyPrefix: 'transaction_tables.transaction_item'
    });
    const { t: frequencyLocale } = useTranslation('sections', {
        keyPrefix: 'send_and_request.schedule_payments.payment_details'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();

    const { handleRequest: handleCancelPayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.deleteSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('cancel_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleDeactivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.deactivateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('pause_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleActivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.activateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('activate_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const handlePauseResumePayment = (id: string, status: EScheduleStatus) => {
        if (status === EScheduleStatus.ACTIVE) {
            handleDeactivatePayment(id);
        } else {
            handleActivatePayment(id);
        }
    };

    const columns = [
        {
            label: t('name'),
            key: 'info',
            width: 300
        },
        {
            label: t('frequency'),
            key: 'frequency',
            render: (value: EScheduleFrequency) =>
                getFrequencyList(frequencyLocale).find((item) => item.value === value)?.label,
            filterOptions: getFrequencyList(frequencyLocale)
        },
        {
            label: t('method'),
            key: 'method',
            render: (_: string, data: TSchedule) =>
                enumTranslate[data.method][i18n.language as 'en' | 'fr'],
            filterOptions: [
                { label: transactionLocale('wallet'), value: ETransactionMethod.WALLET },
                { label: transactionLocale('bank'), value: ETransactionMethod.EFT },
                { label: transactionLocale('eps'), value: ETransactionMethod.EPS }
            ]
        },
        {
            label: t('action'),
            key: 'action',
            render: (_: string, data: TSchedule) =>
                getTransactionAction(
                    data.action,
                    data.method,
                    getScheduleTypeByMethodAndAction(data.method, data.action),
                    transactionLocale
                ),
            filterOptions: [
                { label: transactionLocale('send_money'), value: ETransactionAction.TRANSFER },
                { label: 'Load balance', value: ETransactionAction.DEPOSIT },
                { label: 'Send to bank', value: ETransactionAction.WITHDRAW },
                {
                    label: transactionLocale('request_money'),
                    value: ETransactionAction.REQUEST
                }
            ]
        },
        {
            label: t('status'),
            key: 'status',
            render: (value: EScheduleStatus) => (
                <Status variant={getScheduleStatusVariant(value)}>
                    {
                        getBillingStatusList(frequencyLocale).find((item) => item.value === value)
                            ?.label
                    }
                </Status>
            ),
            filterOptions: getBillingStatusList(frequencyLocale)
        },
        {
            label: t('amount'),
            key: 'amount',
            render: (value: number) => `$${value.toFixed(2)}`
        },
        {
            label: 'Payments',
            key: 'remainingPayments',
            render: (value: number, data: TSchedule) =>
                value === -1 ? 'Infinite' : value + data.totalPayments
        },
        {
            label: t('payment_date'),
            key: 'nextPaymentAt',
            render: (value: string) =>
                value ? moment(formatDateForMoment(value)).format('MM/DD/YYYY') : '-'
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: undefined, data: TSchedule) => (
                <div className={styles.table_actions}>
                    <Dropdown
                        items={[
                            {
                                label: 'View details',
                                icon: 'open_eye',
                                onClick: () => {
                                    changeModalData({
                                        schedule: data,
                                        refetchSchedules: refetchPaymentsList,
                                        onEdit: (schedule: TSchedule) => {
                                            onClose();
                                            onEdit(schedule);
                                        }
                                    });
                                    onOpen({
                                        modalId: modalIds.VIEW_SCHEDULE
                                    });
                                }
                            },
                            (data.status === EScheduleStatus.ACTIVE ||
                                data.status === EScheduleStatus.INACTIVE) && {
                                label:
                                    data.status === EScheduleStatus.ACTIVE ? 'Pause' : 'Continue',
                                icon: data.status === EScheduleStatus.ACTIVE ? 'pause' : 'play',
                                onClick: () => handlePauseResumePayment(data.id, data.status)
                            },
                            data.status !== EScheduleStatus.COMPLETE && {
                                label: 'Edit',
                                icon: 'edit',
                                onClick: () => onEdit(data)
                            },
                            {
                                label:
                                    data.status === EScheduleStatus.DELETED ? 'Delete' : 'Cancel',
                                isRed: true,
                                icon:
                                    data.status === EScheduleStatus.DELETED
                                        ? 'trash'
                                        : 'close_outlined',
                                onClick: () => handleCancelPayment(data.id)
                            }
                        ]}
                    />
                </div>
            )
        }
    ];

    return (
        <motion.div {...pageAnimation} className={styles.content}>
            <div className={styles.content}>
                <div className={styles.search_wrapper}>
                    <Input
                        value={search}
                        onChange={(val) => onSearch(val)}
                        full
                        className={styles.search}
                        leftIcon="search"
                        rightIcon={search.length > 0 ? 'filed_close' : undefined}
                        onClickRightIcon={() => onSearch('')}
                        placeholder="Search by recipient..."
                    />
                    <Button
                        variant="primary"
                        size="normal"
                        onClick={() => navigate(routes.send_and_request)}>
                        Schedule new
                    </Button>
                </div>
            </div>
            {paymentsList.length === 0 &&
            filter?.frequency?.length === 0 &&
            filter?.status?.length === 0 &&
            filter?.method?.length === 0 &&
            filter?.action?.length === 0 ? (
                <div className={styles.empty}>
                    <img src={emptyIllustration} alt="empty payees" />
                    <p className={styles.title}>No payments have been scheduled yet</p>
                    <p className={styles.description}>
                        Payments you have scheduled will appear here
                    </p>
                    <Button
                        variant="primary"
                        size="normal"
                        onClick={() => navigate(routes.send_and_request)}>
                        Schedule payment
                    </Button>
                </div>
            ) : (
                <Table
                    //@ts-ignore
                    columns={columns}
                    rows={paymentsList}
                    className={styles.table}
                    emptyDescription={t('empty_payments')}
                    isActions
                    //@ts-ignore
                    filter={filter}
                    onFilterChange={(val) => {
                        onFilterChange({
                            ...filter,
                            ...val
                        });
                    }}
                />
            )}
        </motion.div>
    );
};

export default PaymentsTable;
