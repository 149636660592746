import { useProgressBar } from '@hooks/helpers/useProgressBar';
import styles from './styles.module.scss';
import ProgressBar from '@components/progress_bar';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider, useWatch } from 'react-hook-form';
import { apiAccountService, apiContactService, apiScheduleService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { eTransferRequestMoneyProgressSteps } from '@const/progress_bar_steps';
import React, { useEffect, useMemo, useState } from 'react';
import { ESchedulePaymentType, ESendRequestMoneyMethod } from '@enum';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { ETransactionAction, ETransactionMethod, TContact } from '@xeppt/xeppt-sdk/types';
import ChooseMethodSection from '@sections/send_and_request/steps/choose_methods/method';
import { useTranslation } from 'react-i18next';
import { EContactType } from '@xeppt/xeppt-sdk/types/wallet';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import { useUserContext } from '@hooks/context/useUserContext';
import { EScheduleFrequency } from '@xeppt/xeppt-sdk/types/schedule';
import Button from '@components/common/button';
import Typography from '@components/common/typography';
import Recipient from '@sections/send_and_request/steps/recipient';
import Amount from '@sections/send_and_request/steps/amount';
import Schedule from '@sections/send_and_request/steps/schedule';
import Summary from '@sections/send_and_request/steps/summary';
import Success from '@sections/send_and_request/steps/success';
import Requester from '@sections/send_and_request/steps/requester';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { routes } from '@const/routes';

const defaultPaymentData = {
    paymentType: ESchedulePaymentType.PAYMENTS_DATE,
    contact: '',
    amount: undefined,
    isSchedule: false,
    account: '',
    message: '',
    paymentFrequency: EScheduleFrequency.ONCE,
    nextPaymentAt: new Date(),
    numberOfPayments: 1,
    isInfinitePayment: false
};

const RequestMoneySection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.send_money'
    });
    const [type, contactId, scheduleId] = useQueryParams(['type', 'contactId', 'id']);
    const form = useForm({ defaultValues: defaultPaymentData });
    const { refetchAccount } = useUserContext();
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const [sendMethod, setSendMethod] = useState<ESendRequestMoneyMethod>(
        ESendRequestMoneyMethod.INTERNAL_REQUEST
    );
    const [contactList, setContactsList] = useState<TContact[]>([]);
    const [isMethodChosen, setIsMethodChosen] = useState(false);
    const watchedFrequency = useWatch({ name: 'paymentFrequency', control: form.control });
    const isSchedule = useWatch({ name: 'isSchedule', control: form.control });
    const { handleBack } = useNotFound();

    const isBank = useMemo(
        () =>
            sendMethod === ESendRequestMoneyMethod.BANK ||
            sendMethod === ESendRequestMoneyMethod.BANK_REQUEST,
        [sendMethod]
    );

    const isWallet = useMemo(
        () =>
            sendMethod === ESendRequestMoneyMethod.INTERNAL ||
            sendMethod === ESendRequestMoneyMethod.INTERNAL_REQUEST,
        [sendMethod]
    );

    const isETransfer = useMemo(
        () =>
            sendMethod === ESendRequestMoneyMethod.E_TRANSFER ||
            sendMethod === ESendRequestMoneyMethod.E_TRANSFER_REQUEST,
        [sendMethod]
    );

    const isEps = useMemo(() => sendMethod === ESendRequestMoneyMethod.EPS_REQUEST, [sendMethod]);

    const steps = useMemo(() => {
        return eTransferRequestMoneyProgressSteps
            .filter((item) => {
                if (!isSchedule) {
                    if (sendMethod !== ESendRequestMoneyMethod.E_TRANSFER_REQUEST) {
                        return item?.id !== '1' && item?.id !== '4';
                    }
                    return item?.id !== '4';
                }
                if (sendMethod !== ESendRequestMoneyMethod.E_TRANSFER_REQUEST) {
                    return item?.id !== '1';
                }
                return true;
            })
            .map((item) => {
                if (item?.id === '2') {
                    if (isBank) return { ...item, title: 'Bank account' };
                    if (isWallet) return { ...item, title: 'Recipient' };
                    if (isETransfer) return { ...item, title: 'Recipient' };
                    if (isEps) return { ...item, title: 'Card' };
                    return item;
                }
                return item;
            });
    }, [
        eTransferRequestMoneyProgressSteps,
        isSchedule,
        sendMethod,
        isMethodChosen,
        isBank,
        isWallet,
        isETransfer,
        isEps
    ]);

    const { availableStep, prevStep, nextStep, currentStep, changeStep } = useProgressBar(steps, [
        form.watch(),
        sendMethod
    ]);
    const { data: contacts, handleRequest: refetchContacts } = useApiQuery({
        method: () => apiContactService.getContacts()
    });

    useEffect(() => {
        if (type) {
            //@ts-ignore
            setSendMethod(type as ESendRequestMoneyMethod);
            setIsMethodChosen(true);
        }
        if (contactId) {
            form.setValue('contact', contactId);
        }
    }, [type, contactId, contactList]);

    useEffect(() => {
        if (scheduleId) {
            changeStep('3');
        } else {
            if (sendMethod === ESendRequestMoneyMethod.E_TRANSFER_REQUEST) {
                changeStep('1');
            } else {
                changeStep('2');
            }
        }
    }, [sendMethod, scheduleId]);

    const { handleRequest: handleRequestMoneyByETransfer, isLoading: isETransferRequestLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                return apiAccountService.requestMoneyETransfer({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    message: values.message,
                    method: 'email'
                });
            },
            onSuccess: () => {
                requestSuccessLocale('request_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const getTransactionMethod = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                return ETransactionMethod.WALLET;
            case ESendRequestMoneyMethod.BANK_REQUEST:
                return ETransactionMethod.EFT;
            case ESendRequestMoneyMethod.EPS_REQUEST:
                return ETransactionMethod.EPS;
        }
    };

    const getTransactionAction = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                return ETransactionAction.REQUEST;
            case ESendRequestMoneyMethod.BANK_REQUEST:
                return ETransactionAction.DEPOSIT;
            case ESendRequestMoneyMethod.EPS_REQUEST:
                return ETransactionAction.DEPOSIT;
        }
    };

    useApiQuery({
        method: () => apiScheduleService.getSchedule(scheduleId as string),
        condition: !!scheduleId,
        isInitialRequest: false,
        onSuccess: (data) => {
            form.reset({
                paymentType: ESchedulePaymentType.PAYMENTS_DATE,
                contact: data.method === ETransactionMethod.WALLET ? data.referenceId : undefined,
                //@ts-ignore
                amount: data.amount,
                account: data.method === ETransactionMethod.EFT ? data.referenceId : undefined,
                paymentFrequency: data.frequency,
                nextPaymentAt: data.nextPaymentAt,
                numberOfPayments: data.remainingPayments,
                isInfinitePayment: data.remainingPayments === -1
            });
        },
        deps: [scheduleId]
    });

    const { handleRequest: createSchedulePayment, isLoading: isScheduleLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const {
                paymentFrequency,
                amount,
                numberOfPayments,
                nextPaymentAt,
                isInfinitePayment,
                contact,
                account
            } = values;
            return apiScheduleService.createSchedule({
                amount: Number(amount || 0),
                nextPaymentAt,
                frequency: paymentFrequency,
                numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments),
                referenceId:
                    sendMethod === ESendRequestMoneyMethod.BANK_REQUEST ||
                    sendMethod === ESendRequestMoneyMethod.EPS_REQUEST
                        ? account
                        : contact,
                method: getTransactionMethod() as ETransactionMethod,
                action: getTransactionAction() as ETransactionAction
            });
        },
        onSuccess: () => {
            requestSuccessLocale('create_schedule_payment');
            nextStep();
            refetchAccount();
            form.reset(defaultPaymentData);
        },
        onError: requestErrorLocale
    });

    const { handleRequest: updateSchedulePayment, isLoading: isUpdateScheduleLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                const {
                    paymentFrequency,
                    amount,
                    numberOfPayments,
                    nextPaymentAt,
                    isInfinitePayment
                } = values;
                return apiScheduleService.updateSchedule(scheduleId as string, {
                    amount: Number(amount || 0),
                    nextPaymentAt,
                    frequency: paymentFrequency,
                    numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments)
                });
            },
            onSuccess: () => {
                requestSuccessLocale('update_schedule_payment');
                nextStep();
                refetchAccount();
                form.reset(defaultPaymentData);
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleRequestMoneyByWallet, isLoading: isWalletRequestLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                return apiAccountService.requestMoneyWallet({
                    contactId: values.contact,
                    amount: Number(values.amount),
                    message: values.message
                });
            },
            onSuccess: () => {
                requestSuccessLocale('request_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleRequestMoneyByEft, isLoading: isEftRequestLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                return apiAccountService.requestMoneyEft({
                    id: values.account,
                    amount: Number(values.amount)
                });
            },
            onSuccess: () => {
                requestSuccessLocale('request_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const { handleRequest: handleRequestMoneyByEps, isLoading: isEpsRequestLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                return apiAccountService.requestMoneyEps({
                    id: values.account,
                    amount: Number(values.amount)
                });
            },
            onSuccess: () => {
                requestSuccessLocale('request_money');
                nextStep();
                refetchAccount();
            },
            onError: requestErrorLocale
        });

    const onSubmit = () => {
        if (scheduleId) {
            updateSchedulePayment(undefined);
        } else {
            if (watchedFrequency !== EScheduleFrequency.ONCE) {
                createSchedulePayment(undefined);
            } else {
                switch (sendMethod) {
                    case ESendRequestMoneyMethod.E_TRANSFER_REQUEST:
                        handleRequestMoneyByETransfer(undefined);
                        break;
                    case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                        handleRequestMoneyByWallet(undefined);
                        break;
                    case ESendRequestMoneyMethod.BANK_REQUEST:
                        handleRequestMoneyByEft(undefined);
                        break;
                    case ESendRequestMoneyMethod.EPS_REQUEST:
                        handleRequestMoneyByEps(undefined);
                        break;
                }
            }
        }
    };

    useEffect(() => {
        if (contacts) {
            if (sendMethod === ESendRequestMoneyMethod.E_TRANSFER_REQUEST) {
                setContactsList(contacts.filter((item) => item.type === EContactType.INTERAC));
            } else {
                setContactsList(contacts.filter((item) => item.type === EContactType.XEPPT));
            }
        }
    }, [sendMethod, contacts]);

    const handleNextStep = (onSuccess: () => void) => {
        form.trigger().then((isValid) => {
            if (isValid) {
                onSuccess();
            }
        });
    };

    useEffect(() => {
        scrollTo(0, 0);
    }, [currentStep, sendMethod]);

    const renderContent = () => {
        switch (currentStep?.id) {
            case '1':
                return <Requester isRequest onSubmit={() => handleNextStep(nextStep)} />;
            case '2':
                return (
                    <Recipient
                        onSubmit={() => handleNextStep(nextStep)}
                        contactsList={contactList || []}
                        method={sendMethod}
                    />
                );
            case '3':
                return (
                    <Amount
                        isEdit={!!scheduleId}
                        onPrev={prevStep}
                        method={sendMethod}
                        contactList={contactList || []}
                        onSubmit={() =>
                            handleNextStep(() => (isSchedule ? nextStep() : changeStep('5')))
                        }
                    />
                );
            case '4':
                return <Schedule onPrev={prevStep} onSubmit={() => handleNextStep(nextStep)} />;
            case '5':
                return (
                    <Summary
                        isEdit={!!scheduleId}
                        onPrev={prevStep}
                        onSubmit={() =>
                            handleNextStep(() => {
                                onSubmit();
                            })
                        }
                        contactList={contactList || []}
                        isLoading={
                            isScheduleLoading ||
                            isWalletRequestLoading ||
                            isUpdateScheduleLoading ||
                            isEftRequestLoading ||
                            isETransferRequestLoading ||
                            isEpsRequestLoading
                        }
                        method={sendMethod}
                        isRequest
                    />
                );
            case '6':
                return (
                    <Success
                        onReset={() => {
                            form.reset(defaultPaymentData);
                            changeStep('1');
                            refetchContacts();
                        }}
                        isSchedule={isSchedule}
                        method={sendMethod}
                    />
                );
        }
    };

    const renderTitle = () => {
        switch (sendMethod) {
            case ESendRequestMoneyMethod.INTERNAL_REQUEST:
                return 'request_internal';
            case ESendRequestMoneyMethod.E_TRANSFER_REQUEST:
                return 'request_e_transfer';
            case ESendRequestMoneyMethod.BANK_REQUEST:
                return 'request_bank';
            case ESendRequestMoneyMethod.EPS_REQUEST:
                return 'request_eps';
            default:
                return 'title_request';
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            {isMethodChosen ? (
                <>
                    <div className={styles.header}>
                        <Button
                            className={styles.back}
                            leftIcon="arrow_left"
                            onClick={() => {
                                if (isMethodChosen) {
                                    form.reset(defaultPaymentData);
                                    setIsMethodChosen(false);
                                    changeStep('1');
                                } else {
                                    handleBack(routes.send_and_request);
                                }
                            }}>
                            Back
                        </Button>
                        <Typography
                            variant="h4"
                            dangerouslySetInnerHTML={{ __html: t(renderTitle()) }}
                        />
                    </div>
                    <div className={styles.content_wrapper}>
                        <ProgressBar
                            disabled={!!scheduleId}
                            steps={steps}
                            availableStep={availableStep}
                            currentStep={currentStep}
                            className={styles.progress}
                            changeStep={changeStep}
                        />
                        <div className={styles.body_wrapper}>
                            <FormProvider {...form}>{renderContent()}</FormProvider>
                        </div>
                    </div>
                </>
            ) : (
                <ChooseMethodSection
                    method={sendMethod}
                    onChangeMethod={(val) => setSendMethod(val)}
                    onSubmit={() => setIsMethodChosen(true)}
                    title={t('title_request')}
                    type="request"
                    styles={styles}
                />
            )}
        </motion.div>
    );
};

export default RequestMoneySection;
