import React, { FC, useMemo } from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { ECardType, TCardLite } from '@xeppt/xeppt-sdk/types/card';
import FormField from '@components/form_field';
import { useUserContext } from '@hooks/context/useUserContext';
import { useLocales } from '@hooks/helpers/useLocales';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import classNames from 'classnames/bind';
import { ECardStatus } from '@xeppt/xeppt-sdk/types';

interface IProps {
    onChange: (val: ECardType) => void;
    cards: TCardLite[];
}

const physicalAmount = 7;

const cx = classNames.bind(styles);

const CardTypeSection: FC<IProps> = ({ cards, onChange }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'order_card.card_type'
    });
    const { account } = useUserContext();
    const { submitLocale } = useLocales();
    const navigate = useNavigate();

    const isEnoughAmount = useMemo(() => {
        return (account?.balance || 0) < physicalAmount;
    }, [account, physicalAmount]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.card}>
                <div className={styles.content_wrapper}>
                    <Typography className={styles.title} variant="h4">
                        {t('physical_card')}
                    </Typography>
                    <Typography className={styles.description} variant="body3">
                        {t('physical_description')}
                    </Typography>
                    <div className={styles.shipping}>
                        <Icon name="delivery" width={20} height={20} />
                        {t('physical_get')}
                    </div>
                    <div className={styles.tag}>${physicalAmount.toFixed(2)}</div>
                    {isEnoughAmount && (
                        <Typography variant="body3" className={styles.helper_text}>
                            {t('not_enough_balance')}
                        </Typography>
                    )}
                </div>
                <Button
                    variant="primary"
                    size="normal"
                    onClick={() => {
                        if (isEnoughAmount) {
                            navigate(routes.send_and_request_request);
                        } else {
                            onChange(ECardType.PHYSICAL);
                        }
                    }}>
                    {isEnoughAmount ? submitLocale('load_balance') : 'Order'}
                </Button>
            </div>
            <div className={cx([styles.card])}>
                <div className={styles.content_wrapper}>
                    <Typography className={styles.title} variant="h4">
                        {t('supplementary_card')}
                    </Typography>
                    <Typography className={styles.description} variant="body3">
                        {t('supplementary_description')}
                    </Typography>
                    <div className={styles.shipping}>
                        <Icon name="delivery" width={20} height={20} />
                        {t('supplementary_get')}
                    </div>
                    <div className={styles.tag}>Free</div>
                </div>
                <Button
                    variant="primary"
                    size="normal"
                    disabled={
                        (cards || [])?.length === 0 &&
                        !cards?.find(
                            (item) =>
                                item.type === ECardType.PHYSICAL &&
                                item.status === ECardStatus.ACTIVE
                        )
                    }
                    onClick={() => {
                        onChange(ECardType.PHYSICAL_SUPPLEMENTARY);
                    }}>
                    Order
                </Button>
            </div>
        </div>
    );
};

export default CardTypeSection;
