import moment from 'moment-timezone';

export const getYears = (count: number, future: boolean) => {
    const finishYear = new Date().getFullYear() + (future ? 50 : 0),
        years = [];
    let startYear = finishYear - (count + (future ? 50 : 0));
    while (startYear <= finishYear) {
        years.push(startYear++);
    }
    return years;
};

export const prettifyDate = (inputDate: Date, t: (val: string) => string) => {
    const date = new Date(inputDate);
    const now = new Date();
    const diff = Math.abs(now.getTime() - date.getTime()); // Difference in milliseconds

    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (minutes < 1) {
        return 'just now';
    } else if (minutes < 60) {
        return minutes === 1
            ? `1 ${t('minute')} ${t('ago')}`
            : `${minutes} ${t('minutes')} ${t('ago')}`;
    } else if (hours < 24) {
        return hours === 1 ? `1 ${t('hour')} ${t('ago')}` : `${hours} ${t('hours')} ${t('ago')}`;
    } else if (days <= 3) {
        return days === 1 ? `1 ${t('day')} ${t('ago')}` : `${days} ${t('days')} ${t('ago')}`;
    } else {
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long' };
        return date.toLocaleDateString(undefined, options);
    }
};

export const formatDateForMoment = (date: Date | string, isPlus?: boolean) => {
    const dateObj = new Date(date);

    const year = dateObj.getUTCFullYear();
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getUTCDate() + (isPlus ? 1 : 0)).padStart(2, '0');

    return `${year}-${month}-${day}`;
};

export const getAllTimezonesOptions = () => {
    return moment.tz.names().map((item) => ({ label: item, value: item }));
};

export function isValidDateFormat(dateString: string): boolean {
    // Regex for MM/DD/YYYY format
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;

    return regex.test(dateString);
}

export const getThisWeekRangeDates = (minDate?: Date, maxDate?: Date): [Date, Date] => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday
    const diffToMonday = (dayOfWeek + 6) % 7; // Adjust to make Monday the start of the week

    // Get the start (Monday) of the week
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - diffToMonday);
    startOfWeek.setHours(0, 0, 0, 0); // Normalize to the start of the day

    // Get the end (Sunday) of the week
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999); // Normalize to the end of the day

    // Apply minDate
    if (minDate) {
        const minDateObject = new Date(minDate);
        if (startOfWeek < minDateObject) {
            startOfWeek.setTime(minDateObject.getTime());
        }
    }

    // Apply maxDate
    if (maxDate) {
        const maxDateObject = new Date(maxDate);
        if (endOfWeek > maxDateObject) {
            endOfWeek.setTime(maxDateObject.getTime());
        }
    }

    return [startOfWeek, endOfWeek];
};

export const getPreviousCalendarWeekRange = (minDate?: Date, maxDate?: Date) => {
    const today = new Date();

    // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const currentDayOfWeek = today.getDay();

    // Start of the current week (Monday)
    const startOfThisWeek = new Date(today);
    startOfThisWeek.setDate(today.getDate() - currentDayOfWeek + (currentDayOfWeek === 0 ? -6 : 1));

    // Start of the previous week (Monday of last week)
    const startOfPreviousWeek = new Date(startOfThisWeek);
    startOfPreviousWeek.setDate(startOfThisWeek.getDate() - 7);

    // End of the previous week (Sunday of last week)
    const endOfPreviousWeek = new Date(startOfPreviousWeek);
    endOfPreviousWeek.setDate(startOfPreviousWeek.getDate() + 6);

    // Apply minDate and maxDate constraints if provided
    const constrainedStartDate =
        minDate && startOfPreviousWeek < minDate ? minDate : startOfPreviousWeek;
    const constrainedEndDate = maxDate && endOfPreviousWeek > maxDate ? maxDate : endOfPreviousWeek;

    return [constrainedStartDate, constrainedEndDate];
};

export const getLastTwoWeeksRangeDates = (minDate?: Date, maxDate?: Date): [Date, Date] => {
    // If minDate and maxDate are provided, use them; otherwise, use defaults
    const today = maxDate || new Date();
    const past = minDate || new Date();

    // If minDate is not provided, calculate past
    if (!minDate) {
        past.setDate(today.getDate() - 14);
    }

    return [past, today];
};

export const getLastMonthRangeDates = (minDate?: Date, maxDate?: Date) => {
    // Get today's date and calculate the first and last day of the current month
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    // If minDate or maxDate isn't provided, default to the current month's start and end
    const adjustedStart = minDate ? new Date(minDate) : startOfMonth;
    const adjustedEnd = maxDate ? new Date(maxDate) : endOfMonth;

    // Ensure the range is limited to the current month's boundaries
    const rangeStart = adjustedStart > startOfMonth ? adjustedStart : startOfMonth;
    const rangeEnd = adjustedEnd < endOfMonth ? adjustedEnd : endOfMonth;

    // Return only the first and last date as ISO strings
    return [rangeStart, rangeEnd];
};

export const getPreviousMonthRangeDates = (minDate?: Date, maxDate?: Date): [Date, Date] => {
    // If minDate and maxDate are provided, use them; otherwise, use defaults
    const today = maxDate || new Date();
    const lastMonthStart = minDate || new Date();

    // If minDate is not provided, calculate the range for the previous month
    if (!minDate) {
        // Set to the first day of the current month
        lastMonthStart.setDate(1);
        // Move to the previous month
        lastMonthStart.setMonth(today.getMonth() - 1);
    }

    const lastMonthEnd = new Date(lastMonthStart);
    // Set the end date to the last day of the previous month
    lastMonthEnd.setMonth(lastMonthStart.getMonth() + 1);
    lastMonthEnd.setDate(0); // 0th day of the next month equals the last day of the previous month

    return [lastMonthStart, lastMonthEnd];
};

export const getThisYearRangeDates = (minDate?: Date, maxDate?: Date): [Date, Date] => {
    // Get today's date and calculate the first and last day of the current year
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1); // January 1st of this year
    const endOfYear = new Date(now.getFullYear(), 11, 31); // December 31st of this year

    // If minDate or maxDate isn't provided, default to the year's start and end
    const adjustedStart = minDate ? new Date(minDate) : startOfYear;
    const adjustedEnd = maxDate ? new Date(maxDate) : endOfYear;

    // Ensure the range is limited to the current year's boundaries
    const rangeStart = adjustedStart > startOfYear ? adjustedStart : startOfYear;
    const rangeEnd = adjustedEnd < endOfYear ? adjustedEnd : endOfYear;

    // Return only the first and last date as ISO strings
    return [rangeStart, rangeEnd];
};

export const getPreviousYearRangeDates = (minDate?: Date, maxDate?: Date): [Date, Date] => {
    // If minDate and maxDate are provided, use them; otherwise, use defaults
    const today = maxDate || new Date();
    const startOfPreviousYear = minDate || new Date(today.getFullYear() - 1, 0, 1); // January 1st of the previous year
    const endOfPreviousYear = new Date(today.getFullYear() - 1, 11, 31); // December 31st of the previous year

    // If minDate and maxDate are provided, ensure the boundaries align with the previous year
    const rangeStart =
        startOfPreviousYear > new Date(today.getFullYear() - 1, 0, 1)
            ? startOfPreviousYear
            : new Date(today.getFullYear() - 1, 0, 1);
    const rangeEnd = maxDate && maxDate < endOfPreviousYear ? maxDate : endOfPreviousYear;

    // Return the range as ISO strings
    return [rangeStart, rangeEnd];
};

export const formatToDateString = (date: Date, time: string): string => {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year} ${time}`;
};

export function formatDateRange(
    dateFrom: Date | null,
    dateTo: Date | null
): { dateFrom: string | null; dateTo: string | null } {
    const updatedDateFrom = dateFrom ? formatToDateString(dateFrom, '00:00:00') : null;
    const updatedDateTo = dateTo ? formatToDateString(dateTo, '23:59:59') : null;

    return {
        dateFrom: updatedDateFrom,
        dateTo: updatedDateTo
    };
}

export const isDayToday = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() && date.getMonth() === today.getMonth();
};
