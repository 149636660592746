import { useState } from 'react';

interface IProps<T, R> {
    method: (props: T) => Promise<R>;
    onSuccess?: (data: R) => void;
    onError?: (error: any) => void;
    onFinish?: () => void;
}

export const useApiMutation = <T, R>({ method, onError, onSuccess, onFinish }: IProps<T, R>) => {
    const [response, setResponse] = useState<R | undefined>(undefined);
    const [error, setError] = useState<string>();
    const [isLoading, setLoading] = useState(false);

    const handleRequest = async (requestData: T) => {
        setLoading(true);
        setError(undefined);
        return await method(requestData)
            .then((result) => {
                setResponse(result);
                if (onSuccess) onSuccess(result);
                return result;
            })
            .catch((error) => {
                console.log({ error });
                setError(error?.errorCode || '');
                if (onError) onError(error);
            })
            .finally(() => {
                setLoading(false);
                if (onFinish) onFinish();
            });
    };

    return {
        data: response,
        error,
        isLoading,
        handleRequest
    };
};
