import React, { FC, SVGAttributes } from 'react';

const Search: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.7209 17.6603C18.428 17.3674 17.9531 17.3674 17.6602 17.6603C17.3673 17.9532 17.3673 18.428 17.6602 18.7209L18.7209 17.6603ZM21.4697 22.5304C21.7626 22.8233 22.2374 22.8233 22.5303 22.5304C22.8232 22.2375 22.8232 21.7626 22.5303 21.4697L21.4697 22.5304ZM17.6602 18.7209L21.4697 22.5304L22.5303 21.4697L18.7209 17.6603L17.6602 18.7209ZM10.5714 18.3929C6.25178 18.3929 2.75 14.8911 2.75 10.5714H1.25C1.25 15.7195 5.42335 19.8929 10.5714 19.8929V18.3929ZM18.3929 10.5714C18.3929 14.8911 14.8911 18.3929 10.5714 18.3929V19.8929C15.7195 19.8929 19.8929 15.7195 19.8929 10.5714H18.3929ZM10.5714 2.75C14.8911 2.75 18.3929 6.25178 18.3929 10.5714H19.8929C19.8929 5.42335 15.7195 1.25 10.5714 1.25V2.75ZM10.5714 1.25C5.42335 1.25 1.25 5.42335 1.25 10.5714H2.75C2.75 6.25178 6.25178 2.75 10.5714 2.75V1.25Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Search;
