import React, { FC } from 'react';
import { Icon } from '@components/icons';
import styles from './styles.module.scss';

interface IProps {
    label: string;
    onClose?: () => void;
}

const Chips: FC<IProps> = ({ label, onClose }) => {
    return (
        <div className={styles.wrapper}>
            <p>{label}</p>
            <button onClick={onClose}>
                <Icon name="filed_close" width={18} height={18} />
            </button>
        </div>
    );
};

export default Chips;
