import React, { FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import {
    capitalizeFirstLetter,
    hideCardNumber,
    prettifyCardStatus,
    prettifyCardType
} from '@utils/index';
import { Icon } from '@components/icons';
import Status from '@components/common/status';
import xepptCard from '@svg/xeppt_card.svg';
import { ECardStatus, TCardLite } from '@xeppt/xeppt-sdk/types/card';

interface IProps {
    card: TCardLite;
    onClick?: () => void;
}

const CardRow: FC<IProps> = ({ card, onClick }) => {
    return (
        <button
            className={styles.wrapper}
            onClick={() => {
                if (onClick) onClick();
            }}>
            <div className={styles.content}>
                <div
                    className={styles.card_image}
                    style={{ backgroundImage: `url(${xepptCard})` }}
                />
                <div className={styles.text_wrapper}>
                    <Typography variant="body1">
                        <>
                            <span>{capitalizeFirstLetter(prettifyCardType(card?.type))}</span>{' '}
                            {hideCardNumber(card.cardNumber, 'full', 'dots')}
                        </>
                    </Typography>
                    {card.status === ECardStatus.CUSTOMER_REQUEST && (
                        <span>{capitalizeFirstLetter(prettifyCardStatus(card.status))}</span>
                    )}
                </div>
            </div>
            {card.status === ECardStatus.CUSTOMER_REQUEST ? (
                <Status variant="warning">
                    {capitalizeFirstLetter(prettifyCardStatus(card.status))}
                </Status>
            ) : (
                <Icon name="nav_right" />
            )}
        </button>
    );
};

export default CardRow;
