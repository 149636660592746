import React, { FC, SVGAttributes } from 'react';

const Question: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.08203 9.52312C9.08198 9.06014 9.19211 8.60381 9.4033 8.19181C9.61449 7.77981 9.92068 7.42398 10.2966 7.15373C10.6725 6.88347 11.1073 6.70654 11.5651 6.63755C12.0229 6.56857 12.4906 6.60951 12.9294 6.757C13.3683 6.90449 13.7657 7.15429 14.0889 7.48577C14.4122 7.81724 14.6519 8.22086 14.7882 8.66329C14.9246 9.10572 14.9538 9.57425 14.8733 10.0302C14.7928 10.4861 14.6049 10.9163 14.3253 11.2853C14.2165 11.4059 14.101 11.5203 13.9793 11.628C13.8799 11.7123 13.8312 11.7469 13.7339 11.8128L12.6755 12.5426C12.4139 12.7226 12.2 12.9637 12.0523 13.2449C11.9046 13.5261 11.8276 13.839 11.828 14.1566"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8155 18.2056C12.4125 18.2056 12.8965 17.7216 12.8965 17.1245C12.8965 16.5275 12.4125 16.0435 11.8155 16.0435C11.2184 16.0435 10.7344 16.5275 10.7344 17.1245C10.7344 17.7216 11.2184 18.2056 11.8155 18.2056Z"
                fill="currentColor"
            />
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Question;
