import React, { useState } from 'react';
import Typography from '@components/common/typography';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import styles from './styles.module.scss';
import sidebarIllustration from '@svg/illustrations/install_extension_sidebar.svg';
import arrowIllustration from '@svg/illustrations/install_arrow.svg';
import arrowMobileIllustration from '@svg/illustrations/install_mobile_arrow.svg';
import rocketIllustration from '@svg/illustrations/install_rocket.svg';
import slide1 from '@img/install_1.png';
import slide2 from '@img/install_2.png';
import slide3 from '@img/install_3.png';
import slide4 from '@img/install_4.png';
import slide1Mobile from '@img/install_1_mobile.png';
import slide2Mobile from '@img/install_2_mobile.png';
import slide3Mobile from '@img/install_3_mobile.png';
import slide4Mobile from '@img/install_4_mobile.png';
import { slideAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import icon1 from '@svg/install_1.svg';
import icon2 from '@svg/install_2.svg';
import icon3 from '@svg/install_3.svg';
import icon4 from '@svg/install_4.svg';
import useResponsive from '@hooks/helpers/useResponsive';

const InstallExtensionLayout = () => {
    const navigate = useNavigate();
    const [slideCount, setSlideCount] = useState(0);
    const { isMobile, isDesktop } = useResponsive();
    const [isRender, setIsRender] = useState(true);

    const handleChangeSlide = (slide: number) => {
        setSlideCount(slide);
        setIsRender(false);
        setTimeout(() => {
            setIsRender(true);
        }, 0);
    };

    const slides = [
        {
            image: slide1,
            imageMob: slide1Mobile,
            icon: icon1,
            title: '<span>Track</span> orders, cashback amounts and statuses directly from your browser',
            description:
                "With easy access to your transaction history, you can effortlessly monitor how much you've earned from each purchase without needing to log into separate accounts or apps. This convenience ensures you never miss out on cashback opportunities and always stay updated on the progress of your rewards",
            button: 'Further',
            action: () => handleChangeSlide(1)
        },
        {
            image: slide2,
            imageMob: slide2Mobile,
            icon: icon2,
            title: '<span>Redeem</span> your earned cashback effortlessly',
            description:
                'Once you’ve earned cashback, redeeming it is a breeze with XEPPT browser extension. Simply transfer your accumulated rewards directly to your personal XEPPT wallet in just a few clicks. Plus, with secure transactions and no hidden fees, you can enjoy your rewards without any hassle',
            button: 'Further',
            action: () => handleChangeSlide(2)
        },
        {
            image: slide3,
            imageMob: slide3Mobile,
            icon: icon3,
            title: '<span>Stay on top</span> of your cashback with our dashboard',
            description:
                'Stay on top of your savings with a simple dashboard that shows available cashback, pending cashback, and total withdrawn amounts at a glance. Track your earnings in real-time and never miss out on a reward!',
            button: 'Further',
            action: () => handleChangeSlide(3)
        },
        {
            image: slide4,
            imageMob: slide4Mobile,
            icon: icon4,
            title: '<span>Pin XEPPT</span> extension to your browser toolbar',
            description:
                'Keep it visible so you never miss an opportunity to earn, track, or withdraw your cashback, all while browsing seamlessly! Say goodbye to complicated processes and enjoy a hassle-free way to earn and manage your cashback while shopping online',
            button: 'Go to Cashback page',
            action: () => navigate(routes.cashback_history)
        }
    ];

    const renderActiveSlide = (slide: {
        image: string;
        imageMob: string;
        title: string | TrustedHTML;
        description: string;
        button: string;
        icon: string;
        action: () => void;
    }) => {
        return (
            <>
                <motion.div {...slideAnimation} className={styles.image_wrapper}>
                    <img src={isDesktop ? slide.image : slide.imageMob} alt="" />
                    <div
                        className={styles.dots_wrapper}
                        style={{ marginBottom: slideCount === 0 ? -10 : 30 }}>
                        {Array(slides.length)
                            .fill('')
                            .map((_, i) => (
                                <div
                                    key={i}
                                    onClick={() => handleChangeSlide(i)}
                                    className={`${styles.dot} ${i === slideCount ? styles.isActive : ''}`}
                                />
                            ))}
                    </div>
                </motion.div>
                <motion.div {...slideAnimation} className={styles.slide}>
                    <div className={styles.icon}>
                        <img src={slide.icon} alt="" />
                    </div>
                    <div className={styles.content}>
                        <Typography
                            variant="h4"
                            className={styles.title}
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                        />
                        <p className={styles.description}>{slide.description}</p>
                        <button className={styles.gold_button} onClick={slide.action}>
                            {slide.button}
                        </button>
                    </div>
                </motion.div>
            </>
        );
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.header_wrapper}>
                    <Typography className={styles.title} variant="h3">
                        Cashback made easy
                    </Typography>
                    <Typography className={styles.description} variant="body1">
                        Monitor your orders and rewards in real-time and redeem your cashback
                        seamlessly
                    </Typography>
                </div>
                <div className={styles.content_wrapper}>
                    {isRender ? renderActiveSlide(slides[slideCount]) : ''}
                </div>
            </div>
            <div className={styles.right}>
                <img
                    src={isMobile ? arrowMobileIllustration : arrowIllustration}
                    className={styles.arrow}
                    alt=""
                />
                {isMobile && (
                    <img
                        src={rocketIllustration}
                        className={`${styles.arrow} ${styles.rocket}`}
                        alt=""
                    />
                )}
                <div className={styles.content}>
                    <div className={styles.sign_wrapper}>
                        <p>Already have an account?</p>
                        <Link className={styles.link} to={routes.signin}>
                            Sign in
                        </Link>
                    </div>
                    <div className={styles.text_wrapper}>
                        <Typography variant="h3" className={styles.title}>
                            You’re all set!
                        </Typography>
                        <p className={styles.description}>
                            XEPPT is successfully installed. Sign up to unlock all XEPPT features,
                            receive cashback, and withdraw it directly to your wallet!
                        </p>
                    </div>
                    <div className={styles.actions}>
                        <button className={styles.dark_button} onClick={() => navigate(routes.signup)}>Sign up</button>
                        <div className={styles.links}>
                            By continuing, you agree with our
                            <Link className={styles.link} to={routes.terms_of_service}>
                                Terms of use
                            </Link>
                            and
                            <Link className={styles.link} to={routes.terms_of_service}>
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                </div>
                <img className={styles.illustration} src={sidebarIllustration} alt="" />
            </div>
        </div>
    );
};

export default InstallExtensionLayout;
