import React, { FC, useMemo } from 'react';
import { Icon } from '@components/icons';

import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Typography from '@components/common/typography';

export type TProgressItem = {
    id: string;
    title: string;
};

interface IProps {
    steps: (TProgressItem | undefined)[];
    availableStep: TProgressItem | undefined;
    currentStep: TProgressItem | undefined;
    className?: string;
    isMini?: boolean;
    disabled?: boolean;
    changeStep?: (id: string) => void;
}

const cx = classNames.bind(styles);

const ProgressBar: FC<IProps> = ({
    isMini = false,
    className,
    disabled,
    steps: propsSteps,
    currentStep,
    changeStep
}) => {
    const steps = useMemo(() => {
        return propsSteps.filter((item) => !!item) as TProgressItem[];
    }, [propsSteps]);

    return (
        <>
            {!isMini ? (
                <div className={cx([styles.wrapper, className])}>
                    {steps.map((item, i) => {
                        const isCurrentStep =
                            i === steps.findIndex((item) => item.id === currentStep?.id);
                        const isDoneStep =
                            i < steps.findIndex((item) => item.id === currentStep?.id) ||
                            !currentStep;
                        return (
                            <div
                                key={item.id}
                                onClick={() => {
                                    if (!disabled && isDoneStep && changeStep) {
                                        changeStep(item.id);
                                    }
                                }}
                                className={cx([
                                    styles.step,
                                    {
                                        isCurrentStep,
                                        isDoneStep,
                                        isPointer: !!changeStep && isDoneStep
                                    }
                                ])}>
                                <div className={styles.point} />
                                <Typography variant="body3">{item.title}</Typography>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className={cx([styles.mini_progress, className])}>
                    {steps.map((item, i) => (
                        <span
                            style={{ width: `${100 / steps.length}%`, display: 'block' }}
                            className={cx([
                                {
                                    isDone:
                                        i <
                                            steps.findIndex(
                                                (item) => item.id === currentStep?.id
                                            ) || !currentStep
                                }
                            ])}
                            key={item.id}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default ProgressBar;
