import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import { enumTranslate } from '@locales/index';
import { Icon } from '@components/icons';
import Status from '@components/common/status';
import {
    calculateEndDate,
    calculateStartDate,
    copyStringToClipboard,
    getFirstLettersBySpace,
    getScheduleDescription,
    getScheduleStatusVariant
} from '@utils/index';
import { EScheduleStatus, ETransactionAction, ETransactionMethod } from '@xeppt/xeppt-sdk/types';
import { IModalComponentProps } from '@context/modal_context';
import { TSchedule } from '@xeppt/xeppt-sdk/types/schedule';
import { getBillingStatusList, getFrequencyList } from '@utils/billing';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import classNames from 'classnames/bind';
import Dropdown from '@components/common/dropdown';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiScheduleService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';

const cx = classNames.bind(styles);

const ViewScheduleModal: FC<
    IModalComponentProps<{
        schedule: TSchedule;
        refetchSchedules?: () => void;
        onEdit: (schedule: TSchedule) => void;
    }>
> = ({ onClose, modalData }) => {
    const { t: frequencyLocale, i18n } = useTranslation('sections', {
        keyPrefix: 'send_and_request.schedule_payments.payment_details'
    });
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    if (!modalData?.schedule) return null;

    const { schedule, refetchSchedules, onEdit } = modalData;

    const isRequest = useMemo(() => {
        return schedule.action === ETransactionAction.REQUEST;
    }, [schedule]);

    const getRecipientName = useMemo(() => {
        return schedule.info.split('(')?.[0] || '';
    }, [schedule]);

    const getEmail = useMemo(() => {
        return schedule.info.split('(')?.[1]?.replace(')', '') || '';
    }, [schedule]);

    const getAvatar = useMemo(() => {
        if (
            schedule.method === ETransactionMethod.WALLET ||
            schedule.method === ETransactionMethod.E_TRANSFER
        ) {
            return getFirstLettersBySpace(getRecipientName);
        } else if (ETransactionMethod.EFT === schedule.method) {
            return <Icon name="bank" width={24} height={24} />;
        } else if (ETransactionMethod.EPS === schedule.method) {
            return <Icon name="credit_card" width={24} height={24} />;
        } else if (ETransactionMethod.PAY_BILL === schedule.method) {
            return <Icon name="pay_bill" width={24} height={24} />;
        }
        return '';
    }, [schedule, getRecipientName]);

    const getNextPaymentText = useMemo(() => {
        if (schedule.status === EScheduleStatus.COMPLETE) {
            return 'Completed';
        } else if (schedule.status === EScheduleStatus.ACTIVE) {
            return 'Next payment';
        } else if (schedule.status === EScheduleStatus.INACTIVE) {
            return 'Paused';
        } else if (schedule.status === EScheduleStatus.DELETED) {
            return 'Deleted';
        }
    }, []);

    const { handleRequest: handleCancelPayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.deleteSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('cancel_schedule');
            refetchSchedules && refetchSchedules();
            onClose();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleDeactivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.deactivateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('pause_schedule');
            refetchSchedules && refetchSchedules();
            onClose();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleActivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.activateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('activate_schedule');
            refetchSchedules && refetchSchedules();
            onClose();
        },
        onError: requestErrorLocale
    });

    const handlePauseResumePayment = (id: string, status: EScheduleStatus) => {
        if (status === EScheduleStatus.ACTIVE) {
            handleDeactivatePayment(id);
        } else {
            handleActivatePayment(id);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <p className={styles.title}>Scheduled Send money</p>
            </div>
            <div className={styles.content}>
                <div className={styles.user}>
                    <Dropdown
                        className={`${styles.actions} modal_dropdown_index`}
                        items={[
                            (schedule.status === EScheduleStatus.ACTIVE ||
                                schedule.status === EScheduleStatus.INACTIVE) && {
                                label:
                                    schedule.status === EScheduleStatus.ACTIVE
                                        ? 'Pause'
                                        : 'Continue',
                                icon: schedule.status === EScheduleStatus.ACTIVE ? 'pause' : 'play',
                                onClick: () =>
                                    handlePauseResumePayment(schedule.id, schedule.status)
                            },
                            schedule.status !== EScheduleStatus.COMPLETE && {
                                label: 'Edit',
                                icon: 'edit',
                                onClick: () => onEdit(schedule)
                            },
                            {
                                label:
                                    schedule.status === EScheduleStatus.DELETED
                                        ? 'Delete'
                                        : 'Cancel',
                                isRed: true,
                                icon:
                                    schedule.status === EScheduleStatus.DELETED
                                        ? 'trash'
                                        : 'close_outlined',
                                onClick: () => handleCancelPayment(schedule.id)
                            }
                        ]}
                    />
                    <div className={styles.user_avatar}>{getAvatar}</div>
                    <p className={styles.user_name}>{getRecipientName}</p>
                    <p className={styles.user_email}>{getEmail}</p>
                    <p className={styles.method}>
                        {schedule?.method &&
                            enumTranslate[schedule.method][i18n.language as 'en' | 'fr']}
                    </p>
                    <div className={styles.divider} />
                    <div className={styles.payment_id}>
                        <p>Schedule ID:</p>
                        <div className={styles.id}>
                            <p>{schedule.id}</p>
                            <button onClick={() => copyStringToClipboard(schedule.id)}>
                                <Icon name="copy" width={18} height={18} />
                            </button>
                        </div>
                    </div>
                    <Status variant={getScheduleStatusVariant(schedule?.status)}>
                        {schedule?.status &&
                            getBillingStatusList(frequencyLocale).find(
                                (item) => item.value === schedule?.status
                            )?.label}
                    </Status>
                    <div className={styles.details}>
                        <div className={styles.row}>
                            <div>
                                <b>${schedule.amount.toFixed(2)} </b> /{' '}
                                {getFrequencyList(frequencyLocale)
                                    .find((item) => item.value === schedule.frequency)
                                    ?.label?.toLowerCase() || ''}
                            </div>
                            <div>
                                Occurrences:{' '}
                                {schedule.remainingPayments > 0
                                    ? schedule.totalPayments + schedule.remainingPayments
                                    : 'Until cancelled'}
                            </div>
                        </div>
                        <div className={styles.progress}>
                            <div className={styles.progress_title}>
                                {schedule.remainingPayments > 0
                                    ? `Payments Remaining: ${schedule.remainingPayments}`
                                    : `${isRequest ? 'Requested' : 'Paid'}: $${(schedule.totalPayments * schedule.amount).toFixed(2)} (${schedule.totalPayments} payments)`}
                            </div>
                            {schedule.remainingPayments > 0 && (
                                <div className={styles.progress_bg}>
                                    <div
                                        style={{
                                            width: `${(100 / (schedule.totalPayments + schedule.remainingPayments)) * schedule.totalPayments}%`
                                        }}
                                    />
                                </div>
                            )}
                            {schedule.remainingPayments > 0 && (
                                <div className={styles.row}>
                                    <div>
                                        Paid: $
                                        {(schedule.totalPayments * schedule.amount).toFixed(2)}
                                    </div>
                                    <div>
                                        Remaining: $
                                        {(schedule.remainingPayments * schedule.amount).toFixed(2)}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.dates}>
                    <div className={styles.row}>
                        <div className={styles.date}>
                            <Icon name="calendar" />
                            <div className={styles.text_wrapper}>
                                <p>Start date</p>
                                <span>
                                    {moment(
                                        calculateStartDate(
                                            schedule.frequency,
                                            schedule?.nextPaymentAt,
                                            schedule?.lastPaymentAt,
                                            schedule?.totalPayments
                                        )
                                    ).format('MM/DD/YYYY')}
                                </span>
                            </div>
                        </div>
                        <div className={styles.dashed_divider} />
                        <div className={styles.date}>
                            <Icon name="calendar_check" />
                            <div className={styles.text_wrapper}>
                                <p>Due date</p>
                                <span>
                                    {moment(
                                        calculateEndDate(
                                            schedule.frequency,
                                            schedule?.nextPaymentAt,
                                            schedule?.lastPaymentAt,
                                            schedule?.remainingPayments
                                        )
                                    ).format('MM/DD/YYYY')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.row}>
                        <div className={styles.date}>
                            <Icon name="reload" />
                            <div className={styles.text_wrapper}>
                                <p>Payment frequency </p>
                                <span>
                                    {getScheduleDescription(
                                        schedule?.lastPaymentAt ||
                                            schedule?.nextPaymentAt ||
                                            new Date(),
                                        schedule.frequency
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className={styles.date}>
                            <div
                                className={cx([
                                    styles.dot,
                                    getScheduleStatusVariant(schedule?.status)
                                ])}
                            />
                            <div className={styles.text_wrapper}>
                                <p>{getNextPaymentText}</p>
                                <span>
                                    {moment(
                                        schedule.status === EScheduleStatus.ACTIVE
                                            ? schedule.nextPaymentAt
                                            : schedule.lastPaymentAt
                                    ).format('MM/DD/YYYY')}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewScheduleModal;
