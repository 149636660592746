import React, { FC, SVGAttributes } from 'react';

const FilledCard: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 7.55039C22 8.21039 21.46 8.75039 20.8 8.75039H3.2C2.54 8.75039 2 8.21039 2 7.55039V7.54039C2 5.25039 3.85 3.40039 6.14 3.40039H17.85C20.14 3.40039 22 5.26039 22 7.55039Z"
                fill="currentColor"
            />
            <path
                d="M2 11.45V16.46C2 18.75 3.85 20.6 6.14 20.6H17.85C20.14 20.6 22 18.74 22 16.45V11.45C22 10.79 21.46 10.25 20.8 10.25H3.2C2.54 10.25 2 10.79 2 11.45ZM8 17.25H6C5.59 17.25 5.25 16.91 5.25 16.5C5.25 16.09 5.59 15.75 6 15.75H8C8.41 15.75 8.75 16.09 8.75 16.5C8.75 16.91 8.41 17.25 8 17.25ZM14.5 17.25H10.5C10.09 17.25 9.75 16.91 9.75 16.5C9.75 16.09 10.09 15.75 10.5 15.75H14.5C14.91 15.75 15.25 16.09 15.25 16.5C15.25 16.91 14.91 17.25 14.5 17.25Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default FilledCard;
