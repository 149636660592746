import React, { FC, SVGAttributes } from 'react';

const Money: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12.5" r="10" stroke="currentColor" strokeWidth="1.5" />
            <path
                d="M12 17.5V18V18.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path d="M12 6.5V7V7.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path
                d="M15 10C15 8.61929 13.6569 7.5 12 7.5C10.3431 7.5 9 8.61929 9 10C9 11.3807 10.3431 12.5 12 12.5C13.6569 12.5 15 13.6193 15 15C15 16.3807 13.6569 17.5 12 17.5C10.3431 17.5 9 16.3807 9 15"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Money;
