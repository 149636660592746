import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import Input from '@components/common/input';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import useClickOutside from '@hooks/helpers/useClickOutside';
import { TIconType } from '@components/icons';

interface IProps {
    value?: string | number;
    onChange: (value: string | number) => void;
    label?: string;
    placeholder?: string;
    helperText?: string;
    className?: string;
    name?: string;
    icon?: TIconType;
    error?: boolean;
    items: {
        value: string | number;
        label: string;
    }[];
    full?: boolean;
    disabled?: boolean;
    isAllowSearch?: boolean;
    readOnly?: boolean;
}

const cx = classNames.bind(styles);

const Select: FC<IProps> = ({
    value = '',
    placeholder,
    disabled,
    className,
    full,
    label,
    items,
    error,
    onChange,
    name,
    helperText,
    icon,
    readOnly,
    isAllowSearch = false
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const ref = useRef(null);

    const handleChange = (value: string | number) => {
        onChange(value);
        setSearch('');
        setIsOpen(false);
    };

    useClickOutside({
        ref,
        callback: () => setIsOpen(false),
        event: 'mousedown'
    });

    useEffect(() => {
        if (search.length > 0) {
            setIsOpen(true);
        }
    }, [search]);

    const memoItems = useMemo(
        () =>
            items.filter((item) =>
                isAllowSearch ? item.label.toLowerCase().includes(search.toLowerCase()) : true
            ),
        [items, isAllowSearch, search]
    );

    const foundValue = useMemo(() => {
        return items?.find((item) => item.value === value)?.label;
    }, [value, items]);

    const handleChangeSearch = (value: string) => {
        isAllowSearch && setSearch(value);
        if (value.length <= 1) {
            onChange('');
        }
        setIsOpen(false);
    };

    return (
        <div className={cx([styles.wrapper, { isFull: full }, className])} ref={ref}>
            <Input
                rightIcon={!readOnly ? icon || 'nav_down' : undefined}
                value={search || foundValue}
                placeholder={placeholder}
                label={label}
                full={full}
                name={name}
                readOnly={readOnly}
                className={cx([{ isOpen }])}
                onFocus={() => setIsOpen(true)}
                onClickIcon={() => setIsOpen((state) => !state)}
                disabled={disabled}
                error={error}
                onChange={handleChangeSearch}
                helperText={helperText}
            />
            <div className={cx([styles.dropdown, { isOpen }])}>
                {memoItems.length > 0 ? (
                    memoItems.map((item, i) => (
                        <button
                            key={i}
                            onClick={() => {
                                if (!disabled) handleChange(item.value);
                            }}>
                            {item.label}
                        </button>
                    ))
                ) : (
                    <button onClick={() => setIsOpen(false)}>Empty</button>
                )}
            </div>
        </div>
    );
};

export default Select;
