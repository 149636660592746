import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Radio from '@components/common/radio';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';

interface IProps {
    handleChoseOtherType: () => void;
    type: 'manual' | 'instant';
    onChange: (value: 'manual' | 'instant') => void;
}

const cx = classNames.bind(styles);

const FirstStepLinkBank: FC<IProps> = ({ type, onChange }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank.step_1'
    });
    const instantStyles = useMemo(
        () => cx([styles.card, { isActive: type === 'instant' }]),
        [type]
    );
    const manualStyles = useMemo(() => cx([styles.card, { isActive: type === 'manual' }]), [type]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.description} variant="h4">
                Please choose a verification method to verify your bank details and link a bank
                account{' '}
            </Typography>
            <button className={instantStyles} onClick={() => onChange('instant')}>
                <div className={styles.left}>
                    <Typography className={styles.title} variant="body1" weight="semibold">
                        {t('instant')}
                    </Typography>
                    <p className={styles.description}>{t('instant_description')}</p>
                </div>
                <Radio checked={type === 'instant'} onClick={() => onChange('instant')} />
            </button>
            <button className={manualStyles} onClick={() => onChange('manual')}>
                <div className={styles.left}>
                    <Typography className={styles.title} variant="body1" weight="semibold">
                        {t('manual')}
                    </Typography>
                    <p className={styles.description}>{t('manual_description')}</p>
                </div>
                <Radio checked={type === 'manual'} />
            </button>
        </motion.div>
    );
};

export default FirstStepLinkBank;
