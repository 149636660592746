import styles from './styles.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import Payee from '@sections/pay_bills/manage_payees/payee';
import PayeesTable from '@sections/pay_bills/manage_payees/payees_table';
import { useTranslation } from 'react-i18next';
import { apiPayBillService } from '@api';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiQuery } from '@hooks/api/useApiQuery';
import Button from '@components/common/button';
import { routes } from '@const/routes';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { useQueryParams } from '@hooks/helpers/useQueryParams';

const ManagePayeesSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.manage_payees'
    });
    const [chosenPayee] = useQueryParams(['payee']);
    const [isEdit, setIsEdit] = useState(false);
    const { handleBack } = useNotFound();

    const { data: payeesList, handleRequest: refetchPayees } = useApiQuery({
        method: () => apiPayBillService.listPayees(1000, 1)
    });

    useEffect(() => {
        if (chosenPayee) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }
    }, [chosenPayee]);

    const renderTitle = useMemo(() => {
        if (!isEdit) {
            return t('title');
        }
        if (isEdit && !chosenPayee) {
            return 'Add new payee';
        }
        if (isEdit && chosenPayee) {
            return 'Edit payee';
        }
    }, [isEdit, chosenPayee]);

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Button
                            className={styles.back}
                            leftIcon="arrow_left"
                            onClick={() => {
                                if (isEdit) {
                                    handleBack(routes.pay_bills);
                                } else {
                                    if (chosenPayee) {
                                        handleBack(routes.pay_bills_payees);
                                    } else {
                                        handleBack(routes.pay_bills);
                                    }
                                }
                            }}>
                            Back
                        </Button>
                        <Typography variant="h4">{renderTitle}</Typography>
                    </div>
                </div>
                {isEdit ? (
                    <Payee
                        payeesList={payeesList?.data || []}
                        chosenPayee={chosenPayee}
                        handleBack={() => {
                            setIsEdit(false);
                        }}
                    />
                ) : (
                    <PayeesTable
                        refetchPayees={refetchPayees}
                        payeesList={payeesList?.data || []}
                        onAdd={() => setIsEdit(true)}
                    />
                )}
            </div>
        </motion.div>
    );
};

export default ManagePayeesSection;
