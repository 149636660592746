import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { Icon } from '@components/icons';
import Ripple from '@components/common/ripple';

interface IProps {
    checked?: boolean;
    isDelete?: boolean;
    onClick?: () => void;
    variant?: 'red' | 'dark' | 'gold';
    className?: string;
    disabled?: boolean;
    size?: 'default' | 'small';
}

const cx = classNames.bind(styles);
const Checkbox: FC<IProps> = ({
    disabled,
    checked,
    variant = 'dark',
    onClick,
    isDelete,
    size,
    className
}) => {
    const rootStyles = useMemo(
        () =>
            cx([
                styles.root,
                variant,
                size,
                { isChecked: checked, isDisabled: disabled },
                className
            ]),
        [checked, variant, disabled, className, size]
    );

    return (
        <label className={rootStyles} onChange={onClick}>
            <span>{checked && <Icon name={isDelete ? 'minus' : 'check_checkbox'} />}</span>
            <input type="checkbox" disabled={disabled} onChange={() => {}} />
            <div className={styles.hover}></div>
        </label>
    );
};

export default Checkbox;
