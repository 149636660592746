import React, { FC, SVGAttributes } from 'react';

const Schedule: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.47 11.4197L2.73 9.67969L1 11.4197"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.53 12.5801L21.27 14.3201L23.01 12.5801"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.26 14.3203V12.0002C21.26 6.88024 17.11 2.74023 12 2.74023C9.08002 2.74023 6.47002 4.10027 4.77002 6.21027"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.73999 9.67969V11.9997C2.73999 17.1197 6.88999 21.2597 12 21.2597C14.92 21.2597 17.53 19.8997 19.23 17.7897"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 16.167V16.5837V17.0003"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M12 7V7.41667V7.83333"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M14.5 9.91634C14.5 8.76575 13.3807 7.83301 12 7.83301C10.6193 7.83301 9.5 8.76575 9.5 9.91634C9.5 11.0669 10.6193 11.9997 12 11.9997C13.3807 11.9997 14.5 12.9324 14.5 14.083C14.5 15.2336 13.3807 16.1663 12 16.1663C10.6193 16.1663 9.5 15.2336 9.5 14.083"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Schedule;
