import React, { useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import Input from '@components/common/input';
import RangeDatePicker from '@components/range_date_picker';
import Table from '@components/table';
import styles from './styles.module.scss';
import moment from 'moment-timezone';
import illustration from '@svg/illustrations/no_requests.svg';
import { useHelpCenterModals } from '@hooks/modals/useHelpCenterModals';
import ChatDrawer from '@sections/help_center/chat_drawer';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiSupportService } from '@api';
import Skeleton from '@components/common/skeleton';
import { ETicketStatus } from '@xeppt/xeppt-sdk/types';
import Status from '@components/common/status';
import { capitalizeFirstLetter, getTicketStatus, getTicketStatusVariant } from '@utils/index';
import Dropdown from '@components/common/dropdown';
import { Icon } from '@components/icons';

const HelpCenterLayout = () => {
    const [chosenTicket, setChosenTicket] = useState<string>();
    const columns = [
        {
            label: 'Subject',
            key: 'subject'
        },
        {
            label: 'ID',
            key: 'id'
        },
        {
            label: 'Created',
            key: 'createdAt',
            render: (value: Date) => value && moment(value).format('MM.DD.YYYY, HH:mm')
        },
        {
            label: 'Updated',
            key: 'updatedAt',
            render: (value: Date) => value && moment(value).format('MM.DD.YYYY, HH:mm')
        },
        {
            label: 'Status',
            key: 'status',
            render: (val: ETicketStatus) => (
                <Status variant={getTicketStatusVariant(getTicketStatus(val))}>
                    {capitalizeFirstLetter(getTicketStatus(val))}
                </Status>
            )
        },
        {
            label: 'Actions',
            key: 'id',
            isRightAlignment: true,
            render: (val: string) => {
                const options = [
                    {
                        label: (
                            <div className={styles.action}>
                                <Icon name="open_eye" /> View Details
                            </div>
                        ),
                        onClick: () => {
                            setIsOpen(true);
                            setChosenTicket(val);
                        }
                    }
                ];
                return <Dropdown items={options} />;
            }
        }
    ];
    const [isOpen, setIsOpen] = useState(false);

    const {
        data: ticketsData,
        isLoading,
        handleRequest: refetchTickets
    } = useApiQuery({
        method: () => apiSupportService.getTicketsList(),
        isInitialRequest: false
    });

    const {
        data: ticketData,
        handleRequest: refetchTicket,
        removeResponse: removeTicketResponse
    } = useApiQuery({
        method: () => apiSupportService.getTicketById(chosenTicket || ''),
        isInitialRequest: false,
        condition: !!chosenTicket,
        deps: [chosenTicket]
    });

    const { handleOpenSubmitRequestModal } = useHelpCenterModals(refetchTickets);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <Typography variant="h4">Help Center</Typography>
                <Button
                    variant="primary"
                    size="normal"
                    onClick={() => handleOpenSubmitRequestModal(undefined)}>
                    Submit a request
                </Button>
            </div>
            <Typography variant="h5" className={styles.table_header}>
                My requests
            </Typography>
            {isLoading ? (
                <>
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                </>
            ) : (
                <>
                    {(ticketsData?.count || 0) !== 0 ? (
                        <>
                            <div className={styles.filters}>
                                <Input
                                    className={styles.search}
                                    full
                                    leftIcon="search"
                                    placeholder="Search by request ID or subject..."
                                />
                                <RangeDatePicker
                                    rootClassName={styles.range}
                                    full
                                    placeholder="Select a date range created"
                                />
                            </div>
                            <Table
                                columns={columns}
                                rows={ticketsData?.requests || []}
                                isActions
                                emptyDescription="No requests yet"
                            />
                        </>
                    ) : (
                        <div className={styles.empty_wrapper}>
                            <img src={illustration} alt="No requests yet" />
                            <Typography variant="body3">
                                It seems like everything is fine so far... Requests you submit will
                                appear right here
                            </Typography>
                            <Button
                                variant="primary"
                                size="normal"
                                onClick={() => handleOpenSubmitRequestModal(undefined)}>
                                Submit a request
                            </Button>
                        </div>
                    )}
                </>
            )}
            <ChatDrawer
                isOpen={isOpen && !!ticketData}
                ticketData={ticketData}
                refetchTicket={refetchTicket}
                onClose={() => {
                    setIsOpen(false);
                    removeTicketResponse();
                    setChosenTicket(undefined);
                }}
            />
        </motion.div>
    );
};

export default HelpCenterLayout;
