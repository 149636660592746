import React, { useEffect } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext, useWatch } from 'react-hook-form';
import FormField from '@components/form_field';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import businessIllustration from '@svg/illustrations/auth/business_info.svg';
import { EUserType } from '@xeppt/xeppt-sdk/types';

const BusinessDetailsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.business_details'
    });
    const { labelLocale, submitLocale, placeholderLocale } = useLocales();
    const { nextStep, prevStep, handleChangeIllustration } = useSignupContext();
    const { trigger, control } = useFormContext();
    const type = useWatch({ name: 'type', control });

    useEffect(() => {
        handleChangeIllustration(businessIllustration);
    }, []);

    const onSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                nextStep();
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.steps}>STEP 7/{type === EUserType.BUSINESS ? '11' : '7'}</div>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <FormField
                    name="business.companyName"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            full
                            placeholder={placeholderLocale('enter_company_name')}
                            required
                            label={labelLocale('company_name')}
                        />
                    )}
                />
                <FormField
                    name="business.tradingName"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            full
                            placeholder={placeholderLocale('enter_trading_name')}
                            required
                            label={labelLocale('trading_name')}
                        />
                    )}
                />
                <div className={styles.row}>
                    <FormField
                        name="business.registrationNumber"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                required
                                placeholder={placeholderLocale('enter_registration_number')}
                                label={labelLocale('registration_number')}
                            />
                        )}
                    />
                    <FormField
                        name="business.category"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                placeholder={placeholderLocale('enter_category')}
                                required
                                label={labelLocale('category')}
                            />
                        )}
                    />
                </div>
                <div className={styles.row}>
                    <FormField
                        name="business.size"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                full
                                placeholder={placeholderLocale('enter_company_size')}
                                required
                                label={labelLocale('size')}
                            />
                        )}
                    />
                    <FormField
                        name="business.website"
                        renderComponent={(props) => (
                            <Input
                                {...props}
                                placeholder={placeholderLocale('enter_company_website')}
                                full
                                required
                                label={labelLocale('website')}
                            />
                        )}
                    />
                </div>
                <div className={styles.actions}>
                    <Button
                        className={styles.next}
                        onClick={onSubmit}
                        size="normal"
                        rightIcon="arrow_right"
                        variant="primary">
                        {submitLocale('next')}
                    </Button>
                    <Button className={styles.back} leftIcon="arrow_left" onClick={prevStep}>
                        {submitLocale('back')}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default BusinessDetailsSection;
