import { modalIds } from '@const/modals';
import { apiAccountService } from '@api';
import useModalContext from '@hooks/context/useModalContext';
import { TBankAccount, TEPSCard } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import { useUserContext } from '@hooks/context/useUserContext';

interface IProps {
    id: string | null;
    chosenAccount?: TBankAccount;
    chosenCard?: TEPSCard;
}

export const useManageAccountModals = ({ id, chosenAccount, chosenCard }: IProps) => {
    const { onClose, onOpen, changeModalData, handleChangeLoading } = useModalContext();
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const { refetchAccount } = useUserContext();

    const removeBankAccount = () => {
        if (id) {
            changeModalData(chosenAccount);
            onOpen({
                modalId: modalIds.DELETE_BANK_ACCOUNT,
                onSubmit: () => {
                    handleChangeLoading(true);
                    apiAccountService
                        .unlinkBankAccount(id)
                        .then(() => {
                            requestSuccessLocale('delete_bank');
                            refetchAccount();
                            onClose();
                        })
                        .catch(requestErrorLocale)
                        .finally(() => handleChangeLoading(false));
                }
            });
        }
    };

    const removeEPSCard = () => {
        if (id) {
            changeModalData(chosenCard);
            onOpen({
                modalId: modalIds.DELETE_EPS_CARD,
                onSubmit: () => {
                    handleChangeLoading(true);
                    apiAccountService
                        .unlinkEPSCard(id)
                        .then(() => {
                            requestSuccessLocale('delete_card');
                            refetchAccount();
                            onClose();
                        })
                        .catch(requestErrorLocale)
                        .finally(() => handleChangeLoading(false));
                }
            });
        }
    };

    const setPreferredAccount = () => {
        if (id) {
            changeModalData(chosenAccount);
            onOpen({
                modalId: modalIds.PREFERRED_ACCOUNT,
                onSubmit: () => {
                    handleChangeLoading(true);
                    apiAccountService
                        .setPrimaryBankAccount(id)
                        .then(() => {
                            requestSuccessLocale('preferred_account');
                            refetchAccount();
                            onClose();
                        })
                        .catch(requestErrorLocale)
                        .finally(() => handleChangeLoading(false));
                }
            });
        }
    };

    const setPreferredCard = () => {
        if (id) {
            changeModalData(chosenCard);
            onOpen({
                modalId: modalIds.PREFERRED_CARD,
                onSubmit: () => {
                    handleChangeLoading(true);
                    apiAccountService
                        .setPrimaryEPSCard(id)
                        .then(() => {
                            requestSuccessLocale('preferred_card');
                            refetchAccount();
                            onClose();
                        })
                        .catch(requestErrorLocale)
                        .finally(() => handleChangeLoading(false));
                }
            });
        }
    };

    return {
        removeBankAccount,
        setPreferredAccount,
        removeEPSCard,
        setPreferredCard
    };
};
