import React, { FC, SVGAttributes } from 'react';

const Power: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.864 5.86374C20.1226 7.14472 20.9798 8.77679 21.3271 10.5536C21.6743 12.3303 21.4961 14.172 20.8149 15.8457C20.1337 17.5194 18.9802 18.9499 17.5001 19.9563C16.0201 20.9628 14.28 21.5 12.5 21.5C10.72 21.5 8.97992 20.9628 7.49988 19.9563C6.01984 18.9499 4.86628 17.5194 4.18509 15.8457C3.5039 14.172 3.32567 12.3303 3.67293 10.5536C4.0202 8.7768 4.87736 7.14474 6.13603 5.86375M12.5 3.5V8.22749"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Power;
