import React, { FC, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { useForm } from '@hooks/helpers/useForm';
import { FormProvider } from 'react-hook-form';
import FormField from '@components/form_field';
import { apiContactService } from '@api';
import { EContactType, TContact, TCreateContact } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import Radio from '@components/common/radio';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { useDebounceValue } from 'usehooks-ts';
import { getFirstLetter, highlightSearchText } from '@utils/index';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import emptySearchIllustration from '@svg/illustrations/empty_serch.svg';
import Skeleton from '@components/common/skeleton';
import InfoBlock from '@components/common/info_block';
import useResponsive from '@hooks/helpers/useResponsive';

interface IProps {
    handleBack: () => void;
    isEdit: boolean;
    editableContact?: TContact;
    contactList: TContact[];
}

const Contact: FC<IProps> = ({ handleBack, editableContact, contactList }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.manage_contacts'
    });
    const [tabType] = useQueryParams(['type']);
    const form = useForm({});
    const { requestSuccessLocale, requestErrorLocale, labelLocale, validationLocale } =
        useLocales();
    const [isEmail, setIsEmail] = useState(true);
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);
    const [search, setSearch] = useState('');
    const [type, setType] = useState<EContactType>(EContactType.XEPPT);
    const { isMobile } = useResponsive();

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    useEffect(() => {
        if (tabType) {
            //@ts-ignore
            setType(Number(tabType) as EContactType);
            setSearch('');
        }
    }, []);

    const { data: searchContacts, isLoading: isSearchLoading } = useApiQuery({
        method: () => apiContactService.searchContact(requestSearch),
        deps: [requestSearch],
        condition: requestSearch.length >= 3
    });

    const { handleRequest: handleSaveContact } = useApiMutation({
        method: (val: string) => {
            return apiContactService.addContact(val);
        },
        onSuccess: () => {
            handleBack();
            requestSuccessLocale('add_contact');
        },
        onError: requestErrorLocale
    });

    useEffect(() => {
        if (!!editableContact) {
            form.reset(editableContact);
            setIsEmail(!!editableContact?.email);
            setType(EContactType.INTERAC);
        }
    }, []);

    const { handleRequest: handleCreateContact, isLoading } = useApiMutation({
        method: () => {
            const values = form.getValues();
            const data: TCreateContact = {
                firstName: values?.firstName,
                lastName: values?.lastName,
                phone: undefined,
                email: undefined
            };
            if (isEmail) {
                data.email = values?.email;
            } else {
                data.phone = values?.phone;
            }
            return apiContactService.createContact(data);
        },
        onSuccess: () => {
            requestSuccessLocale('create_contact');
            handleBack();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleUpdateContact, isLoading: isUpdateContactLoading } =
        useApiMutation({
            method: () => {
                const values = form.getValues();
                const data: TCreateContact = {
                    firstName: values?.firstName,
                    lastName: values?.lastName
                };
                return apiContactService.updateContact(editableContact?.id || '', data);
            },
            onSuccess: () => {
                if (!!editableContact) {
                    requestSuccessLocale('update_contact');
                } else {
                    requestSuccessLocale('create_contact');
                }
                handleBack();
            },
            onError: requestErrorLocale
        });

    const onSubmit = () => {
        form.trigger().then((isValid) => {
            if (isValid) {
                if (editableContact) {
                    handleUpdateContact(undefined);
                } else {
                    handleCreateContact(undefined);
                }
            }
        });
    };

    const contacts = useMemo(() => {
        const filteredContacts = contactList.filter((item) => item.type === type);
        return filteredContacts?.filter((item) => {
            return (
                item?.tag?.toLowerCase()?.includes(search?.toLowerCase()) ||
                item?.email?.toLowerCase()?.includes(search?.toLowerCase()) ||
                item?.phone?.toLowerCase()?.includes(search?.toLowerCase()) ||
                `${item.firstName?.toLowerCase()} ${item.lastName?.toLowerCase()}`.includes(
                    search?.toLowerCase()
                )
            );
        });
    }, [contactList, type, search]);

    return (
        <FormProvider {...form}>
            <motion.div {...pageAnimation} className={styles.add_contact_wrapper}>
                {/*<div className={styles.radio_row}>*/}
                {/*    <div className={styles.section} onClick={() => setType(EContactType.XEPPT)}>*/}
                {/*        <Radio*/}
                {/*            className={styles.radio}*/}
                {/*            small*/}
                {/*            disabled={!!editableContact}*/}
                {/*            checked={type === EContactType.XEPPT}*/}
                {/*        />*/}
                {/*        <Typography>XEPPT Contact</Typography>*/}
                {/*    </div>*/}
                {/*    <div className={styles.section} onClick={() => setType(EContactType.INTERAC)}>*/}
                {/*        <Radio*/}
                {/*            className={styles.radio}*/}
                {/*            small*/}
                {/*            disabled={!!editableContact}*/}
                {/*            checked={type === EContactType.INTERAC}*/}
                {/*        />*/}
                {/*        <Typography>*/}
                {/*            <i>Interac</i> Contact*/}
                {/*        </Typography>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Typography variant="body3" className={styles.description}>
                    {t(
                        !!editableContact ? 'update_contact_description' : 'add_contact_description'
                    )}
                </Typography>
                {type === EContactType.XEPPT && !editableContact && (
                    <div className={styles.content}>
                        <Input
                            value={search}
                            leftIcon="search"
                            rightIcon={search.length > 0 ? 'filed_close' : undefined}
                            onClickRightIcon={() => setSearch('')}
                            placeholder="Search by contact name, email or phone number..."
                            onChange={(val) => setSearch(val)}
                            full
                        />
                        {!isSearchLoading &&
                        search &&
                        searchContacts?.length === 0 &&
                        contacts.length === 0 ? (
                            <div className={styles.empty_search}>
                                <img src={emptySearchIllustration} alt="empty search" />
                                <div className={styles.title}>No results found</div>
                                <div className={styles.description}>
                                    Please check if there are any spelling mistakes
                                </div>
                            </div>
                        ) : (
                            <div className={styles.result_wrapper}>
                                {!!search && (
                                    <div className={styles.result}>
                                        {isSearchLoading ? (
                                            <Skeleton className={styles.skeleton} />
                                        ) : (
                                            searchContacts?.map((contact) => {
                                                return (
                                                    <div
                                                        className={styles.contact}
                                                        key={contact.id}>
                                                        <div className={styles.left}>
                                                            <div className={styles.avatar}>
                                                                {getFirstLetter(contact?.firstName)}
                                                                {getFirstLetter(contact?.lastName)}
                                                            </div>
                                                            <div className={styles.text_wrapper}>
                                                                <div
                                                                    className={styles.title}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: highlightSearchText(
                                                                            `${contact.firstName} ${contact.lastName}`,
                                                                            search
                                                                        )
                                                                    }}
                                                                />
                                                                <div
                                                                    className={styles.description}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: highlightSearchText(
                                                                            contact.email ||
                                                                                //@ts-ignore
                                                                                `+${contact.phone}`,
                                                                            search
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Button
                                                            className={styles.add_button}
                                                            variant="outlined"
                                                            size="normal"
                                                            onClick={() =>
                                                                handleSaveContact(contact.id)
                                                            }>
                                                            Add {!isMobile ? 'contact' : ''}
                                                        </Button>
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                )}
                                <div className={styles.result}>
                                    <div className={styles.result_length}>
                                        Existing contacts ({contacts.length})
                                    </div>
                                    {contacts.map((contact) => {
                                        return (
                                            <div className={styles.contact} key={contact.id}>
                                                <div className={styles.left}>
                                                    <div className={styles.avatar}>
                                                        {getFirstLetter(contact?.firstName)}
                                                        {getFirstLetter(contact?.lastName)}
                                                    </div>
                                                    <div className={styles.text_wrapper}>
                                                        <div
                                                            className={styles.title}
                                                            dangerouslySetInnerHTML={{
                                                                __html: highlightSearchText(
                                                                    `${contact.firstName} ${contact.lastName}`,
                                                                    search
                                                                )
                                                            }}
                                                        />
                                                        <div
                                                            className={styles.description}
                                                            dangerouslySetInnerHTML={{
                                                                __html: highlightSearchText(
                                                                    contact.email ||
                                                                        //@ts-ignore
                                                                        `+${contact.phone}`,
                                                                    search
                                                                )
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {type === EContactType.INTERAC && (
                    <div className={styles.add_interac}>
                        <InfoBlock>
                            Please make sure the email address and/or mobile number is correct,
                            because that’s how your contact will be notified when you send or
                            request money using Interac e-Transfer. You agree that you have the
                            contact’s consent to use their information for the Interac e-Transfer
                            service.
                        </InfoBlock>
                        <div className={styles.row}>
                            <FormField
                                name="firstName"
                                renderComponent={(props) => (
                                    <Input label={labelLocale('first_name')} full {...props} />
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: validationLocale('firstName')
                                    }
                                }}
                            />
                            <FormField
                                name="lastName"
                                renderComponent={(props) => (
                                    <Input label={labelLocale('last_name')} full {...props} />
                                )}
                                rules={{
                                    required: { value: true, message: validationLocale('lastName') }
                                }}
                            />
                        </div>
                        <Typography variant="body3" className={styles.ddr_info}>
                            Choose a contact method
                        </Typography>
                        <div className={styles.form}>
                            {((!!editableContact && !!editableContact?.email) ||
                                !editableContact) && (
                                <div className={styles.row_wrapper}>
                                    <div className={styles.radio_wrapper}>
                                        <Radio
                                            className={styles.radio}
                                            small
                                            disabled={!!editableContact}
                                            checked={isEmail}
                                            onClick={() => setIsEmail(true)}
                                        />
                                        <div>Email address</div>
                                    </div>
                                    {isEmail && (
                                        <div className={styles.row}>
                                            <FormField
                                                name="email"
                                                renderComponent={(props) => (
                                                    <Input
                                                        label={labelLocale('email')}
                                                        full
                                                        readOnly={!isEmail || !!editableContact}
                                                        disabled={!isEmail}
                                                        {...props}
                                                    />
                                                )}
                                                rules={{
                                                    required: {
                                                        value: isEmail && !editableContact,
                                                        message: validationLocale('email')
                                                    }
                                                }}
                                            />
                                            {!editableContact && (
                                                <FormField
                                                    name="confirmEmail"
                                                    renderComponent={(props) => (
                                                        <Input
                                                            disabled={!isEmail || !!editableContact}
                                                            label={labelLocale('confirm_email')}
                                                            full
                                                            readOnly={!isEmail || !!editableContact}
                                                            {...props}
                                                        />
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: isEmail && !editableContact,
                                                            message:
                                                                validationLocale('emailConfirm')
                                                        },
                                                        validate: (val: string) => {
                                                            if (!!editableContact) {
                                                                return undefined;
                                                            }
                                                            return (
                                                                val === form.getValues('email') ||
                                                                validationLocale('emailConfirm')
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            {/*//@ts-ignore*/}
                            {((!!editableContact && !!editableContact?.phone) ||
                                !editableContact) && (
                                <div className={styles.row_wrapper}>
                                    <div className={styles.radio_wrapper}>
                                        <Radio
                                            className={styles.radio}
                                            small
                                            disabled={!!editableContact}
                                            checked={!isEmail}
                                            onClick={() => setIsEmail(false)}
                                        />
                                        <div>Mobile number</div>
                                    </div>
                                    {!isEmail && (
                                        <div className={styles.row}>
                                            <FormField
                                                name="phone"
                                                renderComponent={(props) => (
                                                    <Input
                                                        label={labelLocale('enter_contact_phone')}
                                                        full
                                                        readOnly={isEmail || !!editableContact}
                                                        disabled={isEmail}
                                                        {...props}
                                                        mask="(999) 999 99 99"
                                                    />
                                                )}
                                                rules={{
                                                    required: {
                                                        value: !isEmail && !editableContact,
                                                        message: validationLocale('phone')
                                                    }
                                                }}
                                            />
                                            {!editableContact && (
                                                <FormField
                                                    name="confirmPhone"
                                                    renderComponent={(props) => (
                                                        <Input
                                                            disabled={isEmail || !!editableContact}
                                                            label={labelLocale(
                                                                'enter_contact_confirm_phone'
                                                            )}
                                                            full
                                                            readOnly={isEmail || !!editableContact}
                                                            {...props}
                                                            mask="(999) 999 99 99"
                                                        />
                                                    )}
                                                    rules={{
                                                        required: {
                                                            value: !isEmail && !editableContact,
                                                            message: validationLocale('phone')
                                                        },
                                                        validate: (val: string) => {
                                                            if (!!editableContact) {
                                                                return undefined;
                                                            }
                                                            return (
                                                                val === form.getValues('phone') ||
                                                                validationLocale(
                                                                    'verify_contact_form'
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className={styles.actions}>
                    {(type === EContactType.INTERAC || !!editableContact) && (
                        <Button
                            variant="primary"
                            size="normal"
                            onClick={onSubmit}
                            isLoading={isLoading || isUpdateContactLoading}>
                            {t(!!editableContact ? 'update_contact_submit' : 'add_contact_submit')}
                        </Button>
                    )}
                </div>
            </motion.div>
        </FormProvider>
    );
};

export default Contact;
