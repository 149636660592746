import React, { FC, useMemo } from 'react';
import { TEPSCard } from '@xeppt/xeppt-sdk/types';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import visa from '@svg/visa.svg';
import mastercard from '@svg/mastercard.svg';
import amex from '@svg/amex.svg';
import moment from 'moment-timezone';

interface IProps {
    card: TEPSCard;
}

const CardSection: FC<IProps> = ({ card }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'manage_accounts.bank'
    });
    const image = useMemo(() => {
        if (card.cardBrand === 'visa') {
            return visa;
        } else if (card.cardBrand === 'mastercard') {
            return mastercard;
        } else if (card.cardBrand === 'amex') {
            return amex;
        }
        return visa;
    }, [card]);
    return (
        <div className={styles.wrapper}>
            <img className={styles.brand} src={image} alt="" />
            <div className={styles.row}>
                <div>
                    <Typography className={styles.title} variant="body2" weight="bold">
                        Card number
                    </Typography>
                    <Typography className={styles.description} variant="body2">
                        {card.cardNumber}
                    </Typography>
                </div>
                <div>
                    <Typography className={styles.title} variant="body2" weight="bold">
                        Good thru
                    </Typography>
                    <Typography className={styles.description} variant="body2">
                        {moment(card.expiresAt).format('MM/YY')}
                    </Typography>
                </div>
            </div>
            <div>
                <Typography className={styles.title} variant="body2" weight="bold">
                    Cardholder name
                </Typography>
                <Typography className={styles.description} variant="body2">
                    {card.cardHolderName}
                </Typography>
            </div>
        </div>
    );
};

export default CardSection;
