import React, { FC, useMemo } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { useUserContext } from '@hooks/context/useUserContext';
import FormField from '@components/form_field';
import Input from '@components/common/input';
import { useLocales } from '@hooks/helpers/useLocales';
import { useFrequency } from '@hooks/helpers/useFrequency';
import { ESendRequestMoneyMethod } from '@enum';
import Textarea from '@components/common/textarea';
import Button from '@components/common/button';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import { TContact } from '@xeppt/xeppt-sdk/types';
import Info from '@components/common/info';
import InfoBlock from '@components/common/info_block';
import Switch from '@components/common/switch';

interface IProps {
    onSubmit: () => void;
    onPrev: () => void;
    method?: ESendRequestMoneyMethod;
    contactList?: TContact[];
    isEdit: boolean;
}

const Amount: FC<IProps> = ({ onSubmit, onPrev, method, contactList, isEdit }) => {
    const { account } = useUserContext();
    const { validationLocale, labelLocale, submitLocale } = useLocales();
    const { getValues } = useFormContext();
    const { amountSendMoneyValidation } = useFrequency({
        isFrequencyExist: true,
        method: ESendRequestMoneyMethod.INTERNAL
    });

    const isBank = useMemo(
        () =>
            method === ESendRequestMoneyMethod.BANK ||
            method === ESendRequestMoneyMethod.BANK_REQUEST,
        [method]
    );

    const isWallet = useMemo(
        () =>
            method === ESendRequestMoneyMethod.INTERNAL ||
            method === ESendRequestMoneyMethod.INTERNAL_REQUEST,
        [method]
    );

    const isETransfer = useMemo(
        () =>
            method === ESendRequestMoneyMethod.E_TRANSFER ||
            method === ESendRequestMoneyMethod.E_TRANSFER_REQUEST,
        [method]
    );

    const isRequest = useMemo(
        () =>
            method === ESendRequestMoneyMethod.E_TRANSFER_REQUEST ||
            method === ESendRequestMoneyMethod.EPS_REQUEST ||
            method === ESendRequestMoneyMethod.BANK_REQUEST ||
            method === ESendRequestMoneyMethod.INTERNAL_REQUEST,
        [method]
    );

    const isEps = useMemo(() => method === ESendRequestMoneyMethod.EPS_REQUEST, [method]);

    const isPayBills = useMemo(() => method === ESendRequestMoneyMethod.PAY_BILLS, [method]);

    const recipientName = useMemo(() => {
        const values = getValues();
        const foundContact = contactList?.find((item) => item.id === values.contact);
        if (isBank) return 'Recipient';
        if (isWallet || isETransfer) return `${foundContact?.firstName} ${foundContact?.lastName}`;
        if (isPayBills) return 'Payee';
    }, []);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.title}>Amount</div>
                <div className={styles.description}>
                    Enter the amount you want to {isRequest ? 'request' : 'send'}
                </div>
            </div>
            <div className={styles.form}>
                {isPayBills && (
                    <InfoBlock>
                        <b>Please note</b>: If this payment is made on the last day of your card
                        statement period, it may take 1-2 business days to be processed and applied
                        to your card account and reflected on your monthly statement (the "Posting
                        Date"). As a result, the transaction date shown in your online banking
                        account for this payment may be different than the Posting Date. It is the
                        Posting Date that determines when the payment was received in accordance
                        with your cardholder agreement.
                    </InfoBlock>
                )}
                {isETransfer && (
                    <div className={styles.limits_wrapper}>
                        <div className={styles.title}>
                            Sending Limit{' '}
                            <Info variant="bottom" content="Some info about daily limits" />
                        </div>
                        <div className={styles.right}>
                            <div className={styles.active_limit}>
                                <div className={styles.active_title}>
                                    Limit remaining for 24 hours
                                </div>
                                <div className={styles.active_amount}>
                                    {account?.eTransferAccount?.dailyLimit} CAD
                                </div>
                            </div>
                            <div className={styles.daily_limit}>
                                <div className={styles.daily_title}>24-hours Limit</div>
                                <div className={styles.daily_amount}>3 000 CAD</div>
                            </div>
                        </div>
                    </div>
                )}
                <Typography variant="body3" className={styles.balance}>
                    Current XEPPT Wallet Balance:{' '}
                    <span>${(account?.balance || 0)?.toFixed(2)}</span>
                </Typography>
                <div className={styles.row}>
                    <FormField
                        name="amount"
                        renderComponent={(props) => (
                            <Input
                                full
                                label={isRequest ? 'Amount you request' : 'Amount you send'}
                                prefix="CAD"
                                rightIcon="canadian"
                                fixedAmount
                                onlyNumbers
                                hideErrorIcon
                                {...props}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('amount') },
                            validate: amountSendMoneyValidation
                        }}
                    />
                    <FormField
                        name="amount"
                        renderComponent={(props) => (
                            <Input
                                full
                                label={isRequest ? 'Amount you receive' : `${recipientName} gets`}
                                prefix="CAD"
                                rightIcon="canadian"
                                fixedAmount
                                onlyNumbers
                                hideErrorIcon
                                {...props}
                            />
                        )}
                        rules={{
                            required: { value: true, message: validationLocale('amount') },
                            validate: amountSendMoneyValidation
                        }}
                    />
                </div>
                {(isETransfer || isWallet) && (
                    <FormField
                        name="message"
                        renderComponent={(props) => (
                            <Textarea
                                full
                                label={labelLocale('message_optional')}
                                maxLength={250}
                                placeholder="Add message if necessary"
                                {...props}
                            />
                        )}
                    />
                )}
                {method !== ESendRequestMoneyMethod.E_TRANSFER &&
                    method !== ESendRequestMoneyMethod.E_TRANSFER_REQUEST && (
                        <div
                            className={styles.schedule_switch}
                            style={{ marginTop: isBank || isEps ? 24 : 0 }}>
                            <FormField<boolean>
                                name="isSchedule"
                                renderComponent={({ value, onChange }) => (
                                    <Switch checked={value} onClick={() => onChange(!value)} />
                                )}
                            />
                            <p>Schedule at a later date or set up recurring payment</p>
                        </div>
                    )}
            </div>
            <div className={styles.actions}>
                {!isEdit && (
                    <Button
                        leftIcon="arrow_left"
                        variant="outlined-dark"
                        size="normal"
                        onClick={onPrev}>
                        {submitLocale('previous')}
                    </Button>
                )}
                <Button onClick={onSubmit} rightIcon="arrow_right" variant="primary" size="normal">
                    {submitLocale('next')}
                </Button>
            </div>
        </motion.div>
    );
};

export default Amount;
