import React, { FC, SVGAttributes } from 'react';

const Reload: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 12C21 16.968 16.968 21 12 21C7.032 21 3.999 15.996 3.999 15.996M3.999 15.996H8.067M3.999 15.996V20.496M3 12C3 7.032 6.996 3 12 3C18.003 3 21 8.004 21 8.004M21 8.004V3.504M21 8.004H17.004"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Reload;
