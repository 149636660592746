import React, { FC, useEffect, useState } from 'react';
import FormField from '@components/form_field';
import { TAddress } from '@types';
import AddressAutocomplete from '@components/common/address';
import styles from './styles.module.scss';
import Input from '@components/common/input';
import { useFormContext, useWatch } from 'react-hook-form';
import { useLocales } from '@hooks/helpers/useLocales';
import { Country } from 'country-state-city';

interface IProps {
    name: string;
    disabled?: boolean;
    isFull?: boolean;
    label?: string;
}

const FullAddress: FC<IProps> = ({ name, disabled, isFull = true, label }) => {
    const { labelLocale, validationLocale, placeholderLocale } = useLocales();
    const [isAddressFiled, setIsAddressField] = useState(false);
    const { control } = useFormContext();
    const [isRerender, setIsRerender] = useState(true);
    const { setValue } = useFormContext();

    const value = useWatch({ name, control });

    useEffect(() => {
        if (value?.address1) {
            setIsAddressField(true);
        }
    }, [value]);

    return (
        <div className={styles.wrapper}>
            <FormField<TAddress>
                name={name}
                renderComponent={({ onChange, errors, ...props }) => {
                    return (
                        <AddressAutocomplete
                            {...props}
                            placeholder={placeholderLocale('enter_address')}
                            onChange={(val) => {
                                onChange(val);
                                setValue(
                                    `${name}.country`,
                                    Country.getAllCountries().find(
                                        (item) => item.isoCode === val.country
                                    )?.name || val.country
                                );
                                setIsRerender(false);
                                setTimeout(() => {
                                    setIsRerender(true);
                                }, 0);
                            }}
                            helperText={
                                !!errors?.[name]
                                    ? validationLocale('address')
                                    : isFull
                                      ? undefined
                                      : ''
                            }
                            label={label}
                            full
                            error={!!errors?.[name]}
                            disabled={disabled}
                        />
                    );
                }}
            />
            {isFull && isAddressFiled && isRerender && (
                <div className={styles.address_wrapper}>
                    <div className={styles.row}>
                        <FormField
                            name={`${name}.address1`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('address_1')}
                                    readOnly={disabled}
                                    placeholder={placeholderLocale('enter_address_1')}
                                />
                            )}
                            rules={{ required: true }}
                        />
                        <FormField
                            name={`${name}.address2`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('address_2')}
                                    placeholder={placeholderLocale('enter_address_2')}
                                    readOnly={disabled}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name={`${name}.country`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('country')}
                                    placeholder={placeholderLocale('enter_country')}
                                    readOnly={disabled}
                                />
                            )}
                            rules={{ required: true }}
                        />
                        <FormField
                            name={`${name}.city`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    placeholder={placeholderLocale('enter_city')}
                                    label={labelLocale('city')}
                                    readOnly={disabled}
                                />
                            )}
                            rules={{ required: true }}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name={`${name}.region`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label={labelLocale('region')}
                                    placeholder={placeholderLocale('enter_region')}
                                    readOnly={disabled}
                                />
                            )}
                            rules={{ required: true }}
                        />
                        <FormField
                            name={`${name}.zipCode`}
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    placeholder={placeholderLocale('enter_postal_code')}
                                    label={labelLocale('zipCode')}
                                    readOnly={disabled}
                                />
                            )}
                            rules={{ required: true }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FullAddress;
