import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useUserContext } from '@hooks/context/useUserContext';
import Button from '@components/common/button';
import { useLocales } from '@hooks/helpers/useLocales';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { EDdrStatus } from '@xeppt/xeppt-sdk/types';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useSendAndRequestModals } from '@hooks/modals/useSendAndRequestModals';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import Checkbox from '@components/common/checkbox';
import Typography from '@components/common/typography';
import { Link } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { useInteracModals } from '@hooks/modals/useInteracModals';

interface IProps {
    onSubmit: () => void;
    isRequest: boolean;
}

const Requester: FC<IProps> = ({ onSubmit, isRequest }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.manage_autodeposit'
    });
    const { account, user, refetchAccount } = useUserContext();
    const { submitLocale, requestErrorLocale, requestSuccessLocale } = useLocales();
    const { handleOpenRequestAutodepositModal } = useSendAndRequestModals();
    const { handleChangeInteracEmail } = useInteracModals();
    const [isUpdate, setIsUpdate] = useState(false);
    const [isAgree, setIsAgree] = useState(false);

    const { data: ddrStatus, handleRequest: refetchDdr } = useApiQuery({
        method: () => apiAccountService.checkETransferDdr(),
        isInitialRequest: false
    });

    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (ddrStatus === EDdrStatus.PENDING) {
            intervalId = setInterval(() => refetchDdr(), 10000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    const { handleRequest: onSetAutodeposit, isLoading } = useApiMutation({
        method: () => {
            return new Promise((res) => {
                apiAccountService.requestETransferDdr();
                setTimeout(() => {
                    res('ok');
                }, 2000);
            });
        },
        onSuccess: () => {
            requestSuccessLocale('set_ddr');
            refetchAccount();
            handleOpenRequestAutodepositModal((val) => {
                setIsUpdate(val);
                refetchDdr();
            });
        },
        onError: requestErrorLocale
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.title}>Requester</div>
                <div className={styles.description}>
                    To request money from Interac contact, please set up Interac e-Transfer
                    Autodeposit so the funds will deposit automatically into your Wallet
                </div>
            </div>
            <div className={styles.email_wrapper}>
                <div className={styles.left}>Interac email address</div>
                <div className={styles.right}>
                    {user?.profile?.firstName} {user?.profile?.lastName} (
                    {account?.eTransferAccount?.interacEmail})
                    <Button leftIcon="edit" onClick={handleChangeInteracEmail} />
                </div>
            </div>
            {isUpdate && (
                <>
                    <motion.div {...pageAnimation} className={styles.content}>
                        <div className={styles.form_row}>
                            <Checkbox
                                className={styles.checkbox}
                                checked={isAgree}
                                onClick={() => setIsAgree((state) => !state)}
                            />
                            <Typography className={styles.send_disclaimer}>
                                <span>{t('agree_1')}</span>
                                <br />
                                {t('agree_2')} <span>{t('agree_3')}</span> {t('agree_4')}
                                <br />
                                <br />
                                {t('agree_5')}
                                <br />
                                <br />
                                {t('agree_6')}{' '}
                                <Link to={routes.privacy_policy}>{t('agree_7')}</Link>
                            </Typography>
                        </div>
                    </motion.div>
                    <Button
                        variant="primary"
                        size="normal"
                        onClick={() => onSetAutodeposit(undefined)}
                        isLoading={isLoading}
                        disabled={!isAgree}>
                        {submitLocale('save')}
                    </Button>
                </>
            )}
            <div className={styles.actions}>
                {ddrStatus === EDdrStatus.ENABLED && isRequest && (
                    <Button
                        onClick={onSubmit}
                        rightIcon="arrow_right"
                        variant="primary"
                        size="normal">
                        {submitLocale('next')}
                    </Button>
                )}
                {!isRequest && (
                    <Button
                        onClick={onSubmit}
                        rightIcon="arrow_right"
                        variant="primary"
                        size="normal">
                        {submitLocale('next')}
                    </Button>
                )}
                {ddrStatus === EDdrStatus.DISABLED && (
                    <Button
                        onClick={() => setIsUpdate(true)}
                        variant="primary"
                        size="normal"
                        isLoading={isLoading}>
                        Set up Autodeposit
                    </Button>
                )}
            </div>
        </div>
    );
};

export default Requester;
