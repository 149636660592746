import React, { FC, useEffect } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import Select from '@components/common/select';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { IModalComponentProps } from '@context/modal_context';
import { ECardStatus } from '@xeppt/xeppt-sdk/types';
import { ECardStatusTranslation } from '@locales/index';

const CancelCardReasonModal: FC<IModalComponentProps<{ reasonId: string | number }>> = ({
    modalData,
    changeModalData,
    handleChangeDisable
}) => {
    const { labelLocale } = useLocales();
    const { t, i18n } = useTranslation('modals', {
        keyPrefix: 'cancel_card_reason'
    });
    useEffect(() => {
        if (!modalData?.reasonId) {
            handleChangeDisable(true);
        } else {
            handleChangeDisable(false);
        }
    }, [modalData]);
    return (
        <div className={styles.wrapper}>
            <Typography className={styles.title} variant="h4">
                {t('title')}
            </Typography>
            <div className={styles.inputs_wrapper}>
                <div className={styles.input_wrapper}>
                    <Select
                        label={labelLocale('reason')}
                        onChange={(value) => changeModalData({ ...modalData, reasonId: value })}
                        value={modalData?.reasonId}
                        items={[
                            {
                                value: ECardStatus.FRAUD,
                                label: ECardStatusTranslation[ECardStatus.FRAUD][
                                    i18n.language as 'en' | 'fr'
                                ]
                            },
                            {
                                value: ECardStatus.STOLEN,
                                label: ECardStatusTranslation[ECardStatus.STOLEN][
                                    i18n.language as 'en' | 'fr'
                                ]
                            },
                            {
                                value: ECardStatus.LOST,
                                label: ECardStatusTranslation[ECardStatus.LOST][
                                    i18n.language as 'en' | 'fr'
                                ]
                            },
                            {
                                value: ECardStatus.CUSTOMER_REQUEST,
                                label: ECardStatusTranslation[ECardStatus.CUSTOMER_REQUEST][
                                    i18n.language as 'en' | 'fr'
                                ]
                            },
                            {
                                value: ECardStatus.OTHER,
                                label: ECardStatusTranslation[ECardStatus.OTHER][
                                    i18n.language as 'en' | 'fr'
                                ]
                            }
                        ]}
                        full
                    />
                </div>
            </div>
        </div>
    );
};

export default CancelCardReasonModal;
