import { useUserContext } from '@hooks/context/useUserContext';
import { useNotificationsContext } from '@hooks/context/useNotificationsContext';
import { useEffect } from 'react';
import { ENewNotificationType } from '@enum';

export const useGlobalProvider = () => {
    const { refetchAccount, refetchUser, handleLogout } = useUserContext();
    const { newNotificationType } = useNotificationsContext();

    useEffect(() => {
        if (newNotificationType === ENewNotificationType.USER) {
            refetchUser();
        }
        if (
            newNotificationType === ENewNotificationType.ACCOUNT ||
            newNotificationType === ENewNotificationType.TRANSACTION ||
            newNotificationType === ENewNotificationType.CARD
        ) {
            refetchAccount();
        }
        if (newNotificationType === ENewNotificationType.LOGOUT) {
            handleLogout();
        }
    }, [newNotificationType]);
};
