import React, { FC, SVGAttributes } from 'react';

const AddPerson: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8765 15.2061C7.03251 15.2061 3.74951 15.7871 3.74951 18.1151C3.74951 20.4431 7.01251 21.0451 10.8765 21.0451C14.7215 21.0451 18.0035 20.4631 18.0035 18.1361C18.0035 15.8091 14.7415 15.2061 10.8765 15.2061Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8765 11.886C13.3995 11.886 15.4445 9.841 15.4445 7.318C15.4445 4.795 13.3995 2.75 10.8765 2.75C8.35451 2.75 6.30951 4.795 6.30951 7.318C6.30051 9.832 8.33051 11.877 10.8455 11.886H10.8765Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M20.2036 8.66992V12.6799"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M22.2497 10.6738H18.1597"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AddPerson;
