import React, { useEffect } from 'react';
import Typography from '@components/common/typography';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import { useFormContext, useWatch } from 'react-hook-form';
import FormField from '@components/form_field';
import { apiRegistrationService } from '@api';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { passwordValidation } from '@utils/validation';
import passwordIllustration from '@svg/illustrations/auth/password.svg';
import { EUserType } from '@xeppt/xeppt-sdk/types';

const PasswordSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.password'
    });
    const { labelLocale, submitLocale, requestErrorLocale, validationLocale, placeholderLocale } =
        useLocales();
    const { nextStep, handleChangeIllustration, prevStep } = useSignupContext();
    const { getValues, trigger, setValue, control } = useFormContext();
    const type = useWatch({ name: 'type', control });

    useEffect(() => {
        handleChangeIllustration(passwordIllustration);
    }, []);

    const { handleRequest: handleActivate, isLoading } = useApiMutation({
        method: () => {
            const { phoneNumber, phoneCode, email, password, passwordConfirm, type } = getValues();
            return apiRegistrationService.registerAccount({
                password,
                passwordConfirm,
                phoneCode,
                email,
                type,
                phoneNumber
            });
        },
        onSuccess: (data) => {
            nextStep();
            setValue('userId', data.id);
        },
        onError: requestErrorLocale
    });

    const onSubmit = () => {
        trigger().then((isValid) => {
            if (isValid) {
                handleActivate(undefined);
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.steps}>STEP 5/{type === EUserType.BUSINESS ? '11' : '7'}</div>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <FormField
                    name="password"
                    renderComponent={({ value, ...props }) => (
                        <Input
                            {...props}
                            label={labelLocale('password')}
                            full
                            value={value}
                            placeholder={placeholderLocale('enter_password')}
                            type="password"
                            required
                            passwordErrors={
                                !!value ? passwordValidation(value, validationLocale) : undefined
                            }
                        />
                    )}
                />
                <FormField
                    name="passwordConfirm"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            label={labelLocale('confirm_password')}
                            full
                            placeholder={placeholderLocale('enter_confirm_password')}
                            type="password"
                            required
                        />
                    )}
                />
                <div className={styles.actions}>
                    <Button
                        className={styles.next}
                        onClick={onSubmit}
                        size="normal"
                        rightIcon="arrow_right"
                        isLoading={isLoading}
                        variant="primary">
                        {submitLocale('next')}
                    </Button>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        onClick={() => prevStep()}>
                        {submitLocale('back')}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default PasswordSection;
