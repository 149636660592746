import React, { useState, useEffect } from 'react';
import Typography from '@components/common/typography';
import Link from '@components/common/link';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import FormField from '@components/form_field';
import { zodResolver } from '@hookform/resolvers/zod';
import { defaultForgotPassword } from '@const/default_form_data';
import { forgotPasswordValidation } from '@helpers/validation_objects/forgot_password';
import { FormProvider } from 'react-hook-form';
import styles from './styles.module.scss';
import { apiAuthService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { passwordValidation } from '@utils/validation';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import passwordIllustration from '@svg/illustrations/auth/forgot.svg';
import passwordResetIllustration from '@svg/illustrations/auth/password.svg';
import mailSentIllustration from '@svg/illustrations/mail_sent.svg';
import useSignupContext from '@hooks/context/useSignupContext';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const ForgotPasswordLayout = () => {
    const { t } = useTranslation('forgot_password');
    const {
        validationLocale,
        requestSuccessLocale,
        labelLocale,
        requestErrorLocale,
        placeholderLocale
    } = useLocales();
    const [token] = useQueryParams(['token']);
    const navigate = useNavigate();
    const [isSentEmail, setIsSentEmail] = useState(false);
    const { handleChangeIllustration } = useSignupContext();
    const formMethods = useForm({
        defaultValues: defaultForgotPassword,
        resolver: zodResolver(
            forgotPasswordValidation({
                currentStep: isSentEmail ? 1 : 0,
                messages: {
                    email: validationLocale('email'),
                    password: validationLocale('password'),
                    passwordConfirm: validationLocale('passwordConfirm')
                }
            })
        )
    });

    useEffect(() => {
        if (token) {
            handleChangeIllustration(passwordIllustration);
        } else {
            handleChangeIllustration(passwordResetIllustration);
        }
    }, [token]);

    const { handleRequest: handleResetPassword, isLoading: isResetLoading } = useApiMutation({
        method: () => {
            const { password, passwordConfirm } = formMethods.getValues();
            return apiAuthService.resetPassword(token as string, password, passwordConfirm);
        },
        onSuccess: () => {
            requestSuccessLocale('reset_password');
            navigate(routes.signin);
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleSendEmail, isLoading: isSendEmailLoading } = useApiMutation({
        method: () => {
            const { email } = formMethods.getValues();
            return apiAuthService.forgotPassword(email);
        },
        onSuccess: () => {
            requestSuccessLocale('forgot_password');
            setIsSentEmail(true);
        },
        onError: requestErrorLocale
    });

    const onForgotPassword = () => {
        if (isSentEmail) {
            setIsSentEmail(false);
        } else {
            formMethods.trigger().then((isValid) => {
                if (isValid) {
                    if (!token) {
                        handleSendEmail(undefined);
                    } else {
                        handleResetPassword(undefined);
                    }
                }
            });
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            {isSentEmail && (
                <img
                    src={mailSentIllustration}
                    className={styles.sent_illustration}
                    alt="Illustration"
                />
            )}
            <Typography className={styles.title} variant="h3">
                {!isSentEmail && !token && t('title')}
                {isSentEmail && !token && t('title_sent')}
                {token && t('title_reset')}
            </Typography>
            <Typography
                className={cx(styles.description, { isLarge: isSentEmail })}
                variant="body2">
                {!isSentEmail && !token && t('description')}
                {isSentEmail &&
                    !token &&
                    t('description_sent').replace('{{email}}', formMethods.getValues().email)}
                {token && t('description_reset')}
            </Typography>
            <FormProvider {...formMethods}>
                {!isSentEmail && (
                    <div className={styles.form}>
                        {!token && (
                            <FormField
                                name="email"
                                renderComponent={(props) => (
                                    <Input
                                        {...props}
                                        placeholder={placeholderLocale('enter_email')}
                                        full
                                        label={labelLocale('email')}
                                        name="email"
                                    />
                                )}
                            />
                        )}
                        {!!token && (
                            <>
                                <FormField
                                    name="password"
                                    renderComponent={({ value, ...props }) => (
                                        <Input
                                            {...props}
                                            full
                                            type="password"
                                            name="password"
                                            placeholder={placeholderLocale('enter_password')}
                                            value={value}
                                            label={labelLocale('password')}
                                            passwordErrors={passwordValidation(
                                                value,
                                                validationLocale
                                            )}
                                        />
                                    )}
                                />
                                <FormField
                                    name="passwordConfirm"
                                    renderComponent={({ value, ...props }) => (
                                        <Input
                                            {...props}
                                            full
                                            type="password"
                                            name="passwordConfirm"
                                            value={value}
                                            placeholder={placeholderLocale(
                                                'enter_confirm_password'
                                            )}
                                            label={labelLocale('passwordConfirm')}
                                            passwordErrors={passwordValidation(
                                                value,
                                                validationLocale
                                            )}
                                        />
                                    )}
                                />
                            </>
                        )}
                    </div>
                )}
            </FormProvider>
            <Button
                onClick={onForgotPassword}
                isLoading={isResetLoading || isSendEmailLoading}
                variant="primary"
                size="normal">
                {!isSentEmail && !token && t('submit')}
                {isSentEmail && !token && t('change_email')}
                {token && t('reset_button')}
            </Button>
            <Button
                className={styles.back}
                onClick={() => navigate(routes.signin)}
                leftIcon="arrow_left">
                {t('back')}
            </Button>
        </motion.div>
    );
};
export default ForgotPasswordLayout;
