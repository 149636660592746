import React from 'react';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import Card from '@components/cards/card';
import styles from './styles.module.scss';
import { Icon } from '@components/icons';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useUserContext } from '@hooks/context/useUserContext';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import Skeleton from '@components/common/skeleton';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiWalletService } from '@api';
import Dropdown from '@components/common/dropdown';
import { ENewNotificationType } from '@enum';
import Button from '@components/common/button';

const CardsSection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.cards'
    });
    const { isDataLoading } = useUserContext();
    const { verifiedAction } = useVerificationGuard();

    const { data: cards, isLoading: isCardsLoading } = useApiQuery({
        method: () => apiWalletService.getCards(),
        notificationType: ENewNotificationType.CARD
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <Typography variant="h4" className={styles.title}>
                    {t('title')}
                </Typography>
                <Button
                    className={styles.all}
                    rightIcon="arrow_right"
                    onClick={() => navigate(routes.xeppt_cards)}>
                    See all
                </Button>
            </div>
            <div className={styles.row_wrapper}>
                <div className={styles.row}>
                    {isDataLoading || isCardsLoading ? (
                        <>
                            <Skeleton className={styles.skeleton} />
                            <Skeleton className={styles.skeleton} />
                        </>
                    ) : (
                        <>
                            {cards?.map((item) => (
                                <Card showStatus={false} key={item.id} card={item} />
                            ))}
                            <button
                                className={styles.new_card}
                                onClick={() =>
                                    verifiedAction(() => navigate(routes.order_xeppt_card))
                                }>
                                <div>
                                    <Icon name="plus" />
                                </div>
                                <Typography variant="body1" weight="bold">
                                    {t('new_card')}
                                </Typography>
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardsSection;
