import React, { FC } from 'react';
import styles from './styles.module.scss';
import TransactionItem from './transaction_item';
import Typography from '@components/common/typography';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useTranslation } from 'react-i18next';
import { TCardTransaction, TTransactionLite } from '@xeppt/xeppt-sdk/types';
import Skeleton from '@components/common/skeleton';
import CardTransactionItem from '@components/transactions_table/card_transaction_item';
import illustration from '@svg/illustrations/empty_transaction.svg';

interface IProps {
    transactions: TTransactionLite[] | TCardTransaction[];
    isTransactionsLoading?: boolean;
    isCard?: boolean;
    refetchTransactions?: () => void;
}

const TransactionTable: FC<IProps> = ({
    transactions,
    isTransactionsLoading,
    isCard = false,
    refetchTransactions
}) => {
    const { t } = useTranslation('components', {
        keyPrefix: 'transaction_tables'
    });
    return (
        <div className={styles.wrapper}>
            {isTransactionsLoading ? (
                <>
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                    <Skeleton className={styles.skeleton} />
                </>
            ) : (
                <>
                    {transactions.length === 0 && !isTransactionsLoading ? (
                        <div className={styles.empty_transactions}>
                            <img src={illustration} alt="illustration" />
                            <Typography variant="body3" className={styles.title}>
                                {t('empty')}
                            </Typography>
                            <div className={styles.description}>
                                Your transactions will appear here
                            </div>
                        </div>
                    ) : (
                        transactions.map((item, i) => {
                            if (isCard) {
                                return (
                                    <CardTransactionItem
                                        key={i}
                                        transaction={item as TCardTransaction}
                                    />
                                );
                            } else {
                                return (
                                    <TransactionItem
                                        key={i}
                                        refetchTransactions={refetchTransactions}
                                        transaction={item as TTransactionLite}
                                    />
                                );
                            }
                        })
                    )}
                </>
            )}
        </div>
    );
};

export default TransactionTable;
