import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import TableSection from '@sections/transactions/table';
import classNames from 'classnames/bind';
import FilterSection from '@sections/transactions/filter';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import {
    ETransactionAction,
    ETransactionMethod,
    TTransactionLite,
    TTransactionsFilter
} from '@xeppt/xeppt-sdk/types/transaction';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { ENewNotificationType } from '@enum';
import { routes } from '@const/routes';
import Button from '@components/common/button';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { useIsFirstRender } from '@mantine/hooks';

const cx = classNames.bind(styles);

export const actionsTransactions = [
    ETransactionAction.TRANSFER,
    ETransactionAction.CARD_SERVICE,
    ETransactionAction.CARD_DEPOSIT,
    ETransactionAction.PAYMENT,
    ETransactionAction.REFUND,
    ETransactionAction.DEPOSIT,
    ETransactionAction.WITHDRAW,
    ETransactionAction.CASHBACK
];

const TransactionsLayout = () => {
    const [filter, setFilter] = useState<TTransactionsFilter>({
        action: undefined,
        dateFrom: undefined,
        dateTo: undefined
    });
    const isFirstRender = useIsFirstRender();
    const [activeTab, setActiveTab] = useState('transactions');
    const [cursor, setCursor] = useState<Date>();
    const loader = useRef(null);
    const [transactions, setTransactions] = useState<TTransactionLite[]>([]);
    const [trigger, setTrigger] = useState(0);
    const { handleBack } = useNotFound();
    const {
        data: transactionsResponse,
        isLoading: isTransactionsLoading,
        handleRequest: handleGetTransactions
    } = useApiQuery({
        isInitialRequest: false,
        method: (request: any) => {
            return apiAccountService.findTransactions(
                {
                    dateTo: request?.dateTo,
                    dateFrom: request?.dateFrom,
                    action:
                        activeTab === 'requests'
                            ? [ETransactionAction.REQUEST]
                            : request?.action?.length === 0
                              ? actionsTransactions
                              : request?.action,
                    method: request?.method?.length === 0 ? undefined : request?.method
                },
                {
                    cursor: request?.isNew ? request?.dateTo : cursor,
                    limit: 100
                }
            );
        },
        onSuccess: (data, requestData) => {
            if (!requestData?.isNew) {
                setTransactions((state) => [...state, ...data.data]);
                setCursor(data.cursor);
            } else {
                setTransactions(data?.data || []);
                setCursor(data.cursor);
            }
        },
        notificationType: ENewNotificationType.TRANSACTION
    });
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const loadItems = () => {
            if (
                transactions.length < (transactionsResponse?.total || 0) &&
                !isTransactionsLoading
            ) {
                handleGetTransactions();
            }
        };

        loadItems();
    }, [trigger]);

    const handleObserver = useCallback((entries: any) => {
        const target = entries[0];
        if (target.isIntersecting) {
            setTrigger((prev) => prev + 1);
        }
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            handleGetTransactions({ ...filter, isNew: true });
        }
    }, [activeTab]);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: '20px',
            threshold: 0
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, [handleObserver]);

    return (
        <motion.div {...pageAnimation}>
            <div className={styles.wrapper}>
                <div className={cx([styles.left, { isOpen }])}>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        onClick={() => {
                            handleBack(routes.send_and_request);
                        }}>
                        Back
                    </Button>
                    <TableSection
                        transactions={transactions.filter(
                            (item) => item.method !== ETransactionMethod.E_TRANSFER
                        )}
                        toggleFilter={() => setIsOpen((state) => !state)}
                        isTransactionsLoading={isTransactionsLoading}
                        onChangeTab={(val) => setActiveTab(val)}
                        activeTab={activeTab}
                        filter={filter}
                        refetchTransactions={() =>
                            handleGetTransactions({ ...filter, isNew: true })
                        }
                        onChangeFilter={(value) => {
                            setCursor(undefined);
                            setTransactions([]);
                            setFilter(value);
                            handleGetTransactions({ ...value, isNew: true });
                        }}
                    />
                    <div ref={loader} />
                </div>
                <div className={cx([styles.right, { isOpen }])}>
                    <FilterSection
                        activeTab={activeTab}
                        toggleFilter={() => setIsOpen((state) => !state)}
                        onChangeFilter={(value) => {
                            setCursor(undefined);
                            setTransactions([]);
                            setFilter(value);
                            handleGetTransactions({ ...value, isNew: true });
                        }}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default TransactionsLayout;
