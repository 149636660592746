import styles from './styles.module.scss';
import React, { useState } from 'react';
import Typography from '@components/common/typography';
import PaymentsTable from '@sections/pay_bills/schedule_payments/payments_table';
import { useTranslation } from 'react-i18next';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiScheduleService } from '@api';
import { FormProvider } from 'react-hook-form';
import { useForm } from '@hooks/helpers/useForm';
import { defaultPaymentData } from '@const/default_form_data';
import { ENewNotificationType, ESchedulePaymentType } from '@enum';
import { useLocales } from '@hooks/helpers/useLocales';
import Pagination from '@components/pagination';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { EScheduleFrequency, EScheduleStatus, TSchedule } from '@xeppt/xeppt-sdk/types/schedule';
import { ETransactionMethod } from '@xeppt/xeppt-sdk/types';
import { routes } from '@const/routes';
import Button from '@components/common/button';
import { useNotFound } from '@hooks/helpers/useNotFound';
import SearchInput from '@components/search';
import { useNavigate } from 'react-router-dom';

const defaultData = {
    numberOfPayments: 1,
    paymentType: ESchedulePaymentType.PAYMENTS_DATE,
    paymentFrequency: EScheduleFrequency.ONCE,
    info: '',
    id: '',
    amount: 0,
    isInfinitePayment: false,
    nextPaymentAt: new Date(),
    paymentsEndDate: new Date()
};

const SchedulePaymentsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.schedule_payments'
    });
    const navigate = useNavigate();
    const [isEdit, setIsEdit] = useState(false);
    const [search, setSearch] = useState('');
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const [filter, setFilter] = useState<{
        paymentFrequency?: EScheduleFrequency[];
        status?: EScheduleStatus[];
    }>({
        paymentFrequency: [],
        status: []
    });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { handleBack } = useNotFound();
    const { data: paymentsList, handleRequest: refetchPaymentsList } = useApiQuery({
        method: () =>
            apiScheduleService.listSchedules(pageSize, page, {
                frequency:
                    (filter.paymentFrequency || [])?.length > 0
                        ? filter.paymentFrequency
                        : undefined,
                status: (filter.status || [])?.length > 0 ? filter.status : undefined,
                method: [ETransactionMethod.PAY_BILL]
            }),
        deps: [filter, page, pageSize],
        notificationType: ENewNotificationType.BILL
    });
    const form = useForm({
        defaultValues: defaultData
    });

    const { handleRequest: onSubmit, isLoading } = useApiMutation({
        method: () => {
            const {
                id,
                amount,
                paymentFrequency,
                numberOfPayments,
                nextPaymentAt,
                isInfinitePayment
            } = form.getValues();
            return apiScheduleService.updateSchedule(id, {
                amount: Number(amount),
                frequency: paymentFrequency,
                numberOfPayments: isInfinitePayment ? -1 : Number(numberOfPayments),
                nextPaymentAt
            });
        },
        onSuccess: () => {
            requestSuccessLocale('update_schedule_payment');
            form.reset(defaultData);
            setIsEdit(false);
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Button
                            className={styles.back}
                            leftIcon="arrow_left"
                            onClick={() => handleBack(routes.pay_bills)}>
                            Back
                        </Button>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                </div>
                <div className={styles.search_row}>
                    <SearchInput
                        className={styles.search}
                        value={search}
                        onChange={(val) => setSearch(val)}
                        placeholder="Search by payee..."
                    />
                    <Button
                        variant="primary"
                        size="small"
                        onClick={() => navigate(routes.pay_bills_pay)}>
                        Schedule new
                    </Button>
                </div>
                <PaymentsTable
                    refetchPaymentsList={refetchPaymentsList}
                    paymentsList={paymentsList?.data || []}
                    onFilterChange={(filter) => setFilter(filter)}
                    filter={filter}
                    onEdit={(data: TSchedule) => {
                        setIsEdit(true);
                        form.reset({
                            numberOfPayments: data.remainingPayments,
                            info: data.info,
                            id: data.id,
                            amount: data.amount,
                            nextPaymentAt: data.nextPaymentAt || new Date(),
                            paymentsEndDate: data.lastPaymentAt,
                            paymentFrequency: data.frequency,
                            isInfinitePayment: data.remainingPayments === -1,
                            paymentType: ESchedulePaymentType.NUMBER_OF_PAYMENTS
                        });
                    }}
                />
                {(paymentsList?.pagesTotal || 0) > 1 && (
                    <Pagination
                        pageCount={paymentsList?.pagesTotal || 0}
                        onPageChange={(val) => setPage(val)}
                        pageSize={pageSize}
                        onPageSizeChange={(val) => setPageSize(val)}
                    />
                )}
            </div>
        </motion.div>
    );
};

export default SchedulePaymentsSection;
