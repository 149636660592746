import React, { useEffect } from 'react';
import Typography from '@components/common/typography';
import Link from '@components/common/link';
import Input from '@components/common/input';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import FormField from '@components/form_field';
import { zodResolver } from '@hookform/resolvers/zod';
import { signInValidation } from '@helpers/validation_objects/signin';
import { defaultSignInData } from '@const/default_form_data';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiAuthService, apiUserService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { EUserRole } from '@xeppt/xeppt-sdk/types';
import { useUserContext } from '@hooks/context/useUserContext';
import { toast } from 'react-toastify';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import useSignupContext from '@hooks/context/useSignupContext';
import loginIllustration from '@svg/illustrations/auth/login.svg';

const SignInLayout = () => {
    const { t } = useTranslation('sign_in');
    const [uri] = useQueryParams(['uri']);
    const { validationLocale, labelLocale, requestErrorLocale, submitLocale, placeholderLocale } =
        useLocales();
    const navigate = useNavigate();
    const location = useLocation();
    const { handleLogout } = useUserContext();
    const { handleChangeIllustration } = useSignupContext();
    const {
        control,
        formState: { isValid },
        getValues
    } = useForm({
        defaultValues: defaultSignInData,
        resolver: zodResolver(
            signInValidation({
                messages: {
                    email: validationLocale('email')
                }
            })
        )
    });

    const { handleRequest: handleSubmitForm, isLoading } = useApiMutation({
        method: () => {
            const { email, password } = getValues();
            return apiAuthService.login({
                emailOrPhone: email,
                password
            });
        },
        onSuccess: () => {
            if (uri) {
                apiAuthService
                    .authorize()
                    .then((data) => {
                        window.location.replace(`${uri}?uuid=${data}`);
                    })
                    .catch((e) => {
                        console.log({ e });
                    });
            } else {
                apiUserService.profile().then(({ role }) => {
                    if (role !== EUserRole.REGULAR_USER) {
                        handleLogout();
                        toast.error(t('requests.error.role'));
                    } else {
                        if (location.state?.redirect) {
                            navigate(location.state?.redirect);
                        } else {
                            navigate(routes.dashboard);
                        }
                    }
                });
            }
        },
        onError: requestErrorLocale
    });

    useEffect(() => {
        handleChangeIllustration(loginIllustration);
    }, []);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <div className={styles.form}>
                <FormField
                    control={control}
                    name="email"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            full
                            placeholder={placeholderLocale('enter_email')}
                            label={labelLocale('email')}
                            name="email"
                        />
                    )}
                />
                <FormField
                    control={control}
                    name="password"
                    renderComponent={(props) => (
                        <Input
                            {...props}
                            full
                            placeholder={placeholderLocale('enter_password')}
                            type="password"
                            name="password"
                            label={labelLocale('password')}
                        />
                    )}
                />
                <Link to={routes.forgot_password} className={styles.forgot}>
                    {t('forgot')}
                </Link>
            </div>
            <Button
                onClick={handleSubmitForm}
                disabled={!isValid}
                isLoading={isLoading}
                variant="primary"
                size="normal">
                {submitLocale('signin')}
            </Button>
        </motion.div>
    );
};
export default SignInLayout;
