import React, { forwardRef, InputHTMLAttributes, MutableRefObject, useMemo, useState } from 'react';
import { Icon, TIconType } from '@components/icons';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Typography from '@components/common/typography';
import InputMask from '@components/common/input/masked_input';

interface IProps {
    type?: 'text' | 'password';
    value?: string;
    onChange?: (value: string) => void;
    onBlur?: () => void;
    leftIcon?: TIconType;
    rightIcon?: TIconType;
    helperText?: string;
    error?: boolean;
    onlyNumbers?: boolean;
    label?: string;
    placeholder?: string;
    hideErrorIcon?: boolean;
    fixedAmount?: boolean;
    className?: string;
    mask?: string;
    prefix?: string;
    suffix?: string;
    unmask?: boolean;
    full?: boolean;
    onClickIcon?: () => void;
    onClickLeftIcon?: () => void;
    onClickRightIcon?: () => void;
    disabled?: boolean;
    stringClassName?: string;
    ref?: MutableRefObject<null>;
    inputClassName?: string;
    wrapperRef?: MutableRefObject<null>;
    onClick?: () => void;
    passwordErrors?: { message: string; isValid: boolean }[];
}

const cx = classNames.bind(styles);

const Input = forwardRef(function (
    {
        type = 'text',
        value = '',
        onChange,
        placeholder,
        onClickLeftIcon,
        onClickRightIcon,
        onlyNumbers,
        label,
        rightIcon,
        leftIcon,
        prefix,
        suffix,
        helperText,
        full,
        fixedAmount,
        className,
        stringClassName,
        onClickIcon,
        passwordErrors,
        error,
        disabled,
        inputClassName,
        onBlur,
        unmask = true,
        hideErrorIcon,
        wrapperRef,
        readOnly = false,
        onClick,
        ...props
    }: IProps & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>,
    ref
) {
    const [isOpenEye, setIsOpenEye] = useState(false);
    const [inputId] = useState(String(props.id ? props.id : Math.random()));
    const rootStyles = useMemo(
        () =>
            cx([
                styles.root,
                className,
                {
                    disabled,
                    full,
                    isLeftIcon: !!leftIcon || !!suffix,
                    isRightIcon: !!rightIcon || !!prefix,
                    isOnclickIcon: onClickIcon || onClickLeftIcon || onClickRightIcon,
                    error
                }
            ]),
        [full, leftIcon, rightIcon, onClickIcon, error, disabled, className, suffix, prefix]
    );

    const labelStyles = useMemo(() => cx([styles.label, { isActive: !!value }]), [value]);

    const handleClickIcon = () => {
        if (!readOnly && !disabled) {
            onClick && onClick();
            if (onClickIcon) {
                onClickIcon();
            }
        }
    };

    const unmaskValue = (value: string) => {
        let unmaskedValue = '';
        if (props.mask) {
            for (let i = 0; i < value.length; i++) {
                const isValueChar =
                    props.mask[i] === '9' || props.mask[i] === 'a' || props.mask[i] === '*';
                //@ts-ignore
                const isMaskChar = value[i] === props.maskChar;

                if (isValueChar && !isMaskChar) unmaskedValue += value[i];
            }
        }

        return unmaskedValue;
    };

    return (
        <div className={`${rootStyles} ${stringClassName}`} onBlur={onBlur} ref={wrapperRef}>
            {label && (
                <label htmlFor={inputId} className={labelStyles}>
                    {label}
                </label>
            )}
            <div className={styles.input_wrapper}>
                {(leftIcon || suffix) && (
                    <button
                        onClick={() => {
                            handleClickIcon();
                            onClickLeftIcon && onClickLeftIcon();
                        }}
                        disabled={disabled || readOnly}
                        className={styles.left_icon}>
                        {leftIcon && <Icon width={24} height={24} name={leftIcon} />}
                        {suffix}
                    </button>
                )}
                <InputMask
                    disabled={disabled || readOnly}
                    placeholder={placeholder}
                    type={type === 'password' ? (isOpenEye ? 'text' : 'password') : type}
                    value={value}
                    ref={ref}
                    id={inputId}
                    className={inputClassName}
                    onChange={(e: any) => {
                        if (props.mask && unmask) {
                            if (onChange) onChange(unmaskValue(e.target.value));
                        } else {
                            const value = onlyNumbers
                                ? e.target.value
                                      .replace(/[^0-9.]/g, '')
                                      .replace(/(\..*)\./g, '$1')
                                      .replace(/(\.\d{2})\d+/g, '$1')
                                : e.target.value;
                            if (onChange) onChange(value);
                        }
                    }}
                    readOnly={readOnly}
                    onClick={onClick}
                    onBlur={() => {
                        if (fixedAmount) {
                            const numberValue = parseFloat(value);
                            if (!isNaN(numberValue)) {
                                onChange && onChange(numberValue.toFixed(2));
                            }
                        }
                    }}
                    {...props}
                />
                {(rightIcon || prefix) && (!error || hideErrorIcon) && (
                    <button
                        className={styles.right_icon}
                        onClick={() => {
                            handleClickIcon();
                            onClickRightIcon && onClickRightIcon();
                        }}
                        disabled={disabled || readOnly}>
                        {rightIcon && <Icon width={24} height={24} name={rightIcon} />}
                        {prefix}
                    </button>
                )}
                {type === 'password' && (
                    <button
                        onClick={() => setIsOpenEye((state) => !state)}
                        style={{ cursor: 'pointer' }}
                        className={styles.right_icon}>
                        <Icon width={24} height={24} name={isOpenEye ? 'open_eye' : 'closed_eye'} />
                    </button>
                )}
                {error && type !== 'password' && !hideErrorIcon && (
                    <Icon
                        width={24}
                        height={24}
                        name="error_filled"
                        className={styles.right_icon}
                    />
                )}
            </div>
            {helperText && (
                <Typography variant="body3" className={styles.helper_text}>
                    {error && <Icon name="error_triangle" width={16} height={16} />}
                    {helperText}
                </Typography>
            )}
            {type === 'password' &&
                passwordErrors &&
                passwordErrors.find((item) => !item.isValid) && (
                    <div>
                        {passwordErrors.map((item, i) => {
                            return (
                                <Typography
                                    key={i}
                                    variant="body3"
                                    className={cx([
                                        styles.validation_error,
                                        { isValid: item.isValid }
                                    ])}>
                                    <Icon
                                        name={item.isValid ? 'check_outlined' : 'error_outlined'}
                                    />{' '}
                                    {item.message}
                                </Typography>
                            );
                        })}
                    </div>
                )}
        </div>
    );
});

Input.displayName = 'Input';

export default Input;
