import styles from './styles.module.scss';
import React, { useEffect, useState } from 'react';
import Contact from '@sections/send_and_request/manage_contacts/contact';
import ContactsTable from '@sections/send_and_request/manage_contacts/contacts_table';
import Typography from '@components/common/typography';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { TContact } from '@xeppt/xeppt-sdk/types';
import { apiContactService } from '@api';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { EContactViewType } from '@enum';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import Button from '@components/common/button';
import { useNotFound } from '@hooks/helpers/useNotFound';
import { routes } from '@const/routes';

const ManageContactsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'send_and_request.manage_contacts'
    });
    const [isAdd] = useQueryParams(['isAdd']);
    const [activeTab, setActiveTab] = useState(EContactViewType.CONTACTS);
    const [isEdit, setIsEdit] = useState(false);
    const [editableContact, setEditableContact] = useState<TContact>();

    const { data: contactList, handleRequest: refetchContacts } = useApiQuery({
        method: () => apiContactService.getContacts(),
        isInitialRequest: false
    });
    const { handleBack } = useNotFound();

    const { data: blockedContactList, handleRequest: refetchBlockedContacts } = useApiQuery({
        method: () => apiContactService.getBlockedContacts(),
        isInitialRequest: false
    });

    useEffect(() => {
        if (isAdd) {
            setIsEdit(true);
            setEditableContact(undefined);
        }
    }, [isAdd]);

    return (
        <motion.div {...pageAnimation} className={styles.main_wrapper}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <Button
                            className={styles.back}
                            leftIcon="arrow_left"
                            onClick={() => handleBack(routes.send_and_request)}>
                            Back
                        </Button>
                        <Typography variant="h4">{t('title')}</Typography>
                    </div>
                </div>
                {isEdit ? (
                    <Contact
                        handleBack={() => {
                            setIsEdit(false);
                            refetchContacts();
                            refetchBlockedContacts();
                        }}
                        isEdit={isEdit}
                        editableContact={editableContact}
                        contactList={contactList || []}
                    />
                ) : (
                    <ContactsTable
                        handleEdit={(data: TContact) => {
                            setEditableContact(data);
                            setIsEdit(true);
                        }}
                        activeTab={activeTab}
                        onChangeTab={(val) => setActiveTab(val)}
                        blockedContacts={blockedContactList || []}
                        refetchContacts={() => {
                            refetchContacts();
                            refetchBlockedContacts();
                        }}
                        contactList={contactList || []}
                        onAdd={() => {
                            setIsEdit(true);
                            setEditableContact(undefined);
                        }}
                    />
                )}
            </div>
        </motion.div>
    );
};

export default ManageContactsSection;
