import React, { FC, useMemo } from 'react';
import {
    capitalizeFirstLetter,
    getTagVariant,
    hideCardNumber,
    prettifyAmount,
    prettifyCardStatus
} from '@utils/index';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import Status from '../../common/status';
import Typography from '../../common/typography';
import { ECardType, TCardLite } from '@xeppt/xeppt-sdk/types/card';
import xepptCard from '@img/card_full.png';
import xepptCardMini from '@img/card_mini.png';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import moment from 'moment-timezone';

interface IProps {
    card: TCardLite;
    className?: string;
    isBalance?: boolean;
    showStatus?: boolean;
    isExpiry?: boolean;
    isMini?: boolean;
}

const cx = classNames.bind(styles);
const Card: FC<IProps> = ({
    card,
    className,
    isBalance,
    showStatus = true,
    isExpiry = true,
    isMini
}) => {
    const navigate = useNavigate();
    const rootStyles = useMemo(() => cx([styles.wrapper, className]), [className]);

    return (
        <div
            className={rootStyles}
            style={{ backgroundImage: `url(${isMini ? xepptCardMini : xepptCard})` }}
            onClick={() => navigate(`${routes.xeppt_cards}/${card.id}`)}>
            {showStatus && <div />}
            {showStatus ? (
                <Status className={styles.status} variant={getTagVariant(card.status)}>
                    {capitalizeFirstLetter(prettifyCardStatus(card.status))}
                </Status>
            ) : (
                <div />
            )}
            <div>
                {isBalance && (
                    <div className={styles.amount}>${prettifyAmount(card.balance.toFixed(2))}</div>
                )}
            </div>
            <div className={`${styles.content_wrapper} ${isMini ? styles.isMini : ''}`}>
                {!showStatus &&
                    (card.type === ECardType.PHYSICAL_SUPPLEMENTARY ||
                        card.type === ECardType.VIRTUAL_SUPPLEMENTARY) && (
                        <Typography className={styles.badge}>Supplementary</Typography>
                    )}
                <Typography className={styles.number}>{hideCardNumber(card.cardNumber)}</Typography>
                <Typography className={styles.card_holder}>{card.cardHolder}</Typography>
                <Typography className={styles.expiry}>
                    GOOD THRU<span>{moment(card.expiration).format('MM/YY')}</span>
                </Typography>
            </div>
        </div>
    );
};

export default Card;
