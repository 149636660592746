import React, { FC, useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import TableTag from '@components/table_tag';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import {
    ETransactionAction,
    ETransactionType,
    TTransactionsFilter
} from '@xeppt/xeppt-sdk/types/transaction';
import {
    transactionsActionsFilterArray,
    transactionsMethodsFilterArray
} from '@const/transactions';
import { getDisableFeature } from '@utils/index';
import RangeDatePicker from '@components/range_date_picker';
import { ETransactionMethod } from '@xeppt/xeppt-sdk/types';

interface IProps {
    toggleFilter: () => void;
    onChangeFilter: (val: TTransactionsFilter) => void;
    activeTab: string;
}

const FilterSection: FC<IProps> = ({ toggleFilter, onChangeFilter, activeTab }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'transactions.filter'
    });
    const { labelLocale } = useLocales();
    const [dateFilter, setDateFilter] = useState('');
    const [dateFrom, setDateFrom] = useState<string | null>(null);
    const [dateTo, setDateTo] = useState<string | null>(null);
    const [typeFilter, setTypeFilter] = useState<ETransactionAction[]>([]);
    const [method, setMethod] = useState<ETransactionMethod[]>([]);
    const [activeDirection, setActionDirection] = useState<ETransactionType[]>([]);

    const handleChangeType = (value: ETransactionAction) => {
        if (typeFilter.includes(value)) {
            setTypeFilter((state) => state.filter((item) => item !== value));
        } else {
            setTypeFilter((state) => [...state, value]);
        }
    };

    const handleChangeMethod = (value: ETransactionMethod) => {
        if (method.includes(value)) {
            setMethod((state) => state.filter((item) => item !== value));
        } else {
            setMethod((state) => [...state, value]);
        }
    };

    const handleChangeDirection = (value: ETransactionType) => {
        if (activeDirection.includes(value)) {
            setActionDirection((state) => state.filter((item) => item !== value));
        } else {
            setActionDirection((state) => [...state, value]);
        }
    };

    useEffect(() => {
        if (dateTo && dateFrom) {
            onChangeFilter({
                action: typeFilter,
                dateTo: new Date(dateTo),
                dateFrom: new Date(dateFrom),
                method,
                type: activeDirection
            });
        } else if (typeFilter) {
            onChangeFilter({
                action: typeFilter,
                method,
                type: activeDirection
            });
        }
    }, [dateFilter, typeFilter, activeDirection, dateFrom, dateTo, method]);

    const transactionsActionsFilter = useMemo(() => {
        return transactionsActionsFilterArray(t);
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.title_wrapper}>
                <Typography variant="h4">Filters</Typography>
                <button onClick={toggleFilter}>
                    <Icon name="close" />
                </button>
            </div>
            <div className={styles.block_wrapper}>
                <RangeDatePicker
                    values={[dateFrom, dateTo]}
                    onChange={(val) => {
                        setDateFrom(val?.[0]);
                        setDateTo(val?.[1]);
                        setDateFilter('');
                    }}
                    maxDate={getDisableFeature()}
                    isDisableFeature
                    full
                    label={labelLocale('date_range')}
                />
            </div>
            <div className={styles.block_wrapper}>
                <Typography variant="h4">{t('type')}</Typography>
                <div className={styles.tags_wrapper}>
                    <TableTag
                        variant="outlined-dark"
                        isActive={activeDirection.includes(ETransactionType.DEBIT)}
                        onClick={() => handleChangeDirection(ETransactionType.DEBIT)}>
                        Incoming
                    </TableTag>
                    <TableTag
                        variant="outlined-dark"
                        isActive={activeDirection.includes(ETransactionType.CREDIT)}
                        onClick={() => handleChangeDirection(ETransactionType.CREDIT)}>
                        Outgoing
                    </TableTag>
                </div>
            </div>
            <div className={styles.block_wrapper}>
                <Typography variant="h4">Method</Typography>
                <div className={styles.tags_wrapper}>
                    <TableTag
                        variant="outlined-dark"
                        isActive={method.length === 0}
                        onClick={() => setMethod([])}>
                        {t('all')}
                    </TableTag>
                    {transactionsMethodsFilterArray(t).map((item) => {
                        return (
                            <TableTag
                                key={item.method}
                                variant="outlined-dark"
                                isActive={method.includes(item.method)}
                                onClick={() => handleChangeMethod(item.method)}>
                                {item.label}
                            </TableTag>
                        );
                    })}
                </div>
            </div>
            {activeTab === 'transactions' && (
                <div className={styles.block_wrapper}>
                    <Typography variant="h4">Action</Typography>
                    <div className={styles.tags_wrapper}>
                        <TableTag
                            variant="outlined-dark"
                            isActive={typeFilter.length === 0}
                            onClick={() => setTypeFilter([])}>
                            {t('all')}
                        </TableTag>
                        {transactionsActionsFilter.map((item) => {
                            return (
                                <TableTag
                                    key={item.action}
                                    variant="outlined-dark"
                                    isActive={typeFilter.includes(item.action)}
                                    onClick={() => handleChangeType(item.action)}>
                                    {item.label}
                                </TableTag>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterSection;
