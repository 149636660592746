import React, { FC } from 'react';
import Input from '@components/common/input';

interface IProps {
    onChange?: (val: string) => void;
    value?: string;
    placeholder?: string;
    full?: boolean;
    disabled?: boolean;
    error?: boolean;
    helperText?: string;
    label?: string;
    className?: string;
}

const SearchInput: FC<IProps> = ({ ...props }) => {
    return (
        <Input
            leftIcon="search"
            rightIcon={props.value ? 'close' : undefined}
            onClickRightIcon={() => props.onChange && props.onChange('')}
            {...props}
        />
    );
};

export default SearchInput;
