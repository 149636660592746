import React, { FC, useEffect, useState } from 'react';
import Typography from '@components/common/typography';
import styles from './styles.module.scss';
import { useLocales } from '@hooks/helpers/useLocales';
import Address from '@components/address';
import Input from '@components/common/input';
import Select from '@components/common/select';
import FormField from '@components/form_field';
import { useFormContext, useWatch } from 'react-hook-form';
import { ECardType, TCardLite } from '@xeppt/xeppt-sdk/types/card';
import { ECardStatus } from '@xeppt/xeppt-sdk/types';
import { capitalizeFirstLetter, hideCardNumber } from '@utils/index';
import DatePicker from '@components/date_picker';
import { emailRegex } from '@const/regex';
import Checkbox from '@components/common/checkbox';
import { occupationsList } from '@_mock/index';

const Cardholder = () => {
    const { labelLocale, validationLocale } = useLocales();
    const [isSameAddress, setIsSameAddress] = useState(false);
    const { getValues, setValue } = useFormContext();

    useEffect(() => {
        if (isSameAddress) {
            const address = getValues().shippingAddress;
            setValue('billingAddress', address);
        }
    }, [isSameAddress]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.content_block}>
                <p className={styles.title}>Personal info</p>
                <div className={styles.form}>
                    <div className={styles.row}>
                        <FormField
                            name="firstName"
                            renderComponent={(props) => (
                                <Input {...props} full label={labelLocale('first_name')} />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: validationLocale('first_name')
                                }
                            }}
                        />
                        <FormField
                            name="middleName"
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label="Middle name (optional)"
                                    placeholder="Enter your middle name"
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name="lastName"
                            renderComponent={(props) => (
                                <Input {...props} full label={labelLocale('last_name')} />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: validationLocale('last_name')
                                }
                            }}
                        />
                        <FormField
                            name="alias"
                            renderComponent={(props) => (
                                <Input
                                    placeholder="Enter your alias"
                                    {...props}
                                    full
                                    label="Alias (optional) "
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField<string | null>
                            name="birthDate"
                            renderComponent={(props) => (
                                <DatePicker
                                    isBirthday
                                    {...props}
                                    full
                                    label={labelLocale('birth_date')}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: validationLocale('birthDate')
                                }
                            }}
                        />
                        <FormField<string | number>
                            name="citizenship"
                            renderComponent={(props) => (
                                <Select
                                    items={[{ value: 'Canada', label: 'Canada' }]}
                                    {...props}
                                    full
                                    label="Citizenship"
                                    placeholder="Select your citizenship"
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.content_block}>
                <p className={styles.title}>Contact info</p>
                <div className={styles.form}>
                    <div className={styles.row}>
                        <FormField
                            name="phoneNumber"
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label="Phone number (home) (optional)"
                                    placeholder="Enter your phone number (home)"
                                    mask="+9 (999) 999-9999"
                                />
                            )}
                        />
                        <FormField
                            name="cellPhone"
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label="Cell phone number"
                                    placeholder="Enter your cell phone number"
                                    mask="+9 (999) 999-9999"
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: validationLocale('cell_phone')
                                }
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name="email"
                            renderComponent={(props) => (
                                <Input {...props} full label={labelLocale('email')} />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: validationLocale('email')
                                },
                                validate: (value: string) => {
                                    if (!value) {
                                        return undefined;
                                    }
                                    return emailRegex.test(value)
                                        ? undefined
                                        : validationLocale('email');
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.content_block}>
                <p className={styles.title}>Billing address</p>
                <div className={styles.form}>
                    <div className={styles.row} style={{ alignItems: 'center' }}>
                        <Checkbox
                            checked={isSameAddress}
                            onClick={() => setIsSameAddress(!isSameAddress)}
                        />
                        <p className={styles.checkbox_label}>
                            My billing address is the same as my shipping address
                        </p>
                    </div>
                    <Address label="billing_address" name="billingAddress" />
                </div>
            </div>
            <div className={styles.content_block}>
                <p className={styles.title}>Employment info</p>
                <div className={styles.form}>
                    <div className={styles.row}>
                        <FormField<string | number>
                            name="occupation"
                            renderComponent={(props) => (
                                <Select
                                    items={occupationsList.map((item) => ({
                                        value: capitalizeFirstLetter(item),
                                        label: capitalizeFirstLetter(item)
                                    }))}
                                    isAllowSearch
                                    {...props}
                                    full
                                    placeholder="Select your occupation"
                                    label="Occupation"
                                />
                            )}
                        />
                        <FormField
                            name="employerName"
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label="Employer name"
                                    placeholder="Enter your employer name"
                                />
                            )}
                        />
                    </div>
                    <div className={styles.row}>
                        <FormField
                            name="employerPhone"
                            renderComponent={(props) => (
                                <Input
                                    {...props}
                                    full
                                    label="Employer business telephone number"
                                    placeholder="Enter your employer business telep..."
                                    mask="+9 (999) 999-9999"
                                />
                            )}
                        />
                    </div>
                    <Address label="employer_address" name="employerAddress" />
                </div>
            </div>
        </div>
    );
};

export default Cardholder;
