import React, { FC, SVGAttributes } from 'react';

const Logout: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.0174 7.38948V6.45648C15.0174 4.42148 13.3674 2.77148 11.3324 2.77148H6.45744C4.42344 2.77148 2.77344 4.42148 2.77344 6.45648V17.5865C2.77344 19.6215 4.42344 21.2715 6.45744 21.2715H11.3424C13.3714 21.2715 15.0174 19.6265 15.0174 17.5975V16.6545"
                stroke="#B3261E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.8105 12.0215H9.76953"
                stroke="#B3261E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.8828 9.10645L21.8108 12.0214L18.8828 14.9374"
                stroke="#B3261E"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Logout;
