import React, { useEffect, useMemo } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import AccountsSection from '@sections/manage_accounts/accounts';
import Typography from '@components/common/typography';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useUserContext } from '@hooks/context/useUserContext';
import BankSection from '@sections/manage_accounts/bank';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import { useManageAccountModals } from '@hooks/modals/useManageAccountModals';
import CardsSection from '@sections/manage_accounts/cards';
import CardSection from '@sections/manage_accounts/card';
import { Icon } from '@components/icons';
import Status from '@components/common/status';
import classNames from 'classnames/bind';
import WalletSection from '@sections/manage_accounts/wallet';
import useResponsive from '@hooks/helpers/useResponsive';

const cx = classNames.bind(styles);

const ManageAccountsLayout = () => {
    const { t } = useTranslation('manage_accounts');
    const [id] = useQueryParams(['id']);
    const navigate = useNavigate();
    const { account } = useUserContext();
    const { isMobile } = useResponsive();

    const chosenAccount = useMemo(() => {
        return account?.bankAccounts?.find((item) => item.id === id);
    }, [id, account]);

    const chosenCard = useMemo(() => {
        return account?.epsCards?.find((item) => item.id === id);
    }, [id, account]);

    const { removeBankAccount, setPreferredAccount, setPreferredCard, removeEPSCard } =
        useManageAccountModals({
            id,
            chosenAccount,
            chosenCard
        });

    useEffect(() => {
        if (chosenAccount) {
            navigate(`${routes.manage_accounts}?id=${chosenAccount.id}`);
        }
    }, [chosenAccount, chosenCard]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.content}>
                    <div className={styles.actions_wrapper}>
                        <div
                            className={styles.action}
                            onClick={() => navigate(`${routes.link_card_bank}?type=bank`)}>
                            <Button
                                iconSize={32}
                                variant="icon-dark"
                                size="large"
                                leftIcon="link_bank"
                            />
                            {t('link_bank')}
                        </div>
                        <div
                            className={styles.action}
                            onClick={() => navigate(`${routes.link_card_bank}?type=card`)}>
                            <Button
                                iconSize={32}
                                variant="icon"
                                size="large"
                                leftIcon="link_card"
                            />
                            {t('link_card')}
                        </div>
                    </div>
                    <div
                        className={cx(styles.item, { isChecked: 'wallet' === id })}
                        onClick={() => navigate(`${routes.manage_accounts}?id=wallet`)}>
                        <div className={styles.data}>
                            <Icon name="filled_wallet" />
                            <div className={styles.text_wrapper}>
                                <Typography className={styles.title}>XEPPT Wallet</Typography>
                                <Typography className={styles.description}>
                                    ${account?.balance.toFixed(2)} available
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {account?.bankAccounts?.map((item) => {
                        return (
                            <div
                                className={cx(styles.item, { isChecked: item.id === id })}
                                key={item.id}
                                onClick={() => navigate(`${routes.manage_accounts}?id=${item.id}`)}>
                                <div className={styles.data}>
                                    <Icon name="filled_bank" />
                                    <div className={styles.text_wrapper}>
                                        <Typography className={styles.title}>
                                            {item.institutionName}
                                        </Typography>
                                        <Typography className={styles.description}>
                                            {item.accountName} {item.accountNumber}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {account?.epsCards?.map((item) => {
                        return (
                            <div
                                className={cx(styles.item, { isChecked: item.id === id })}
                                key={item.id}
                                onClick={() => navigate(`${routes.manage_accounts}?id=${item.id}`)}>
                                <div className={styles.data}>
                                    <Icon name="filled_card" />
                                    <div className={styles.text_wrapper}>
                                        <Typography className={styles.title}>
                                            {item.cardHolderName} ({item.cardBrand})
                                        </Typography>
                                        <Typography className={styles.description}>
                                            {item.cardNumber}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div
                className={cx(styles.right, {
                    isShown:
                        !isMobile || (isMobile && (id === 'wallet' || chosenCard || chosenAccount))
                })}>
                {(id === 'wallet' || chosenAccount || chosenCard) && (
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        onClick={() => navigate(routes.manage_accounts)}>
                        Back
                    </Button>
                )}
                {chosenAccount && <BankSection account={chosenAccount} />}
                {chosenCard && <CardSection card={chosenCard} />}
                {id === 'wallet' && <WalletSection />}
                {id !== 'wallet' && (chosenAccount || chosenCard) && (
                    <div className={styles.actions}>
                        <button
                            className={styles.action}
                            onClick={() => {
                                if (chosenAccount) {
                                    removeBankAccount();
                                } else {
                                    removeEPSCard();
                                }
                            }}>
                            <Icon name="trash" />
                            <Typography>Remove</Typography>
                        </button>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

export default ManageAccountsLayout;
