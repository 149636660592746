import React, { FC, SVGAttributes } from 'react';

const ClosedEye: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.7606 14.3667C9.1856 13.7927 8.8356 13.0127 8.8356 12.1377C8.8356 10.3847 10.2476 8.97168 11.9996 8.97168C12.8666 8.97168 13.6646 9.32268 14.2296 9.89668"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.1048 12.6987C14.8728 13.9887 13.8568 15.0067 12.5678 15.2407"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.6546 17.4722C5.0676 16.2262 3.7236 14.4062 2.7496 12.1372C3.7336 9.85823 5.0866 8.02823 6.6836 6.77223C8.2706 5.51623 10.1016 4.83423 11.9996 4.83423C13.9086 4.83423 15.7386 5.52623 17.3356 6.79123"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.4476 8.99072C20.1356 9.90472 20.7406 10.9597 21.2496 12.1367C19.2826 16.6937 15.8066 19.4387 11.9996 19.4387C11.1366 19.4387 10.2856 19.2987 9.46759 19.0257"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M19.887 4.24951L4.11301 20.0235"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ClosedEye;
