import React, { FC, HTMLAttributes, ReactNode, useMemo } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';

interface IProps {
    weight?: 'medium' | 'semibold' | 'bold';
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body1' | 'body2' | 'body3';
    children?: ReactNode;
    fz?: number;
    lh?: string;
    fw?: number;
    className?: string;
    style?: React.CSSProperties;
}

const cx = classNames.bind(styles);

const Typography: FC<IProps & HTMLAttributes<HTMLParagraphElement>> = ({
    variant = 'default',
    children,
    style,
    className,
    fz,
    lh,
    weight,
    fw,
    ...props
}) => {
    const rootStyles = useMemo(
        () => cx([styles.root, variant, weight, className]),
        [className, variant, weight]
    );

    return (
        <p
            className={rootStyles}
            style={{
                fontSize: fz,
                lineHeight: lh,
                fontWeight: fw,
                ...style
            }}
            {...props}>
            {children}
        </p>
    );
};

export default Typography;
