import React, { FC, useEffect, useState } from 'react';
import Typography from '@components/common/typography';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import useSignupContext from '@hooks/context/useSignupContext';
import VerificationCode from '@components/verification_code';
import { useFormContext, useWatch } from 'react-hook-form';
import FormField from '@components/form_field';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { apiRegistrationService } from '@api';
import { EUserType, TNewPhone, TVerifyPhone } from '@xeppt/xeppt-sdk/types';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import codeIllustration from '@svg/illustrations/auth/code.svg';

interface IProps {
    onBack: () => void;
}

const VerificationPhoneSection: FC<IProps> = ({ onBack }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'signup.verification_phone'
    });
    const { submitLocale } = useLocales();
    const { requestSuccessLocale, requestErrorLocale } = useLocales();
    const { nextStep, handleChangeIllustration } = useSignupContext();
    const { getValues, control, trigger } = useFormContext();
    const watchedPhoneCode = useWatch({ name: 'phoneCode', control });
    const watchedPhoneNumber = useWatch({ name: 'phoneNumber', control });
    const [resendTimer, setResendTimer] = useState(0);
    const [intervalId, setIntervalId] = useState<any>();
    const type = useWatch({ name: 'type', control });

    useEffect(() => {
        handleChangeIllustration(codeIllustration);
    }, []);

    useEffect(() => {
        if (resendTimer === 0) {
            clearInterval(intervalId);
        }
    }, [resendTimer]);

    const { handleRequest: handleVerify } = useApiMutation({
        method: (data: TVerifyPhone) => apiRegistrationService.verifyPhone(data),
        onSuccess: () => {
            nextStep();
            requestSuccessLocale('verify_phone');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleSentValidationCode } = useApiMutation({
        method: (data: TNewPhone) => apiRegistrationService.validatePhone(data),
        onSuccess: () => {
            requestSuccessLocale('send_security_code_phone');
            setResendTimer(60);
            setIntervalId(
                setInterval(() => {
                    setResendTimer((state) => state - 1);
                }, 1000)
            );
        },
        onError: requestErrorLocale
    });

    const onResend = () => {
        const { phoneCode, phoneNumber } = getValues();
        handleSentValidationCode({
            phoneCode,
            phoneNumber
        });
    };

    const onSubmit = () => {
        const { phoneNumber, phoneCode, verificationCode } = getValues();
        trigger().then((isValid) => {
            if (isValid) {
                handleVerify({
                    phoneNumber,
                    phoneCode,
                    code: verificationCode
                });
            }
        });
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.steps}>STEP 4/{type === EUserType.BUSINESS ? '11' : '7'}</div>
            <Typography className={styles.title} variant="h3">
                {t('title')}
            </Typography>
            <Typography className={styles.description} variant="body2">
                {t('code_sent')}{' '}
                <span>
                    {watchedPhoneCode.includes('+') ? watchedPhoneCode : `+${watchedPhoneCode}`}{' '}
                    {watchedPhoneNumber}
                </span>{' '}
            </Typography>
            <div className={styles.form}>
                <FormField
                    name="verificationCode"
                    renderComponent={(props) => <VerificationCode {...props} onSubmit={onSubmit} />}
                />
                {resendTimer > 0 ? (
                    <p className={styles.resend_text}>Resend the code in 60 sec </p>
                ) : (
                    <button className={styles.resend_button} onClick={onResend}>
                        Resend code
                    </button>
                )}
                <div className={styles.actions}>
                    <Button
                        className={styles.next}
                        onClick={onSubmit}
                        size="normal"
                        variant="primary">
                        {submitLocale('verify')}
                    </Button>
                    <Button
                        className={styles.back}
                        leftIcon="arrow_left"
                        onClick={() => {
                            onBack();
                        }}>
                        {submitLocale('back')}
                    </Button>
                </div>
            </div>
        </motion.div>
    );
};

export default VerificationPhoneSection;
