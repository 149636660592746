import {
    AccountService,
    AuthService,
    ContactService,
    NotificationService,
    RegistrationService,
    ScheduleService,
    UserService,
    WalletService,
    SupportService,
    XepptClient,
    DocumentService
} from '@xeppt/xeppt-sdk';
import { PayBillService } from '@xeppt/xeppt-sdk/services/paybill';

export const sdk = new XepptClient(
    {
        baseURL: process.env.REACT_APP_BACKEND_URL
    },
    () => {
        localStorage.removeItem('client-metadata');
    }
);

export const apiRegistrationService = new RegistrationService(sdk);
export const apiAuthService = new AuthService(sdk);
export const apiUserService = new UserService(sdk);
export const apiWalletService = new WalletService(sdk);
export const apiAccountService = new AccountService(sdk);
export const apiScheduleService = new ScheduleService(sdk);
export const apiPayBillService = new PayBillService(sdk);
export const apiNotificationService = new NotificationService(sdk);
export const apiContactService = new ContactService(sdk);
export const apiSupportService = new SupportService(sdk);
export const apiDocumentService = new DocumentService(sdk);
