import React, { FC, SVGAttributes } from 'react';

const Chat: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 12.018C24 5.69654 18.948 0 11.976 0C5.16 0 0 5.58838 0 11.982C0 19.3971 6.048 24 12 24C13.968 24 16.152 23.4712 17.904 22.4377C18.516 22.0651 19.032 21.7887 19.692 22.005L22.116 22.7261C22.728 22.9184 23.28 22.4377 23.1 21.7887L22.296 19.0966C22.164 18.7241 22.188 18.3275 22.38 18.015C23.412 16.1162 24 14.0371 24 12.018ZM17.5499 13.5678C18.3899 13.5678 19.0859 12.8708 19.0859 12.0295C19.0859 11.1762 18.4019 10.4912 17.5499 10.4912C16.6979 10.4912 16.0139 11.1762 16.0139 12.0295C16.0139 12.8708 16.6979 13.5558 17.5499 13.5678ZM8.02344 12.0315C8.02344 12.8727 7.33944 13.5698 6.48744 13.5698C5.63544 13.5698 4.95144 12.8727 4.95144 12.0315C4.95144 11.1782 5.63544 10.4932 6.48744 10.4932C7.33944 10.4932 8.02344 11.1782 8.02344 12.0315ZM13.5547 12.0196C13.5547 12.8729 12.8707 13.5579 12.0187 13.5699C11.1667 13.5699 10.4827 12.8729 10.4827 12.0316C10.4827 11.1783 11.1667 10.4933 12.0187 10.4933C12.8587 10.4813 13.5547 11.1783 13.5547 12.0196Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default Chat;
