import React, { FC, SVGAttributes } from 'react';

const Person: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9998 13.1111C13.8408 13.1111 15.3332 11.6187 15.3332 9.77779C15.3332 7.93684 13.8408 6.44446 11.9998 6.44446C10.1589 6.44446 8.6665 7.93684 8.6665 9.77779C8.6665 11.6187 10.1589 13.1111 11.9998 13.1111Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
            />
            <path
                d="M18.6668 19.4512C18.2735 18.27 17.4068 17.2256 16.2013 16.4812C14.9957 15.7367 13.5191 15.3334 12.0002 15.3334C10.4813 15.3334 9.0035 15.7367 7.79905 16.4812C6.59461 17.2256 5.72683 18.27 5.3335 19.4512"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
};

export default Person;
