import React, { FC, ReactNode, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './styles.module.scss';
import useClickOutside from '@hooks/helpers/useClickOutside';
import { Icon, TIconType } from '@components/icons';
import { createPortal } from 'react-dom';

interface IProps {
    className?: string;
    items: (
        | {
              onClick: () => void;
              label: ReactNode | string;
              icon?: TIconType;
              isRed?: boolean;
          }
        | undefined
        | false
    )[];
}

const cx = classNames.bind(styles);

const Dropdown: FC<IProps> = ({ className, items }) => {
    const [isOpen, setIsOpen] = useState(false);
    const triggerRef = useRef(null);
    const dropdownRef = useRef(null);

    const showDropdown = () => {
        if (!dropdownRef.current) return;
        //@ts-ignore
        const triggerRect = triggerRef.current?.getBoundingClientRect();

        // Calculate the position of the dropdown
        const top = triggerRect?.bottom + window.scrollY;
        const left = triggerRect?.left - 200 + window.scrollX;
        // Position the dropdown
        console.log(triggerRect?.left, window.scrollX);
        //@ts-ignore
        dropdownRef.current.style.top = `${top}px`;
        //@ts-ignore
        dropdownRef.current.style.position = `absolute`;
        //@ts-ignore
        dropdownRef.current.style.display = `flex`;
        //@ts-ignore
        dropdownRef.current.style.left = `${left + 30}px`;
        setIsOpen(true);
    };

    const hideDropdown = () => {
        //@ts-ignore
        dropdownRef.current.style.display = `none`;
        setIsOpen(false);
    };

    useClickOutside({
        ref: dropdownRef,
        callback: hideDropdown,
        event: 'mousedown'
    });

    return (
        <div className={cx([styles.wrapper, className])}>
            <div ref={triggerRef}>
                <Icon name="more_vertical" onClick={() => !isOpen && showDropdown()} />
            </div>
            {createPortal(
                <div className={`dropdown_dropdown isOpen ${className}`} ref={dropdownRef}>
                    {items.map((item, i) => {
                        if (!item) return null;
                        return (
                            <button
                                key={i}
                                className={item?.isRed ? 'red' : ''}
                                onClick={() => {
                                    item.onClick();
                                    setIsOpen(false);
                                }}>
                                {item.icon && <Icon name={item.icon} />}
                                {item.label}
                            </button>
                        );
                    })}
                </div>,
                document.body
            )}
        </div>
    );
};

export default Dropdown;
