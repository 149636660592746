import React from 'react';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import { Icon } from '@components/icons';
import { useUserContext } from '@hooks/context/useUserContext';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import { useVerificationGuard } from '@hooks/helpers/useVerificationGuard';
import Skeleton from '@components/common/skeleton';
import { useAccountsModals } from '@hooks/modals/useAccountsModals';
import Cashback from '@sections/dashboard/cashback';

const AccountsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.accounts'
    });
    const navigate = useNavigate();
    const { account, isDataLoading } = useUserContext();
    const { handleOpenLinkCardOrBankModal } = useAccountsModals();
    const { verifiedAction } = useVerificationGuard();

    const handleOpenModal = () => {
        if (!!account?.bankAccounts.length) {
            verifiedAction(() => navigate(routes.link_card_bank));
        } else {
            verifiedAction(handleOpenLinkCardOrBankModal);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.accounts}>
                <Typography className={styles.title} variant="body1" weight="bold">
                    {t('title')}
                </Typography>
                {isDataLoading && <Skeleton className={styles.account_skeleton} />}
                {!!account?.bankAccounts.length && (
                    <ul className={styles.accounts_list}>
                        {account?.bankAccounts.map((item) => (
                            <li
                                key={item.id}
                                className={styles.account_item}
                                onClick={() => navigate(`${routes.manage_accounts}?id=${item.id}`)}>
                                <Icon name="filled_bank" />
                                <div className={styles.text_wrapper}>
                                    <Typography className={styles.title} variant="body3">
                                        {item.institutionName}
                                    </Typography>
                                    <Typography
                                        className={styles.description}
                                        variant="body3"
                                        fz={14}>
                                        {item.accountName} {item.accountNumber}
                                    </Typography>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                {!!account?.epsCards.length && (
                    <ul className={styles.accounts_list}>
                        {account?.epsCards.map((item) => (
                            <li
                                key={item.id}
                                className={styles.account_item}
                                onClick={() => navigate(`${routes.manage_accounts}?id=${item.id}`)}>
                                <Icon name="filled_card" />
                                <div className={styles.text_wrapper}>
                                    <Typography variant="body3">{item.cardHolderName}</Typography>
                                    <Typography variant="body3" fz={14}>
                                        {item.cardBrand} {item.cardNumber}
                                    </Typography>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                <Button className={styles.add_card} onClick={handleOpenModal}>
                    <div>
                        <Icon name="plus" />
                    </div>
                    <Typography variant="h5">{t('link_new')}</Typography>
                </Button>
            </div>
            <Cashback />
        </div>
    );
};

export default AccountsSection;
