import React, { FC, useEffect, useMemo, useState } from 'react';
import ProgressBar from '@components/progress_bar';
import { useProgressBar } from '@hooks/helpers/useProgressBar';
import FirstStepLinkCard from '@sections/link_card_bank/link_card/step1';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import SecondStepLinkCard from '@sections/link_card_bank/link_card/step2';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useForm } from '@hooks/helpers/useForm';
import { defaultLinkCard } from '@const/default_form_data';
import { FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { linkCardValidation } from '@helpers/validation_objects/link_card_or_bank';
import { useLocales } from '@hooks/helpers/useLocales';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import Skeleton from '@components/common/skeleton';
import { Icon } from '@components/icons';
import { useNotificationsContext } from '@hooks/context/useNotificationsContext';
import { EEventName } from '@xeppt/xeppt-sdk/types/notification';

const steps = (localization: (val: string) => string) => [
    {
        title: localization('card_details'),
        id: '1'
    },
    {
        title: localization('complete'),
        id: '2'
    }
];

interface IProps {
    handleChoseOtherType: () => void;
}

const LinkCardSection: FC<IProps> = ({ handleChoseOtherType }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_card'
    });
    const [isDone, setIsDone] = useState(false);
    const navigate = useNavigate();
    const stepsMemo = useMemo(() => [...steps(t), undefined], [steps]);
    const { currentStep, availableStep, nextStep } = useProgressBar(stepsMemo);
    const { validationLocale, submitLocale } = useLocales();
    const { lastNotificationType } = useNotificationsContext();
    const formMethods = useForm({
        defaultValues: defaultLinkCard,
        resolver: zodResolver(
            linkCardValidation({
                messages: {
                    firstName: validationLocale('firstName'),
                    lastName: validationLocale('lastName'),
                    cardNumber: validationLocale('card_number'),
                    code: validationLocale('security_code'),
                    expire: validationLocale('expire')
                }
            })
        )
    });

    useEffect(() => {
        if (lastNotificationType === EEventName.EpsCardCreated) {
            setIsDone(true);
        }
    }, [lastNotificationType]);

    const { data: iframeLink } = useApiQuery({
        method: () => apiAccountService.getEPSCardIFrameLink(),
        isInitialRequest: false
    });

    const renderStep = () => {
        switch (currentStep?.id) {
            case '1':
                return <FirstStepLinkCard handleChoseOtherType={handleChoseOtherType} />;
            case '2':
                return <SecondStepLinkCard />;
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            {/*<FormProvider {...formMethods}>*/}
            {/*    <div className={styles.content}>{renderStep()}</div>*/}
            {/*    <Button*/}
            {/*        variant="primary"*/}
            {/*        size="normal"*/}
            {/*        onClick={() => {*/}
            {/*            if (currentStep?.id === '1') {*/}
            {/*                nextStep();*/}
            {/*                setIsDone(true);*/}
            {/*            } else {*/}
            {/*                navigate(routes.dashboard);*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        disabled={!formMethods.formState.isValid}>*/}
            {/*        {isDone ? submitLocale('done') : submitLocale('next')}*/}
            {/*    </Button>*/}
            {/*</FormProvider>*/}
            <Skeleton className={styles.skeleton} />
            {iframeLink && (
                <iframe
                    className={`${styles.iframe} iframe_card`}
                    src={`${iframeLink}&test=true`}
                />
            )}
            {isDone && (
                <Button
                    className={styles.success_button}
                    variant="primary"
                    size="normal"
                    onClick={() => navigate(routes.dashboard)}>
                    Done
                </Button>
            )}
            <button className={styles.back} onClick={handleChoseOtherType}>
                <Icon name="arrow_left" />
                <p>{t('link_bank')}</p>
            </button>
        </motion.div>
    );
};

export default LinkCardSection;
