import React, { FC, useEffect, useMemo, useState } from 'react';
import Typography from '@components/common/typography';
import moment from 'moment/moment';
import styles from './styles.module.scss';
import classNames from 'classnames/bind';
import { currencies } from '@const/currency';
import { enumTranslate, ETransactionStatusTranslate } from '@locales/index';
import { useTranslation } from 'react-i18next';
import { Icon } from '@components/icons';
import {
    ETransactionAction,
    ETransactionMethod,
    ETransactionStatus,
    TTransaction,
    TTransactionLite
} from '@xeppt/xeppt-sdk/types';
import { getTransactionAction, getTransactionStatus, prettifyAmount } from '@utils/index';
import Status from '@components/common/status';
import { ETransactionType } from '@xeppt/xeppt-sdk/types/transaction';
import { modalIds } from '@const/modals';
import useModalContext from '@hooks/context/useModalContext';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';

interface IProps {
    transaction: TTransactionLite;
    refetchTransactions?: () => void;
}

const cx = classNames.bind(styles);
const TransactionItem: FC<IProps> = ({ transaction, refetchTransactions }) => {
    const { t, i18n } = useTranslation('components', {
        keyPrefix: 'transaction_tables.transaction_item'
    });
    const { changeModalData, onOpen, isOpen } = useModalContext();
    const [chosenTransaction, setChosenTransaction] = useState('');

    const isMoneyOutTransaction = useMemo(
        () => transaction.type === ETransactionType.CREDIT,
        [transaction]
    );

    const amountStyles = useMemo(
        () =>
            cx([
                styles.amount,
                {
                    plus: !isMoneyOutTransaction,
                    isCancel:
                        transaction.status === ETransactionStatus.CANCELED ||
                        transaction.status === ETransactionStatus.DECLINED
                }
            ]),
        [transaction]
    );

    const iconStyles = useMemo(
        () =>
            cx([
                styles.icon,
                {
                    isWallet: !isMoneyOutTransaction
                }
            ]),
        [transaction]
    );

    const isTransactionRequest = useMemo(
        () => transaction?.action === ETransactionAction.REQUEST,
        [transaction]
    );

    const isLoadBalance = useMemo(
        () =>
            (transaction.method === ETransactionMethod.EFT ||
                transaction.method === ETransactionMethod.EPS) &&
            transaction.type === ETransactionType.DEBIT,
        [transaction]
    );

    const getTransactionDescription = useMemo(() => {
        return (
            (isLoadBalance
                ? t('load_balance')
                : isTransactionRequest
                  ? t('request_money')
                  : getTransactionAction(
                        transaction.action,
                        transaction.method,
                        transaction.type,
                        t
                    )) +
            ` ${isMoneyOutTransaction ? t('to') : t('from')} ${
                enumTranslate[transaction.method][i18n.language as 'en' | 'fr']
            }`
        );
    }, [transaction]);

    const { handleRequest: fetchTransaction } = useApiQuery({
        method: () => apiAccountService.getTransactionById(chosenTransaction || ''),
        isInitialRequest: false,
        condition: !!chosenTransaction,
        onSuccess: (data) => {
            handleViewTransaction(data);
        }
    });

    useEffect(() => {
        if (chosenTransaction) {
            fetchTransaction();
        }
    }, [chosenTransaction]);

    useEffect(() => {
        if (!isOpen) {
            setChosenTransaction('');
        }
    }, [isOpen]);

    const handleViewTransaction = (transaction: TTransaction) => {
        changeModalData({ transaction, refetchTransactions });
        onOpen({
            modalId: modalIds.VIEW_TRANSACTION
        });
    };

    return (
        <button
            className={styles.table_item}
            onClick={() => {
                setChosenTransaction(transaction.id);
            }}>
            <div className={styles.left}>
                <div className={iconStyles}>
                    {transaction.automatic ? (
                        <Icon
                            className={styles.recurring_icon}
                            name="sync"
                            width={38}
                            height={38}
                        />
                    ) : isMoneyOutTransaction ? (
                        <Icon name="arrow_up" width={24} height={24} />
                    ) : (
                        <Icon name="arrow_down" width={24} height={24} />
                    )}
                </div>
                <div className={styles.text_wrapper}>
                    <Typography className={styles.title} variant="body1" weight="semibold">
                        {!isMoneyOutTransaction ? transaction?.source : transaction?.destination}
                    </Typography>
                    <Typography className={styles.description} variant="body3">
                        {getTransactionDescription} <span>●</span> <br />{' '}
                        <i>{moment(transaction.createdAt).format('DD MMMM YYYY')}</i>
                    </Typography>
                </div>
            </div>
            <div className={styles.right}>
                {transaction.status !== ETransactionStatus.APPROVED && (
                    <Status
                        variant={getTransactionStatus(transaction?.status)}
                        className={styles.status}>
                        {transaction?.status &&
                            ETransactionStatusTranslate[transaction.status][
                                i18n.language as 'en' | 'fr'
                            ]}
                    </Status>
                )}
                <Typography variant="body1" weight="bold" className={amountStyles}>
                    {isMoneyOutTransaction && '-'}
                    {currencies[transaction.currency as 'CAD'].symbol}
                    {prettifyAmount(transaction.amount.toFixed(2))}
                </Typography>
            </div>
        </button>
    );
};

export default TransactionItem;
