import { modalIds } from '@const/modals';
import { apiWalletService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import useModalContext from '@hooks/context/useModalContext';
import { ECancelCardReason, TCard } from '@xeppt/xeppt-sdk/types';
import { useUserContext } from '@hooks/context/useUserContext';

interface IProps {
    refetchCard?: () => void;
    card?: TCard;
}

export const useXepptCardModals = ({ refetchCard, card }: IProps) => {
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const { onOpen, onClose, changeModalData, handleChangeLoading, modalData } = useModalContext();
    const { refetchAccount } = useUserContext();

    const handleActivateCard = () => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: (data: { secureId: string; code: string }) => {
                // onOpen({
                //     modalId: modalIds.ACTIVATE_CARD,
                //     onSubmit: (data) => {
                handleChangeLoading(true);
                apiWalletService
                    .activateCard(card?.id || '', {
                        id: data?.secureId,
                        code: data?.code
                    })
                    .then(() => {
                        refetchCard && refetchCard();
                        onClose();
                        requestSuccessLocale('activate_card');
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
                //     }
                // })}
            }
        });
    };

    const handleOpeCardDetails = () => {
        // onOpen({
        //     modalId: modalIds.CARD_DETAILS_ENTER_PASSWORD,
        //     onSubmit: () => {
        //         changeModalData(card)
        //         onOpen({
        //             modalId: modalIds.CARD_DETAILS,
        //             onSubmit: onClose
        //         })
        //     }
        // });
        changeModalData(card);
        onOpen({
            modalId: modalIds.CARD_DETAILS,
            onSubmit: onClose
        });
    };

    const handleOpenLockCard = () => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: () =>
                onOpen({
                    modalId: modalIds.LOCK_CARD,
                    onSubmit: () => onClose
                })
        });
    };

    const handleOpenUnlockCard = () => {
        onOpen({
            modalId: modalIds.USER_VERIFICATION_PHONE,
            onSubmit: () =>
                onOpen({
                    modalId: modalIds.UNLOCK_CARD,
                    onSubmit: () => onClose
                })
        });
    };

    const handleOpenAddMoneyModal = () => {
        changeModalData(card);
        onOpen({
            modalId: modalIds.LOAD_CARD_BALANCE,
            onSubmit: (data: { amount: string }) => {
                handleChangeLoading(true);
                apiWalletService
                    .loadCardBalance(card?.id || '', Number(data?.amount))
                    .then(() => {
                        onClose();
                        requestSuccessLocale('load_card_balance');
                        refetchCard && refetchCard();
                        refetchAccount();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleOpenManageModal = () => {
        changeModalData({ ...card, refetchCard });
        onOpen({
            modalId: modalIds.MANAGE_CREDIT_CARD
        });
    };

    const handleOpenCardAgreementModal = () => {
        changeModalData({ ...card });
        onOpen({
            modalId: modalIds.CARD_AGREEMENT_MODAL
        });
    };

    const handleOpenUpdatePinModal = () => {
        onOpen({
            modalId: modalIds.PIN_VERIFICATION_PHONE,
            onSubmit: () =>
                onOpen({
                    modalId: modalIds.UPDATE_PIN,
                    onSubmit: (data: { id: string; code: string; pin: string }) => {
                        handleChangeLoading(true);
                        apiWalletService
                            .setCardPin({
                                cardId: data?.id,
                                code: data?.code,
                                pin: data?.pin
                            })
                            .then(() => {
                                modalData?.refetchCard && modalData.refetchCard();
                                onOpen({
                                    modalId: modalIds.SUCCESS_UPDATE_PIN,
                                    onSubmit: () =>
                                        onOpen({
                                            modalId: modalIds.MANAGE_CREDIT_CARD,
                                            onSubmit: onClose
                                        })
                                });
                            })
                            .catch(requestErrorLocale)
                            .finally(() => handleChangeLoading(false));
                    }
                })
        });
    };

    const handleOpenLimitsModal = () => {
        onOpen({
            modalId: modalIds.CARD_LIMITS,
            onSubmit: (data: {
                id: string;
                usageLimit: string;
                limitStartDate: Date;
                limitEndDate: Date;
            }) => {
                handleChangeLoading(true);
                apiWalletService
                    .updateCardLimit({
                        cardId: data?.id,
                        limit: Number(data?.usageLimit),
                        limitEndDate: data.limitStartDate,
                        limitStartDate: data.limitEndDate
                    })
                    .then(() => {
                        requestSuccessLocale('update_limits');
                        onClose();
                        modalData?.refetchCard && modalData.refetchCard();
                    })
                    .catch(requestErrorLocale)
                    .finally(() => handleChangeLoading(false));
            }
        });
    };

    const handleOpenReplaceCardModal = () => {
        onOpen({
            modalId: modalIds.REPLACE_CARD_REASON,
            onSubmit: () =>
                onOpen({
                    modalId: modalIds.USER_VERIFICATION_PHONE,
                    onSubmit: () =>
                        onOpen({
                            modalId: modalIds.REPLACE_CARD_SUBMIT,
                            onSubmit: (data: {
                                secureId: string;
                                code: string;
                                id: string;
                                cardHolder: string;
                                reasonId: number;
                            }) => {
                                handleChangeLoading(true);
                                apiWalletService
                                    .replaceCard(
                                        {
                                            cardId: data?.id,
                                            cardHolder: data?.cardHolder,
                                            reasonId: data?.reasonId
                                        },
                                        {
                                            id: data?.secureId,
                                            code: data?.code
                                        }
                                    )
                                    .then(() => {
                                        modalData?.refetchCard && modalData.refetchCard();
                                        onOpen({
                                            modalId: modalIds.REPLACE_CARD_SUCCESS,
                                            onSubmit: () =>
                                                onOpen({
                                                    modalId: modalIds.MANAGE_CREDIT_CARD,
                                                    onSubmit: onClose
                                                })
                                        });
                                    })
                                    .catch(requestErrorLocale)
                                    .finally(() => handleChangeLoading(false));
                            }
                        })
                })
        });
    };

    const handleOpenCancelCardModal = () => {
        // onOpen({
        //     modalId: modalIds.CANCEL_CARD_PHONE_VERIFICATION,
        //     onSubmit: () =>
        onOpen({
            modalId: modalIds.CANCEL_CARD_REASON,
            onSubmit: () =>
                onOpen({
                    modalId: modalIds.USER_VERIFICATION_PHONE,
                    onSubmit: () =>
                        onOpen({
                            modalId: modalIds.CANCEL_CARD_SUBMIT,
                            onSubmit: (data: {
                                secureId: string;
                                code: string;
                                id: string;
                                reasonId: ECancelCardReason;
                            }) => {
                                handleChangeLoading(true);
                                apiWalletService
                                    .cancelCard(data?.id, data?.reasonId, {
                                        id: data?.secureId,
                                        code: data?.code
                                    })
                                    .then(() => {
                                        modalData?.refetchCard && modalData.refetchCard();
                                        onOpen({
                                            modalId: modalIds.CANCEL_CARD_SUCCESS,
                                            onSubmit: () =>
                                                onOpen({
                                                    modalId: modalIds.MANAGE_CREDIT_CARD,
                                                    onSubmit: onClose
                                                })
                                        });
                                    })
                                    .catch(requestErrorLocale)
                                    .finally(() => handleChangeLoading(false));
                            }
                        })
                })
        });
        // });
    };

    return {
        handleActivateCard,
        handleOpenUnlockCard,
        handleOpenLockCard,
        handleOpenCardAgreementModal,
        handleOpeCardDetails,
        handleOpenAddMoneyModal,
        handleOpenManageModal,
        handleOpenUpdatePinModal,
        handleOpenLimitsModal,
        handleOpenReplaceCardModal,
        handleOpenCancelCardModal
    };
};
