import React, { FC, SVGAttributes } from 'react';

const AddPayee: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.2785 1.30365C12.0475 1.21122 11.7856 1.23943 11.5795 1.37895C11.3734 1.51848 11.25 1.75114 11.25 2V7.25H7C5.88439 7.25 4.90993 7.52941 4.21967 8.21967C3.52941 8.90993 3.25 9.88439 3.25 11V21.25H2C1.58579 21.25 1.25 21.5858 1.25 22C1.25 22.4142 1.58579 22.75 2 22.75H22C22.4142 22.75 22.75 22.4142 22.75 22C22.75 21.5858 22.4142 21.25 22 21.25H20.75V15.75C20.75 15.3358 20.4142 15 20 15C19.5858 15 19.25 15.3358 19.25 15.75V21.25H16.74V15.75C16.74 15.3358 16.4042 15 15.99 15C15.5758 15 15.24 15.3358 15.24 15.75V21.25H12.74V12.75H14.25C14.6642 12.75 15 12.4142 15 12C15 11.5858 14.6642 11.25 14.25 11.25H4.75V11C4.75 10.1156 4.97059 9.59007 5.28033 9.28033C5.59007 8.97059 6.11561 8.75 7 8.75H14.25C14.6642 8.75 15 8.41422 15 8C15 7.58579 14.6642 7.25 14.25 7.25H12.75V6.02338C14.2477 6.3825 15.8357 6.2735 17.2785 5.69636C17.5633 5.58246 17.75 5.30668 17.75 5V2C17.75 1.75114 17.6266 1.51848 17.4205 1.37895C17.2144 1.23943 16.9525 1.21122 16.7215 1.30365C15.3003 1.87212 13.6997 1.87212 12.2785 1.30365ZM11.24 12.75V21.25H8.74V12.75H11.24ZM7.24 12.75V21.25H4.75V12.75H7.24ZM16.25 4.46952C15.1119 4.81683 13.8881 4.81683 12.75 4.46952V3.02338C13.8989 3.29888 15.1011 3.29888 16.25 3.02338V4.46952Z"
                fill="currentColor"
            />
            <path
                d="M19.043 9V13.01"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21.09 11.0039H17"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default AddPayee;
