import React, { FC, SVGAttributes } from 'react';

const FiledClose: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 12C24 5.37211 18.6266 0 12 0C5.37211 0 0 5.37211 0 12C0 18.6266 5.37211 24 12 24C18.6266 24 24 18.6266 24 12ZM8.16669 7.10603C7.8738 6.81314 7.39893 6.81314 7.10603 7.10603C6.81314 7.39893 6.81314 7.8738 7.10603 8.16669L10.9391 11.9998L7.11035 15.8277C6.81742 16.1206 6.81738 16.5954 7.11024 16.8884C7.4031 17.1813 7.87797 17.1813 8.1709 16.8885L11.9998 13.0604L15.8333 16.894C16.1262 17.1869 16.6011 17.1869 16.894 16.894C17.1869 16.6011 17.1869 16.1262 16.894 15.8333L13.0605 11.9999L16.8909 8.1703C17.1838 7.87744 17.1839 7.40256 16.891 7.10964C16.5981 6.81671 16.1233 6.81666 15.8303 7.10953L11.9999 10.9392L8.16669 7.10603Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default FiledClose;
