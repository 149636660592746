import { TAddress } from '@types';
import { z } from 'zod';
import { validator } from '@xeppt/xeppt-sdk';
import { ECardType } from '@xeppt/xeppt-sdk/types/card';

export interface IProps {
    currentStep: number;
    cardType?: ECardType;
    messages: {
        shippingAddress: TAddress;
        firstName: string;
        lastName: string;
        birthDate: string;
        occupation: string;
        employerName: string;
        employerPhone: string;
        employerAddress: TAddress;
        billingAddress: TAddress;
        cellPhone: string;
        citizenship: string;
        email: string;
    };
}

export const requestCardValidation = ({ currentStep, messages, cardType }: IProps) => {
    const steps = [
        z.object({
            ...(cardType === ECardType.PHYSICAL_SUPPLEMENTARY && { cardId: z.string() }),
            firstName: validator.firstName(messages.firstName),
            lastName: validator.firstName(messages.lastName)
        }),
        z.object({
            shippingAddress: validator.address(messages.shippingAddress)
        }),
        z.object({
            firstName: validator.firstName(messages.firstName),
            lastName: validator.firstName(messages.lastName),
            email: validator.email(messages.email),
            middleName: z.string().optional(),
            alias: z.string().optional(),
            birthDate: z.string().min(3, messages.birthDate),
            citizenship: z.string().min(3, messages.citizenship),
            phoneNumber: z.string().optional(),
            cellPhone: z.string().min(3, messages.cellPhone),
            billingAddress: validator.address(messages.billingAddress),
            occupation: z.string().min(3, messages.occupation),
            employerName: z.string().min(3, messages.employerName),
            employerPhone: z.string().min(3, messages.employerPhone),
            employerAddress: validator.address(messages.employerAddress)
        }),
        z.object({
            agree: z.boolean().refine((data) => data, {})
        })
    ];
    return steps[currentStep];
};
