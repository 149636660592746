import React from 'react';
import styles from './styles.module.scss';
import illustration from '@svg/illustrations/setup_interca.svg';
import InfoBlock from '@components/common/info_block';

const SuccessAutodepositModal = () => {
    return (
        <div className={styles.wrapper}>
            <img src={illustration} alt="success" />
            <div className={styles.title}>Almost done!</div>
            <div className={styles.description}>
                Please check your email and follow the instructions from Interac to finish the setup
            </div>
            <InfoBlock variant="warning">
                <div className={styles.info_title}>Your confirmation is mandatory</div>
                <div className={styles.info_description}>
                    You will have 24 hours to verify your setup request once you receive the
                    verification notification from Interac. Otherwise, your request will be
                    cancelled and you will need to start over.
                </div>
            </InfoBlock>
        </div>
    );
};

export default SuccessAutodepositModal;
