import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@components/common/button';
import styles from './styles.module.scss';
import TransactionTable from '@components/transactions_table';
import { useNavigate } from 'react-router-dom';
import { routes } from '@const/routes';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiAccountService } from '@api';
import { ENewNotificationType } from '@enum';
import Tabs from '@components/tabs';
import { actionsTransactions } from '@layouts/transactions';
import { ETransactionAction } from '@xeppt/xeppt-sdk/types';

const TransactionsSection = () => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'dashboard.transactions'
    });
    const [activeTab, setActiveTab] = useState('transactions');
    const navigate = useNavigate();

    const {
        data: transactions,
        isLoading: isTransactionsLoading,
        handleRequest: refetchTransactions
    } = useApiQuery({
        method: () =>
            apiAccountService.findTransactions(
                {
                    action:
                        activeTab === 'transactions'
                            ? actionsTransactions
                            : [ETransactionAction.REQUEST]
                },
                { cursor: undefined, limit: 3 }
            ),
        notificationType: ENewNotificationType.TRANSACTION,
        deps: [activeTab]
    });

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.table_header}>
                    <Tabs
                        onChange={(val) => setActiveTab(val)}
                        tabs={[
                            {
                                label: 'Transaction',
                                value: 'transactions'
                            },
                            {
                                label: 'Requests',
                                value: 'requests'
                            }
                        ]}
                        activeTab={activeTab}
                    />
                    <Button
                        className={styles.all}
                        rightIcon="arrow_right"
                        onClick={() => navigate(routes.transactions)}>
                        See all
                    </Button>
                </div>
                <TransactionTable
                    refetchTransactions={refetchTransactions}
                    isTransactionsLoading={isTransactionsLoading}
                    transactions={transactions?.data || []}
                />
            </div>
        </div>
    );
};

export default TransactionsSection;
