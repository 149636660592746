import React, { FC, SVGAttributes } from 'react';

const Settings: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.7146 7.1751L21.0267 5.98146C20.4448 4.97146 19.1552 4.62303 18.1438 5.20256V5.20256C17.6624 5.48616 17.0879 5.56663 16.5471 5.4262C16.0062 5.28578 15.5435 4.936 15.2608 4.454C15.079 4.14763 14.9813 3.79868 14.9776 3.44244V3.44244C14.994 2.87129 14.7785 2.31781 14.3803 1.90811C13.982 1.4984 13.4348 1.26734 12.8634 1.26758H11.4776C10.9178 1.26757 10.3811 1.49064 9.98622 1.88741C9.59134 2.28419 9.37085 2.82196 9.37354 3.38174V3.38174C9.35695 4.5375 8.41525 5.46568 7.25938 5.46556C6.90313 5.46186 6.55418 5.36416 6.24782 5.18232V5.18232C5.23643 4.6028 3.9468 4.95122 3.36486 5.96123L2.62642 7.1751C2.04519 8.18384 2.38888 9.47266 3.39521 10.0581V10.0581C4.04934 10.4357 4.45231 11.1337 4.45231 11.889C4.45231 12.6443 4.04934 13.3423 3.39521 13.7199V13.7199C2.39016 14.3014 2.0461 15.5871 2.62642 16.5927V16.5927L3.3244 17.7965C3.59706 18.2885 4.05454 18.6515 4.5956 18.8053C5.13666 18.9591 5.7167 18.8909 6.20735 18.6159V18.6159C6.6897 18.3344 7.26449 18.2573 7.80396 18.4017C8.34343 18.546 8.80288 18.8999 9.08019 19.3847C9.26202 19.691 9.35973 20.04 9.36343 20.3962V20.3962C9.36343 21.5638 10.31 22.5104 11.4776 22.5104H12.8634C14.0271 22.5104 14.972 21.57 14.9776 20.4063V20.4063C14.9749 19.8448 15.1968 19.3055 15.5938 18.9084C15.9909 18.5113 16.5302 18.2895 17.0918 18.2922C17.4472 18.3017 17.7947 18.399 18.1033 18.5754V18.5754C19.1121 19.1566 20.4009 18.813 20.9863 17.8066V17.8066L21.7146 16.5927C21.9965 16.1089 22.0739 15.5325 21.9296 14.9914C21.7853 14.4503 21.4312 13.9891 20.9458 13.7098V13.7098C20.4604 13.4305 20.1063 12.9693 19.962 12.4282C19.8177 11.887 19.8951 11.3107 20.177 10.8268C20.3604 10.5068 20.6257 10.2414 20.9458 10.0581V10.0581C21.9461 9.47298 22.289 8.19169 21.7146 7.18522V7.18522V7.1751Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="12.175"
                cy="11.8889"
                r="2.9133"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Settings;
