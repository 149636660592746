import React, { useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';
import ChooseTypeSection from '@sections/link_card_bank/choose_type';
import Button from '@components/common/button';
import LinkCardSection from '@sections/link_card_bank/link_card';
import LinkBankSection from '@sections/link_card_bank/link_bank';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useQueryParams } from '@hooks/helpers/useQueryParams';
import { useNotFound } from '@hooks/helpers/useNotFound';

const LinkCardBankLayout = () => {
    const [type, setType] = useState<'card' | 'bank'>();
    const { handleBack } = useNotFound();
    const [currentStep, setCurrentStep] = useState('1');
    const [loginId, accountId, linkedType] = useQueryParams(['loginId', 'accountId', 'type']);
    const { t } = useTranslation('sections', {
        keyPrefix: 'link_card_bank.link_bank'
    });

    useEffect(() => {
        if (loginId && accountId) {
            setType('bank');
        }
    }, [loginId, accountId]);

    useEffect(() => {
        if (linkedType) {
            setType(linkedType as 'card');
        }
    }, [linkedType]);

    const isCard = useMemo(() => type === 'card', [type]);

    return (
        <motion.div {...pageAnimation} className={styles.wrapper}>
            <div className={styles.header}>
                <Button
                    className={styles.back}
                    leftIcon="arrow_left"
                    onClick={() => {
                        if (type) {
                            if (currentStep === '2') {
                                setCurrentStep('1');
                            } else if (currentStep === '3') {
                                setCurrentStep('2');
                            } else {
                                setType(undefined);
                                setCurrentStep('1');
                            }
                        } else {
                            setCurrentStep('1');
                            handleBack();
                        }
                    }}>
                    Back
                </Button>
                <Typography variant="h4">
                    {!type ? t('title') : isCard ? t('link_card') : t('link_bank')}
                </Typography>
            </div>
            <div className={styles.content}>
                {!type && <ChooseTypeSection onChange={(value) => setType(value)} />}
                {type &&
                    (isCard ? (
                        <LinkCardSection
                            handleChoseOtherType={() => {
                                setType('bank');
                            }}
                        />
                    ) : (
                        <LinkBankSection
                            activeStep={currentStep}
                            onChangeStep={(val) => setCurrentStep(val)}
                            handleChoseOtherType={() => {
                                setType('card');
                            }}
                        />
                    ))}
            </div>
        </motion.div>
    );
};

export default LinkCardBankLayout;
