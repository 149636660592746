import React, { FC, SVGAttributes } from 'react';

const Delivery: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 14H6.395C6.689 14 6.979 14.066 7.242 14.194L9.284 15.182C9.547 15.309 9.837 15.375 10.132 15.375H11.174C12.182 15.375 13 16.166 13 17.142C13 17.182 12.973 17.216 12.934 17.227L10.393 17.93C9.93705 18.056 9.45087 18.0119 9.025 17.806L6.842 16.75"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13 16.5L17.593 15.089C17.9929 14.9663 18.4213 14.9731 18.817 15.1083C19.2128 15.2435 19.5558 15.5003 19.797 15.842C20.166 16.352 20.016 17.084 19.478 17.394L11.963 21.731C11.728 21.867 11.4678 21.9537 11.1982 21.9858C10.9287 22.018 10.6554 21.9949 10.395 21.918L4 20.02M13 5H15M15 12H13C11.114 12 10.172 12 9.586 11.414C9 10.828 9 9.886 9 8V6C9 4.114 9 3.172 9.586 2.586C10.172 2 11.114 2 13 2H15C16.886 2 17.828 2 18.414 2.586C19 3.172 19 4.114 19 6V8C19 9.886 19 10.828 18.414 11.414C17.828 12 16.886 12 15 12Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Delivery;
