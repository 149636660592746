import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Input from '@components/common/input';
import Button from '@components/common/button';
import { useTranslation } from 'react-i18next';
import { useLocales } from '@hooks/helpers/useLocales';
import { apiAccountService, apiPayBillService } from '@api';
import { motion } from 'framer-motion';
import { pageAnimation } from '@const/animation';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { TCreatePayee, TPayee, TSearchedPayees } from '@xeppt/xeppt-sdk/types';
import { useDebounceValue } from 'usehooks-ts';
import { highlightSearchText } from '@utils/index';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { Icon } from '@components/icons';

interface IProps {
    handleBack: () => void;
    chosenPayee?: string | null;
    payeesList: TPayee[];
}

const defaultPayee = {
    alias: '',
    accountNumber: '',
    name: '',
    code: ''
};

const Payee: FC<IProps> = ({ handleBack, chosenPayee, payeesList }) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.manage_payees'
    });
    const [data, setData] = useState(defaultPayee);
    const [search, setSearch] = useState('');
    const [requestSearch, setRequestSearch] = useDebounceValue('', 500);
    const [chosenSearch, setChosenSearch] = useState('');
    const {
        labelLocale,
        submitLocale,
        requestErrorLocale,
        requestSuccessLocale,
        validationLocale
    } = useLocales();
    const [isError, setIsError] = useState(false);

    const { data: chosenPayeeData } = useApiQuery({
        method: () => apiPayBillService.getPayee(chosenPayee as string),
        condition: !!chosenPayee,
        deps: [chosenPayee]
    });

    useEffect(() => {
        if (chosenPayeeData) {
            setData({
                accountNumber: chosenPayeeData.accountNumber,
                alias: chosenPayeeData.alias || '',
                code: chosenPayeeData.code,
                name: chosenPayeeData.name
            });
            setChosenSearch(chosenPayeeData.name);
        }
    }, [chosenPayeeData]);

    const { handleRequest: handleUpdatePayee, isLoading: isUpdatePayeeLoading } = useApiMutation({
        method: () => {
            return apiPayBillService
                .updatePayee(chosenPayee as string, data as TCreatePayee)
                .then()
                .catch();
        },
        onSuccess: () => {
            handleBack();
            requestSuccessLocale('update_payee');
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleCreatePayee, isLoading: isCreatePayeeLoading } = useApiMutation({
        method: () => {
            return apiPayBillService.createPayee({
                ...data,
                ...(payeesList.find((item) => item.code === chosenPayee) as TSearchedPayees)
            });
        },
        onSuccess: () => {
            setData(defaultPayee);
            handleBack();
            requestSuccessLocale('create_payee');
        },
        onError: requestErrorLocale
    });

    const { data: searchedPayees, handleRequest: handleSearchPayee } = useApiMutation({
        method: () => apiPayBillService.searchPayees(search || '')
    });

    useEffect(() => {
        setRequestSearch(search);
    }, [search]);

    useEffect(() => {
        if (requestSearch.length >= 3) {
            handleSearchPayee(undefined);
        }
    }, [requestSearch]);

    const handleAddPayee = () => {
        if (data.accountNumber) {
            setIsError(false);
            if (chosenPayee && data.accountNumber) {
                handleUpdatePayee(undefined);
            }
            if (!chosenPayee) {
                handleCreatePayee(undefined);
            }
        } else {
            setIsError(true);
        }
    };

    return (
        <motion.div {...pageAnimation} className={styles.add_content}>
            <div className={styles.search_wrapper}>
                {!chosenPayee && (
                    <p className={styles.description}>
                        To add a new payee, please find it in the payees list and fill out the
                        details
                    </p>
                )}
                {!chosenPayee && (
                    <Input
                        full
                        leftIcon="search"
                        value={search || chosenSearch}
                        onChange={(val) => setSearch(val)}
                        placeholder="Search for payee name..."
                    />
                )}
                {chosenPayee && (
                    <div className={styles.chosen_payee}>
                        <div>{data.name}</div>
                        <Icon name="check" />
                    </div>
                )}
                {search && (
                    <div className={styles.results_wrapper}>
                        {searchedPayees && searchedPayees.length > 0 && (
                            <div className={styles.result}>
                                {searchedPayees?.map((item) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setData((state) => ({ ...state, ...item }));
                                                setChosenSearch(item.name);
                                            }}
                                            className={styles.title}
                                            key={item.code}
                                            dangerouslySetInnerHTML={{
                                                __html: highlightSearchText(item.name, search)
                                            }}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        <div className={styles.result}>
                            <div className={styles.result_length}>Existing payees</div>
                            {payeesList
                                .filter((item) =>
                                    item.name.toLowerCase().includes(search.toLowerCase())
                                )
                                .map((item) => {
                                    return (
                                        <div
                                            className={styles.title}
                                            key={item.code}
                                            dangerouslySetInnerHTML={{
                                                __html: highlightSearchText(item.name, search)
                                            }}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                )}
            </div>
            {search.length === 0 && (
                <>
                    <Input
                        full
                        label={labelLocale('account_number')}
                        helperText={
                            isError ? validationLocale('account_number') : t('help_account')
                        }
                        onlyNumbers
                        placeholder="Enter the account number"
                        value={data.accountNumber}
                        className={styles.data_input}
                        onChange={(val) => setData((state) => ({ ...state, accountNumber: val }))}
                        error={isError}
                    />
                    <Input
                        full
                        label={t('name_label')}
                        helperText={t('helper_name')}
                        placeholder="Enter payee nickname"
                        value={data.alias}
                        className={styles.data_input}
                        onChange={(val) => setData((state) => ({ ...state, alias: val }))}
                    />
                    <Button
                        variant="primary"
                        size="normal"
                        onClick={handleAddPayee}
                        className={styles.submit}
                        isLoading={isCreatePayeeLoading || isUpdatePayeeLoading}>
                        {submitLocale(chosenPayee ? 'save_changes' : 'add_payee')}
                    </Button>
                </>
            )}
        </motion.div>
    );
};

export default Payee;
