import React, { FC, SVGAttributes } from 'react';

const Filter: FC<SVGAttributes<SVGElement>> = (props) => {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.21736 5C5.54528 5 5 5.55445 5 6.23698V6.96501C5 7.47059 5.19226 7.95679 5.53617 8.32249L9.30508 12.33L9.30674 12.3275C10.0343 13.0724 10.4437 14.0793 10.4437 15.1292V18.6851C10.4437 18.9228 10.6923 19.0744 10.8987 18.9624L13.045 17.7928C13.369 17.6158 13.5712 17.2721 13.5712 16.8988V15.12C13.5712 14.076 13.9748 13.0732 14.6958 12.33L18.4647 8.32249C18.8077 7.95679 19 7.47059 19 6.96501V6.23698C19 5.55445 18.4555 5 17.7835 5H6.21736Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Filter;
