import React, { useRef, useState, useEffect, FC } from 'react';
import styles from './styles.module.scss';
import Typography from '@components/common/typography';

interface IProps {
    onChange: (value: string) => void;
    error?: boolean;
    errorText?: string;
    value?: string;
    onSubmit?: () => void;
}

const VerificationCode: FC<IProps> = ({ onChange, error, errorText, value, onSubmit }) => {
    const [code, setCode] = useState(value || '');

    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    useEffect(() => {
        if (onChange) onChange(code);
        if (code.length === 6 && onSubmit) {
            onSubmit();
        }
    }, [code]);

    function handleInput(e: any, index: number) {
        const input = e.target;
        const previousInput = inputRefs[index - 1];
        const nextInput = inputRefs[index + 1];

        if (!/^\d+$/.test(input.value)) {
            const removedSymbol = code.split('');
            removedSymbol[index] = '';
            setCode(removedSymbol.join(''));
        }

        if (!isNaN(+input.value)) {
            const newCode = code.split('');

            newCode[index] = input.value;

            setCode(newCode.join(''));

            input.select();

            if (input.value === '') {
                if (previousInput) {
                    //@ts-ignore
                    previousInput.current.focus();
                }
            } else if (nextInput) {
                //@ts-ignore
                nextInput.current.select();
            }
        }
    }

    function handleFocus(e: any) {
        e.target.select();
    }

    function handleKeyDown(e: any, index: number) {
        const input = e.target;
        const previousInput = inputRefs[index - 1];

        if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
            e.preventDefault();
            setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
            if (previousInput) {
                //@ts-ignore
                previousInput.current.focus();
            }
        }
    }

    const handlePaste = (e: any) => {
        const pastedCode = e.clipboardData.getData('text');
        if (pastedCode.length === 6) {
            setCode(pastedCode);
            inputRefs.forEach((inputRef, index) => {
                //@ts-ignore
                inputRef.current.value = pastedCode.charAt(index);
            });
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {Array(6)
                    .fill('')
                    .map((_, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength={1}
                            onChange={(e: any) => {
                                if (!isNaN(+e.target.value)) {
                                    handleInput(e, index);
                                } else {
                                    //@ts-ignore
                                    inputRefs[index].current.value = '';
                                }
                            }}
                            ref={inputRefs[index]}
                            value={code[index]}
                            autoFocus={index === 0}
                            placeholder=" "
                            onFocus={handleFocus}
                            onKeyDown={(e: any) => handleKeyDown(e, index)}
                            onPaste={handlePaste}
                            className={error ? styles.error : ''}
                        />
                    ))}
            </div>
            {error && (
                <Typography variant="body3" className={styles.error_text}>
                    {errorText}
                </Typography>
            )}
        </div>
    );
};

export default VerificationCode;
