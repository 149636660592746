import React, { FC } from 'react';
import styles from './styles.module.scss';
import Button from '@components/common/button';
import Table from '@components/table';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { apiScheduleService } from '@api';
import { useLocales } from '@hooks/helpers/useLocales';
import { getBillingStatusList, getFrequencyList } from '@utils/billing';
import { pageAnimation } from '@const/animation';
import { motion } from 'framer-motion';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { formatDateForMoment } from '@utils/date';
import { EScheduleFrequency, EScheduleStatus, TSchedule } from '@xeppt/xeppt-sdk/types/schedule';
import Dropdown from '@components/common/dropdown';
import emptyIllustration from '@svg/illustrations/empty_schedule_bill.svg';
import { routes } from '@const/routes';
import { useNavigate } from 'react-router-dom';
import { modalIds } from '@const/modals';
import useModalContext from '@hooks/context/useModalContext';

interface IProps {
    onEdit: (data: TSchedule) => void;
    refetchPaymentsList: () => void;
    paymentsList: TSchedule[];
    filter: { paymentFrequency?: EScheduleFrequency[]; status?: EScheduleStatus[] };
    onFilterChange: (val: {
        paymentFrequency?: EScheduleFrequency[];
        status?: EScheduleStatus[];
    }) => void;
}

const PaymentsTable: FC<IProps> = ({
    paymentsList,
    refetchPaymentsList,
    onEdit,
    filter,
    onFilterChange
}) => {
    const { t } = useTranslation('sections', {
        keyPrefix: 'pay_bills.schedule_payments.table'
    });
    const { t: frequencyLocale } = useTranslation('sections', {
        keyPrefix: 'pay_bills.pay_bills.payment_details'
    });
    const { onOpen, changeModalData, onClose } = useModalContext();
    const { requestErrorLocale, requestSuccessLocale } = useLocales();
    const navigate = useNavigate();

    const { handleRequest: handleCancelPayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.deleteSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('cancel_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleDeactivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.deactivateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('pause_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const { handleRequest: handleActivatePayment } = useApiMutation({
        method: (id: string) => {
            return apiScheduleService.activateSchedule(id);
        },
        onSuccess: () => {
            requestSuccessLocale('activate_schedule');
            refetchPaymentsList();
        },
        onError: requestErrorLocale
    });

    const handlePauseResumePayment = (id: string, status: EScheduleStatus) => {
        if (status === EScheduleStatus.ACTIVE) {
            handleDeactivatePayment(id);
        } else {
            handleActivatePayment(id);
        }
    };

    const columns = [
        {
            label: t('payee'),
            key: 'payeeName',
            render: (_: string, data: TSchedule) => (
                <div style={{ width: '100%' }}>{data.info}</div>
            ),
            width: 250
        },
        {
            label: t('frequency'),
            key: 'paymentFrequency',
            render: (value: EScheduleFrequency, data: TSchedule) =>
                getFrequencyList(frequencyLocale).find((item) => item.value === data.frequency)
                    ?.label,
            filterOptions: getFrequencyList(frequencyLocale)
        },
        {
            label: t('status'),
            key: 'status',
            render: (value: EScheduleStatus) =>
                getBillingStatusList(frequencyLocale).find((item) => item.value === value)?.label,
            filterOptions: getBillingStatusList(frequencyLocale)
        },
        {
            label: t('amount'),
            key: 'amount',
            render: (value: number) => <b>{`$${value.toFixed(2)}`}</b>
        },
        {
            label: 'Payments',
            key: 'remainingPayments',
            render: (value: number) => (value === -1 ? 'Infinite' : value)
        },
        {
            label: t('payment_date'),
            key: 'nextPaymentAt',
            render: (value: string) =>
                value ? moment(formatDateForMoment(value)).format('MM/DD/YYYY') : '-'
        },
        {
            label: t('actions'),
            key: 'actions',
            render: (_: undefined, data: TSchedule) => {
                return (
                    <div className={styles.table_actions}>
                        <Dropdown
                            items={[
                                {
                                    icon: 'open_eye',
                                    label: 'View details',
                                    onClick: () => {
                                        changeModalData({
                                            schedule: data,
                                            refetchSchedules: refetchPaymentsList,
                                            onEdit: (schedule: TSchedule) => {
                                                onClose();
                                                onEdit(schedule);
                                            }
                                        });
                                        onOpen({
                                            modalId: modalIds.VIEW_SCHEDULE
                                        });
                                    }
                                },
                                (data.status === EScheduleStatus.ACTIVE ||
                                    data.status === EScheduleStatus.INACTIVE) && {
                                    icon: data.status === EScheduleStatus.ACTIVE ? 'pause' : 'play',
                                    label:
                                        data.status === EScheduleStatus.ACTIVE ? 'Pause' : 'Resume',
                                    onClick: () => handlePauseResumePayment(data.id, data.status)
                                },
                                {
                                    icon: 'trash',
                                    isRed: true,
                                    label: 'Delete',
                                    onClick: () => handleCancelPayment(data.id)
                                }
                            ]}
                        />
                    </div>
                );
            }
        }
    ];

    return (
        <motion.div {...pageAnimation} className={styles.content}>
            {paymentsList.length === 0 ? (
                <div className={styles.empty}>
                    <img src={emptyIllustration} alt="empty payees" />
                    <p className={styles.title}>No bill payments have been scheduled yet</p>
                    <p className={styles.description}>
                        Bill payments you have scheduled will appear here
                    </p>
                    <Button
                        variant="primary"
                        size="normal"
                        onClick={() => navigate(routes.pay_bills_pay)}>
                        Schedule a bill payment
                    </Button>
                </div>
            ) : (
                <Table
                    //@ts-ignore
                    columns={columns}
                    rows={paymentsList}
                    emptyDescription={t('empty_payments')}
                    isActions
                    //@ts-ignore
                    filter={filter}
                    onFilterChange={(val) => {
                        onFilterChange({
                            ...filter,
                            ...(val?.paymentFrequency && {
                                //@ts-ignore
                                paymentFrequency: val.paymentFrequency as EScheduleFrequency[]
                            }),
                            ...(val?.status && {
                                //@ts-ignore
                                status: val.status as EScheduleStatus[]
                            })
                        });
                    }}
                />
            )}
        </motion.div>
    );
};

export default PaymentsTable;
